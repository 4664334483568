import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';

const SEAT_STATUS ={
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  SELECTED: 'selected',
  ASSIGNED: 'assigned'
}

function initSeatStatus({
  selected,
  selectSeats,
  number,
  availability
}) {
  if (selected) return SEAT_STATUS.ASSIGNED

  const selectedIndex = selectSeats.findIndex(item => item.number === number)
  if (selectedIndex > -1) {
    return SEAT_STATUS.SELECTED
  }

  if (availability === 'AVAILABLE') return SEAT_STATUS.AVAILABLE
  else return SEAT_STATUS.UNAVAILABLE
}

const Seat = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  // console.log('seat', props)
  // const color = props.availability === 'AVAILABLE' ? "#499167" : "#FE5F55";
//   const [color, setColor] = useState(
//     props.selected ? 
//     "#1976d2" : 
//     (
//       props.selectSeats.findIndex(item=>item.number === props.number) >= 0 ? 
//     "#ffc107" : 
//     (props.availability === 'AVAILABLE' ? "#499167" : "#FE5F55")
//     )
// )



  const [seatStatus, setSeatStatus] = useState(initSeatStatus({
    selected: props.selected,
    selectSeats: props.selectSeats,
    number: props.number,
    availability: props.availability
  }))

  // const style = {
  //   // position: "absolute",
  //   // left: `${props.y*3}em`, 
  //   // top: `${(props.x-1)*3}em`,
  //   // backgroundColor: color,
  //   // color: "white",
  //   // width: "2.8em",
  //   // height: "2.8em",
  //   cursor: props.availability === 'AVAILABLE' ? "pointer": "not-allowed"
  // }

  const seatClass = ['seat', seatStatus]

  if (!props.availability) {
    seatClass.push('seat-disabled')
  }

  const handleClick=()=>{
    if(props.availability  !== 'AVAILABLE'){
      return
    }

    // console.log('seat click', props.selectSeats, props.number);

    if(props.selectSeats.findIndex(item=>item.number === props.number) >= 0){
      props.removeSeat(props.number)
      // setColor("#499167")
      setSeatStatus(SEAT_STATUS.AVAILABLE)
    }else{
      console.log(props.selectSeats, props.max)
      if(props.selectSeats.length >= props.max){
        enqueueSnackbar(`more than ${props.max} seats`, {variant: 'warning',  autoHideDuration: 2000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
      }else{
        props.addSeat(props.data)
        // setColor("#ffc107")
        setSeatStatus(SEAT_STATUS.SELECTED)
      }
    }
  }

  return (
    <div className={seatClass.join(' ')} onClick={handleClick}>
      
{/* <p style={{fontSize: '0.8em', margin: 'auto'}}>{props.number}</p>
<small>${props.price}</small> */}
      <Typography variant="button" display="block" sx={{fontSize: '0.9em'}} className="no">
      { props.number}
      </Typography>
      <Typography variant="caption" display="block" sx={{ lineHeight: 1}} className="price">
        {props.price.toLocaleString('en', {
                        style: 'currency', 
                        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
      </Typography>
    </div>
  )
}

export default Seat;
