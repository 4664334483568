const IS_NDCS = [ 'flygndc','flxndc', 'ndctest','citndc','flxgndc']
const IS_DirectNDCS = [ 'flxndc', 'ndctest','citndc']
const NDC_AIRLINES = [
  { label: 'American Airlines', code: 'AA' },
  { label: 'Air France', code: 'AF' },
  { label: 'Air Canada', code: 'AC' },
  { label: 'British Airways', code: 'BA' },
  { label: 'Copa Airlines', code: 'CM' },
  { label: 'Emirates', code: 'EK' },
  { label: 'Iberia', code: 'IB' },
  { label: 'KLM Royal Dutch Airlines', code: 'KL' },
  // { label: 'Lufthansa Airlines', code: 'LH' },
  { label: 'Qantas Airlines', code: 'QF' },
  { label: 'Singapore Airlines', code: 'SQ' },
  { label: 'Taca Airlines', code: 'TA' },
  { label: 'United Airlines', code: 'UA' },
];

const USA_STATES = [{"code": "AK", "name": "Alaska"},{"code": "AL", "name": "Alabama"},{"code": "AR", "name": "Arkansas"},{"code": "AZ", "name": "Arizona"},{"code": "CA", "name": "California"},{"code": "CO", "name": "Colorado"},{"code": "CT", "name": "Connecticut"},{"code": "DC", "name": "Washington, D.C."},{"code": "DE", "name": "Delaware"},{"code": "FL", "name": "Florida"},{"code": "GA", "name": "Georgia"},{"code": "HI", "name": "Hawaii"},{"code": "IA", "name": "Iowa"},{"code": "ID", "name": "Idaho"},{"code": "IL", "name": "Illinois"},{"code": "IN", "name": "Indiana"},{"code": "KS", "name": "Kansas"},{"code": "KY", "name": "Kentucky"},{"code": "LA", "name": "Louisiana"},{"code": "MA", "name": "Massachusetts"},{"code": "MD", "name": "Maryland"},{"code": "ME", "name": "Maine"},{"code": "MI", "name": "Michigan"},{"code": "MN", "name": "Minnesota"},{"code": "MO", "name": "Missouri"},{"code": "MS", "name": "Mississippi"},{"code": "MT", "name": "Montana"},{"code": "NC", "name": "North Carolina"},{"code": "ND", "name": "North Dakota"},{"code": "NE", "name": "Nebraska"},{"code": "NH", "name": "New Hampshire"},{"code": "NJ", "name": "New Jersey"},{"code": "NM", "name": "New Mexico"},{"code": "NV", "name": "Nevada"},{"code": "NY", "name": "New York"},{"code": "OH", "name": "Ohio"},{"code": "OK", "name": "Oklahoma"},{"code": "OR", "name": "Oregon"},{"code": "PA", "name": "Pennsylvania"},{"code": "RI", "name": "Rhode Island"},{"code": "SC", "name": "South Carolina"},{"code": "SD", "name": "South Dakota"},{"code": "TN", "name": "Tennessee"},{"code": "TX", "name": "Texas"},{"code": "UT", "name": "Utah"},{"code": "VA", "name": "Virginia"},{"code": "VT", "name": "Vermont"},{"code": "WA", "name": "Washington"},{"code": "WI", "name": "Wisconsin"},{"code": "WV", "name": "West Virginia"},{"code": "WY", "name": "Wyoming"}]

export {IS_NDCS, IS_DirectNDCS, NDC_AIRLINES, USA_STATES}
