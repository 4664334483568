import {
  Grid,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import PersonIcon from '@mui/icons-material/Person';



export default function TravelerSeats(props) {
  console.log('traveler', props);
  const [checkedSeats, setCheckedSeats] = useState([]);

  const handleClickCheck = (flight) => {
    // console.log('click flight', flight)
    // console.log('click flights', checkedSeats)

    let newCheckedSeats = [...checkedSeats];
    let findIndex = newCheckedSeats.findIndex(
      (s) =>
        s.id === flight.segments[0].origin + flight.segments[0].destination,
    );
    let checked;
    if (findIndex >= 0) {
      checked = !newCheckedSeats[findIndex].checked;
      newCheckedSeats[findIndex].checked = checked;
    } else {
      newCheckedSeats.push({
        id: flight.segments[0].origin + flight.segments[0].destination,
        checked: true,
      });
      checked = true;
    }

    // console.log('new checked seats', checked, newCheckedSeats)
    // return
    setCheckedSeats(newCheckedSeats);
    props.onHandleDeleteSeat(props.passengerKey, flight, checked);
  };

  return (
     <Grid item xs={12} sx={{paddingTop: '10px'}}>
                    <Grid container sx={{marginBottom: '5px'}}>
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                  <Typography variant='h6'>
              <PersonIcon sx={{verticalAlign: 'text-bottom'}}/>

              {intl.get('BOOK.TRAVELER')}
              </Typography>
              <Divider/>
                  </Grid>
              <Grid item xs={6}>
              <Typography variant='h6'>
      {props.index}. {props.traveler.name}
          {` (${intl.get(
            'TRAVELER.' +
              (props.traveler.passengerTypeCode.toUpperCase() === 'ADT'
                ? 'ADULT'
                : 'CHILD'),
          )})`}
          
      </Typography>
      <Divider/>
              </Grid>

                </Grid>

              </Grid>

      {/* <legend className="block-fieldset__legend">
        {props.index}. {props.traveler.name}
        <span className="paxes-fieldset__type">
          &nbsp;
        </span>
      </legend> */}
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={3}>
              <Typography gutterBottom variant='subtitle2'>Segment</Typography>
            </Grid>
            <Grid item xs={3}>
            <Typography gutterBottom variant='subtitle2'>Selected</Typography>
              
            </Grid>
            <Grid item xs={3}>
              {props.showBookedDeleted && <Typography gutterBottom variant='subtitle2'>Booked</Typography>}
              
            </Grid>
            <Grid item xs={3}>
            {props.showBookedDeleted && <Typography gutterBottom variant='subtitle2'>Delete</Typography>}
              
            </Grid>
          </Grid>
        </Grid>
        {props.flights.map((flight) => (
          <Grid
            item
            xs={12}
            key={flight.segments[0].origin + flight.segments[0].destination}
          >
            <Grid container>
              <Grid item xs={3}>
                <Typography variant='button' align="center" gutterBottom>
                  {flight.segments[0].origin +
                    '-' +
                    flight.segments[0].destination}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="button" display="block" gutterBottom>
                  {flight.selectedSeat && flight.selectedSeat.number}
                  {/* {flight.selectedSeat &&
                    flight.selectedSeat.number &&
                    flight.areadySelectedSeat.number &&
                    ' (updating)'} */}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {flight.selectedSeat &&
                    '$' +
                      flight.selectedSeat.seat.OfferItems.find(
                        (item) => item.PassengerRef === props.passengerKey,
                      ).TotalPrice}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="button" display="block" gutterBottom>
                  {flight.areadySelectedSeat.number}
                  {/* {flight.areadySelectedSeat.number && ' (booked)'} */}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {flight.areadySelectedSeat.seat &&
                    flight.areadySelectedSeat.seat.OfferItems.find(
                      (item) => item.PassengerRef === props.passengerKey,
                    ).TotalPrice.toLocaleString('en', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {flight.areadySelectedSeat.number && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          checkedSeats.find(
                            (s) =>
                              s.id ===
                              flight.segments[0].origin +
                                flight.segments[0].destination,
                          )
                            ? checkedSeats.find(
                                (s) =>
                                  s.id ===
                                  flight.segments[0].origin +
                                    flight.segments[0].destination,
                              ).checked
                            : false
                        }
                        onChange={() => handleClickCheck(flight)}
                      />
                    }
                    label="Delete"
                  />
                )}
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        ))}
      </Grid>
      </Grid>
  );
}
