function convertSeatStatus(status) {
  if (status == 'A') {
    return 'AVAILABLE';
  } else if (status == 'N') {
    return 'UNAVAILABLE';
  }

  return status;
}

const process=(json, segments)=>{
  console.log('seat map start process', json)
  if(segments){
    for(let segment of segments){
      for(let seatMapResonse of json.seatMapResponse.seatMapResponses){
        if(segment.departureAirportCode === seatMapResonse.FlightSegments[0].origin 
          && segment.arrivalAirportCode === seatMapResonse.FlightSegments[0].destination){
            segment.seatMap = seatMapResonse
          }
      }
    }

    if(segments.every(s=>s.seatMap)){
      json.seatMapResponse.seatMapResponses = segments.map(s=>s.seatMap)
    }
  }

  console.log('seat map end process', json)
  

  for (let flight of json.seatMapResponse.seatMapResponses) {
    let seatNum = [];
    for (let seatMapDetail of flight.SeatMapDetails) {
      for (let airRow of seatMapDetail.airRows) {
        for (let airSeat of airRow.airSeats) {
          let alphabet = airSeat.seatNumber.substring(
            airSeat.seatNumber.length - 1,
          );
          if (seatNum.indexOf(alphabet) < 0) {
            seatNum.push(alphabet);
          }
        }
      }
    }
    seatNum.sort();

    for (let seatMapDetail of flight.SeatMapDetails) {
      for (let airRow of seatMapDetail.airRows) {
        for (let i = 0; i < airRow.airSeats.length; i++) {
          let alphabet = airRow.airSeats[i].seatNumber.substring(
            airRow.airSeats[i].seatNumber.length - 1,
          );
          if (i > 0) {
            if (
              airRow.airSeats[i].seatCharacteristics &&
              airRow.airSeats[i].seatCharacteristics
                .split(',')
                .indexOf('A') >= 0 &&
              airRow.airSeats[i - 1].seatCharacteristics &&
              airRow.airSeats[i - 1].seatCharacteristics
                .split(',')
                .indexOf('A') >= 0
            ) {
              if (
                seatNum.indexOf(alphabet) - 1 >= 0 &&
                seatNum[seatNum.indexOf(alphabet) - 1] != 'x'
              )
                seatNum.splice(seatNum.indexOf(alphabet), 0, 'x');
            }
          }
        }
      }
    }

    let deck = {
      deckConfiguration: {
        width: 7,
        length: 30,
        startseatRow: 14,
        endSeatRow: 38,
        startWingsRow: 0,
        endWingsRow: 0,
        startWingsX: 0,
        endWingsX: 0,
        exitRowsX: [],
      },
      facilities: [],
      seats: [],
      id: json.seatMapResponse.shoppingResponseID,
      selectedSeatNumbers: [],
    };
    // let selectedSeatNumbers = [];
    for (let traveler of flight.TravelerIdentifiers) {
      if (traveler.AreadySelectedSeatNumber) {
        deck.selectedSeatNumbers.push(traveler.AreadySelectedSeatNumber);
      }
    }

    // console.log('deck', deck)

    let x = 1;
    for (let i = 0; i < flight.SeatMapDetails.length; i++) {
      if (i > 0) {
        x +=
          parseInt(flight.SeatMapDetails[i].StartRowNumber) -
          parseInt(flight.SeatMapDetails[i - 1].EndRowNumber) -
          1;
      }

      for (let airRow of flight.SeatMapDetails[i].airRows) {
        for (let j = 0; j < airRow.airSeats.length; j++) {
          

          let selected = false;
          if (
            deck.selectedSeatNumbers.indexOf(
              airRow.rowNumber + airRow.airSeats[j].seatNumber,
            ) >= 0
          ) {
            selected = true;
          }

          // console.log(airRow, airRow.airSeats[j])
          let seat = {
            cabin: 'ECONOMY',
            colNo: airRow.airSeats[j].seatNumber,
            rowNo: airRow.rowNumber,
            number: airRow.rowNumber + airRow.airSeats[j].seatNumber,
            characteristicsCodes: airRow.airSeats[j].seatCharacteristics
              ? airRow.airSeats[j].seatCharacteristics.split(',')
              : [],
            travelerPricing: [
              {
                id: '1',
                seatAvailabilityStatus: airRow.airSeats[j]
                  .seatAvailabilityStatus
                  ? convertSeatStatus(
                      airRow.airSeats[
                        j
                      ].seatAvailabilityStatus.toUpperCase(),
                    )
                  : airRow.airSeats[j].seatAvailabilityStatus,
                price: {
                  currency: airRow.airSeats[j].OfferItems ? airRow.airSeats[j].OfferItems[0].CurrencyCode : 'USD',
                  total: airRow.airSeats[j].OfferItems ? airRow.airSeats[j].OfferItems[0].TotalPrice : 0,
                },
              },
            ],
            coordinates: {
              x: x,
              y: seatNum.indexOf(
                airRow.airSeats[j].seatNumber.substring(
                  airRow.airSeats[j].seatNumber.length - 1,
                ),
              ),
            },
            selected: selected,
            isExitRow: false,
            isOverWings: false
          };

          // 判断是否是出口座位
          const seatCharacteristicsList = airRow.airSeats[j].seatCharacteristics.split(',')
          if (seatCharacteristicsList.indexOf('E') > -1) {
            seat.isExitRow = true
          }
          if (seatCharacteristicsList.indexOf('OW') > -1) {
            seat.isOverWings = true
          }


          deck.seats.push(seat);
        }

        x++;
      }
    }

    deck.deckConfiguration.width =
      Math.max.apply(
        Math,
        deck.seats.map((item) => item.coordinates.y),
      ) + 1;
    deck.deckConfiguration.length =
      deck.seats[deck.seats.length - 1].coordinates.x;
    deck.segments = flight.FlightSegments;
    // deck.travelers = flight.TravelerIdentifiers

    deck.seatNumbers = [...seatNum]
    flight.deck = deck;
  }

  // return json
}


export default {process}
