import { Alert, Stack, Button, Typography } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ProgressBox from '../../components/ProgressBox';
import CloseIcon from '@mui/icons-material/Close';
import intl from 'react-intl-universal';


export default function Share() {
    const location = useLocation();
    const navigate = useNavigate();
    const search = location.search;
    const [loaded, setLoaded] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const {key} = useParams();
    const [alertType, setAlertType] = useState("error")
    const [sourceType, setSourceType] = useState(null)
    // const history = useHistory();

    // console.log("share location", location);
    // console.log("key", key);
    
    // console.log("navigate", navigate);
    useEffect(()=>{
      if(location.state != null && "share" in location.state)
      {
        let clientName = null
        if(sessionStorage.getItem("userInfo") != null){
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
          if(!!userInfo.ClientBusinessName && userInfo.ClientBusinessName != "null"){
            clientName = userInfo.ClientBusinessName
          }

          if(userInfo.type){
            setSourceType(userInfo.type)
          }
        }

        document.title = (!clientName ? "": `(${clientName})`) + intl.get("SHARE.TITLE")
        setErrorMsg(intl.get("SHARE.TIP"));
        setAlertType("info")
        setLoaded(true)
//#region 
  //       const ua = window.navigator.userAgent.toLowerCase();
	// 		  if (ua.indexOf('micromessenger') < 0) return
  //       let params = {website: window.location.href.split('#')[0]}
	// 	    // alert(JSON.stringify(params));

  //       fetch('../api/wechat', { 
	// 		  method: 'POST',
	// 		  headers: {
	// 			  'Content-Type': 'application/json; charset=utf-8'
	// 		  },
	// 		  body: JSON.stringify(params)
	// 		})
  //   .then(response => response.json())
  //   .then(data =>{
  //     console.log(data)
  //     // alert(data);
  //     const json = JSON.parse(data)
  //     console.log("signature", json.signature);
	// 		wx.config({
	// 	  debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
	// 	  appId: json.appId, // 必填，公众号的唯一标识
	// 	  timestamp: json.timestamp, // 必填，生成签名的时间戳
	// 	  nonceStr: json.nonceStr, // 必填，生成签名的随机串
	// 	  signature: json.signature,// 必填，签名
	// 	  jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的 JS 接口列表
	// 	})

	// 	// console.log("config", wx);
	// 	wx.checkJsApi({
	// 	  jsApiList: ['updateAppMessageShareData'], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
	// 	  success: function (res) {
	// 		  console.log(res);
	// 		  // 以键值对的形式返回，可用的 api 值true，不可用为false
	// 		  // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
	// 	  }
	//   });

  //   // alert(`link: ${window.location.href.split('#')[0]}`)
  //   // alert(`img url: ${window.location.protocol}//${window.location.host}/images/500_1.jpg`)
  //   				wx.updateAppMessageShareData({
	// 				title: 'Flight-shops', // 分享标题
	// 				desc: 'Share a ticket search with others', // 分享描述
	// 				link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
	// 				imgUrl: `${window.location.protocol}//${window.location.host}/images/500_1.jpg`, // 分享图标
	// 				success: function () {
	// 					// 设置成功
	// 					console.log("success");
	// 					// alert("已注册分享，请点击右上角选择分享");
	// 				}
	// 			})
			
	// 			// wx.updateTimelineShareData({
	// 			//   title: 'demo title', // 分享标题
	// 			//   desc: 'demo desc', // 分享描述
	// 			//   link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
	// 			//   imgUrl: `${window.location.protocol}//${window.location.host}/images/500_1.jpg`, // 分享图标
	// 			//   success: function () {
	// 			// 	  // 设置成功
	// 			// 	  console.log("success");
	// 			// 	//   alert("已注册分享到朋友圈，请点击右上角选择分享");
	// 			//   }
	// 		  // })

  //   	  wx.error(function (res) {
	// 	console.error(res);
	// 	// config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
	// });
	  // }).catch(error=>{console.error(error); alert(error)});
    //#endregion
    
      }else{
        if(!key){
          setErrorMsg("DATA NOT FOUND");
        }else{
          // console.log("request share api");
          fetch('../api/share?key='+ key)
          .then(response => response.json())
          .then(data =>{
            // console.log("share data", data);
            const json = JSON.parse(data);
            try{
              const routing = JSON.parse(json.routing);
              const travelers = JSON.parse(json.travelers);
              setLoaded(true)
                      navigate('/Book', {state:{routing: JSON.parse(json.routing), travelers: JSON.parse(json.travelers), hideBookButton: false, 
                        currency: json.currency, shareInfo: json.shareInfo,
                        searchInfo: json.searchInfo
                      }});
                }
                catch(err){
                    setErrorMsg(err);
                }
          })
          .catch(err=>{console.error(err); setLoaded(true);setErrorMsg(err)});
        }
      }
    },[])

    function handleClose(){
      navigate(-1)
    }

    function copyLink(){
      let link = "https://wechat.thetraveloutlet.com"+location.pathname;

      navigator.clipboard.writeText(link)
        .then(() => {
          alert("Copied");
        // console.log("Text copied to clipboard...")
    })
        .catch(err => {
          alert("Copy failed");
        // console.log('Something went wrong', err);
    })
    }

  return (
//     loaded ?(sourceType == "traveloutlet" ?  <Stack spacing={3}>
// <Typography>
//       <a href={"https://wechat.thetraveloutlet.com"+location.pathname} target="_blank">{"https://wechat.thetraveloutlet.com"+location.pathname}</a>
//       </Typography>
//       <Button variant="contained" color="primary" onClick={copyLink}>
//     {intl.get("SHARE.COPY_LINK")}
//   </Button>
//   <Button variant="outlined" color="error" startIcon={<CloseIcon />} onClick={handleClose}>
//     Close
//   </Button>
//     </Stack> : (errorMsg != null ?<Stack spacing={2}>
//       <Alert severity={alertType}>{errorMsg}</Alert>
      
//       <Button variant="outlined" color="error" startIcon={<CloseIcon />} onClick={handleClose}>
//     Close
//   </Button></Stack>: <></>)) : <ProgressBox/>

loaded ?(errorMsg != null ?<Stack spacing={2}>
      <Alert severity={alertType}>{errorMsg}</Alert>
      
      <Button variant="outlined" color="error" startIcon={<CloseIcon />} onClick={handleClose}>
    Close
  </Button></Stack>: <></>) : <ProgressBox/>
  )
}
