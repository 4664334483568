import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import el from 'date-fns/esm/locale/el/index.js';
import axios from 'axios';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchHotelInput from '../SearchHotelInput';


const englishpattern = new RegExp("[A-Za-z]+");


export default function SearchHotel() {
    const fromRef = useRef(null)
    const checkInRef = useRef(null)
    const checkOutRef = useRef(null)
    const roomsRef = useRef(null)
    const adultsRef = useRef(null)
    const childrenRef = useRef(null)
    const nagative = useNavigate()

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const selectedHotel=(item)=>{
        // console.log('selected', item)
        setSelectedValue(item)
        setOpen(false)
    }
    
    // const defaultOptions = [{label: '洛杉矶', longitude: '-118.24437', latitude: '34.05593'},
    // {label: '纽约', longitude: '-74.00666', latitude: '40.71643'},
    // {label: '新加坡', longitude: '103.87546', latitude: '1.30243'},
    // {label: '首尔', longitude: '126.97937', latitude: '37.56403'},
    // {label: '东京', longitude: '139.78738', latitude: '35.6792'},
    // {label: '大阪', longitude: '135.51058', latitude: '34.68569'},
    // {label: '迪拜', longitude: '54.9472934', latitude: '25.0757595'},
    // {label: '巴黎', longitude: '2.29363', latitude: '48.87372'},
    // {label: '伦敦', longitude: '-0.12563', latitude: '51.50568'},
    // {label: '曼谷', longitude: '100.56033', latitude: '13.79156'},
    // {label: '普吉岛', longitude: '98.3285753', latitude: '7.8537497'},
    // {label: '芭提雅', longitude: '100.8740972', latitude: '12.9002587'},
    // {label: '吉隆坡', longitude: '101.71288', latitude: '3.15618'},
    // ]
    // const [options, setOptions] = useState(defaultOptions)
    const [selectedValue, setSelectedValue] = useState(null)
   
  const handleClick = ()=>{
    // console.log(`FROM: ${fromRef.current.value}, IN: ${checkInRef.current.value}, OUT: ${checkOutRef.current.value},
    // ROOMS: ${roomsRef.current.value}, ADULTS: ${adultsRef.current.value}, CHILDREN: ${childrenRef.current.value}`);
    if(!selectedValue) {alert('Going to ?'); 
        return
    }else{
    nagative('/hotel'+`?longitude=${selectedValue.longitude}&latitude=${selectedValue.latitude}&checkin=${checkInRef.current.value}&checkout=${checkOutRef.current.value}&rooms=${roomsRef.current.value}&adults=${adultsRef.current.value}&children=${childrenRef.current.value}`)
    }
  }  
  return (
    <div className="col mb-2">

        <div className="form-row">
            <div className="col-md-12 col-lg form-group">
            {/* <Autocomplete
            filterOptions={(x) => x}
            onChange={(even, value)=>{console.log('on change', value), setSelectedValue(value)}}
            onInputChange={(event, newInputValue) => {
                console.log(newInputValue);
                setOptions([])
                let newOptions = []

                if(newInputValue){
                    let url = 'api/SearchHotelWheres?where=' + newInputValue
                    if (!englishpattern.test(newInputValue)) {
						url += "&lang=cn";
					}
                    axios.get(url)
                    .then(function (res) {
                        console.log(res)
                        let json = JSON.parse(res.data)
                        let exists = []
                        for(let item of json){
                            if(!item.name || !item.countryname || !item.Longitude || !item.Latitude){
                                continue
                            }

                            if(exists.indexOf(`${item.name},${item.countryname}`) >= 0){
                                continue
                            }else{
                                exists.push(`${item.name},${item.countryname}`)
                            }

                            newOptions.push({label: `${item.name},${item.countryname}`, longitude: item.Longitude, latitude: item.Latitude})
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    }).then(function () {
                        console.log('new options', newOptions);
                        setOptions(newOptions)
                    });
                }else{
                    setOptions(defaultOptions)
                }
              }}
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label="目的地" inputRef={fromRef} />}
    /> */}
                {/* <input type="hidden" name="hotel-from" className="hidden-flight" id="hotel-from" defaultValue=""/> */}
                <input onClick={()=> setOpen(true)} value={selectedValue ? selectedValue.label: ""} onChange={()=>console.log('change', selectedValue)} name="hotelsFrom" autoComplete='off' ref={fromRef} type="text" id="hotelsFrom" className="form-control airport-input ui-autocomplete-input" placeholder="输入城市 / 酒店"/>
                {/* <span className="icon-inside"><i className="fas fa-map-marker-alt"></i></span> */}
            </div>
            <div className="col-md-6 col-lg form-group">
                <input name="CheckIn" ref={checkInRef} type="text" id="CheckIn" className="form-control" autoComplete="off" required="required" placeholder="入住日期"/>
                <span className="icon-inside"><i className="far fa-calendar-alt"></i></span>
            </div>
            <div className="col-md-6 col-lg form-group">
                <input name="CheckOut" ref={checkOutRef} type="text" id="CheckOut" className="form-control" autoComplete="off" required="required" placeholder="退房日期"/>
                <span className="icon-inside"><i className="far fa-calendar-alt"></i></span>
            </div>
            <div className="col-md-6 col-lg travellers-class form-group">
                <input name="hotels_Travellers_Class" type="text" id="hotels_Travellers_Class" className="travellers-class-input form-control" autoComplete="off" placeholder="房间 / 旅客" required="required" onKeyPress={()=>{return false}} defaultValue="1 房间 / 1 旅客"/>
                <span className="icon-inside"><i className="fas fa-caret-down"></i></span>
                <div className="travellers-dropdown travellers-dropdown" style={{display: "none"}}>
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                            <p className="mb-sm-0">
                                房间
                            </p>
                        </div>
                        <div className="col-sm-5">
                            <div className="qty input-group">
                                <div className="input-group-prepend">
                                    <button type="button" className="btn bg-light-4" data-value="decrease" data-target="#only-hotels-rooms" data-toggle="spinner">-</button>
                                </div>
                                <input type="text" ref={roomsRef} data-ride="spinner" id="only-hotels-rooms" name="only-hotels-rooms" className="qty-spinner form-control" defaultValue="1" readOnly/>
                                <div className="input-group-append">
                                    <button type="button" className="btn bg-light-4" data-value="increase" data-target="#only-hotels-rooms" data-toggle="spinner">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 mb-4"/>
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                            <p className="mb-sm-0">
                                成人
                                <small className="text-muted">
                                    (12+ 岁)</small>
                            </p>
                        </div>
                        <div className="col-sm-5">
                            <div className="qty input-group">
                                <div className="input-group-prepend">
                                    <button type="button" className="btn bg-light-4" data-value="decrease" data-target="#hoteladulttravellers" data-toggle="spinner">-</button>
                                </div>
                                <input name="hoteladulttravellers" ref={adultsRef} type="text" id="hoteladulttravellers" data-ride="spinner" className="qty-spinner form-control" defaultValue="1" />
                                <div className="input-group-append">
                                    <button type="button" className="btn bg-light-4" data-value="increase" data-target="#hoteladulttravellers" data-toggle="spinner">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-2"/>
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                            <p className="mb-sm-0">
                                小孩
                                <small className="text-muted">
                                    (1-12 岁)</small>
                            </p>
                        </div>
                        <div className="col-sm-5">
                            <div className="qty input-group">
                                <div className="input-group-prepend">
                                    <button type="button" className="btn bg-light-4" data-value="decrease" data-target="#hotelchildrentravellers" data-toggle="spinner">-</button>
                                </div>
                                <input name="hotelchildrentravellers" ref={childrenRef} type="text" id="hotelchildrentravellers" data-ride="spinner" className="qty-spinner form-control" defaultValue="0" />
                                <div className="input-group-append">
                                    <button type="button" className="btn bg-light-4" data-value="increase" data-target="#hotelchildrentravellers" data-toggle="spinner">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary btn-block submit-done mt-3" type="button">
                        完成</button>
                </div>
            </div>
            <div className="col-md-6 col-lg d-grid form-group">
                <button className="btn btn-primary btn-block btn-submit" type="submit" onClick={handleClick}>
                    查询</button>
            </div>
        </div>
        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle>
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <SearchHotelInput selectedHotel={selectedHotel}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
