import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import TripDetail from '../../components/TripDetail';
import intl from 'react-intl-universal';
import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
  AppBar,
  Skeleton,
  Stack,
  Button,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Alert,
  Toolbar,
  Paper,
  Grid,
  Card,
  DialogActions,
  Backdrop,
  CircularProgress,
  ListItemText,
  FormControlLabel,
  Switch,
  Fade,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import moment from 'moment';
import Search from '../Search';

import OrderSummary from '../../components/OrderSummary';
import Contact from '../../components/Contact';
import Traveler from '../../components/Traveler';
import PaymentDetail from '../../components/PaymentDetail';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BasicCard from '../../components/BasicCard';
import PersistentDrawerLeft from '../../components/PersistentDrawerLeft';
import { set } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import MyTabs from '../../components/MyTabs';
import RecipeReviewCard from '../../components/RecipeReviewCard';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import SeatDialog from '../../components/SeatDemo';
import SeatDemo from '../../components/SeatDemo';
import FlightClassIcon from '@mui/icons-material/FlightClass';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import Bundle from '../../components/Bundle';
import ButtonAppBar from '../../components/ButtonAppBar';
import { loadCss } from '../../common/helper.js';
import Crypto from '../../utils/ctypto.js';
import { IS_DirectNDCS } from '../../data/config';
import seatmapHelper from '../../utils/seatmapHelper.js';
import Seats from '../../components/Seats/index.jsx';
import { LoadingButton } from '@mui/lab';
import {getSegments} from '../../utils/utils.js'

const HIDE_SEAT_AIRLINES = ['CM', 'EK'];

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    // console.log('key', key, 'key1', keys1)

    if (
      keys1.indexOf('airlineCode') >= 0 &&
      (key != 'departureAirportCode' ||
        key != 'arrivalAirportCode' ||
        key != 'flightNumber' ||
        key != 'airlineCode')
    ) {
      continue;
    }

    const val1 = object1[key];
    const val2 = object2[key];
    //   console.log('equal', val1, val2)
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

function convertSeatStatus(status) {
  if (status == 'A') {
    return 'AVAILABLE';
  } else if (status == 'N') {
    return 'UNAVAILABLE';
  }

  return status;
}

function removeNonNumeric(str) {
  if(!str)
    return ''
  return str.replace(/\D/g, '');
}

const SearchSeat = (seatNumber, seatMapDetails) => {
  let seat = null;
  if (seatNumber && seatMapDetails) {
    let rowNum = seatNumber.substring(0, seatNumber.length - 1);
    let columnNum = seatNumber.substring(seatNumber.length - 1);
    for (let seatMapDetail of seatMapDetails) {
      for (let airRow of seatMapDetail.airRows) {
        for (let airSeat of airRow.airSeats) {
          if (airRow.rowNumber === rowNum && airSeat.seatNumber === columnNum) {
            seat = airSeat;
          }
        }
      }
    }
  }

  return seat;
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function FlightDetail(props) {
  const { enqueueSnackbar } = useSnackbar();
  console.log("book", props)
  const navigate = useNavigate();
  const methods = useForm();
  const [progress, setProgress] = useState(false);
  const [markup, setMarkup] = useState(0);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [tripData, setTripData] = useState(props.routing);
  // const [bundle, setBundle] = useState(null)
  // const [checkboxStates, setCheckboxStates] = useState(props.prebookingInfo && props.prebookingInfo.serviceList ? new Array(props.prebookingInfo.serviceList.length).fill(false) : null)
  const [selectdFareKey, setSelectedFareKey] = useState(props.routing.key);
  const [seatDialogOpen, setSeatDialogOpen] = useState(false);
  const [backDropOpen, setBackdropOpen] = useState(false);
  const [seatmaps, setSeatmaps] = useState(null);
  // const [traverlers, setTravelers] = useState([])
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [submitSeats, setSubmitSeats] = useState([]);
  const [prebookings, setPrebookings] = useState([
    { key: props.routing.key, prebooking: props.prebookingInfo },
  ]);
  const [isPrebooking, setIsPrebooking] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [upsellLoading, setUpsellLoading] = useState(false);
  const [upsellRoutings, setUpsellRoutings] = useState(null);
  const [services, setServices] = useState(null);
  const [bundles, setBundles] = useState([]);
  const [bundleOpen, setBundleOpen] = useState(false);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [submitBundles, setSubmitBundles] = useState([]);
  const [passengerToBundles, setPassengerToBundles] = useState([]);
  const [showSeat, setShowSeat] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [openSeat, setOpenSeat] = useState(false);
  const [seatsActiveStep, setSeatsActiveStep] = useState(0);
  const [seatsCount, setSeatsCount] = useState(0);
  const [showSeatSubmit, setShowSeatSubmit] = useState(false);
  const [travelers, setTravelers] = useState([]);
  const [loadingSeatmap, setLoadingSeatmap] = useState(false);

  const [loadingBookSeat, setLoadingBookSeat] = useState(false);
  const [bundlesCount, setBundlesCount] = useState(0);
  const [showServicesSubmit, setShowServicesSubmit] = useState(false);
  const [servicesActiveStep, setServicesActiveStep] = useState(0);
  const [loadingBookServices, setLoadingBookServices] = useState(false);

  // const [checked, setChecked] = useState(false)

  // useEffect(()=>{
  // 	console.log('effect seatmaps', seatmaps)
  // },[seatmaps])
  // const [submit]
  // const rules = [{name: 'Fare', messages: props.fareRules}]
  // if(props.routing.brand && props.routing.brands){
  // 	rules.push({name: 'Brand', messages: props.round.brand.brands.map((brand)=> `${attr.name}: ${attr.value}`)})
  // }

  // if(props.NDCRule){
  // 	for(let NDCRuleKey in props.NDCRule){
  // 		let NDCRule = {name: NDCRuleKey}
  // 		if(NDCRuleKey === 'penalty'){
  // 			for(let penalty of NDCRuleKey[NDCRuleKey]){
  // 				for()
  // 			}
  // 		}
  // 	}
  // }

  let GDSPrice = null;

  if (props.fullData) {
    for (let full of props.fullData) {
      if (deepEqual(props.routing.trips, full.trips)) {
        GDSPrice = full.adultBasePrice + full.adultTax;
        break;
      }
    }
  }

  useEffect(() => {
    // console.log('effect')
  }, [tripData, selectdFareKey]);

  useEffect(() => {
    if (
      props.prebookingInfo &&
      IS_DirectNDCS.includes(props.routing.cid) &&
      props.prebookingInfo.offerInfo
    ) {
      setUpsellLoading(true);

      axios
        .post('api/PrebookingOnly', {
          value: Crypto.encrypt(
            'upsell?' +
              new URLSearchParams({
                cid: props.routing.cid,
                customerSessionId: props.prebookingInfo.customerSessionId,
                gds: props.prebookingInfo.gds.toUpperCase(),
                pcc: props.prebookingInfo.pcc,
                adults: props.travelers.adults,
                children: props.travelers.children,
              }).toString() +
              '&offer=' +
              JSON.stringify(props.prebookingInfo.offerInfo),
          ),
        })
        .then(function (res) {
          // console.log(res)
          // var json = JSON.parse(res.data);
          // console.log('upsell', json);
          if ('APIError' in res.data) {
            for (let error of res.data.APIError.errors) {
              enqueueSnackbar(error.message, {
                variant: 'error',
                autoHideDuration: 6000,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
              });
              break;
            }
          } else {
            setUpsellRoutings(res.data.airTicketRuleResponse.upsellRoutings);
          }
        })
        .catch(function (error) {
          // console.error(error);
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        })
        .then(function () {
          setUpsellLoading(false);
        });
    }
  }, []);

  const requestServices = (value) => {
    setServiceLoading(true);
    setServices(null);

    axios
      .post('api/PrebookingOnly', { value: Crypto.encrypt(value) })
      .then(function (res) {
        console.log('res', res)
        // var json = JSON.parse(res.data);
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
            break;
          }
        } else {
          console.log('services', res.data.airTicketRuleResponse.serviceList);
          setServices(res.data.airTicketRuleResponse.serviceList);
          let segments = [];
          for (let serviceList of res.data.airTicketRuleResponse.serviceList) {
            for (let segment of serviceList.routings) {
              if (
                segments.findIndex(
                  (seg) =>
                    seg.departureAirportCode === segment.DepartureAirport &&
                    seg.arrivalAirportCode === segment.ArrivalAirport,
                ) >= 0
              ) {
                continue;
              }

              segments.push({
                departureAirportCode: segment.DepartureAirport,
                arrivalAirportCode: segment.ArrivalAirport,
              });
            }
          }

          let bundles = [];
          let filterServices =
            res.data.airTicketRuleResponse.serviceList &&
            res.data.airTicketRuleResponse.serviceList.length > 0 &&
            res.data.airTicketRuleResponse.serviceList.filter(
              (item) => item.name !== 'FareLock',
            );
          for (let segment of segments) {
            let newServices = [];
            if (filterServices) {
              for (let service of filterServices) {
                let matched = false;

                for (let routing of service.routings) {
                  if (
                    segment.departureAirportCode === routing.DepartureAirport &&
                    segment.arrivalAirportCode === routing.ArrivalAirport
                  ) {
                    matched = true;
                  }
                }

                if (matched) {
                  newServices.push(service);
                }
              }
            }

            if (newServices.length === 0) {
              continue;
            }

            let passengers = [];

            for (let service of newServices) {
              for (let offerItem of service.offerInfo.offerItems) {
                let passengerIndex = passengers.findIndex(
                  (item) => item.passengeRefs === offerItem.passengeRefs,
                );
                if (passengerIndex < 0) {
                  passengers.push({
                    passengeRefs: offerItem.passengeRefs,
                    passenger: { type: service.passengerTypes },
                    services: [service],
                  });
                } else {
                  passengers[passengerIndex].services.push(service);
                }
              }
            }

            // console.log('passengers', passengers)

            bundles.push({
              segment: {
                departureAirportCode: segment.departureAirportCode,
                arrivalAirportCode: segment.arrivalAirportCode,
              },
              passengers,
            });
          }

          console.log('bundles length', bundles)
          setBundlesCount(bundles.length);
          if (bundles.length <= 1) {
            setShowServicesSubmit(true);
          }
        }
      })
      .catch(function (error) {
        console.error(error);
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setServiceLoading(false);
      });
  };

  useEffect(() => {
    if (
      props.prebookingInfo &&
      IS_DirectNDCS.includes(props.routing.cid) &&
      props.prebookingInfo.offerInfo &&
      props.routing.mainAirlineCode != 'EK'
    ) {
      requestServices(
        'service?' +
          new URLSearchParams({
            cid: props.routing.cid,
            customerSessionId: props.prebookingInfo.customerSessionId,
            gds: props.prebookingInfo.gds.toUpperCase(),
            pcc: props.prebookingInfo.pcc,
            adults: props.travelers.adults,
            children: props.travelers.children,
          }).toString() +
          '&offer=' +
          JSON.stringify(props.prebookingInfo.offerInfo),
      );
    }
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // const { register, handleSubmit, formState: { errors } } = useForm();

  // const { register, handleSubmit, watch, formState: { errors } } = useForm();

  // console.log('method', methods.formState.errors)
  const onSubmit = (data) => {
    if(data.contact && data.contact.countryCode){
      data.contact.country = data.contact.countryCode
    }

    if(data.contact && data.contact.stateName && !data.contact.state){
      data.contact.state = data.contact.stateName
    }
    // console.log('book submit', submitSeats)
    // console.log('seatmap', seatmaps)
    
    console.log(' submit data', data)
    // return
    setDisabled(true);
    let passengers = [];
    for (let travelerType in data.traveler) {
      for (let traveler of data.traveler[travelerType]) {
        passengers.push({
          firstName: traveler.firstName,
          middleName: traveler.middleName,
          lastName: traveler.lastName,
          type:
            traveler.travelerType && traveler.travelerType == 'student'
              ? 'student'
              : travelerType,
          birthday: traveler.birthday,
          gender: traveler.sex,
          nationality: traveler.idIssueCountry || null,
          idIssueCountry: traveler.idIssueCountry || null,
          idType: traveler.idNumber ? 'P' : null,
          idNumber: traveler.idNumber || null,
          idExpirationDate: traveler.idExpirationDate || null,
          selectedType: traveler.travelerType || null,
          loyaltyPrograms: traveler.flyerProgram && traveler.flyerNumber ? {airline: traveler.flyerProgram, accountNumber: traveler.flyerNumber} : null
        });
      }
    }

    // console.log('passengerToBundles', passengerToBundles)
    for (let passengerToBundle of passengerToBundles) {
      if (
        passengerToBundle.passengeRefs &&
        passengerToBundle.passengeRefs.length >= 2
      ) {
        let passengerIndex =
          parseInt(removeNonNumeric(passengerToBundle.passengeRefs)) - 1;
        if (passengers[passengerIndex]) {
          for (let bundleItem of passengerToBundle.bundles) {
            for (let offerItem of bundleItem.offerInfo.offerItems) {
              let selections = [];
              selections.push({
                serviceid: offerItem.serviceId,
                serviceDefinitionId: offerItem.serviceDefinitionId,
              });

              let service = {
                shoppingResponseId: bundleItem.offerInfo.responseId,
                offerId: bundleItem.offerInfo.offerId,
                owner: bundleItem.offerInfo.owner,
                offerItemRef: offerItem.offerItemId,
                passengerRef: offerItem.passengeRefs,
                baseFare: bundleItem.baseAmount,
                tax: bundleItem.taxAmount,
                totalPrice: bundleItem.totalAmount,
                serviceName: bundleItem.name,
                selections: selections,
              };

              if (passengers[passengerIndex].services) {
                passengers[passengerIndex].services.push(service);
              } else {
                passengers[passengerIndex].services = [service];
              }
            }
          }
        }
      }
    }

    if (submitSeats.length > 0) {
      for (let i = 0, len = passengers.length; i < len; i++) {
        let seats = [];
        for (let [index, submit] of submitSeats.entries()) {
          if (submit.seats && submit.seats.length > i) {
            let rowNumber = submit.seats[i].number.substring(
              0,
              submit.seats[i].number.length - 1,
            );
            let seatNumber = submit.seats[i].number.substring(
              submit.seats[i].number.length - 1,
            );
            for (let seatMapDetail of seatmaps.seatMapResponse.seatMapResponses[
              index
            ].SeatMapDetails) {
              for (let airRow of seatMapDetail.airRows) {
                if (airRow.rowNumber == rowNumber) {
                  for (let airSeat of airRow.airSeats) {
                    if (airSeat.seatNumber == seatNumber) {
                      for (let offerItem of airSeat.OfferItems) {
                        if (offerItem.PassengerRef == offerItem.PassengerRef.replace(/[0-9]/ig,"") + (i + 1)) {
                          let seat = {
                            shoppingResponseId:
                              seatmaps.seatMapResponse.shoppingResponseID,
                            offerId: seatmaps.seatMapResponse.offerID,
                            owner: seatmaps.seatMapResponse.owner,
                            offerItemRef: offerItem.OfferItemRef,
                            passengerRef: offerItem.PassengerRef,
                            rowNumber: rowNumber,
                            seatNumber: seatNumber,
                            baseFare: offerItem.BaseFare,
                            tax: offerItem.Tax,
                            totalPrice: offerItem.TotalPrice,
                            airline:
                              seatmaps.seatMapResponse.seatMapResponses[index]
                                .FlightSegments[0].marketingAirline,
                            flightNumber:
                              seatmaps.seatMapResponse.seatMapResponses[index]
                                .FlightSegments[0].flightNumber,
                            flightId:
                              seatmaps.seatMapResponse.seatMapResponses[index]
                                .FlightSegments[0].FlightID,
                          };

                          seats.push(seat);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        passengers[i].seats = seats;
      }
    }

    let userInfo = null;
    let cid = props.routing.cid;
    let accountCode = null;
    if (sessionStorage.getItem('userInfo')) {
      userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      accountCode = userInfo.AccountCode;
      if (!cid && userInfo.type == 'traveloutlet') {
        cid = 'gtttvo';
      }
    }

    let routing = { ...tripData };
    delete routing.sells;
    if (props.travelers.adults > 0) {
      routing.adultBasePrice =
        Math.round((parseFloat(routing.adultBasePrice) + markup) * 100) / 100;
    }

    if (props.travelers.children > 0) {
      routing.childBasePrice =
        Math.round((parseFloat(routing.childBasePrice) + markup) * 100) / 100;
    }

    routing.adultSalesPrice = routing.adultBasePrice;
    routing.childSalesPrice = routing.childBasePrice;

    routing.totalPrice =
      Math.round(
        ((routing.adultBasePrice + routing.adultTax) * props.travelers.adults +
          (routing.childBasePrice + routing.childTax) *
            props.travelers.children +
          passengerToBundles
            .map((item) =>
              item.bundles.reduce((prev, curr) => prev + curr.totalAmount, 0),
            )
            .reduce((prev, curr) => prev + curr, 0) +
          submitSeats
            .map((seat) =>
              seat.seats.reduce((prev, curr) => {
                return prev + curr.travelerPricing[0].price.total;
              }, 0),
            )
            .reduce((x, y) => x + y, 0)) *
          100,
      ) / 100;

    if (
      !cid &&
      props.shareInfo &&
      props.shareInfo.sourceType == 'traveloutlet'
    ) {
      cid = 'gtttvo';
    }

    let params = {
      adults: props.travelers.adults,
      children: props.travelers.children,
      routing: JSON.stringify(routing),
      passengers: passengers,
      contact: data.contact,
      userInfo: userInfo,
      currency: props.currency,
      dateTime: new Date(),
      domain: `${window.location.protocol}//${window.location.host}`,
      openId: sessionStorage.getItem('openid'),
      shareInfo: props.shareInfo ? props.shareInfo : null,
      cid: cid,
    };

    //#region 
    console.log('post', params);
    // setDisabled(false)
    // return;

    //#endregion

    setProgress(true);

    axios
      .post('api/book', params)
      .then(function (res) {
        // let json = JSON.parse(res.data);
        // console.log("booked json", json)
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
            break;
          }
        } else if ('airTicketPriceChangedResponse' in res.data) {
          enqueueSnackbar('Ticket price changed, please re-search.', {
            variant: 'warning',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        } else {
          // let order = { gds: routing.gds, pcc: null };
          // if (IS_DirectNDCS.includes(cid)) {
          //   let pcc = null;
          //   if (routing.mainAirlineCode == 'AA') {
          //     // pcc = "A8V6"//------------beta
          //     pcc = 'A95R'; //--------release
          //   } else if (routing.mainAirlineCode == 'UA') {
          //     pcc = 'APQP';
          //   } else if (routing.mainAirlineCode == 'CM') {
          //     // pcc = "A8W9"//-----------beta
          //     pcc = 'BAWM'; //-----------release
          //   } else if (routing.mainAirlineCode == 'EK') {
          //     // pcc = "EKTT"
          //     pcc = 'EMNT'; //-----------release
          //   }
          //   order.pcc = pcc
          //   // order = { gds: 'F', pcc: pcc };
          // }else if(cid == "flxgndc"){
          //   order.pcc = "1ED5"
          // }

          navigate('/success', {
            state: {
              bookedRsp: res.data,
              bookInfo: params,
              currency: props.currency,
              orderStatus: 'No Payment',
              seats: submitSeats,
              bundles: passengerToBundles,
              order: {
                gds: res.data.airTicketBookingResponse.gds,
                pcc: res.data.airTicketBookingResponse.pcc,
              },
              source: cid,
              // from: 'book'
            },
          });
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setProgress(false);
        setDisabled(false);
      });
  };

  let travelerTypes = [];
  if (props.routing.isStudent) {
    travelerTypes.push('student');
  }

  if (props.routing.isYouth) {
    travelerTypes.push('youth');
  }

  let travelerDetails = [];
  let index = 0;
  for (let i = 0; i < props.travelers.adults; i++) {
    travelerDetails.push(
      <Traveler
        key={'travelerAdult' + i}
        index={index + 1}
        type={'adult'}
        number={i}
        disabled={false}
        includeTypes={travelerTypes}
        mainAirlineCode = {props.routing.mainAirlineCode}
      />,
    );
    index++;
  }

  if (!props.routing.isStudent) {
    for (let i = 0; i < props.travelers.children; i++) {
      travelerDetails.push(
        <Traveler
          key={'travelerChild' + i}
          index={index + 1}
          type={'child'}
          number={i}
          disabled={false}
          mainAirlineCode = {props.routing.mainAirlineCode}
        />,
      );
      index++;
    }
  }

  if (!props.routing.isStudent) {
    for (let i = 0; i < props.travelers.infants; i++) {
      travelerDetails.push(
        <Traveler
          key={'travelerInfant' + i}
          index={index + 1}
          type={'infant'}
          number={i}
          disabled={false}
          mainAirlineCode = {props.routing.mainAirlineCode}
        />,
      );
      index++;
    }
  }

  function changeMarkup(markup) {
    // let routing = {...props.routing}
    // if(props.travelers.adults > 0){
    // 	routing.adultBasePrice = (routing.adultBasePrice + markup)*100/100
    // }

    // if(props.travelers.children > 0){
    // 	routing.childBasePrice = (routing.childBasePrice + markup)*100/100
    // }

    // routing.totalPrice = (routing.totalPrice + markup)*100/100
    setMarkup(markup);
  }

  function changeShowType(showType) {
    setDisplaySearch(showType);
  }

  const chooseSell = (item) => {
    setTripData(item);
    setSelectedFareKey(item.key);
    // setBundle(null)
    setBundles([]);
    setPassengerToBundles([]);
    setServices(null);
    setIsPrebooking(true);
    axios
      .post('api/book', {
        adults: props.travelers.adults,
        children: props.travelers.children,
        routing: JSON.stringify(item),
        cid: props.routing.cid,
        isPrebooking: true,
      })
      .then(function (res) {
        // let json = JSON.parse(res.data);
        //   console.log("fare select prebooking", json)
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        } else if ('airTicketPriceChangedResponse' in res.data) {
          enqueueSnackbar('Price changed, please re-search.', {
            variant: 'warning',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        } else if ('airTicketRuleResponse' in res.data) {
          requestServices(
            'service?' +
              new URLSearchParams({
                cid: props.routing.cid,
                customerSessionId:
                  res.data.airTicketRuleResponse.customerSessionId,
                gds: res.data.airTicketRuleResponse.gds.toUpperCase(),
                pcc: res.data.airTicketRuleResponse.pcc,
                adults: props.travelers.adults,
                children: props.travelers.children,
              }).toString() +
              '&offer=' +
              JSON.stringify(res.data.airTicketRuleResponse.offerInfo),
          );

          // console.log('')
          // addPrebookings(item.key, json.airTicketRuleResponse)
          // setCheckboxStates(json.airTicketRuleResponse && json.airTicketRuleResponse.serviceList ? new Array(json.airTicketRuleResponse.serviceList.length).fill(false) : null)
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setIsPrebooking(false);
      });
    // console.log('select', item)
    // let findPrebooking = prebookings.find(prebooking=>prebooking.key === item.key)
    // // console.log('find prebooking', findPrebooking)
    // if(findPrebooking){
    // 	// console.log('prebooking find', prebookings.find(prebooking=>prebooking.key === item.key))
    // 	// setCheckboxStates(findPrebooking.prebooking.serviceList ? new Array(findPrebooking.prebooking.serviceList.length).fill(false) : null)
    // }else{

    // }

    if (submitSeats && submitSeats.length > 0) {
      enqueueSnackbar(
        'The seat you have already selected has been deleted, please select a new seat.',
        {
          variant: 'warning',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        },
      );
    }

    setSeatmaps(null);
    setSelectedSeats([]);
    setSubmitSeats([]);
  };

  const selectBundle = (bundle, index) => {
    // console.log('selected bundle', index, bundle)
    // setBundle(bundle)
    let newBundles = bundles;
    // console.log('new bundles', newBundles)
    let findIndex = newBundles.findIndex((item) => item.index == index);
    if (findIndex < 0) {
      setBundles([...newBundles, { index, bundle }]);
    } else {
      newBundles[findIndex].bundle = bundle;
      setBundles([...newBundles]);
    }
  };

  const handleCloseSeat = () => {
    setSeatDialogOpen(false);
  };

  const handleLoadingSeatmap = () => {
    if (seatmaps != null) {
      // setSeatDialogOpen(true);
      setOpenSeat(true);
      return;
    }

    setLoadingSeatmap(true);

    let params = {
      cid: props.routing.cid,
      adults: props.travelers.adults,
      children: props.travelers.children,
      customerSessionId: 'S12345',
      routing: JSON.stringify(tripData),
    };

    // let params = {byOrder: true, cid: props.routing.cid, gds: "F", pcc: "A8V6", pnr: "LUPFAX",
    // 	customerSessionId: "S12345"
    // }

    // axios
    //   .get('/seats.json', {
    //     baseURL: '/',
    //     params
    //   })
    axios
      .post('api/seats', params)
      .then(function (res) {
        // console.log('seatmap rsp', JSON.parse(res.data))
        // const json = JSON.parse(res.data);
        let json = res.data;

        if ('APIError' in json) {
          for (let error of json.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
            break;
          }

          return;
        }

        let segments = getSegments(props.routing)
        seatmapHelper.process(json, segments);
        let travelerList = [];
        for (let flight of json.seatMapResponse.seatMapResponses) {
          for (let travelerIdentifier of flight.TravelerIdentifiers) {
            let seat = SearchSeat(
              travelerIdentifier.AreadySelectedSeatNumber,
              flight.SeatMapDetails,
            );

            let travelerFlight = {
              segments: flight.FlightSegments,
              areadySelectedSeat: {
                number: travelerIdentifier.AreadySelectedSeatNumber,
                orderItemId: travelerIdentifier.AreadySelectedSeatOrderItemId,
                seat: seat,
              },
              selectedSeat: null,
            };

            let travelerIndex = travelerList.findIndex(
              (item) => item.passengerKey === travelerIdentifier.PassengerKey,
            );
            if (travelerIndex < 0) {
              let traveler = {
                passengerKey: travelerIdentifier.PassengerKey,
                traveler: {
                  passengerTypeCode: travelerIdentifier.PassengerTypeCode,
                  id: travelerIdentifier.ID,
                  name: travelerIdentifier.Name,
                },
                flights: [travelerFlight],
              };

              travelerList.push(traveler);
            } else {
              travelerList[travelerIndex].flights.push(travelerFlight);
            }
          }
        }

        setSeatsCount(json.seatMapResponse.seatMapResponses.length);
        if (json.seatMapResponse.seatMapResponses.length <= 1) {
          setShowSeatSubmit(true);
        }
        setSeatmaps(json);
        setTravelers(travelerList);
        setOpenSeat(true);
        // setSeatDialogOpen(true);
      })
      .catch(function (error) {
        console.error(error);
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        // setBackdropOpen(false);
        setLoadingSeatmap(false);
      });
  };

  const handleSelectedSeats = (seats) => {
    // console.log('selected seats', seats)
    setSelectedSeats([...seats]);
  };

  const handleSubmitSeat = () => {
    // props.onSelectSeats({seatmaps: props.seatmap, selectSeats})

    // setSeatDialogOpen(false);
    // // console.log('seatmaps', selectedSeats)
    // let submitSeats = [];
    // let alert = false;
    // for (let selectedSeat of selectedSeats) {
    //   if (selectedSeat.seats.length > 0) {
    //     let seat = {
    //       segment:
    //         seatmaps.seatMapResponse.seatMapResponses[selectedSeat.index]
    //           .FlightSegments[0],
    //       seats: selectedSeat.seats,
    //     };

    //     submitSeats.push(seat);
    //   }

    //   if (
    //     selectedSeat.seats.length !=
    //     seatmaps.seatMapResponse.seatMapResponses[selectedSeat.index]
    //       .TravelerIdentifiers.length
    //   ) {
    //     alert = true;
    //   }
    // }

    // if (alert || selectedSeats.length == 0) {
    //   enqueueSnackbar('There are some people who did not choose a seat', {
    //     variant: 'warning',
    //     autoHideDuration: 3000,
    //     anchorOrigin: { horizontal: 'center', vertical: 'top' },
    //   });
    // }

    // setSubmitSeats(submitSeats);
    setLoadingBookSeat(true);
  };

  const addPrebookings = (key, prebooking) => {
    setPrebookings([...prebookings, { key: key, prebooking: prebooking }]);
  };

  const handleSelectBundle = (bundles) => {
    setSelectedBundles([...bundles]);
  };

  const handleCloseBundles = () => {
    setBundleOpen(false);
    setSelectedBundles([]);
  };

  const handleSubmitBundles = () => {
    // console.log('submit bundles', selectedBundles)

    setBundleOpen(false);
    setSubmitBundles([...selectedBundles]);
    let passengerBundles = [];
    let hasSeat = false;
    let newSubmitBundles = [...selectedBundles];
    for (let submitBundle of newSubmitBundles) {
      for (let passenger of submitBundle.passengers) {
        if (
          passenger.bundles.find(
            (item) => item.name.toUpperCase().indexOf('SEAT') >= 0,
          )
        ) {
          hasSeat = true;
        }

        let passengerIndex = passengerBundles.findIndex(
          (item) => item.passengeRefs == passenger.passengeRefs,
        );
        if (passengerIndex < 0) {
          passengerBundles.push({
            passengeRefs: passenger.passengeRefs,
            bundles: [...passenger.bundles],
          });
        } else {
          for (let bundle of passenger.bundles) {
            if (
              !passengerBundles[passengerIndex].bundles.find((item) =>
                deepEqual(item, bundle),
              )
            ) {
              passengerBundles[passengerIndex].bundles.push(bundle);
            }
          }
        }
      }
    }

    console.log('passengerBundles',  passengerBundles)
    setPassengerToBundles([...passengerBundles]);
    if (hasSeat) {
      setShowSeat(false);
      if (seatmaps) {
        enqueueSnackbar(
          'You select a seat, the selected seat will be deleted, please select your seat after the order is complete.',
          {
            variant: 'warning',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
      }
      setSeatmaps(null);
      setSelectedSeats([]);
      setSubmitSeats([]);
    } else {
      setShowSeat(true);
    }
  };

  const handleClickBundle = () => {
    setBundleOpen(true);
    // console.log('click to open bundle', submitBundles)
    setSelectedBundles([...submitBundles]);
  };

  const handleClickSeatNext = (step) => {
    // console.log('click next', step)
    // console.log('click next', step, seatsActiveStep, seatsCount)
    if (typeof step === 'number') {
      if (step === seatsCount - 1) {
        setShowSeatSubmit(true);
      }
      setSeatsActiveStep(step);
    } else {
      if (seatsActiveStep >= seatsCount - 1) {
        return;
      }
      setSeatsActiveStep(seatsActiveStep + 1);
      if (seatsActiveStep + 1 === seatsCount - 1) {
        setShowSeatSubmit(true);
      }
    }
  };

  const handleSetloadingBookSeat = (loading) => {
    setLoadingBookSeat(loading);
  };

  const handleBookSeatResponse = (response) => {
    console.log('booked seats success', response);
    // navigate('/OrderDetail', {state: state})
    setSubmitSeats([...response]);
    setOpenSeat(false);
  };

  const handleClickServicesNext = (step) => {
    console.log('step', step, servicesActiveStep, bundlesCount)
    if (typeof step === 'number') {
      if (step === bundlesCount - 1) {
        setShowServicesSubmit(true);
      }
      setServicesActiveStep(step);
    } else {
      if (servicesActiveStep >= bundlesCount - 1) {
        return;
      }

      setServicesActiveStep(servicesActiveStep + 1);
      if (servicesActiveStep + 1 === bundlesCount - 1) {
        setShowServicesSubmit(true);
      }
    }
  };

  const handleServicesSubmit = () => {
    console.log('submit');
    setLoadingBookServices(true)
  };

  const handleBookServicesResponse = (response) => {
    console.log('book services response', response, selectedBundles)
    setLoadingBookServices(false);
    if(response){
      setBundleOpen(false);
    setSubmitBundles([...response]);
    let passengerBundles = [];
    let hasSeat = false;
    let newSubmitBundles = [...response];
    for (let submitBundle of newSubmitBundles) {
      for (let passenger of submitBundle.passengers) {
        if (
          passenger.bundles.find(
            (item) => item.name.toUpperCase().indexOf('SEAT') >= 0,
          )
        ) {
          hasSeat = true;
        }

        let passengerIndex = passengerBundles.findIndex(
          (item) => item.passengeRefs == passenger.passengeRefs,
        );
        if (passengerIndex < 0) {
          passengerBundles.push({
            passengeRefs: passenger.passengeRefs,
            bundles: [...passenger.bundles],
          });
        } else {
          for (let bundle of passenger.bundles) {
            if (
              !passengerBundles[passengerIndex].bundles.find((item) =>
                deepEqual(item, bundle),
              )
            ) {
              passengerBundles[passengerIndex].bundles.push(bundle);
            }
          }
        }
      }
    }

    setPassengerToBundles([...passengerBundles]);
    if (hasSeat) {
      setShowSeat(false);
      if (seatmaps) {
        enqueueSnackbar(
          'You select a seat, the selected seat will be deleted, please select your seat after the order is complete.',
          {
            variant: 'warning',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
      }
      setSeatmaps(null);
      setSelectedSeats([]);
      setSubmitSeats([]);
    } else {
      setShowSeat(true);
    }
    }
  };

  return (
    <div className="theme-page-section theme-page-section-lg _mt-desk-30">
      <div className="container">
        {progress ? (
          <AppBar position="fixed">
            <LinearProgress />
          </AppBar>
        ) : null}
        {props.shareSourceType == 'traveloutlet' && (
          <Box>
            <Typography variant="h6" gutterBottom component="div">
              {intl.get('BOOK.CHANGE_DATE')}
              <IconButton
                color="primary"
                aria-label="Edit"
                onClick={() => {
                  setDisplaySearch(!displaySearch);
                }}
              >
                <EditIcon />
              </IconButton>
            </Typography>
          </Box>
        )}

        {displaySearch && (
          <Search
            showClose={true}
            changeShowType={(showType) => changeShowType(showType)}
            disabled={false}
          />
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div
              className="row row-col-static row-col-mob-gap"
              id="sticky-parent"
              data-gutter="60"
            >
              <div className="col-md-8 _pt-mob-25 _pb-mob-25">
                <div className="theme-payment-page-sections">
                  <div className="theme-payment-page-sections-item">
                    <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
                      <div className="row">
                        <div className="col-md-8"></div>
                      </div>
                      <div className="row _br-3 _mv-10 " data-gutter="20">
                        <div className="col-md-12 ">
                          <div className="theme-search-results-item-flight-details-schedule">
                            <ul className="theme-search-results-item-flight-details-schedule-list">
                              <li>
                                <i className="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                                <TripDetail
                                  routing={tripData}
                                  hideBookButton={true}
                                  hideFareRules={true}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="theme-payment-page-sections-item _p-20">
                    <h3 className="theme-payment-page-sections-item-title font-weight-600">
                      <i className="_pr-10">
                        <FlightClassIcon />
                      </i>
                      Fare Selection
                    </h3>
                    <Grid container spacing={2}>
                      <Grid key={props.routing.key} item xs={12} sm={6}>
                        <BasicCard
                          routing={{ ...props.routing, cid: props.routing.cid }}
                          chooseSell={chooseSell}
                          fullData={props.fullData}
                          travelers={props.travelers}
                          brandRule={
                            props.prebookingInfo && props.prebookingInfo.brand
                          }
                          selectedKey={selectdFareKey}
                          prebooking={
                            prebookings.find(
                              (item) => item.key === props.routing.key,
                            ) &&
                            prebookings.find(
                              (item) => item.key === props.routing.key,
                            ).prebooking
                          }
                          addPrebookings={addPrebookings}
                        />
                      </Grid>
                      {props.routing.sells &&
                        props.routing.sells.length > 0 &&
                        props.routing.sells.map((sell) => (
                          <Grid key={sell.key} item xs={12} sm={6}>
                            <BasicCard
                              routing={sell}
                              chooseSell={chooseSell}
                              fullData={props.fullData}
                              travelers={props.travelers}
                              brandRule={props.prebookingInfo.brand}
                              selectedKey={selectdFareKey}
                              prebooking={
                                prebookings.find(
                                  (item) => item.key === sell.key,
                                ) &&
                                prebookings.find(
                                  (item) => item.key === sell.key,
                                ).prebooking
                              }
                              addPrebookings={addPrebookings}
                            />
                          </Grid>
                        ))}

                      {upsellRoutings &&
                        upsellRoutings.length > 0 &&
                        upsellRoutings
                          .sort(
                            (a, b) =>
                              a.adultBasePrice +
                              a.adultTax -
                              (b.adultBasePrice + b.adultTax),
                          )
                          .map((sell) => {
                            return (
                              <Grid key={sell.key} item xs={12} sm={6}>
                                <BasicCard
                                  routing={{ ...sell, cid: props.routing.cid }}
                                  chooseSell={chooseSell}
                                  fullData={props.fullData}
                                  travelers={props.travelers}
                                  selectedKey={selectdFareKey}
                                  prebooking={
                                    prebookings.find(
                                      (item) => item.key === sell.key,
                                    ) &&
                                    prebookings.find(
                                      (item) => item.key === sell.key,
                                    ).prebooking
                                  }
                                  addPrebookings={addPrebookings}
                                />
                              </Grid>
                            );
                          })}
                      {upsellLoading && (
                        <Grid item xs={12} sm={6}>
                          <CircularProgress />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  {
                    // IS_DirectNDCS.includes(props.routing.cid) &&

                    props.routing.mainAirlineCode != 'EK' &&
                      services &&
                      services.length > 0 &&
                      services.filter((item) => item.name !== 'FareLock') && (
                        <div className="theme-payment-page-sections-item _p-20">
                          <h3 className="theme-payment-page-sections-item-title font-weight-600">
                            <i className="fa fa-cart-plus _pr-10"></i>Travel
                            extras
                          </h3>
                          {serviceLoading || isPrebooking ? (
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress />
                            </Box>
                          ) : (
                            // prebookings.find(item=>item.key === selectdFareKey)
                            // && prebookings.find(item=>item.key === selectdFareKey).prebooking
                            // && prebookings.find(item=>item.key === selectdFareKey).prebooking.serviceList
                            // && prebookings.find(item=>item.key === selectdFareKey).prebooking.serviceList.length > 0
                            // && prebookings.find(item=>item.key === selectdFareKey).prebooking.serviceList.filter(item=>item.name !=="FareLock") &&
                            services &&
                            services.length > 0 &&
                            services.filter(
                              (item) => item.name !== 'FareLock',
                            ) && (
                              <Button
                                variant="outlined"
                                onClick={handleClickBundle}
                              >
                                See All
                              </Button>
                            )
                          )}
                          <Grid container sx={{ p: 2 }} spacing={2}>
                            {passengerToBundles.map((item) => (
                              <Grid key={item.passengeRefs} item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="h6"
                                      style={{ textAlign: 'center' }}
                                    >
                                      {`Traveler ${removeNonNumeric(item.passengeRefs)}`}
                                    </Typography>
                                  </Grid>
                                  {item.bundles.map((bundle, index) => (
                                    <Grid
                                      key={
                                        item.passengeRefs + bundle.name + index
                                      }
                                      item
                                      xs={12}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        sx={{
                                          borderBottom: '1px solid #1976d2',
                                        }}
                                      >
                                        <Grid item xs={4}>
                                          <Typography>{bundle.name}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Grid container spacing={2}>
                                            {bundle.routings.map((routing) => (
                                              <Grid
                                                key={
                                                  routing.DepartureAirport +
                                                  routing.ArrivalAirport
                                                }
                                                item
                                                xs={12}
                                              >
                                                <Typography>
                                                  {routing.DepartureAirport +
                                                    ' to ' +
                                                    routing.ArrivalAirport}
                                                </Typography>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography>
                                            {bundle.totalAmount.toLocaleString(
                                              'en',
                                              {
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              },
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                          <Dialog
                            fullScreen
                            open={bundleOpen}
                            onClose={() => setBundleOpen(false)}
                            aria-labelledby="responsive-dialog-title"
                            // TransitionComponent={Transition}
                          >
                            <DialogTitle>Travel add-ons</DialogTitle>
                            <DialogContent>
                              <Bundle
                                routing={tripData}
                                services={
                                  services &&
                                  services.length > 0 &&
                                  services.filter(
                                    (item) => item.name !== 'FareLock',
                                  )
                                }
                                // selectedBundles={selectedBundles}
                                // onSelectBundle={handleSelectBundle}
                                loadingBook={loadingBookServices}
                                currentStep={servicesActiveStep}
                                onClickNext={handleClickServicesNext}
                                onBookResponse={handleBookServicesResponse}
                                shouldBook = {false}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="contained"
                                onClick={handleCloseBundles}
                              >
                                Close
                              </Button>
                              {!showServicesSubmit && (
                              <Button
                                variant="contained"
                                color="warning"
                                onClick={handleClickServicesNext}
                              >
                                Next
                              </Button>
                            )}
                            {showServicesSubmit && (
                              <LoadingButton
                              loading={loadingBookServices}
                              variant="contained"
                              onClick={handleServicesSubmit}
                              autoFocus
                              color="warning"
                            >
                              Submit
                            </LoadingButton>)}
                            </DialogActions>
                          </Dialog>

                          {/* <Accordion defaultExpanded = {false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        //   id="panel1a-header"
        >
          <Typography>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
		{fareLoading &&
	  <Box sx={{ display: 'flex' }}>
      <CircularProgress/>
    </Box>
	}
	{
	prebookings.find(item=>item.key === selectdFareKey) && prebookings.find(item=>item.key === selectdFareKey).prebooking &&
	prebookings.find(item=>item.key === selectdFareKey).prebooking.serviceList 
	&& prebookings.find(item=>item.key === selectdFareKey).prebooking.serviceList.length>0
	&& 
	prebookings.find(item=>item.key === selectdFareKey).prebooking.serviceList.filter(item=>item.name !=="FareLock").sort((a,b)=>a.totalAmount-b.totalAmount).map((item, index)=>{
	  return <RecipeReviewCard key={'serviceList' + index} index={index} service={item} selectBundle={selectBundle}/>
  })
  }
        </AccordionDetails>
      </Accordion> */}
                        </div>
                      )
                  }
                  {/* {props.prebookingInfo.serviceList && props.prebookingInfo.serviceList.length>0 &&
  <div className="theme-payment-page-sections-item _p-20">
  <h3 className="theme-payment-page-sections-item-title font-weight-600">
	<i className="fa fa-cart-plus _pr-10"></i>Travel add-ons</h3>
  {props.prebookingInfo.serviceList.filter(item=>item.name !=="FareLock").map((item, index)=>{
	return <RecipeReviewCard key={'serviceList' + index} index={index} checked={checkboxStates[index]} service={item} selectBundle={selectBundle}/>
})}
					
  </div>} */}
                  {((IS_DirectNDCS.includes(props.routing.cid) &&
                    !HIDE_SEAT_AIRLINES.includes(
                      props.routing.mainAirlineCode,
                    )) ||
                    (props.routing.cid == 'flxgndc' &&
                      props.routing.mainAirlineCode != 'SQ')) &&
                    showSeat && (
                      <div className="theme-payment-page-sections-item _p-20">
                        <h3 className="theme-payment-page-sections-item-title font-weight-600">
                          <i className="_pr-10">
                            <EventSeatIcon />
                          </i>
                          Seats
                        </h3>
                        <LoadingButton
                          loading={loadingSeatmap}
                          variant="outlined"
                          onClick={handleLoadingSeatmap}
                        >
                          See All
                        </LoadingButton>
                        {/* <Button
                            variant="outlined"
                            onClick={handleOpenBackdrop}
                          >
                            See All
                          </Button> */}
                        <Grid container sx={{ p: 2 }} spacing={2}>
                          {submitSeats.map((item, index) => (
                            <Grid
                              key={
                                item.segment.origin +
                                item.segment.destination +
                                index
                              }
                              item
                              xs={12}
                            >
                              <Typography
                                variant="subtitle1"
                                align="center"
                                gutterBottom
                              >
                                {item.segment.origin +
                                  ' to ' +
                                  item.segment.destination}
                              </Typography>
                              {item.seats.map((seat, seatIndex) => (
                                <Stack
                                  key={seat.number}
                                  //   direction="row"
                                  //   divider={<Divider orientation="vertical" flexItem />}
                                  spacing={2}
                                >
                                  <Item>
                                    <Grid container spacing={2}>
                                      <Grid item xs={4}>
                                        <Typography
                                          variant="h6"
                                          display="block"
                                          gutterBottom
                                        >
                                          Traveler {seatIndex + 1}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Typography
                                          variant="button"
                                          display="block"
                                          gutterBottom
                                        >
                                          {seat.number}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          gutterBottom
                                        >
                                          {seat.travelerPricing[0].price.total.toLocaleString(
                                            'en',
                                            {
                                              style: 'currency',
                                              currency: 'USD',
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            },
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Item>
                                  {/* <Item>Passenger {seatIndex+1}</Item> */}
                                  {/* <Item>Seat {seat.number}</Item> */}
                                  {/* <Item>Price {seat.travelerPricing[0].price.currency + seat.travelerPricing[0].price.total}</Item> */}
                                </Stack>
                              ))}
                            </Grid>
                          ))}
                        </Grid>
                        {/* <Backdrop
                            sx={{
                              color: '#fff',
                              zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={backDropOpen}
                            onClick={() => setBackdropOpen(false)}
                          >
                            <CircularProgress color="inherit" />
                          </Backdrop> */}
                        {/* <Dialog
                            className="dialog-seatMap"
                            fullScreen
                            open={seatDialogOpen}
                            onClose={() => seatDialogOpen(false)}
                            aria-labelledby="responsive-dialog-title"
                          >
                            <DialogTitle>
                              {intl.get('SEATMAP.SEATMAP')}
                            </DialogTitle>
                            <DialogContent>
                              <SeatDemo
                                seats={
                                  seatmaps &&
                                  seatmaps.seatMapResponse.seatMapResponses
                                }
                                onSelectedSeats={handleSelectedSeats}
                                selectedSeats={selectedSeats}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="contained"
                                autoFocus
                                onClick={handleCloseSeat}
                              >
                                Close
                              </Button>
                              <Button
                                variant="contained"
                                onClick={handleSubmitSeat}
                                autoFocus
                                color="yellow"
                              >
                                Submit
                              </Button>
                            </DialogActions>
                          </Dialog> */}
                        <Dialog
                          className="dialog-seatMap"
                          fullScreen
                          open={openSeat}
                          onClose={() => setOpenSeat(false)}
                          aria-labelledby="responsive-dialog-title"
                          // TransitionComponent={Transition}
                        >
                          <DialogTitle>Seat map</DialogTitle>
                          <DialogContent>
                            <Seats
                              seatmaps={seatmaps}
                              // onSelectedSeats={handleSelectedSeats}
                              // selectedSeats={selectedSeats}
                              currentStep={seatsActiveStep}
                              onClickNext={handleClickSeatNext}
                              travelers={travelers}
                              loadingBook={loadingBookSeat}
                              onSubmit={handleSetloadingBookSeat}
                              // requestParas={{
                              //   cid: location.state.routing.cid,
                              //   customerSessionId: 'S12345',
                              //   gds: props.routing.gds,
                              //   pcc: routing.pcc,
                              //   pnr: state.bookedRsp.airTicketBookingResponse
                              //     .recordLocator,
                              // }}
                              onBookResponse={handleBookSeatResponse}
                              shouldBook={false}
                              // segments = {getSegments(props.routing)}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              autoFocus
                              onClick={() => setOpenSeat(false)}
                            >
                              Close
                            </Button>
                            {!showSeatSubmit && (
                              <Button
                                variant="contained"
                                color="warning"
                                onClick={handleClickSeatNext}
                              >
                                Next
                              </Button>
                            )}
                            {showSeatSubmit && (
                              // <Button
                              //   variant="contained"
                              //   onClick={handleSubmitSeat}
                              //   autoFocus
                              //   color="warning"
                              // >
                              //   Submit
                              // </Button>
                              <LoadingButton
                                loading={loadingBookSeat}
                                onClick={handleSubmitSeat}
                                variant="contained"
                              >
                                Submit
                              </LoadingButton>
                            )}
                          </DialogActions>
                        </Dialog>
                      </div>
                    )}
                  {!sessionStorage.getItem('userInfo') ||
                  (props.shareInfo && props.shareInfo.isShare) ? (
                    <Contact displaySame={true} disabled={false} />
                  ) : (
                    <></>
                  )}

                  {/* {!sessionStorage.getItem("userInfo") ?<Payment/>:<></>} */}
                  {travelerDetails}

                  {/* {props.shareSourceType == "traveloutlet" && <PaymentDetail payment={null} displayPaymentButton={false}/>} */}
                </div>
              </div>
              <div className="col-md-4 _pt-mob-25">
                <OrderSummary
                  routing={tripData}
                  travelers={props.travelers}
                  displayBookButton={true}
                  currency={props.currency}
                  changeMarkup={changeMarkup}
                  displaySetMarkup={props.displayMarkup}
                  GDSPrice={GDSPrice}
                  bundles={passengerToBundles}
                  seats={submitSeats}
                  prebooking={
                    prebookings.find((item) => item.key === selectdFareKey) &&
                    prebookings.find((item) => item.key === selectdFareKey)
                      .prebooking
                  }
                  disabled={disabled}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default function Book(props) {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [displayMarkup, setDisplayMarkup] = useState(false);
  const [prebookingInfo, setPrebookingInfo] = useState(null);

  const goback = () => {
    if (location.state.shareInfo && location.state.shareInfo.isShare) {
      sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo));
      navigate('/flight' + location.state.searchInfo.href);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    // loadCss('/Flight-Hotel/vendor/bootstrap/css/bootstrap.min.css')
    loadCss(
      'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css',
    );
    // loadCss('/Flight-Hotel/vendor/font-awesome/css/all.min.css')
    loadCss('/Flight-Hotel/css/stylesheet.css');
    loadCss('/Flight-Hotel/css/styles.css');
    loadCss('/Flight-Hotel/css/FlightDetails.css');
    loadCss('/css/book.css');
    loadCss('/css/seatmap.css');
    // console.log("book openid", sessionStorage.getItem("openid"));
    let userInfo = null;
    let cid = location.state.routing.cid;
    // if(location.state.routing.cid)
    if (location.state.shareInfo && location.state.shareInfo.isShare) {
      if (!cid && location.state.shareSourceType == 'traveloutlet') {
        cid = 'gtttvo';
      }

      if (location.state.searchInfo) {
        if (location.state.searchInfo.from) {
          localStorage.from = location.state.searchInfo.from;
          if (location.state.searchInfo.to) {
            localStorage.to = location.state.searchInfo.to;
          }

          if (location.state.searchInfo.departDate) {
            localStorage.departDate = location.state.searchInfo.departDate;
          }

          if (location.state.searchInfo.returnDate) {
            localStorage.returnDate = location.state.searchInfo.returnDate;
          }
        }
      }
    } else {
      if (sessionStorage.getItem('userInfo') != null) {
        userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        // console.log("user info", userInfo);
        if (userInfo.AccountCode && userInfo.AccountCode != 'null') {
          setDisplayMarkup(true);
        }

        if (!cid && userInfo.type && userInfo.type == 'traveloutlet') {
          cid = 'gtttvo';
          // setIsTraveloutlet(true)
        }
      }
    }

    // setVerified(true)
    let params = {
      adults: location.state.travelers.adults,
      children: location.state.travelers.children,
      routing: JSON.stringify(location.state.routing),
      cid,
      isPrebooking: true,
    };

    // console.log('params', params)

    // console.log(location.state.searchInfo.href)
    // if (process.env.NODE_ENV !== 'development'){
    // 	// if(cid != "flygndc"){
    // 	// 	axios.post('api/book', params)
    // 	// 	.then(function (res) {
    // 	// 	  // console.log("Prebooking", res)
    // 	// 	  let json = JSON.parse(res.data);
    // 	// 	  console.log("booked json", json)
    // 	// 	  if("APIError" in json){
    // 	// 		  for(let error of json.APIError.errors){
    // 	// 			  // console.log(error.errorId, error.message);
    // 	// 			  // setMessage(error.message);

    // 	// 			  alert(error.message)
    // 	// 		  }

    // 	// 		  if(location.state.shareInfo && location.state.shareInfo.isShare){
    // 	// 			  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
    // 	// 			  navigate('/flight'+location.state.searchInfo.href)
    // 	// 		  }else{
    // 	// 			  navigate(-1);
    // 	// 		  }

    // 	// 	  }else if("airTicketPriceChangedResponse" in json){
    // 	// 		  alert("Price changed, please re-search.");
    // 	// 		  if(location.state.shareInfo && location.state.shareInfo.isShare){
    // 	// 			  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
    // 	// 			  navigate('/flight'+location.state.searchInfo.href)
    // 	// 		  }else{
    // 	// 			  navigate(-1);
    // 	// 		  }
    // 	// 	  }else if("airTicketRuleResponse" in json){
    // 	// 		  let fareRuleList = []
    // 	// 		  for(let fareRule in json.airTicketRuleResponse.rules){
    // 	// 			  fareRuleList.push(json.airTicketRuleResponse.rules[fareRule])
    // 	// 		  }
    // 	// 		  setFareRules(fareRuleList)
    // 	// 	  }
    // 	// 	})
    // 	// 	.catch(function (error) {
    // 	// 	  console.error(error, typeof(error));
    // 	// 	  alert(error);
    // 	// 	  if(location.state.shareInfo && location.state.shareInfo.isShare){
    // 	// 		  sessionStorage.setItem('share', JSON.stringify(location.state.shareInfo))
    // 	// 		  navigate('/flight'+location.state.searchInfo.href)
    // 	// 	  }else{
    // 	// 		  navigate(-1);
    // 	// 	  }
    // 	// 	}).then(function () {
    // 	// 	  setVerified(true)
    // 	// 	});
    // 	//   }else{
    // 	// 	  setFareRules([])
    // 	// 	  setVerified(true)
    // 	//   }
    // }else{
    // 	if(location.state.routing.rules){
    // 		let routingRules=[]
    // 		for(let ruleKey in location.state.routing.rules){
    // 			routingRules.push(`${ruleKey}: ${location.state.routing.rules[ruleKey]}`)
    // 		}

    // 		setFareRules(routingRules)
    // 	}

    // 	setVerified(true)
    // }

    axios
      .post('api/book', params)
      .then(function (res) {
        // let json = JSON.parse(res.data);
        //   console.log("prebook res json", json)
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }

          enqueueSnackbar('Coming back to re-search', {
            variant: 'info',
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          setTimeout(goback, 3000);
        } else if ('airTicketPriceChangedResponse' in res.data) {
          //   alert("Price changed, please re-search.");
          enqueueSnackbar('Price changed, coming back to re-search.', {
            variant: 'warning',
            autoHideDuration: 5000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          setTimeout(goback, 3000);
        } else if ('airTicketRuleResponse' in res.data) {
          setPrebookingInfo(res.data.airTicketRuleResponse);
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
        enqueueSnackbar('Coming back to re-search', {
          variant: 'info',
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
        setTimeout(goback, 3000);
      })
      .then(function () {
        setVerified(true);
      });
  }, []);

  return verified ? (
    <>
      <ButtonAppBar />
      <FlightDetail
        {...location.state}
        displayMarkup={displayMarkup}
        prebookingInfo={prebookingInfo}
      />
    </>
  ) : (
    <>
      <AppBar position="fixed">
        <LinearProgress />
      </AppBar>
      <Box sx={{ width: '100%' }}>
        <Stack spacing={1}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={200}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={250}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={250}
          />
        </Stack>
      </Box>
    </>
  );
}
