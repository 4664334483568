import * as React from 'react';
// import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import ButtonBase from '@mui/material/ButtonBase';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Backdrop,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import 'mock/index';
import Bundle from '../../components/Bundle';
import { getURLParam } from '../../common/helper.js';
import { deepEqual } from '../../utils/utils';
import { LoadingButton } from '@mui/lab';
// import SeatDemo from '../../components/SeatDemo/index.jsx';

// import {convertSeatStatus} from '../../utils/utils'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { loadCss } from '../../common/helper.js';
import seatmapHelper from '../../utils/seatmapHelper.js';
import Seats from '../../components/Seats/index.jsx';
import { useState } from 'react';

// const Img = styled('img')({
//   margin: 'auto',
//   display: 'block',
//   maxWidth: '100%',
//   maxHeight: '100%',
// });

const SearchSeat = (seatNumber, seatMapDetails) => {
  let seat = null;
  if (seatNumber && seatMapDetails) {
    let rowNum = seatNumber.substring(0, seatNumber.length - 1);
    let columnNum = seatNumber.substring(seatNumber.length - 1);
    for (let seatMapDetail of seatMapDetails) {
      for (let airRow of seatMapDetail.airRows) {
        for (let airSeat of airRow.airSeats) {
          if (airRow.rowNumber === rowNum && airSeat.seatNumber === columnNum) {
            seat = airSeat;
          }
        }
      }
    }
  }

  return seat;
};

export default function Services() {
  const { enqueueSnackbar } = useSnackbar();
  const [progressingServices, setProgressingServices] = useState(false);
  const [progressingSeats, setProgressingSeats] = useState(false);
  const [services, setServices] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [openSerivce, setOpenSerivce] = useState(false);
  const [openSeat, setOpenSeat] = useState(false);
  const [trips, setTrips] = useState([]);
  const [passengerToServices, setPassengerToServices] = useState([]);
  const [seatmaps, setSeatmaps] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [submitSeats, setSubmitSeats] = useState([]);
  const [expandServices, setExpandServices] = useState(false);
  const [expandSeats, setExpandSeats] = useState(false);
  // const [booking, setBooking] = useState(false)
  const [travelers, setTravelers] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [passengers, setPassengers] = useState([]);
  const [servicesActiveStep, setServicesActiveStep] =
    useState(0);
  const [bundlesCount, setBundlesCount] = useState(0);
  const [showServicesSubmit, setShowServicesSubmit] = useState(false);
  const [showSeatSubmit, setShowSeatSubmit] = useState(false);
  const [seatsCount, setSeatsCount] = useState(0);
  const [seatsActiveStep, setSeatsActiveStep] = useState(0);
  const [loadingBookSeat, setLoadingBookSeat] = useState(false);
  const [loadingBookServices, setLoadingBookServices] = useState(false);

  // let gds = getURLParam('gds');
  // let pcc = getURLParam('pcc');
  // let pnr = getURLParam('pnr');
  // if (!gds || !pcc || !pnr) {
  //   enqueueSnackbar('Requested data is not available', {
  //     variant: 'error',
  //     autoHideDuration: 6000,
  //     anchorOrigin: { horizontal: 'center', vertical: 'top' },
  //   });
  //   return;
  // }

  // {
  //   cid: routing.cid,
  //   customerSessionId: 'S12345',
  //   gds: state.order.gds,
  //   pcc: state.order.pcc,
  //   pnr: state.bookedRsp.airTicketBookingResponse
  //     .recordLocator,
  // }
  React.useEffect(() => {
    // loadCss(
    //   'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css',
    // );
    // loadCss('/Flight-Hotel/css/stylesheet.css');
    // loadCss('/Flight-Hotel/css/styles.css');
    loadCss('/css/seatmap.css');
    let gds = getURLParam('gds');
    let pcc = getURLParam('pcc');
    let pnr = getURLParam('pnr');
    if (!gds || !pcc || !pnr) {
      enqueueSnackbar('Requested data is not available', {
        variant: 'error',
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      return;
    }

    setOpenBackdrop(true);
    axios
      .get(
        'api/passengers?' +
          new URLSearchParams({
            gds: gds,
            pcc: pcc,
            recordLocator: pnr,
          }),
      )
      .then(function (res) {
        if (res.data && res.data.length > 0) {
          // bookServices(pcc, pnr, res.data)
          // bookSeats(pcc, pnr, res.data)
          // let newPassengers = []
          // console.log(res.data.map(item=>{ return {"firstName": item.firstName, "middleName": item.middleName, "lastName": item.lastName, "nameRef": item.nameRef}}))
          setPassengers(
            res.data.map((item) => {
              return {
                firstName: item.firstName,
                middleName: item.middleName,
                lastName: item.lastName,
                nameRef: item.nameRef,
              };
            }),
          );
          // for(let item in res.data){
          //   newPassengers.push({"firstName": item.firstName, "middleName": item.middleName, "lastName": item.lastName, "nameRef": item.nameRef})
          // }

          // setPassengers(newPassengers)
        } else {
          enqueueSnackbar('Passenger data not found', {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setOpenBackdrop(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickService = () => {
    let gds = getURLParam('gds');
    let pcc = getURLParam('pcc');
    let pnr = getURLParam('pnr');
    if (!gds || !pcc || !pnr) {
      enqueueSnackbar('Requested data is not available', {
        variant: 'error',
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      return;
    }

    setSelectedServices([]);
    setPassengerToServices([]);
    setServicesActiveStep(0);
    setBundlesCount(0);
    setShowServicesSubmit(false);
    setProgressingServices(true);
    let cid = null;
    if (gds == 'F') {
      cid = 'flxndc';
    } else if (gds == 'N') {
      cid = 'flxgndc';
    } else {
      enqueueSnackbar('CID is not available', {
        variant: 'error',
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      return;
    }

    axios
      .get(
        'api/services?' +
          new URLSearchParams({
            cid: cid,
            customerSessionId: 'S12345',
            gds: gds,
            pcc: pcc,
            pnr: pnr,
          }),
      )
      // axios.get('api/services')
      .then(function (res) {
        // let json = JSON.parse(res.data);
        // console.log("get service by order", json)
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        } else if ('serviceListResponse' in res.data) {
          setServices(res.data.serviceListResponse.serviceList);
          let segments = [];
          for (let serviceList of res.data.serviceListResponse.serviceList) {
            for (let segment of serviceList.routings) {
              if (
                segments.findIndex(
                  (seg) =>
                    seg.departureAirportCode === segment.DepartureAirport &&
                    seg.arrivalAirportCode === segment.ArrivalAirport,
                ) >= 0
              ) {
                continue;
              }

              segments.push({
                departureAirportCode: segment.DepartureAirport,
                arrivalAirportCode: segment.ArrivalAirport,
              });
            }
          }

          let bundles = [];
          let filterServices =
            res.data.serviceListResponse.serviceList &&
            res.data.serviceListResponse.serviceList.length > 0 &&
            res.data.serviceListResponse.serviceList.filter(
              (item) => item.name !== 'FareLock',
            );
          for (let segment of segments) {
            let newServices = [];
            if (filterServices) {
              for (let service of filterServices) {
                let matched = false;

                for (let routing of service.routings) {
                  if (
                    segment.departureAirportCode === routing.DepartureAirport &&
                    segment.arrivalAirportCode === routing.ArrivalAirport
                  ) {
                    matched = true;
                  }
                }

                if (matched) {
                  newServices.push(service);
                }
              }
            }

            if (newServices.length === 0) {
              continue;
            }

            let passengers = [];

            for (let service of newServices) {
              for (let offerItem of service.offerInfo.offerItems) {
                let passengerIndex = passengers.findIndex(
                  (item) => item.passengeRefs === offerItem.passengeRefs,
                );
                if (passengerIndex < 0) {
                  passengers.push({
                    passengeRefs: offerItem.passengeRefs,
                    passenger: { type: service.passengerTypes },
                    services: [service],
                  });
                } else {
                  passengers[passengerIndex].services.push(service);
                }
              }
            }

            // console.log('passengers', passengers)

            bundles.push({
              segment: {
                departureAirportCode: segment.departureAirportCode,
                arrivalAirportCode: segment.arrivalAirportCode,
              },
              passengers,
            });
          }

          setBundlesCount(bundles.length);
          if (bundles.length <= 1) {
            setShowServicesSubmit(true);
          }
          setTrips([{ segments }]);
          setOpenSerivce(true);
        } else {
          enqueueSnackbar('DATA NOT FOUND', {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setProgressingServices(false);
      });
  };

  const handleSelectService = (bundles) => {
    // console.log('selected services', bundles)
    setSelectedServices([...bundles]);
  };

  const handleClickSeat = () => {
    let gds = getURLParam('gds');
    let pcc = getURLParam('pcc');
    let pnr = getURLParam('pnr');
    if (!gds || !pcc || !pnr) {
      enqueueSnackbar('Requested data is not available', {
        variant: 'error',
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      return;
    }

    let cid = null;
    if (gds == 'F') {
      cid = 'flxndc';
    } else if (gds == 'N') {
      cid = 'flxgndc';
    } else {
      enqueueSnackbar('CID is not available', {
        variant: 'error',
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      return;
    }

    setProgressingSeats(true);
    let params = {
      byOrder: true,
      cid: cid,
      gds: gds,
      pcc: pcc,
      pnr: pnr,
      customerSessionId: 'S12345',
    };

    axios
      .post('api/seats', params)
      .then(function (res) {
        // const json = JSON.parse(res.data);
        let json = res.data;
        // console.log('seat map json', json);
        if ('APIError' in json) {
          for (let error of json.APIError.errors) {
            // alert(error.message)
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
            break;
          }
          return;
        }

        // setOpenSeat(true)

        // for (let flight of json.seatMapResponse.seatMapResponses) {
        //   let seatNum = [];
        //   for (let seatMapDetail of flight.SeatMapDetails) {
        //     for (let airRow of seatMapDetail.airRows) {
        //       for (let airSeat of airRow.airSeats) {
        //         let alphabet = airSeat.seatNumber.substring(
        //           airSeat.seatNumber.length - 1,
        //         );
        //         if (seatNum.indexOf(alphabet) < 0) {
        //           seatNum.push(alphabet);
        //         }
        //       }
        //     }
        //   }
        //   seatNum.sort();

        //   for (let seatMapDetail of flight.SeatMapDetails) {
        //     for (let airRow of seatMapDetail.airRows) {
        //       for (let i = 0; i < airRow.airSeats.length; i++) {
        //         let alphabet = airRow.airSeats[i].seatNumber.substring(
        //           airRow.airSeats[i].seatNumber.length - 1,
        //         );
        //         if (i > 0) {
        //           if (
        //             airRow.airSeats[i].seatCharacteristics &&
        //             airRow.airSeats[i].seatCharacteristics
        //               .split(',')
        //               .indexOf('A') >= 0 &&
        //             airRow.airSeats[i - 1].seatCharacteristics &&
        //             airRow.airSeats[i - 1].seatCharacteristics
        //               .split(',')
        //               .indexOf('A') >= 0
        //           ) {
        //             if (
        //               seatNum.indexOf(alphabet) - 1 >= 0 &&
        //               seatNum[seatNum.indexOf(alphabet) - 1] != 'x'
        //             )
        //               seatNum.splice(seatNum.indexOf(alphabet), 0, 'x');
        //           }
        //         }
        //       }
        //     }
        //   }

        //   let deck = {
        //     deckConfiguration: {
        //       width: 7,
        //       length: 30,
        //       startseatRow: 14,
        //       endSeatRow: 38,
        //       startWingsRow: 0,
        //       endWingsRow: 0,
        //       startWingsX: 0,
        //       endWingsX: 0,
        //       exitRowsX: [],
        //     },
        //     facilities: [],
        //     seats: [],
        //     id: json.seatMapResponse.shoppingResponseID,
        //     selectedSeatNumbers: [],
        //   };
        //   let selectedSeatNumbers = [];
        //   for (let traveler of flight.TravelerIdentifiers) {
        //     if (traveler.AreadySelectedSeatNumber) {
        //       deck.selectedSeatNumbers.push(traveler.AreadySelectedSeatNumber);
        //     }
        //   }

        //   let x = 1;
        //   for (let i = 0; i < flight.SeatMapDetails.length; i++) {
        //     if (i > 0) {
        //       x +=
        //         parseInt(flight.SeatMapDetails[i].StartRowNumber) -
        //         parseInt(flight.SeatMapDetails[i - 1].EndRowNumber) -
        //         1;
        //     }

        //     for (let airRow of flight.SeatMapDetails[i].airRows) {
        //       for (let j = 0; j < airRow.airSeats.length; j++) {

        //         let selected = false;
        //         if (
        //           deck.selectedSeatNumbers.indexOf(
        //             airRow.airSeats[j].seatNumber,
        //           ) >= 0
        //         ) {
        //           selected = true;
        //         }
        //         let seat = {
        //           cabin: 'ECONOMY',
        //           colNo: airRow.airSeats[j].seatNumber,
        //           rowNo: airRow.rowNumber,
        //           number: airRow.rowNumber + airRow.airSeats[j].seatNumber,
        //           characteristicsCodes: airRow.airSeats[j].seatCharacteristics
        //             ? airRow.airSeats[j].seatCharacteristics.split(',')
        //             : [],
        //           travelerPricing: [
        //             {
        //               id: '1',
        //               seatAvailabilityStatus: airRow.airSeats[j]
        //                 .seatAvailabilityStatus
        //                 ? convertSeatStatus(
        //                     airRow.airSeats[
        //                       j
        //                     ].seatAvailabilityStatus.toUpperCase(),
        //                   )
        //                 : airRow.airSeats[j].seatAvailabilityStatus,
        //               price: {
        //                 currency: airRow.airSeats[j].OfferItems[0].CurrencyCode,
        //                 total: airRow.airSeats[j].OfferItems[0].TotalPrice,
        //               },
        //             },
        //           ],
        //           coordinates: {
        //             x: x,
        //             y: seatNum.indexOf(
        //               airRow.airSeats[j].seatNumber.substring(
        //                 airRow.airSeats[j].seatNumber.length - 1,
        //               ),
        //             ),
        //           },
        //           selected: selected,
        //           isExitRow: false,
        //           isOverWings: false
        //         };

        //         // 判断是否是出口座位
        //         const seatCharacteristicsList = airRow.airSeats[j].seatCharacteristics.split(',')
        //         if (seatCharacteristicsList.indexOf('E') > -1) {
        //           seat.isExitRow = true
        //         }
        //         if (seatCharacteristicsList.indexOf('OW') > -1) {
        //           // console.log('OW', airRow);
        //           seat.isOverWings = true
        //         }

        //         deck.seats.push(seat);
        //       }

        //       x++;
        //     }
        //   }

        //   deck.deckConfiguration.width =
        //     Math.max.apply(
        //       Math,
        //       deck.seats.map((item) => item.coordinates.y),
        //     ) + 1;
        //   deck.deckConfiguration.length =
        //     deck.seats[deck.seats.length - 1].coordinates.x;
        //   deck.segments = flight.FlightSegments;
        //   // deck.travelers = flight.TravelerIdentifiers

        //   deck.seatNumbers = [...seatNum]
        //   flight.deck = deck;
        // }

        // console.log('json', json)
        seatmapHelper.process(json, null);
        setSeatsCount(json.seatMapResponse.seatMapResponses.length);
        if (json.seatMapResponse.seatMapResponses.length <= 1) {
          setShowSeatSubmit(true);
        }

        setSeatmaps(json);
        let travelerList = [];
        for (let flight of json.seatMapResponse.seatMapResponses) {
          for (let travelerIdentifier of flight.TravelerIdentifiers) {
            let seat = SearchSeat(
              travelerIdentifier.AreadySelectedSeatNumber,
              flight.SeatMapDetails,
            );

            let travelerFlight = {
              segments: flight.FlightSegments,
              areadySelectedSeat: {
                number: travelerIdentifier.AreadySelectedSeatNumber,
                orderItemId: travelerIdentifier.AreadySelectedSeatOrderItemId,
                seat: seat,
              },
              selectedSeat: null,
            };

            let travelerIndex = travelerList.findIndex(
              (item) => item.passengerKey === travelerIdentifier.PassengerKey,
            );
            if (travelerIndex < 0) {
              let traveler = {
                passengerKey: travelerIdentifier.PassengerKey,
                traveler: {
                  passengerTypeCode: travelerIdentifier.PassengerTypeCode,
                  id: travelerIdentifier.ID,
                  name: travelerIdentifier.Name,
                },
                flights: [travelerFlight],
              };

              travelerList.push(traveler);
            } else {
              travelerList[travelerIndex].flights.push(travelerFlight);
            }
          }
        }

        setTravelers(travelerList);

        setOpenSeat(true);
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setProgressingSeats(false);
      });
  };

  const handleServicesSubmit = () => {
    console.log('submit');
    setLoadingBookServices(true)
    // setOpenSerivce(false);
    // console.log('submit', selectedServices)
    // let hasServices = false;
    // for (let selectedService of selectedServices) {
    //   if (
    //     selectedService.passengers != null &&
    //     selectedService.passengers.some(
    //       (p) => p.bundles != null && p.bundles.length > 0,
    //     )
    //   ) {
    //     hasServices = true;
    //   }
    // }

    // if (!hasServices) {
    //   enqueueSnackbar("You don't choose any of the add-on services.", {
    //     variant: 'warning',
    //     autoHideDuration: 4000,
    //     anchorOrigin: { horizontal: 'center', vertical: 'top' },
    //   });
    //   return;
    // }

    // let passengerServices = [];
    // let newSubmitServices = [...selectedServices];
    // for (let submitService of newSubmitServices) {
    //   for (let passenger of submitService.passengers) {
    //     let passengerIndex = passengerServices.findIndex(
    //       (item) => item.passengeRefs === passenger.passengeRefs,
    //     );
    //     if (passengerIndex < 0) {
    //       passengerServices.push({
    //         passengeRefs: passenger.passengeRefs,
    //         bundles: [...passenger.bundles],
    //       });
    //     } else {
    //       for (let bundle of passenger.bundles) {
    //         if (
    //           !passengerServices[passengerIndex].bundles.find((item) =>
    //             deepEqual(item, bundle),
    //           )
    //         ) {
    //           passengerServices[passengerIndex].bundles.push(bundle);
    //         }
    //       }
    //     }
    //   }
    // }

    // setPassengerToServices([...passengerServices])
    // let pcc = getURLParam('pcc');
    // let pnr = getURLParam('pnr');
    // bookServices(pcc, pnr, passengerServices, passengers);
  };

  const handleBookServicesResponse = (response) => {
    setLoadingBookServices(false);
    if(response){
      // console.log('services response', response)
      // navigate('/OrderDetail', { state: state });
      setPassengerToServices(response);
      setOpenSerivce(false)
    }
  };

  const handleSelectedSeats = (seats) => {
    // console.log('selected seats', seats)
    setSelectedSeats([...seats]);
  };

  // const handleSubmitSeat = () => {
  //   setOpenSeat(false);
  //   let submitSeats = [];
  //   let alert = false;
  //   for (let selectedSeat of selectedSeats) {
  //     if (selectedSeat.seats.length > 0) {
  //       let seat = {
  //         segment:
  //           seatmaps.seatMapResponse.seatMapResponses[selectedSeat.index]
  //             .FlightSegments[0],
  //         seats: selectedSeat.seats,
  //       };

  //       submitSeats.push(seat);
  //     }

  //     if (
  //       selectedSeat.seats.length !==
  //       seatmaps.seatMapResponse.seatMapResponses[selectedSeat.index]
  //         .TravelerIdentifiers.length
  //     ) {
  //       alert = true;
  //     }
  //   }

  //   if (alert || selectedSeats.length === 0) {
  //     enqueueSnackbar('There are some people who did not choose a seat', {
  //       variant: 'warning',
  //       autoHideDuration: 3000,
  //       anchorOrigin: { horizontal: 'center', vertical: 'top' },
  //     });
  //   }

  //   // console.log('submit seats', submitSeats)
  //   for (let i = 0; i < travelers.length; i++) {
  //     let flights = travelers[i].flights;
  //     for (let j = 0; j < flights.length; j++) {
  //       let findSeat = selectedSeats.find((item) => item.index === j);
  //       if (findSeat && findSeat.seats.length > 0) {
  //         for (let k = 0; k < findSeat.seats.length; k++) {
  //           if (i === k) {
  //             travelers[i].flights[j].selectedSeat = {
  //               number: findSeat.seats[k].number,
  //               orderItemId: null,
  //               seat: SearchSeat(
  //                 findSeat.seats[k].number,
  //                 seatmaps.seatMapResponse.seatMapResponses[j].SeatMapDetails,
  //               ),
  //             };
  //           }
  //         }
  //       }
  //     }
  //   }

  //   // setSubmitSeats(submitSeats);

  //   // setTravelers([...travelers]);
  //   let gds = getURLParam('gds');
  //   let pcc = getURLParam('pcc');
  //   let pnr = getURLParam('pnr');
  //   let cid = null;
  //   if (gds == 'F') {
  //     cid = 'flxndc';
  //   } else if (gds == 'N') {
  //     cid = 'flxgndc';
  //   } else {
  //     enqueueSnackbar('CID is not available', {
  //       variant: 'error',
  //       autoHideDuration: 6000,
  //       anchorOrigin: { horizontal: 'center', vertical: 'top' },
  //     });
  //     return;
  //   }
  //   bookSeats(cid, gds, pcc, pnr, passengers, submitSeats, [...travelers]);
  // };

  const handleSubmitSeat = () => {
    setLoadingBookSeat(true);
  };

  const bookServices = (
    cid,
    gds,
    pcc,
    pnr,
    passengerToServices,
    passengers,
  ) => {
    // console.log('book services', passengerToServices, passengers)
    if (
      !passengerToServices ||
      passengerToServices.length === 0 ||
      passengerToServices
        .map((x) => x.bundles.length)
        .reduce((prev, curr) => prev + curr, 0) === 0
    ) {
      return;
    }

    let passengerServices = [];
    for (let passenger of passengers) {
      passengerServices.push({
        firstName: passenger.firstName,
        middleName: passenger.middleName,
        lastName: passenger.lastName,
        nameRef: passenger.nameRef,
      });
    }

    for (let passengerToService of passengerToServices) {
      if (
        passengerToService.passengeRefs &&
        passengerToService.passengeRefs.length >= 2
      ) {
        // let passengerIndex = parseInt(passengerToService.passengeRefs.substring(1)) - 1
        let passengerIndex = passengerServices.findIndex(
          (p) => p.nameRef === passengerToService.passengeRefs,
        );
        if (passengerIndex < 0) {
          continue;
        }

        if (passengerServices[passengerIndex]) {
          for (let bundleItem of passengerToService.bundles) {
            for (let offerItem of bundleItem.offerInfo.offerItems) {
              let selections = [];
              selections.push({
                serviceid: offerItem.serviceId,
                serviceDefinitionId: offerItem.serviceDefinitionId,
              });

              let service = {
                shoppingResponseId: bundleItem.offerInfo.responseId,
                offerId: bundleItem.offerInfo.offerId,
                owner: bundleItem.offerInfo.owner,
                offerItemRef: offerItem.offerItemId,
                passengerRef: offerItem.passengeRefs,
                baseFare: bundleItem.baseAmount,
                tax: bundleItem.taxAmount,
                totalPrice: bundleItem.totalAmount,
                serviceName: bundleItem.name,
                selections: selections,
              };

              if (passengerServices[passengerIndex].services) {
                passengerServices[passengerIndex].services.push(service);
              } else {
                passengerServices[passengerIndex].services = [service];
              }
            }
          }
        }
      }
    }

    // console.log({passengers: passengerServices})
    setProgressingServices(true);
    let params = {
      cid: cid,
      customerSessionId: 'S12345',
      gds: gds,
      pcc: pcc,
      pnr: pnr,
      passengers: JSON.stringify({ passengers: passengerServices }),
    };

    axios
      .post('api/reserveServices', params)
      .then(function (res) {
        const resJson = JSON.parse(res.data);
        if ('APIError' in resJson) {
          for (let error of resJson.APIError.errors) {
            enqueueSnackbar('Book services: ' + error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        } else if (resJson.reserveServiceResponse.isSuccess) {
          enqueueSnackbar('Book services: success', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });

          setPassengerToServices([...passengerToServices]);
        } else {
          enqueueSnackbar(
            'Book services: ' + resJson.reserveServiceResponse.errorMessage,
            {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            },
          );
        }
      })
      .catch(function (error) {
        enqueueSnackbar('Book services: ' + error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setProgressingServices(false);
      });
  };

  const bookSeats = (
    cid,
    gds,
    pcc,
    pnr,
    passengers,
    submitSeats,
    travelers,
  ) => {
    if (!submitSeats || submitSeats.length === 0) {
      return null;
    }
    // console.log('book seats', submitSeats, travelers)

    let passengerSeats = [];
    for (let travelerDetail of travelers) {
      let seats = [];
      for (let flight of travelerDetail.flights) {
        if (flight.selectedSeat && flight.selectedSeat.number) {
          const offerItem = flight.selectedSeat.seat.OfferItems.find(
            (item) => item.PassengerRef === travelerDetail.passengerKey,
          );
          seats.push({
            shoppingResponseId: seatmaps.seatMapResponse.shoppingResponseID,
            offerId: seatmaps.seatMapResponse.offerID,
            owner: seatmaps.seatMapResponse.owner,
            offerItemRef: offerItem.OfferItemRef,
            passengerRef: offerItem.PassengerRef,
            rowNumber: flight.selectedSeat.number.substring(
              0,
              flight.selectedSeat.number.length - 1,
            ),
            seatNumber: flight.selectedSeat.number.substring(
              flight.selectedSeat.number.length - 1,
            ),
            baseFare: offerItem.BaseFare,
            tax: offerItem.Tax,
            totalPrice: offerItem.TotalPrice,
            flightId: flight.segments[0].FlightID,
            airline: flight.segments[0].marketingAirline,
            flightNumber: flight.segments[0].flightNumber,
            oldOrderItemId:
              flight.areadySelectedSeat && flight.areadySelectedSeat.orderItemId
                ? flight.areadySelectedSeat.orderItemId
                : '',
          });
        }
      }

      // console.log('passengers', passengers, travelerDetail)
      let findPassenger = null;
      if (gds == 'N') {
        findPassenger = passengers.find(
          (p) => p.nameRef === travelerDetail.traveler.id,
        );
      } else {
        findPassenger = passengers.find(
          (p) => p.nameRef === travelerDetail.passengerKey,
        );
      }

      if (findPassenger) {
        passengerSeats.push({
          firstName: findPassenger.firstName,
          middleName: findPassenger.middleName,
          lastName: findPassenger.lastName,
          seats: seats,
        });
      }
    }

    let params = {
      cid: cid,
      customerSessionId: 'S12345',
      gds: gds,
      pcc: pcc,
      pnr: pnr,
      passengers: JSON.stringify({ passengers: passengerSeats }),
    };

    // console.log('book seats params', params);
    // return
    setProgressingSeats(true);
    axios
      .post('api/reserveseat', params)
      .then(function (res) {
        // console.log('seatmaps book', res, JSON.parse(res.data))
        const resJson = JSON.parse(res.data);
        if ('APIError' in resJson) {
          for (let error of resJson.APIError.errors) {
            enqueueSnackbar('Book seats: ' + error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        } else if (resJson.reserveSeatResponse.isSuccess) {
          enqueueSnackbar('Book seats: success', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          setSubmitSeats(submitSeats);

          setTravelers([...travelers]);
        } else {
          enqueueSnackbar(resJson.reserveSeatResponse.errorMessage, {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        }
      })
      .catch(function (error) {
        // console.error(error);
        enqueueSnackbar('Book seats: ' + error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setProgressingSeats(false);
      });
  };

  // const handleClickBook = ()=>{
  //   if((!passengerToServices || passengerToServices.length == 0) && (!submitSeats || submitSeats.length == 0)){
  //     enqueueSnackbar('Please select the product you would like to order', {
  //       variant: 'warning',
  //       autoHideDuration: 6000,
  //       anchorOrigin: { horizontal: 'center', vertical: 'top' },
  //     });

  //     return
  //   }

  //   let pcc = getURLParam('pcc')
  //   let pnr = getURLParam('pnr')
  //   if(!pcc || !pnr){
  //     enqueueSnackbar('Requested data is not available',{variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
  //     return
  //   }

  //   setBooking(true)
  //   axios.get('api/passengers?' + new URLSearchParams({
  // 		pcc: pcc,
  // 		recordLocator: pnr
  // 	}))
  // 	.then(function (res) {
  // 	  if(res.data && res.data.length > 0){
  //       bookServices(pcc, pnr, res.data)
  //       bookSeats(pcc, pnr, res.data)

  //     }else
  //     {
  //       enqueueSnackbar("Passenger data not found", {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
  //     }

  // 	})
  // 	.catch(function (error) {
  // 	enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})

  // 	}).then(function () {
  //     setBooking(false)
  // 	});

  //   // console.log('book', passengerToServices, submitSeats)

  //   }

  const handleClickNext = (step) => {
    if (typeof step === 'number') {
      if (step === bundlesCount - 1) {
        setShowServicesSubmit(true);
      }
      setServicesActiveStep(step);
    } else {
      if (servicesActiveStep >= bundlesCount - 1) {
        return;
      }

      setServicesActiveStep(servicesActiveStep + 1);
      if (servicesActiveStep + 1 === bundlesCount - 1) {
        setShowServicesSubmit(true);
      }
    }
  };

  const handleClickSeatNext = (step) => {
    // console.log('click next', step)
    // console.log('click next', seatsActiveStep, seatsCount)
    if (typeof step === 'number') {
      if (step === seatsCount - 1) {
        setShowSeatSubmit(true);
      }
      setSeatsActiveStep(step);
    } else {
      if (seatsActiveStep >= seatsCount - 1) {
        return;
      }
      setSeatsActiveStep(seatsActiveStep + 1);
      if (seatsActiveStep + 1 === seatsCount - 1) {
        setShowSeatSubmit(true);
      }
    }
  };

  const handleSetloadingBookSeat = (loading) => {
    setLoadingBookSeat(loading);
  };

  const handleBookSeatResponse = (response) => {
    setOpenSeat(false);
    setSubmitSeats([...response]);
  };

  return (
    <Box>
      <link href="/css/book.css" rel="stylesheet" type="text/css" />
      <link href="/css/FlightDetails.css" rel="stylesheet" type="text/css" />

      {/* <Button onClick={}>Loading data</Button>
      <Button onClick={handleClickSeat}>Load Seats</Button> */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            // alignItems="center"
            rowSpacing={3}
          >
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  margin: 'auto',
                  maxWidth: 500,
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          Travel add-ons
                        </Typography>
                      </Grid>
                      <Grid item>
                        {/* <Button onClick={handleClickService} variant="contained">add-ons service</Button> */}
                        <LoadingButton
                          loading={progressingServices}
                          onClick={handleClickService}
                          variant="outlined"
                        >
                          Select Services
                        </LoadingButton>
                        <Dialog
                          fullScreen
                          open={openSerivce}
                          // onClose={()=>setBundleOpen(false)}
                          aria-labelledby="responsive-dialog-title"
                          // TransitionComponent={Transition}
                        >
                          <DialogTitle>Travel add-ons</DialogTitle>
                          <DialogContent>
                            <Bundle
                              routing={{ trips }}
                              services={
                                services &&
                                services.length > 0 &&
                                services.filter(
                                  (item) => item.name !== 'FareLock',
                                )
                              }
                              // selectedBundles={selectedServices}
                              // onSelectBundle={handleSelectService}
                              passengers={passengers}
                              currentStep={servicesActiveStep}
                              onClickNext={handleClickNext}
                              loadingBook={loadingBookServices}
                              onBookResponse={handleBookServicesResponse}
                              requestParas={{
                                cid:
                                  getURLParam('gds') == 'F'
                                    ? 'flxndc'
                                    : getURLParam('gds') == 'N'
                                    ? 'flxgndc'
                                    : '',
                                customerSessionId: 'S12345',
                                gds: getURLParam('gds'),
                                pcc: getURLParam('pcc'),
                                pnr: getURLParam('pnr'),
                              }}
                              shouldBook = {true}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              onClick={() => setOpenSerivce(false)}
                            >
                              Close
                            </Button>
                            {!showServicesSubmit && (
                              <Button
                                variant="contained"
                                color="warning"
                                onClick={handleClickNext}
                              >
                                Next
                              </Button>
                            )}
                            {showServicesSubmit && (
                              <LoadingButton
                              loading={loadingBookServices}
                              variant="contained"
                              onClick={handleServicesSubmit}
                              autoFocus
                              color="warning"
                            >
                              Submit
                            </LoadingButton>
                            )}
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} container>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItemButton
                        onClick={() => setExpandServices(!expandServices)}
                      >
                        <ListItemText primary="Selected Services" />
                        {expandServices ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse
                        in={expandServices}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem>
                            {passengerToServices.map((item) => (
                              <Grid key={item.passengeRefs} item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="h6"
                                      style={{ textAlign: 'center' }}
                                    >
                                      {item.passengeRefs.replace('T', 'P')}
                                    </Typography>
                                  </Grid>
                                  {item.bundles.map((bundle, index) => (
                                    <Grid
                                      key={
                                        item.passengeRefs + bundle.name + index
                                      }
                                      item
                                      xs={12}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                      >
                                        <Grid item xs={4}>
                                          <Typography>{bundle.name}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Grid container spacing={2}>
                                            {bundle.routings.map((routing) => (
                                              <Grid
                                                key={
                                                  routing.DepartureAirport +
                                                  routing.ArrivalAirport
                                                }
                                                item
                                                xs={12}
                                              >
                                                <Typography>
                                                  {routing.DepartureAirport +
                                                    ' to ' +
                                                    routing.ArrivalAirport}
                                                </Typography>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography>
                                            ${bundle.totalAmount}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            ))}
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                  </Grid> */}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                sx={{
                  // p: 2,
                  margin: 'auto',
                  maxWidth: 500,
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          Seat
                        </Typography>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          loading={progressingSeats}
                          onClick={handleClickSeat}
                          variant="outlined"
                        >
                          Select Seats
                        </LoadingButton>
                        <Dialog
                          className="dialog-seatMap"
                          fullScreen
                          open={openSeat}
                          onClose={() => setOpenSeat(false)}
                          aria-labelledby="responsive-dialog-title"
                          // TransitionComponent={Transition}
                        >
                          <DialogTitle>Seat map</DialogTitle>
                          <DialogContent>
                            <Seats
                              seatmaps={seatmaps}
                              // onSelectedSeats={handleSelectedSeats}
                              // selectedSeats={selectedSeats}
                              currentStep={seatsActiveStep}
                              onClickNext={handleClickSeatNext}
                              travelers={travelers}
                              loadingBook={loadingBookSeat}
                              onSubmit={handleSetloadingBookSeat}
                              requestParas={{
                                cid:
                                  getURLParam('gds') == 'F'
                                    ? 'flxndc'
                                    : getURLParam('gds') == 'N'
                                    ? 'flxgndc'
                                    : '',
                                customerSessionId: 'S12345',
                                gds: getURLParam('gds'),
                                pcc: getURLParam('pcc'),
                                pnr: getURLParam('pnr'),
                              }}
                              onBookResponse={handleBookSeatResponse}
                              shouldBook={true}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              autoFocus
                              onClick={() => setOpenSeat(false)}
                            >
                              Close
                            </Button>
                            {!showSeatSubmit && (
                              <Button
                                variant="contained"
                                color="warning"
                                onClick={handleClickSeatNext}
                              >
                                Next
                              </Button>
                            )}
                            {showSeatSubmit && (
                              <LoadingButton
                                loading={loadingBookSeat}
                                onClick={handleSubmitSeat}
                                variant="contained"
                              >
                                Submit
                              </LoadingButton>
                            )}
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item></Grid>
                  {/* <Grid item xs={12} container>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItemButton
                        onClick={() => setExpandSeats(!expandSeats)}
                      >
                        <ListItemText primary="Selected Seats" />
                        {expandSeats ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={expandSeats} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem>
                            <Grid container sx={{ p: 2 }} spacing={2}>
                              {submitSeats.map((item, index) => (
                                <Grid
                                  key={
                                    item.segment.origin +
                                    item.segment.destination +
                                    index
                                  }
                                  item
                                  xs={12}
                                >
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                  >
                                    <Typography variant="h5" gutterBottom>
                                      {item.segment.origin +
                                        ' to ' +
                                        item.segment.destination}
                                    </Typography>
                                  </Grid>
                                  {item.seats.map((seat, seatIndex) => (
                                    <Stack
                                      key={seat.number}
                                      spacing={2}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                          <Typography
                                            variant="body1"
                                            gutterBottom
                                          >
                                            P {seatIndex + 1}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                          <Typography
                                            variant="body1"
                                            gutterBottom
                                          >
                                            {seat.number}
                                          </Typography>
                                          <Typography variant="h6" gutterBottom>
                                            $
                                            {
                                              seat.travelerPricing[0].price
                                                .total
                                            }
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  ))}
                                </Grid>
                              ))}
                            </Grid>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                  </Grid> */}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader title="Summary" />

            <CardContent>
              <Grid container direction="column" justifyContent="left">
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Services:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        {console.log('passenger to services', passengerToServices)}
                        {passengerToServices &&
                          passengerToServices.length > 0 &&
                          passengerToServices
                            .map((item) =>
                              item.bundles.reduce(
                                (prev, curr) => prev + curr.totalAmount,
                                0,
                              ),
                            )
                            .reduce((prev, curr) => prev + curr, 0)
                            .toLocaleString('en', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Seats:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        {submitSeats &&
                          submitSeats.length > 0 &&
                          submitSeats
                            .map((seat) =>
                              seat.seats.reduce((prev, curr) => {
                                return (
                                  prev + curr.travelerPricing[0].price.total
                                );
                              }, 0),
                            )
                            .reduce((x, y) => x + y, 0)
                            .toLocaleString('en', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Total:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {(
                          (passengerToServices && passengerToServices.length > 0
                            ? passengerToServices
                                .map((item) =>
                                  item.bundles.reduce(
                                    (prev, curr) => prev + curr.totalAmount,
                                    0,
                                  ),
                                )
                                .reduce((prev, curr) => prev + curr, 0)
                            : 0) +
                          (submitSeats && submitSeats.length > 0
                            ? submitSeats
                                .map((seat) =>
                                  seat.seats.reduce((prev, curr) => {
                                    return (
                                      prev + curr.travelerPricing[0].price.total
                                    );
                                  }, 0),
                                )
                                .reduce((x, y) => x + y, 0)
                            : 0)
                        ).toLocaleString('en', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions disableSpacing></CardActions>
          </Card>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
