// import logo from './logo.svg';
import Flight from './pages/Flight'
import Search from './pages/Search';
import {Route, Routes} from "react-router-dom";
import NotFound from './pages/NotFound'
import Loading from './components/Loading'
import Book from './pages/Book';
import Demo from './pages/Demo'
import Success from './pages/Success';
import Share from './pages/Share';
import Payment from './pages/Payment';
import Order from './pages/Order';
import PaymentResult from './pages/PaymentResult';
import Package from './pages/Package';
import{ useEffect, useState } from 'react'
import intl from 'react-intl-universal';
import moment from 'moment';
import Hotel from './pages/Hotel';
import HotelDetail from './pages/HotelDetail';
import HotelBook from './pages/HotelBook';
import HotelInput from './pages/HotelInput';
import Seatmap from './pages/Seatmap';
import SeatmapDemo from './pages/SeatmapDemo';
import SeatDemo from './components/SeatDemo';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CheckOut from './pages/CheckOut';
import ReserveBundle from './pages/ReserveBundle';
import Services from './pages/Services';
import OrderDetail from './pages/OrderDetail';


// const locales = {
// 	"en-us": require('../src/locales/en-US.json'),
//     "zh-cn": require('../src/locales/zh-CN.json'),
// }

function App() {
  const [initDone, setInitDone] = useState(false)

  function loadLocales(){
    let currentLocale = intl.determineLocale({
        urlLocaleKey: 'lang',
        cookieLocaleKey: 'lang',
        localStorageLocaleKey: 'lang'
    })

    if(!currentLocale){
      currentLocale = "en-US"
    }else if(currentLocale.length == 2){ 
      if(currentLocale.toLocaleLowerCase() == "en"){
        currentLocale = "en-US"
      }else if(currentLocale.toLocaleLowerCase() == "zh"){
        currentLocale = "zh-CN"
      }
    }

    moment.locale(currentLocale)

    intl.init({
            currentLocale: currentLocale.toLowerCase(),
            locales:{[currentLocale.toLocaleLowerCase()]: require('../src/locales/'+currentLocale+'.json')}
        })
    .then(()=>{
          setInitDone(true)
        })
    // console.log('locales', locales)

    // fetch('/api/locales?value=' + currentLocale).then(response=> 
    //   response.json()).then(data => 
    //     {
    //       intl.init({
    //         currentLocale: currentLocale.toLowerCase(),
    //         locales:{[currentLocale.toLocaleLowerCase()]: JSON.parse(data)}
    //     })
    //     .then(()=>{
    //         setInitDone(true)

    //     })
    //     }).catch(error => {
    //       alert('An error occurred in loading the language pack!')
    //   });
    }

  useEffect(()=>{
    loadLocales()
  },[])

  return (
    initDone ?
    <Routes>
        <Route path='/search' element={<Search/>}/>
        <Route path='/search_auth' element={<Search/>}/>
        <Route path='/flight' element={<Flight/>}/>
        <Route path='/book' element={<Book/>}/>
        <Route path='/success' element={<Success/>}/>
        <Route path='/' element={<Search/>}/>
        <Route path="*" element={<NotFound />}/>
        <Route path='/loading' element={<Loading/>}/>
        <Route path='/order' element={<Order/>}/>
        <Route path='/share/:key' element={<Share/>}/>
        <Route path='/payment/:key' element={<Payment/>}/>
        {/* <Route path='/demo' element={<Demo/>}/> */}
        <Route path='/PaymentResult' element={<PaymentResult/>}/>
        <Route path='/package' element={<Package/>}/>
        <Route path='/hotel' element={<Hotel/>}/>
        <Route path='/hotelDetail' element={<HotelDetail/>}/>
        <Route path='/hotelBook' element={<HotelBook/>}/>
        <Route path='/hotelInput' element={<HotelInput/>}/>
        <Route path='/seatmap' element={<Seatmap/>}/>
        <Route path='/seatmapdemo' element={<SeatmapDemo/>}/>
        <Route path='/demo' element={<Demo/>}/>
        <Route path='/seat' element={<SeatDemo/>}/>
        <Route path='/checkOut' element={<CheckOut/>}/>
        <Route path='/reserveBundle' element={<ReserveBundle/>}/>
        <Route path='/Services' element={<Services/>}/>
        <Route path='/OrderDetail' element={<OrderDetail/>}/>
    </Routes> 
    : <Stack spacing={1}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        {/* For other variants, adjust the size with `width` and `height` */}
        <Skeleton variant="rectangular" height="60vh" />
        <Skeleton variant="rounded" height="20vh" />
      </Stack>
  );
}

export default App;
