import React from 'react';
import Box from '@mui/material/Box';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  Divider,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { useEffect } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import style from './index.module.css';
import ButtonAppBar from '../../components/ButtonAppBar';
import { LinearProgress } from '@mui/material';
import { getURLParam } from '../../common/helper.js';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// function parseStatus(opStatus, paymentStatus){
//   if(opStatus == "New" && paymentStatus == "No Payment"){
//     return 1
//   }else if(opStatus == "New" && paymentStatus == "Full Payment"){
//     return 2
//   }else if(opStatus == "Processing"){
//     return 3
//   }else if(opStatus == "Issued"){
//     return 4
//   }else if(opStatus == "Cancelled"){
//     return 5
//   }

//   return 0
// }

// function parseOrderStatus(opStatus){
//   if(opStatus == "New"){
//     return 1
//   }else if(opStatus == "Processing"){
//     return 2
//   }else if(opStatus == "Issued"){
//     return 3
//   }else if(opStatus == "Cancelled"){
//     return 4
//   }

//   return 0
// }

function parseStatus(opStatus, paymentStatus) {
  if (opStatus == 'New' && paymentStatus == 'No Payment') {
    return paymentStatus;
  } else if (opStatus == 'New' && paymentStatus == 'Full Payment') {
    return paymentStatus;
  }

  return opStatus;
}

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

function Item(props) {
  // console.log('order item', props)
  const navigate = useNavigate();
  const [sqlResult, setSqlResult] = useState(null);
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState(null);

  function getURL() {
    return `api/orders?caseNumber=${props.confirmationCode}&cid=${
      props.source
    }&customerSessionId=${'S12345'}`;
  }

  function handleClick() {
    setOpen(true);
      fetch(getURL())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return Promise.reject(response.statusText);
          }
        })
        .then((data) => {
          setOpen(false);
          // let jsonData = JSON.parse(data);
          // console.log('order json', jsonData)
          let apiResultJson = data.apiResult;
          // console.log('apiResultJons', apiResultJson)
          let sqlResultJson = data.sqlResult;
          // console.log(sqlResultJson)
          // for(let passenger of sqlResultJson.passengers){
          //   for(let apiPassenger of apiResultJson.order.passengers){
          //     if(passenger.firstName == apiPassenger.firstName && passenger.lastName == apiPassenger.lastName){
          //       passenger.tktnumber=apiPassenger.ticketNumber
          //     }
          //   }
          // }
          let seats = [];
          let services = [];
          if (apiResultJson.order && apiResultJson.order.passengers) {
            for (let passenger of apiResultJson.order.passengers) {
              if (passenger.seats) {
                for (let seat of passenger.seats) {
                  seats.push({
                    segment: null,
                    seats: [
                      {
                        travelerPricing: [
                          {
                            price: { currency: 'USD', total: seat.TotalPrice },
                          },
                        ],
                      },
                    ],
                  });
                }
              }

              if (passenger.services) {
                let bundles = [];
                for (let service of passenger.services) {
                  bundles.push({ totalAmount: service.TotalPrice });
                }

                services.push({ bundles: bundles });
              }
            }
          }

          // console.log('services', services);
          // {segment: null, seats: [{travelerPricing:[{price: {currency: 'USD', total: }}]}]
          if (sqlResultJson == null) {
            let trips = [];
            let segments = [];
            for (let seg of apiResultJson.order.segments) {
              console.log('seg', seg);
              segments.push({
                departureTime: moment(seg.departureTime).format(
                  'MM/DD/YYYY HH:mm',
                ),
                operateFlight: '',
                arrivalAirportCode: seg.arrivalAirportCode,
                // childFarebasis: "",
                codeShare: '',
                departureAirportCode: seg.departureAirportCode,
                baggageUnit: '',
                stop: 0,
                flightNumber: seg.flightNumber,
                adultFarebasis: '',
                duration: 0,
                airlineCode: seg.airlineCode,
                BookingClass: seg.bookingClass,
                baggagePieces: 0,
                isShare: false,
                arrivalTime: moment(seg.arrivalTime).format('MM/DD/YYYY HH:mm'),
                stopAirports: [],
                baggageAllowance: 0,
                aircraftCode: '',
                cabin:
                  seg.cabin && seg.cabin != '' ? seg.cabin.substring(0, 1) : '',
              });
            }

            trips.push({
              duration: 0,
              segments: segments,
              tripTime: 0,
            });

            // console.log(trips)
            let findAdult = apiResultJson.order.price.find(
              (p) => p.passengerType == 'Adult',
            );
            let findChild = apiResultJson.order.price.find(
              (p) => p.passengerType == 'Child',
            );
            let adults = apiResultJson.order.passengers.filter(
              (p) => p.type == 'Adult',
            ).length;
            let children = apiResultJson.order.passengers.filter(
              (p) => p.type == 'Child',
            ).length;
            sqlResultJson = {
              routingText: JSON.stringify({
                totalPrice: apiResultJson.order.totalPrice,
                adultBasePrice: findAdult ? findAdult.baseFare : 0,
                adultTax: findAdult ? findAdult.tax : 0,
                childBasePrice: findChild ? findChild.baseFare : 0,
                childTax: findChild ? findChild.tax : 0,
                cid: apiResultJson.order.source,
                trips: trips,
              }),
              order: {
                adults: adults,
                children: children,
                currency: 'USD',
                shortLink: '',
              },
            };
          }

          setSqlResult(sqlResultJson);
          setContact(apiResultJson.order.contact);
          navigate(`/success`, {
            state: {
              bookInfo: {
                routing: sqlResultJson.routingText,
                passengers: apiResultJson.order.passengers,
                adults: sqlResultJson && sqlResultJson.order.adults,
                children: sqlResultJson && sqlResultJson.order.children,
                openid: props.openid,
                contact: apiResultJson.order.contact,
              },
              bookedRsp: {
                airTicketBookingResponse: {
                  recordLocator: props.recordLocator,
                  confirmationCode: props.confirmationCode,
                  paymentLink: sqlResultJson && sqlResultJson.order.shortLink,
                  airlineRecordLocator: props.airlineRecordLocator || apiResultJson.order.airlineLocator,
                },
              },
              currency: sqlResultJson && sqlResultJson.order.currency,
              orderTime: props.bookingDate,
              orderStatus: parseStatus(props.opStatus, props.paymentStatus),
              seats: seats,
              bundles: services,
              order: { gds: props.gds, pcc: props.pcc, invoiceNumber: apiResultJson.order.invoiceNumber },
              source: props.source,
            },
          });
        })
        .catch((error) => {
          // console.error(error)
          setOpen(false);
          alert(error);
        });
    // if (sqlResult) {
    //   navigate(`/success`, {
    //     state: {
    //       bookInfo: {
    //         routing: sqlResult.routingText,
    //         passengers: sqlResult.passengers,
    //         adults: sqlResult.order.adults,
    //         children: sqlResult.order.children,
    //         openid: props.openid,
    //         contact: contact,
    //       },
    //       bookedRsp: {
    //         airTicketBookingResponse: {
    //           recordLocator: props.recordLocator,
    //           confirmationCode: props.confirmationCode,
    //           paymentLink: sqlResult.order.shortLink,
    //           airlineRecordLocator: props.airlineRecordLocator,
    //         },
    //       },
    //       currency: sqlResult.order.currency,
    //       orderTime: props.bookingDate,
    //       orderStatus: parseStatus(props.opStatus, props.paymentStatus),
    //       seats: [],
    //       bundles: [],
    //       order: { gds: props.gds, pcc: props.pcc },
    //       source: props.source,
    //     },
    //   });
    // } else {
      
    // }
  }

  function handlePayment() {
    if (
      props.opStatus == 'Cancelled' ||
      props.opStatus == 'Issued' ||
      props.paymentStatus != 'No Payment'
    ) {
      return;
    }

    if (sqlResult) {
      navigate(`/payment/` + sqlResult.order.shortLink);
    } else {
      setOpen(true);
      fetch(getURL())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return Promise.reject(response.statusText);
          }
        })
        .then((data) => {
          setOpen(false);
          // let jsonData = JSON.parse(data);
          let apiResultJson = data.apiResult;
          let sqlResultJson = data.sqlResult;
          for (let passenger of sqlResultJson.passengers) {
            for (let apiPassenger of apiResultJson.order.passengers) {
              if (
                passenger.firstName == apiPassenger.firstName &&
                passenger.lastName == apiPassenger.lastName
              ) {
                passenger.tktnumber = apiPassenger.ticketNumber;
              }
            }
          }
          setSqlResult(sqlResultJson);
          navigate(`/payment/` + sqlResultJson.order.shortLink);
        })
        .catch((error) => {
          setOpen(false);
          // console.error(error)
          alert(error);
        });
    }
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <Grid item xs container direction="column" spacing={2}>
          <Paper sx={{ padding: '5px' }}>
            <Grid item xs>
              {/* <Button variant="contained" endIcon={<AddCircleOutlineIcon />}>
        
</Button> */}
              <Link component="button" variant="body1" onClick={handleClick}>
                {`${intl.get(
                  'AIRPORT.' + props.departure.toUpperCase() + '.CITYNAME',
                )}-${intl.get(
                  'AIRPORT.' + props.arrival.toUpperCase() + '.CITYNAME',
                )} (${props.recordLocator})`}
              </Link>
              {/* <Typography gutterBottom variant="subtitle1" component="div">

          

          <IconButton color="primary" aria-label="display trip detail" onClick={handleClick}>
                    <AddCircleOutlineIcon />
</IconButton>

                    </Typography> */}
              {bull}
              <Typography variant="button" gutterBottom>
                <small
                  onClick={handlePayment}
                  className={
                    props.opStatus == 'Cancelled'
                      ? style.cancelledStatus
                      : style.status
                  }
                >
                  {parseStatus(props.opStatus, props.paymentStatus)}
                </small>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="text.secondary">
              Flight time:{' '}
                {`${moment(props.departureTime).format('MMM Do YYYY')}${
                  props.arrivalTime
                    ? ' - ' + moment(props.arrivalTime).format('MMM Do YYYY')
                    : ''
                }`}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                Order time:{' '}
                {moment(props.bookingDate).format('MMM Do YYYY HH:mm:ss')}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default function Order() {
  const { enqueueSnackbar } = useSnackbar();
  const [orders, setOrders] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // const [groups, setGroups] = useState([])
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [searchPnr, setSearchPnr] = useState('')
  const [startDate, setStartDate] = useState(moment().subtract(3, 'days'))
  // const [endDate, setEndDate] = useState(null)

  const request = (pnr, date) => {
    // console.log(pnr, date)
    // let openid = getURLParam("p");
    let openid = sessionStorage.getItem('openid');
    // if(!openid || sessionStorage.getItem("searchAuth")){
    //     openid = sessionStorage.getItem("openid")
    // }

    if (openid) {
      setLoaded(false)
      // let url = 'api/orders?openid=' + openid
      // if(pnr){
      //   url += '&pnr=' + pnr
      // }else{
      //   if(date){
      //     url += '&dateFrom=' + moment(date).format('YYYY-MM-DD')
      //   }
      // }

      fetch(`api/orders?openid=${openid}&pnr=${pnr || ''}&dateFrom=${date ? moment(date).format('YYYY-MM-DD') : ''}&dateTo=${''}&status=${0}`)
      // fetch(`api/orders?openid=${openid}&dateFrom=${date ? moment(date).format('YYYY-MM-DD') : ''}`)
        .then((response) => {
          // console.log('resonse', response, response.headers.get('Content-Length'))
          if (response.ok) {
            if (response.headers.get('Content-Length') == 0) {
              return null;
            } else {
              return response.json();
            }
          } else {
            return Promise.reject({
              status: response.status,
              message: response.statusText,
            });
          }
        })
        .then((data) => {
          // console.log("order data", data);
          if (data) {
            let items = [];
            for (let orderDetail of data.airTicketOrderResponse) {
              // let trips = orderDetail.order.trip.split('/')
              // console.log("trips", trips);
              items.push({
                departure: orderDetail.departureAirportCode,
                arrival: orderDetail.destinationAirportCode,
                departureTime: orderDetail.departureDateTime,
                arrivalTime: orderDetail.returnDateTime,
                routing: null,
                recordLocator: orderDetail.recordLocator,
                confirmationCode: orderDetail.caseNumber,
                airlineRecordLocator: orderDetail.airlineRecordLocator,
                passengers: orderDetail.passengerInfo,
                currency: 'USD',
                paymentStatus: orderDetail.paymentStatus,
                opStatus: orderDetail.opStatus,
                bookingDate: orderDetail.bookingDate,
                openid,
                gds: orderDetail.gds,
                pcc: orderDetail.pcc,
                source: orderDetail.source,
                // shortUrl: orderDetail.order.shortLink
              });
            }

            setOrders(items);
          }
        })
        .catch((error) => {
          // console.error(error)
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        })
        .then(() => setLoaded(true));
    } else {
      setLoaded(true);
      enqueueSnackbar('OPENID NOT FOUND!', {
        variant: 'error',
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      // alert('NOT FOUND');
    }
  };

  useEffect(() => {
    let caseNumber = getURLParam('caseNumber');
    if (caseNumber) {
      fetch(`api/orders?caseNumber=${caseNumber}&count=2`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return Promise.reject(response.statusText);
          }
        })
        .then((data) => {
          setLoaded(true);
          let jsonData = JSON.parse(data);
          let apiResultJson = JSON.parse(jsonData.apiResult);
          let sqlResultJson = JSON.parse(jsonData.sqlResult);
          for (let passenger of sqlResultJson.passengers) {
            for (let apiPassenger of apiResultJson.order.passengers) {
              if (
                passenger.firstName == apiPassenger.firstName &&
                passenger.lastName == apiPassenger.lastName
              ) {
                passenger.tktnumber = apiPassenger.ticketNumber;
              }
            }
          }

          // if(sqlResultJson == null){
          //   sqlResultJson = {order: {adults: 1, children: 0, currency: "USD", shortLink: ""}}
          // }

          // console.log('case number', props)

          navigate(`/success`, {
            state: {
              bookInfo: {
                routing: sqlResultJson.routingText,
                passengers: sqlResultJson.passengers,
                adults: sqlResultJson.order.adults,
                children: sqlResultJson.order.children,
                openid: null,
                contact: apiResultJson.order.contact,
              },
              bookedRsp: {
                airTicketBookingResponse: {
                  recordLocator: apiResultJson.order.pnr,
                  confirmationCode: apiResultJson.order.caseNumber,
                  paymentLink: sqlResultJson.order.shortLink,
                },
              },
              currency: sqlResultJson.order.currency,
              orderTime: new Date(),
              orderStatus: parseStatus(apiResultJson.order.status, 'Unknown'),
              seats: [],
              bundles: [],
            },
          });
        })
        .catch((error) => {
          setLoaded(true);
          // console.error(error)
          alert(error);
        });
    } else {
      // request(checked);
      request('', startDate)
    }
  }, []);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  //   setLoaded(false);
  //   request(event.target.checked);
  // };
  const handleChangePnr = (event)=>{
    console.log(event.target.value)
    setSearchPnr(event.target.value)
    if(event.target.value.trim().length >= 6){
      request(event.target.value.trim(), null)
    }else if(!event.target.value){
      request(event.target.value, startDate)
    }
  }

  return (
    <>
      <ButtonAppBar />
      {loaded || <LinearProgress />}

      <Box sx={{ bgcolor: '#e7e9ed', padding: '24px' }}>
        <Grid container>
          <Grid item xs={6} md={2}>
            <TextField variant="outlined" label="PNR" size="small" value={searchPnr} onChange={handleChangePnr} />
          </Grid>
          <Grid item xs={6}  md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="From"
        value={startDate}
        onChange={(newValue) => {
          setStartDate(newValue);
          request('', newValue)
        }}
        renderInput={(params) => <TextField {...params} size="small" sx={{width: '90%'}}/>}
      />
    </LocalizationProvider>
          </Grid>
          
          {/* <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="To"
        value={endDate}
        maxDate={new Date()}
        onChange={(newValue) => {
          setEndDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} size="small" sx={{width: '90%'}}/>}
      />
    </LocalizationProvider>
          </Grid> */}
          {/* <Grid item xs={1}>
            <Button variant="contained">Search</Button>
          </Grid> */}
        </Grid>
        {/* <Typography variant="h6" gutterBottom>
          {intl.get('MYORDERS.ORDER_TITLE')}
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label="Last 3 days"
            />
          </FormGroup>
        </Typography> */}
        
        {loaded && orders.length == 0 && (
          <Alert severity="warning" sx={{marginTop: '1rem'}}>NOT FOUND</Alert>
        )}
        <Grid
          container
          sx={{ marginTop: 'auto' }}
          spacing={2}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {orders.map((item, i) => {
            return <Item key={i} {...item} />;
          })}
        </Grid>
        {/* {groups.map((group, i)=>{return <Grid key={i} container spacing={2}>{group.map((item, j)=>{return <Item key={j} {...item}/>})}</Grid>})} */}
      </Box>
    </>
  );
}
