import { listClasses } from "@mui/material";

/**
 * 随机生成 len 位数字符串
 * 由A-Z a-z 0-9等字符组成
 * @param {Number} len 长度
 * @returns 随机字符串
 */
export const generateRandomString = (len = 10) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomCharacter = characters.charAt(randomIndex);
    result += randomCharacter;
  }

  return result;
};

function isObject(object) {
	return object != null && typeof object === 'object';
}

export const deepEqual = (object1, object2)=>{
  const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
	  return false;
	}
	for (const key of keys1) {
		// console.log('key', key, 'key1', keys1)

		if(keys1.indexOf("airlineCode")>=0 &&
		(key != "departureAirportCode" || key != "arrivalAirportCode" || key != "flightNumber" || key != "airlineCode")
		){
			continue
		}
		
	  const val1 = object1[key];
	  const val2 = object2[key];
	//   console.log('equal', val1, val2)
	  const areObjects = isObject(val1) && isObject(val2);
	  if (
		areObjects && !deepEqual(val1, val2) ||
		!areObjects && val1 !== val2
	  ) {
		return false;
	  }
	}
	return true;
}

export const deepPureEqual = (object1, object2)=>{
  const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
	  return false;
	}
	for (const key of keys1) {		
	  const val1 = object1[key];
	  const val2 = object2[key];
	//   console.log('equal', val1, val2)
	  const areObjects = isObject(val1) && isObject(val2);
	  if (
		areObjects && !deepEqual(val1, val2) ||
		!areObjects && val1 !== val2
	  ) {
		return false;
	  }
	}
	return true;
}


export const convertSeatStatus =(status)=>{
  if(status == "A"){
    return "AVAILABLE"
  }else if(status == "N"){
    return "UNAVAILABLE"
  }

  return status
}

export const getSegments = (routing) =>{
	if(!routing){
		return null
	}

	let segments = []
	for(let trip of routing.trips){
		for(let seg of trip.segments){
			segments.push({departureAirportCode: seg.departureAirportCode, arrivalAirportCode: seg.arrivalAirportCode})
		}
	}

	return segments
}
