import React, { useEffect, useState } from 'react'
import ButtonAppBar from '../../components/ButtonAppBar'
import { AppBar, Box, Button, Container, Grid, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { useForm, FormProvider, useFormContext, Controller } from "react-hook-form";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

function removeCss(href) {
  var links = document.getElementsByTagName("link");

  for (var i = links.length-1; i >= 0; i--) {
      var _href = links[i].href;
      // links[i].parentNode.removeChild(links[i]);
      if (links[i] && links[i].href && links[i].href.indexOf(href) != -1) {
          links[i].parentNode.removeChild(links[i]);
      }
  }
}

export default function CheckOut() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const[dateValue, setDateValue] = useState(null)
  const [progressing, setProgressing] = useState(false)
  const [disabled, setDisabled] = useState(false)
  // const [showPayButton, setShowPayButton] = useState(false)

  // let total = 0.01
  
  useEffect(()=>{
    removeCss('css/book.css')
  },[])

  const onSubmit = data => {
    setDisabled(true)
    setProgressing(true)
    // console.log('submit', data)

    let params = {paymentType: 0, channel: "ndctest"}
    params.req = {cid: location.state.cid, routing: {totalPrice: location.state.totalPrice}, currency: "USD", payment: {...data, expirationYear: moment(data.expiration).format('YYYY'), 
    expirationMonth: moment(data.expiration).format('MM')}}
    params.rsp = {airTicketBookingResponse: {confirmationCode: location.state.confirmationCode, recordLocator: location.state.recordLocator}}
 
    // console.log('service book request', params)
    axios.post('/api/payment', params)
      .then(function (res) {
        let json = JSON.parse(res.data)
        if("APIError" in json){
          for(let error of json.APIError.errors){
            enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
            break
          }
        }else{
          if(json.tktStatusResponse.status == "waiting"){
            enqueueSnackbar('success', {variant: 'success', autoHideDuration: 3000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
          }else{
            enqueueSnackbar(res.data, {variant: 'warning', autoHideDuration: 4000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
          }
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
      }).then(function () {
            setProgressing(false)
            setDisabled(false)
      });

  }

  const handleWechat =()=>{
    setDisabled(true)
    setProgressing(true)
    console.log('wechat');
    let params = {paymentType: 1,
      req: {openId: 'oead2wblKN9D9dy6E5ZK5yaL7GcY', cid: 'ndctest', routing: {totalPrice: location.state.totalPrice}, 
    currency: "USD"},
      rsp: {airTicketBookingResponse: {confirmationCode: location.state.confirmationCode, 
        recordLocator: location.state.recordLocator}},
      returnUrl: `${window.location.protocol}//${window.location.host}/paymentresult`}
    
      // console.log(`${window.location.protocol}//${window.location.host}/paymentresult`)
    axios.post('/api/payment', params)
    .then(function (res) {
      // console.log("user data", res)
      let jsonData = JSON.parse(res.data)
      window.location.href=`https://gttb2b.feiquanqiu.com/WebSit/WXPayment.aspx?sign=${jsonData.sign}`
    })
    .catch(function (error) {
      // console.error("flight", error);
      enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    }).then(function () {
      setDisabled(false)
      setProgressing(false)
    });
  }

  return (
    <Box>
    {progressing && <AppBar position="fixed"><LinearProgress /></AppBar>}
    <ButtonAppBar/>
    <Container fixed>
    <Grid container direction="column" justifyContent="space-between">
  <Grid item xs={12} sx={{border: "1px solid #ccc", borderRadius: "6px", marginTop: "1rem", padding: "1rem"}}>
  <Typography variant='h5' sx={{borderBottom: "1px solid #e6e6e6"}}>
    <i>
    <DescriptionIcon/> 
    </i>
    Order</Typography>
  <Grid container padding={2}>
    <Grid item xs={12} sx={{marginBottom: "1rem"}}>
      <Box>
      <Typography variant='caption'>The order {location.state?.confirmationCode} is submitted successfully, please pay as soon as possible!</Typography>
      </Box>
      <Box>
      <Typography variant='caption'>Please complete the payment by 30 minutes or the reservation will be cancelled. The payment transactions are all final and can’t be cancelled. </Typography>
      </Box>
    </Grid>

          <Grid item xs={4} md={4}>
        <Typography variant='h6' sx={{color: 'rgb(211, 47, 47)'}}></Typography>
        </Grid>
        <Grid item xs={4} md={4}></Grid>
        <Grid item xs={4} md={4}>
          <Typography variant='h6' sx={{color: 'rgb(211, 47, 47)'}}>Total: ${location.state?.totalPrice}</Typography>
          </Grid>
      </Grid>
  </Grid>
  <Grid item xs={12} sx={{border: "1px solid #ccc", borderRadius: "6px", marginTop: "1rem", padding: "1rem"}}>
    <Typography variant='h5' sx={{borderBottom: "1px solid #e6e6e6"}}><CreditCardIcon/> Payment</Typography>
  <form onSubmit={handleSubmit(onSubmit)}>
    <Grid container padding={2} spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
        <TextField error={errors.firstName ? true : false} label="First Name" variant="outlined" fullWidth 
        {...register("firstName", { required: true, maxLength: 30 })}
        // helperText={errors.firstName?.message}
        />
      </Grid>
      <Grid item xs={12} md={4}>
      <TextField error={errors.middleName ? true : false} label="Middle Name" variant="outlined" fullWidth {...register("middleName", { maxLength: 30 })}/>
      </Grid>
      <Grid item xs={12} md={4}>
      <TextField error={errors.lastName ? true : false} label="Last Name" variant="outlined" fullWidth {...register("lastName", { required: true, maxLength: 30 })}/>
      </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
          <TextField InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CreditCardOutlinedIcon />
            </InputAdornment>
          ),
        }} label="Credit Card" variant="outlined" error={errors.creditCardNumber ? true : false} {...register("creditCardNumber", 
        { required: true, maxLength: 20,
          pattern: {
            value: /^\d+$/,
            message: "This input is number only.",
          }
        })}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
      <Grid container spacing={1}>
      <Grid item xs={8} md={2}>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>

      <DatePicker defaultValue='2023-06-21'/>
    </LocalizationProvider> */}
      {/* <TextField label="Expiration" placeholder='MM/YY' variant="outlined" fullWidth
      {...register("expiration", { required: true, maxLength: 5 })}
      error={errors.expiration ? true : false}
      /> */}
      <Controller
        name={'expiration'}
        control={control}
        rules={{ required: true }}
        render={({ field,
			fieldState: { invalid, isTouched, isDirty, error },
			formState }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
		<DatePicker
    // error={errors.expiration ? true : false}
		// {...field}
    inputFormat="MM/yy"
    // mask="__/__"
		onBlur={field.onBlur} // notify when input is touched
		inputRef={field.ref}
		// onChange={onChange} // send value to hook form
				//   disableFuture
					minDate={new Date()}
					// maxDate={maxDate}
				  label="Expiration"
				  openTo="year"
				  views={['year', 'month']}
          // format="MM/yy"
				  value={dateValue}
				  onChange={(newValue) => {
					// setDateValue(newValue ? moment(newValue).format("MM/YYYY"): "");
					setDateValue(newValue);
					// console.log("date", newValue)

					field.onChange(newValue)
					// setValue(name, moment(newValue).format("MM/DD/YYYY"))
				  }}
				  renderInput={(params) => <TextField {...params} />}
				/>
				</LocalizationProvider>}
      />
      </Grid>
      <Grid item xs={6} md={2}>
      <TextField type='password' label="CVV" variant="outlined" fullWidth {...register("cardVerificationNumber", 
      { required: true, maxLength: 5 })}
      error={errors.cardVerificationNumber ? true : false}/>
      </Grid>
      </Grid>
      </Grid>
      <Grid item xs={12}>
      <Button type='submit' variant="contained" disabled={disabled}>Pay ${location.state?.totalPrice}</Button>
      </Grid>
    </Grid>
    </form>
  </Grid>
  <Grid item xs={12} sx={{border: "1px solid #ccc", borderRadius: "6px", marginTop: "1rem", padding: "1rem"}}>
    <Typography onClick={handleWechat} variant='subtitle2' style={{"&hover": {color: "#d32f2f"}, cursor:'pointer'}}>
  <svg className="svg-icon" style={{width: '2em', height: '2em', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M395.846 603.585c-3.921 1.98-7.936 2.925-12.81 2.925-10.9 0-19.791-5.85-24.764-14.625l-2.006-3.864-78.106-167.913c-0.956-1.98-0.956-3.865-0.956-5.845 0-7.83 5.928-13.68 13.863-13.68 2.965 0 5.928 0.944 8.893 2.924l91.965 64.43c6.884 3.864 14.82 6.79 23.708 6.79 4.972 0 9.85-0.945 14.822-2.926L861.71 282.479c-77.149-89.804-204.684-148.384-349.135-148.384-235.371 0-427.242 157.158-427.242 351.294 0 105.368 57.361 201.017 147.323 265.447 6.88 4.905 11.852 13.68 11.852 22.45 0 2.925-0.957 5.85-2.006 8.775-6.881 26.318-18.831 69.334-18.831 71.223-0.958 2.92-2.013 6.79-2.013 10.75 0 7.83 5.929 13.68 13.865 13.68 2.963 0 5.928-0.944 7.935-2.925l92.922-53.674c6.885-3.87 14.82-6.794 22.756-6.794 3.916 0 8.889 0.944 12.81 1.98 43.496 12.644 91.012 19.53 139.48 19.53 235.372 0 427.24-157.158 427.24-351.294 0-58.58-17.78-114.143-48.467-163.003l-491.39 280.07-2.963 1.98z" fill="#09BB07" /></svg>
      Wechat pay</Typography>
  </Grid>
</Grid>
    </Container>
    </Box>
  )
}
