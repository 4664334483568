import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Avatar, Button, Divider, FormControlLabel, FormGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
// import intl from 'react-intl-universal';

export default function RecipeReviewCard(props) {
  const [checked, setChecked] = React.useState(props.some || false);
  // console.log('recipeReviewCard', props);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      props.onSelectBundle(props.service, event.target.checked);
    } else {
      props.onSelectBundle(props.service, event.target.checked);
    }
  };

  return (
    <Card raised sx={checked ? {backgroundColor: 'rgb(33 150 243 / 25%)'} : {}}>
      <CardHeader title={props.service.name} avatar={<Avatar sx={{ bgcolor: 'rgb(31, 108, 250)' }}>
        <InfoIcon/>
</Avatar>} subheader={<Divider />} />
      <CardContent>
        {/* {props.service.offerInfo.offerItems.map(item=><Typography key={item.passengeRefs} variant="caption" display="block" gutterBottom>
                {item.passengeRefs}
              </Typography>)} */}
        {/* {console.log('routing', props.service.routings)} */}
        {props.service.routings.map((item) => (
          <Typography
            key={item.DepartureAirport + item.ArrivalAirport}
            variant="caption"
            display="block"
            gutterBottom
            // color='red'
          >
            {`${item.DepartureAirport} to ${item.ArrivalAirport} ${item.AirlineCode}${item.FlightNumber}`}
          </Typography>
        ))}
        {props.service.descriptions &&
          props.service.descriptions.map((item, index) => {
            if (
              item.Text.indexOf('Code: ') >= 0 ||
              item.Text.indexOf('Value:') >= 0 ||
              item.Application !== 'Details'
            ) {
              return null;
            } else {
              return (
                <Typography
                  key={'description' + index}
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  {item.Application}: {item.Text}
                </Typography>
              );
            }
          })}
        <Typography variant="body2" color="text.secondary">
          <strong style={{color: '#212529'}}>
            {props.service.totalAmount.toLocaleString('en', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </strong>{' '}
          per passenger
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="info" fullWidth>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                // sx={{ color: 'rgb(23, 105, 170)' }}
              />
            }
            label="Select"
            // sx={{
            //   '& .MuiFormControlLabel-label': { color: 'rgb(23, 105, 170)'},
            // }}
          />
        </FormGroup>
        </Button>
      </CardActions>
    </Card>
  );
}
