import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Pagination, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import intl from 'react-intl-universal';
import Deck from '../../components/Deck'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
// import Pagination from '@mui/material/Pagination';
import Card from '@mui/material/Card';
import {
  FormControlLabel,
  Checkbox
} from '@mui/material';

function convertSeatStatus(status){
  if(status == "A"){
    return "AVAILABLE"
  }else if(status == "N"){
    return "UNAVAILABLE"
  }

  return status
}

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

// const steps = ['Flight 1 of 2', 'Flight 2 of 2'];


function Traveler(props) {
  // console.log('traveler', props);
  const [checkedSeats, setCheckedSeats] = useState([])


  const handleClickCheck = (flight)=>{
    // console.log('click flight', flight)
    // console.log('click flights', checkedSeats)
    
    let newCheckedSeats = [...checkedSeats]
    let findIndex = newCheckedSeats.findIndex(s=>s.id === flight.segments[0].origin + flight.segments[0].destination)
    let checked
    if(findIndex >= 0){
      checked = !newCheckedSeats[findIndex].checked
      newCheckedSeats[findIndex].checked = checked
      
    }else{
      newCheckedSeats.push({id: flight.segments[0].origin + flight.segments[0].destination, checked: true})
      checked = true
    }
    
    // console.log('new checked seats', checked, newCheckedSeats)
    // return
    setCheckedSeats(newCheckedSeats)
    // props.onHandleDeleteSeat(props.passengerKey, flight, checked)
  }

  return (
    
      <div className='mt-3'>
      <legend className="block-fieldset__legend">
        {props.index}. {props.traveler.name}
        <span className="paxes-fieldset__type">
          &nbsp;(
          {intl.get(
            'TRAVELER.' +
              (props.traveler.passengerTypeCode.toUpperCase() === 'ADT'
                ? 'ADULT'
                : 'CHILD'),
          )}
          )
        </span>
      </legend>
      <Stack spacing={2}>
        {props.flights.map((flight) => (
          <Item
            key={flight.segments[0].origin + flight.segments[0].destination}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="h6" display="block" gutterBottom>
                  {flight.segments[0].origin +
                    '-' +
                    flight.segments[0].destination}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="button" display="block" gutterBottom>
                  {flight.areadySelectedSeat.number}
                  {flight.areadySelectedSeat.number && ' (booked)'}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {flight.areadySelectedSeat.seat &&
                    flight.areadySelectedSeat.seat.OfferItems.find(
                      (item) => item.PassengerRef === props.passengerKey,
                    ).TotalPrice.toLocaleString('en', {
                      style: 'currency', 
                      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="button" display="block" gutterBottom>
                  {flight.selectedSeat && flight.selectedSeat.number}
                  {flight.selectedSeat &&
                    flight.selectedSeat.number &&
                    flight.areadySelectedSeat.number &&
                    ' (updating)'}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {flight.selectedSeat &&
                    '$' +
                      flight.selectedSeat.seat.OfferItems.find(
                        (item) => item.PassengerRef === props.passengerKey,
                      ).TotalPrice}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {flight.areadySelectedSeat.number &&
              <FormControlLabel
            control={
              <Checkbox checked={checkedSeats.find(s=>s.id===flight.segments[0].origin + flight.segments[0].destination)
               ? checkedSeats.find(s=>s.id===flight.segments[0].origin + flight.segments[0].destination).checked : false
              } onChange={()=>handleClickCheck(flight)} />
            }
            label="Delete"
          />
          }

              </Grid>
            </Grid>
          </Item>
        ))}
      </Stack>
      </div>

    
  );
}


export default function SeatMapDialog(props) {
  // console.log('seat demo', props);
  // const location = useLocation();
  // const [seatmaps, setSeatmaps] = useState(null)
  // const [traverlers, setTravelers] = useState([])
  // const [selectedSeats, setSelectedSeats] = useState(props.selectedSeats)
  const [activeStep, setActiveStep] = useState(props.currentStep || 0);
  const [completed, setCompleted] = useState([]);
  const [open, setOpen] = useState(false)
  const [clickStep, setClickStep] = useState(0)
  
  useEffect(()=>{
    // console.log('currentStep', props.currentStep)
    setActiveStep(props.currentStep || 0)
  },[props.currentStep])

  // const travellersSeats = props.travellers.map((traveller, tindex) => {
  //   traveller.flights.map((flight, findex) => {
  //     if (flight.areadySelectedSeat.number) return flight

  //     const areadySelectedSeat = (props.selectedSeats[findex].seats && props.selectedSeats[findex].seats.length > 0) ? props.selectedSeats[findex].seats[tindex].selectedSeat : {number: null}

  //     return {
  //       ...flight,
  //       areadySelectedSeat: areadySelectedSeat
  //     }
  //   })
  // })

  // console.log(travellersSeats);

const handleStep = (step) => () => {
  // console.log('step', step)
  if(activeStep == step){
    return
  }
  setClickStep(step)
  let selectedSeatsCount = 0
  let selectedSeat = props.selectedSeats.find(item=>item.index == activeStep)
  if(selectedSeat){
    selectedSeatsCount = selectedSeat.seats.length
  }

  if(props.seats[activeStep].TravelerIdentifiers.length != selectedSeatsCount){
    setOpen(true)
  }else{
    // setActiveStep(step);

    if(props.onClickNext){
      props.onClickNext(step)
    }
    else{
      setActiveStep(step)
    }
  }
};

const addSeat=(seat)=>{
  const index = props.selectedSeats.findIndex(item=>item.index == activeStep)
  // console.log('add seat', selectedSeats)
  if(index<0){
    let newSelectedSeats = [...props.selectedSeats, {index: activeStep, seats: [seat]}]
    // setSelectedSeats([...newSelectedSeats])
    props.onSelectedSeats(newSelectedSeats)
    if(props.seats[activeStep].TravelerIdentifiers.length == 1){
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted([...newCompleted]);
    }
  }else{
    let newSelectedSeats = props.selectedSeats
    newSelectedSeats[index].seats = [...newSelectedSeats[index].seats, seat]
    // setSelectedSeats([...newSelectedSeats])
    props.onSelectedSeats(newSelectedSeats)
    if(props.seats[activeStep].TravelerIdentifiers.length == newSelectedSeats[index].seats.length){
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted([...newCompleted]);
    }
  }
}

const removeSeat=(seatNumber)=>{
  let newSelectedSeats = props.selectedSeats
  newSelectedSeats[activeStep].seats = newSelectedSeats[activeStep].seats.filter(item=>item.number !== seatNumber)
  // console.log('remove', newSelectedSeats);
  // setSelectedSeats([...newSelectedSeats])
  props.onSelectedSeats(newSelectedSeats)
  // console.log('remove');
  const newCompleted = completed;
  newCompleted[activeStep] = false;
  setCompleted([...newCompleted]);

  // setSelectedSeats(selectSeats.filter(item=>item.number !== seatNumber))
  // props.onSelectedSeats(selectSeats.filter(item=>item.number !== seatNumber))
}

const handleIgnore=()=>{
  setOpen(false)
  // console.log('click step', clickStep)
  setActiveStep(clickStep);
}

const handleChange = (event, value)=>{
  // console.log('change', value)
  if(activeStep == value-1){
    return
  }
  setClickStep(value-1)
  let selectedSeatsCount = 0
  let selectedSeat = props.selectedSeats.find(item=>item.index == activeStep)
  if(selectedSeat){
    selectedSeatsCount = selectedSeat.seats.length
  }

  if(props.seats[activeStep].TravelerIdentifiers.length != selectedSeatsCount){
    setOpen(true)
  }else{
    setActiveStep(value-1);
  }
}

// console.log(props.seats);

  return (
<Box>
<Grid container spacing={2} justifyContent="center">
  <Grid item xs={12} md={8}>
  <Stepper nonLinear activeStep={activeStep}>
        {props.seats && props.seats.map((seat, index)=> seat.FlightSegments.map(segment =>
          <Step key={segment.origin+segment.destination} completed={completed[index]}>
            <StepButton sx={{color: 'primary.main'}} onClick={handleStep(index)} optional={<Typography variant="caption" display="block" gutterBottom>{segment.origin+" to "+segment.destination}</Typography>}>
              {`flight ${index+1} of ${props.seats.length}`}
            </StepButton>
          </Step>
        ))}
      </Stepper>
  </Grid>
</Grid>

<Grid container spacing={2} sx={{marginTop: '1rem;'}}>

  <Grid item sm={12} lg={8}>
    {props.seats &&
    <Deck deck={props.seats[activeStep].deck} selectSeats={props.selectedSeats && props.selectedSeats[activeStep] ? props.selectedSeats[activeStep].seats : []} addSeat={addSeat} removeSeat={removeSeat} max={props.seats[activeStep].TravelerIdentifiers.length }/>}
  </Grid>

  <Grid item sm={12} lg={4}>
  <Card variant="outlined">
  <Typography variant="h5" gutterBottom sx={{textAlign: 'center', padding: '10px 0'}}>
        {props.seats && props.seats[activeStep].FlightSegments[0].origin + " to " + props.seats[activeStep].FlightSegments[0].destination}
      </Typography>

  <Stack spacing={2}>
            {false && props.selectedSeats.length > activeStep && props.selectedSeats[activeStep].seats.map((seat, index)=>
              <Item key={seat.number}>
                {/* <div className='seat' style={{
                    backgroundColor:"#499167",
    color: "white",
    width: "3em",
    height: "3em",
    cursor: "pointer"
  }}> */}
  <Grid container spacing={2}>
  <Grid item xs={4}>
  <Typography variant="h6" display="block" gutterBottom>
    P {index+1}
  </Typography>
  </Grid>
  <Grid item xs={8}>
  <Typography variant="button" display="block" gutterBottom>
{seat.number}
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
      {seat.travelerPricing[0].price.total.toLocaleString('en', {
                        style: 'currency', 
                        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
      </Typography>
  </Grid>
</Grid>

  {/* </div> */}

            </Item>
            )}
</Stack>

  <Divider/>
    <Stack
      spacing={2}
      sx={{padding: '10px'}}
      >
        <Item sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <div className='seat-example available'>
          </div>
          <span>{intl.get('SEATMAP.AVAILABLE')}</span>
        </Item>
        <Item sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <div className='seat-example unavailable'>
            X
          </div>
          <span>{intl.get('SEATMAP.UNAVAILABLE')}</span>
          </Item>
        <Item sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <div className='seat-example selected'>
          </div>
          <span>{intl.get('SEATMAP.SELECTED')}</span>
          </Item>
          <Item sx={{display: 'flex', alignItems: 'center', gap: 2}}>
            <div className='seat-example assigned'>
          </div>
          <span>{intl.get('SEATMAP.ASSIGNED')}</span>
          </Item>
      </Stack>


      {/* <Divider/>
      <Box display="flex" justifyContent="center" sx={{m:3}}>
      <Pagination count={props.seats.length} page={activeStep + 1} color="primary" onChange={handleChange} />
      </Box> */}
    </Card>

    {
      props.travellers && props.travellers.map((item, index) => (
        <Traveler
          key={item.passengerKey}
          index={index + 1}
          {...item}
        />
      ))
    }
  </Grid>
</Grid>
<Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {"Seat Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          There are some people who did not choose a seat
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIgnore} color='warning'>Ignore</Button>
          <Button onClick={()=>setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
</Box>

  )
}
