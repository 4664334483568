import { Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import intl from 'react-intl-universal';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
// import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import moment from 'moment';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

export default function PaymentDetail(props) {
  // console.log('payment detail', props);
  const {
    register,
    setValue,
    // getValues,
    // watch,
    control,
    // formState: { errors },
  } = useFormContext();
  // const [pay, setPay] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  // const [selectedCK, setSelectedCK] = useState(false)
  // const [showCheckPayment, setShowCheckPayment] = useState(false)
  // console.log("get values", getValues());

  let minDate = new Date(new Date().setFullYear(new Date().getFullYear()));
  let maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 100),
  );

  useEffect(() => {
    if (props.payment) {
      setValue('payment', props.payment);
      setValue(
        'payment.expiration',
        `${
          props.payment.expirationMonth
        }/${props.payment.expirationYear.substring(2)}`,
      );
    }

    // if(!props.displaySelect){
    //   setPay(true)
    // }
  }, []);

  // const handleWechat = () => {
  //   setValue('PaymentMethod', 'Wechat');
  //   console.log('click wechat');
  //   setSelectedPaymentMethod('Wechat');
  //   // console.log('wechat');
  //   let params = {
  //     paymentType: 1,
  //     req: {
  //       openId: props.info.req.openId,
  //       cid: props.info.req.cid,
  //       routing: { totalPrice: props.info.req.routing.totalPrice },
  //       currency: props.info.req.currency,
  //     },
  //     rsp: {
  //       airTicketBookingResponse: {
  //         confirmationCode:
  //           props.info.rsp.airTicketBookingResponse.confirmationCode,
  //         recordLocator: props.info.rsp.airTicketBookingResponse.recordLocator,
  //       },
  //     },
  //     returnUrl: `${window.location.protocol}//${window.location.host}/paymentresult`,
  //   };

  //   axios
  //     .post('/api/payment', params)
  //     .then(function (res) {
  //       // console.log("user data", res)
  //       let jsonData = JSON.parse(res.data);
  //       window.location.href = `https://gttb2b.feiquanqiu.com/WebSit/WXPayment.aspx?sign=${jsonData.sign}`;
  //     })
  //     .catch(function (error) {
  //       console.error('flight', error);
  //     })
  //     .then(function () {});
  // };

  const handleClickCreditCard = () => {
    // console.log('click CC', pay)
    setValue('PaymentMethod', 'CC');
    setSelectedPaymentMethod('CC');
    // setPay(true)
    props.onConfirmPayment('CC', true);
    // if(selectedCK){
    //   setSelectedCK(false)
    // }
  };

  const handleClickCheckPayment = () => {
    // console.log('payment', props.payment)
    if (props.payment) {
      return;
    }

    setSelectedPaymentMethod('CK');
    setValue('payment', null);
    setValue('PaymentMethod', 'CK');

    // setSelectedCK(!selectedCK)
    props.onConfirmPayment('CK', true);
    // setPay(true)

    // if(pay){
    //   setPay(false)
    // }
  };

  return (
    <div className="theme-payment-page-sections-item _p-20">
      <h3 className="theme-payment-page-sections-item-title">
        <i className="fas fa-credit-card" style={{ marginRight: '10px' }}></i>
        {intl.get('PAYMENT.TITLE')}
      </h3>
      {/* <Box sx={{ flexGrow: 1 }}> */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Item onClick={handleClickCreditCard} sx={selectedPaymentMethod === 'CC' && {backgroundColor: 'rgb(33 150 243 / 25%)'}}>
              <img
                src="/Flight-Hotel/panelAirR/images/footer-payment-social.svg"
                alt="Alternative text"
                title="Payment"
              />
            </Item>
          </Grid>
          {/* <Grid item xs={12} md={8}>
            <Item
              onClick={() => {
                handleWechat();
              }}
            >
              <img src="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/checkout-bank-WeChat?wid=280&amp;hei=52&amp;fmt=jpeg&amp;qlt=90&amp;.v=0" />
            </Item>
          </Grid> */}
          {props.info &&
            props.info.req &&
            props.info.req.userInfo &&
            props.info.req.userInfo.AccountCode &&
            (props.info.req.userInfo.Role === 'AGT' ||
              props.info.req.userInfo.Role === 'AG' ||
              props.info.req.userInfo.Role === 'BM' ||
              props.info.req.userInfo.Role === 'RM') && (
              <Grid item xs={12} md={8}>
                <Item onClick={handleClickCheckPayment} sx={selectedPaymentMethod === 'CK' && {backgroundColor: 'rgb(33 150 243 / 25%)'}}>
                  <Button>Check payment</Button>
                </Item>
              </Grid>
            )}
        </Grid>
      {/* </Box> */}
      {/* {selectedCK &&
    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2} justifyContent="center" alignItems="center">
    <Grid item xs={12} md={8} sx={{marginTop: 3}}>
    <Button type="submit" variant="contained">{intl.get("PAYMENT.CONFIRM_PAYMENT")}</Button>
    </Grid>
    </Grid>
    </Box>} */}
      {selectedPaymentMethod === 'CC' && (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              {...register('payment.lastName', { required: true })}
              // disabled={disabled}
              variant="outlined"
              // error = {errors?.contact?.zipCode?.type ? true : false}
              label={
                <>
                  {intl.get('PAYMENT.CARDHOLDER_LAST_NAME')}
                  <span className="required">*</span>
                </>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              {...register('payment.middleName', { required: false })}
              // disabled={disabled}
              variant="outlined"
              // error = {errors?.contact?.zipCode?.type ? true : false}
              label={intl.get('PAYMENT.CARDHOLDER_MIDDLE_NAME')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              {...register('payment.firstName', { required: true })}
              // disabled={disabled}
              variant="outlined"
              // error = {errors?.contact?.zipCode?.type ? true : false}
              label={
                <>
                  {intl.get('PAYMENT.CARDHOLDER_FIRST_NAME')}
                  <span className="required">*</span>
                </>
              }
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              {...register('payment.creditCardNumber', { required: true })}
              // disabled={disabled}
              variant="outlined"
              // error = {errors?.contact?.zipCode?.type ? true : false}
              // label={intl.get('PAYMENT.CARD_NUMBER')}
              label={
                <>
                  {intl.get('PAYMENT.CARD_NUMBER')}
                  <span className="required">*</span>
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={'payment.expiration'}
              control={control}
              rules={{ required: true }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputRef={field.ref}
                    onBlur={field.onBlur}
                    inputFormat="MM/yy"
                    // mask = '____/__/__/'
                    // disabled={props.disabled}
                    // onChange={onChange} // send value to hook form
                    //   disableFuture
                    minDate={minDate}
                    maxDate={maxDate}
                    // label={intl.get('COMMON.BIRTHDAY') + '*'}
                    openTo="year"
                    // views={['year', 'month']}
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                      field.onChange(
                        newValue ? moment(newValue).format('MM/DD/YYYY') : '',
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <FormControl fullWidth>
                          <InputLabel htmlFor={'input-contact-expiry_date'}>
                            {intl.get('PAYMENT.EXPIRY_DATE')}
                            <span className="required">*</span>
                          </InputLabel>
                          <OutlinedInput
                            inputRef={params.inputRef}
                            id={'input-contact-expiry_date'}
                            label={intl.get('PAYMENT.EXPIRY_DATE') + '*'}
                            endAdornment={params.InputProps?.endAdornment}
                            {...params.inputProps}
                          />
                        </FormControl>
                      );
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              {...register('payment.cardVerificationNumber', {
                required: true,
              })}
              // disabled={disabled}
              variant="outlined"
              type="password"
              // error = {errors?.contact?.zipCode?.type ? true : false}
              // label={intl.get('PAYMENT.VERIFICATION_CODE')}
              label={
                <>
                  {intl.get('PAYMENT.VERIFICATION_CODE')}
                  <span className="required">*</span>
                </>
              }
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
