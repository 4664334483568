import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3E5672',
      light: '#7b98b5',
      lighter: '#d1dbe6',
      contrastText: '#fff'
    },
    yellow: {
      main: '#FED72C',
      contrastText: '#fff'
    },
  },
});

export default theme;
