import React, { useState } from 'react';
import { useEffect } from 'react';
import ButtonAppBar from '../../components/ButtonAppBar';
import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';
import Typography from '@mui/material/Typography';
import SearchBox from '../../components/SearchBox';
import SearchHotel from '../../components/SearchHotel';
import SearchHotelInput from '../../components/SearchHotelInput';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getURLParam } from '../../common/helper.js';

export default function Package() {
  const navigate = useNavigate();
  const [initDone, setInitDone] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [isShowHotelFlight, setisShowHotelFlight] = useState(false);
  const [isShowHotel, setisShowHotel] = useState(false);
  const [selects, setSelects] = useState({});
  const [open, setOpen] = useState(false);

  const handleSwitch = (name) => {
    // if(name == "flight"){
    //     navigate("/search"+window.location.search)
    //     return
    // }
    setIsShowMessage(false);

    let isSelected = selects[name];
    if (isSelected === undefined) {
      isSelected = false;
    }
    setSelects({ ...selects, [name]: !isSelected });
  };

  useEffect(() => {
    document.title = 'Package';
    setIsShowMessage(true);
    // console.log('all.min.css', isInclude('all.min.css'));
    // loadCss('font-awesome/css/all.min.css');
    // loadCss('css/bootstrap.min.css');
    // loadCss('css/jquery-ui/jquery-ui.min.css');
    // loadCss('Flight-Hotel/vendor/daterangepicker/daterangepicker.css');
    // loadCss('css/stylesheet.css');
    // loadCss('css/package.css');

    // loadScript('/js/jquery-ui.min.js')
    // // loadScript('js/bootstrap.bundle.min.js')
    // loadScript('/js/bootstrap-spinner.js')
    // loadScript('/js/theme.js')
    // loadScript('/js/moment.min.js')
    // loadScript('/js/daterangepicker.js')
    // loadScript('/js/search.js')

    let uid = getURLParam('u');
    let openid = getURLParam('p');
    let useId = null;
    let type = null;
    if (uid) {
      useId = uid;
      type = 'unionid';
    } else if (openid) {
      useId = openid;
      type = 'openid';
    }

    if (useId) {
      if (type == 'openid') {
        sessionStorage.setItem('openid', openid);
      }
      setOpen(true);
      fetch('api/users?type=' + type + '&openId=' + useId)
        .then(
          (
            response, //response.json()
          ) => {
            if (response.ok) {
              return response.json();
            } else {
              // return Promise.reject({
              //   status: response.status,
              //   statusText: response.statusText
              // })
              return Promise.reject(response.statusText);
            }
          },
        )
        .then((data) => {
          //   console.log("user data", data)
          if (data) {
            let json = JSON.parse(data);
            if (type == 'unionid') {
              sessionStorage.setItem('openid', json.OpenID);
            }
            // console.log('account code', json.AccountCode)
            if (
              json.AccountCode &&
              (json.AccountCode.toUpperCase() == 'COMTVO' ||
                json.AccountCode == 'AIG222')
            ) {
              json['type'] = 'traveloutlet';
              //   console.log(window.location);
              if (
                window.location.href
                  .toLocaleLowerCase()
                  .indexOf('thetraveloutlet') < 0
              ) {
                // console.log(window.location.href.replace(window.location.host, 'wechat.thetraveloutlet.com'))
                window.location = window.location.href.replace(
                  window.location.host,
                  'wechat.thetraveloutlet.com',
                );
              }
            }
            // if(openid == "oead2wQTEvWad1TG5YIJUwHWOGac"){
            //   json["type"] = "traveloutlet"
            // }
            sessionStorage.setItem('userInfo', JSON.stringify(json));
          }

          setOpen(false);
        })
        .catch((error) => {
          //   console.error(error)
          setOpen(false);
        });
    } else {
    }
  }, []);

  function handleClick() {
    navigate('/search');
  }

  // handleSwitch=()=>{
  //   console.log('switch')
  // }

  return (
    <>
      <ButtonAppBar />
      <section className="page-header page-header-text-light bg-secondary">
        <div id="content">
          <section className="container-Search">
            <div className="bg-white shadow-md rounded p-4">
              <div className="row">
                <div className="col-lg-12 mb-4 mb-lg-15">
                  <h2 className="text-7 mb-3">
                    {intl.get('PACKAGE.TRAVEL_PACKAGE')}
                  </h2>
                  <hr className="my-2" />
                  {/* <p className="text-2 mt-5">
                                    {intl.get("PACKAGE.PROMOTION_TIPS")}
                                    </p> */}
                  <Typography variant="body1" gutterBottom>
                    {intl.get('PACKAGE.PROMOTION_TIPS')}
                  </Typography>
                  <div className="row">
                    <div
                      className="col-12 mt-2 select-items"
                      style={{ lineHeight: '3' }}
                    >
                      <button
                        type="button"
                        className={
                          'btn btn-outline-primary btn-sm shadow-none' +
                          (selects['hotel'] ? ' item-checked' : '')
                        }
                        onClick={() => handleSwitch('hotel')}
                      >
                        {intl.get('PACKAGE.HOTEL')}
                      </button>
                      <button
                        type="button"
                        className={
                          'btn btn-outline-primary btn-sm shadow-none' +
                          (selects['flight'] ? ' item-checked' : '')
                        }
                        onClick={() => handleSwitch('flight')}
                      >
                        {intl.get('PACKAGE.FLIGHT')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm shadow-none"
                      >
                        {intl.get('PACKAGE.CARS')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm shadow-none"
                      >
                        {intl.get('PACKAGE.Tour')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm shadow-none"
                      >
                        {intl.get('PACKAGE.INSURANCE')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {isShowMessage && (
                <div className="row item-message">
                  <div className="col-lg-12 mb-5 mb-lg-15">
                    <div className="featured-box text-center">
                      <div className="featured-box-icon">
                        <i className="fa fa-magnifying-glass"></i>
                      </div>
                      {/* <h4 style={{fontWeight: '600'}}>{intl.get("PACKAGE.MULTI_SELECTION_TITLE")}</h4> */}
                      <Typography
                        style={{ fontWeight: '600' }}
                        variant="h4"
                        gutterBottom
                      >
                        {intl.get('PACKAGE.MULTI_SELECTION_TITLE')}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {intl.get('PACKAGE.MULTI_SELECTION')}
                      </Typography>
                      {/* <h6 className="text-2 mt-1">
                                        {intl.get("PACKAGE.MULTI_SELECTION")}
                                        </h6> */}
                    </div>
                  </div>
                </div>
              )}
              {selects['hotel'] && selects['flight'] && (
                <div className="row package-search">
                  <div className="col-lg-12 mb-4 mb-lg-0">
                    <div className="form-row">
                      <div className="col-lg-6 form-group">
                        <input
                          type="hidden"
                          name="flight-from"
                          className="hidden-flight"
                          id="flight-from"
                        />
                        <input
                          name="flightFrom"
                          type="text"
                          id="flightFrom"
                          className="form-control airport-input ui-autocomplete-input"
                          placeholder="出发地"
                        />
                        <span className="icon-inside">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      <div className="col-lg-6 form-group">
                        <input
                          type="hidden"
                          name="flight-to"
                          className="hidden-flight"
                          id="flight-to"
                          value=""
                        />
                        <input
                          name="flightTo"
                          type="text"
                          id="flightTo"
                          className="form-control airport-input ui-autocomplete-input"
                          placeholder="目的地"
                        />
                        <span className="icon-inside">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 col-lg-3 form-group">
                        <input
                          name="flightDepart"
                          type="text"
                          id="flightDepart"
                          className="form-control"
                          autoComplete="off"
                          placeholder="出发日期"
                        />
                        <span className="icon-inside">
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>
                      <div className="col-md-6 col-lg-3 form-group">
                        <input
                          name="flightReturn"
                          type="text"
                          id="flightReturn"
                          className="form-control"
                          autoComplete="off"
                          placeholder="回程日期"
                        />
                        <span className="icon-inside">
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>

                      <div className="col-md-6 col-lg-3 travellers-class form-group">
                        <input
                          name="hotelsTravellersClass"
                          type="text"
                          id="hotelsTravellersClass"
                          className="travellers-class-input form-control"
                          autoComplete="off"
                          placeholder="房间 / 旅客"
                          value="1 房间 / 1 旅客"
                        />
                        <span className="icon-inside">
                          <i className="fas fa-caret-down"></i>
                        </span>
                        <div
                          className="roomtravellers-dropdown travellers-dropdown"
                          style={{ display: 'none' }}
                        >
                          <div className="row align-items-center">
                            <div className="col-sm-7">
                              <p className="mb-sm-0">房间</p>
                            </div>
                            <div className="col-sm-5">
                              <div className="qty input-group">
                                <div className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn bg-light-4"
                                    data-value="decrease"
                                    data-target="#hotels-rooms"
                                    data-toggle="spinner"
                                  >
                                    -
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  data-ride="spinner"
                                  id="hotels-rooms"
                                  name="hotels-rooms"
                                  className="qty-spinner form-control"
                                  value="1"
                                  readOnly
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn bg-light-4"
                                    data-value="increase"
                                    data-target="#hotels-rooms"
                                    data-toggle="spinner"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className="mt-2 mb-4" />
                          <div className="row align-items-center">
                            <div className="col-sm-7">
                              <p className="mb-sm-0">
                                成人
                                <small className="text-muted">(12+ 岁)</small>
                              </p>
                            </div>
                            <div className="col-sm-5">
                              <div className="qty input-group">
                                <div className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn bg-light-4"
                                    data-value="decrease"
                                    data-target="#adulttravellers"
                                    data-toggle="spinner"
                                  >
                                    -
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  data-ride="spinner"
                                  id="adulttravellers"
                                  name="adult-travellers"
                                  className="qty-spinner form-control"
                                  defaultValue="1"
                                  readOnly
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn bg-light-4"
                                    data-value="increase"
                                    data-target="#adulttravellers"
                                    data-toggle="spinner"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className="my-2" />
                          <div className="row align-items-center">
                            <div className="col-sm-7">
                              <p className="mb-sm-0">
                                小孩
                                <small className="text-muted">(1-12 岁)</small>
                              </p>
                            </div>
                            <div className="col-sm-5">
                              <div className="qty input-group">
                                <div className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn bg-light-4"
                                    data-value="decrease"
                                    data-target="#childrentravellers"
                                    data-toggle="spinner"
                                  >
                                    -
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  data-ride="spinner"
                                  id="childrentravellers"
                                  name="children-travellers"
                                  className="qty-spinner form-control"
                                  defaultValue="0"
                                  readOnly
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn bg-light-4"
                                    data-value="increase"
                                    data-target="#childrentravellers"
                                    data-toggle="spinner"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <button
                            className="btn btn-primary btn-block submit-done mt-3"
                            type="button"
                          >
                            完成
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 travellers-class form-group">
                        <input
                          name="flightClass"
                          type="text"
                          id="flightClass"
                          className="travellers-class-input form-control"
                          autoComplete="off"
                          defaultValue="经济舱"
                          placeholder="舱等"
                          readOnly
                        />
                        <span className="icon-inside">
                          <i className="fas fa-caret-down"></i>
                        </span>
                        <div
                          className="cabin-dropdown travellers-dropdown"
                          style={{ display: 'none' }}
                        >
                          <div className="mb-3">
                            <div className="custom-control custom-radio">
                              <input
                                id="flightClassEconomic"
                                name="flight-class"
                                className="flight-class custom-control-input"
                                value="E"
                                defaultChecked
                                type="radio"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="flightClassEconomic"
                              >
                                经济舱
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="flightClassPremiumEconomic"
                                name="flight-class"
                                className="flight-class custom-control-input"
                                value="P"
                                type="radio"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="flightClassPremiumEconomic"
                              >
                                超级经济舱
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="flightClassBusiness"
                                name="flight-class"
                                className="flight-class custom-control-input"
                                value="B"
                                type="radio"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="flightClassBusiness"
                              >
                                商务舱
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="flightClassFirstClass"
                                name="flight-class"
                                className="flight-class custom-control-input"
                                value="F"
                                type="radio"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="flightClassFirstClass"
                              >
                                头等舱
                              </label>
                            </div>
                          </div>
                          <button
                            className="btn btn-primary btn-block submit-done mt-3"
                            type="button"
                          >
                            完成
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="hotel-part">
                      <div className="custom-control custom-radio custom-control-inline form-group">
                        <input
                          id="onlyhotel"
                          name="onlyhotel"
                          className="custom-control-input"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="onlyhotel"
                        >
                          <h6 style={{ lineHeight: '24px' }}>
                            我只有部分旅程需要预订住宿
                          </h6>
                        </label>
                      </div>
                    </div>

                    <div id="bookingHotels" method="post">
                      <div className="form-row">
                        <div className="col-lg-3 form-group">
                          <input
                            name="hotelsCheckIn"
                            type="text"
                            id="hotelsCheckIn"
                            className="form-control"
                            autoComplete="off"
                            placeholder="入住日期"
                          />
                          <span className="icon-inside">
                            <i className="far fa-calendar-alt"></i>
                          </span>
                        </div>
                        <div className="col-lg-3 form-group">
                          <input
                            name="hotelsCheckOut"
                            type="text"
                            id="hotelsCheckOut"
                            className="form-control"
                            autoComplete="off"
                            placeholder="退房日期"
                          />
                          <span className="icon-inside">
                            <i className="far fa-calendar-alt"></i>
                          </span>
                        </div>
                        <div className="col-lg-3 form-group">
                          <button
                            className="btn btn-primary btn-block btn-submit"
                            type="submit"
                          >
                            查询
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="row hotels-search"
                style={{
                  display: selects['hotel'] && !selects['flight'] ? '' : 'none',
                }}
              >
                <SearchHotel />
              </div>
              <div
                className="row flight-search"
                style={{
                  display: !selects['hotel'] && selects['flight'] ? '' : 'none',
                }}
              >
                <SearchBox />
              </div>
            </div>
          </section>
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </section>
    </>
  );
}
