import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {  useSnackbar } from 'notistack';
import moment from 'moment';

function parseStatus(opStatus, paymentStatus) {
  if (opStatus === 'New' && paymentStatus === 'No Payment') {
    return paymentStatus;
  } else if (opStatus === 'New' && paymentStatus === 'Full Payment') {
    return paymentStatus;
  }

  return opStatus;
}


export default function OrderDetail() {
  const location = useLocation();
  console.log('order detail', location)
  const [open, setOpen] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();


  async function fetchWithRetry(url, options = {}, retries = 3, backoff = 3000) {
    for (let i = 0; i < retries; i++) {
      // console.log('try', i)
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            // console.log('data', data)
            // 根据返回数据中的某个值来判断是否重试
            if (!("order" in data.apiResult)) {
              if('hop2WsError' in data.apiResult){
                for(let error of data.apiResult.hop2WsError.errors){
                  throw new Error(error.message)
                }
              }else{
                throw new Error('Data indicates retry is needed');
              }
            }

            return data;
        } catch (error) {
            if (i < retries - 1) {
                await new Promise(resolve => setTimeout(resolve, backoff));
            } else {
                throw error;
            }
        }
    }
}

  React.useEffect(()=>{
    fetchWithRetry(`api/orders?caseNumber=${location.state.bookedRsp.airTicketBookingResponse.confirmationCode}&cid=${
      location.state.source
    }&customerSessionId=${'S12345'}`)
    .then(data => {let apiResultJson = data.apiResult;
      let sqlResultJson = data.sqlResult;
      let seats = [];
      let services = [];
      if (apiResultJson.order && apiResultJson.order.passengers) {
        for (let passenger of apiResultJson.order.passengers) {
          if (passenger.seats) {
            for (let seat of passenger.seats) {
              seats.push({
                segment: null,
                seats: [
                  {
                    travelerPricing: [
                      {
                        price: { currency: 'USD', total: seat.TotalPrice },
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (passenger.services) {
            let bundles = [];
            for (let service of passenger.services) {
              bundles.push({ totalAmount: service.TotalPrice });
            }

            services.push({ bundles: bundles });
          }
        }
      }

      // console.log('services', services);
      // {segment: null, seats: [{travelerPricing:[{price: {currency: 'USD', total: }}]}]
      if (sqlResultJson === null) {
        let trips = [];
        let segments = [];
        for (let seg of apiResultJson.order.segments) {
          // console.log('seg', seg);
          segments.push({
            departureTime: moment(seg.departureTime).format(
              'MM/DD/YYYY HH:mm',
            ),
            operateFlight: '',
            arrivalAirportCode: seg.arrivalAirportCode,
            // childFarebasis: "",
            codeShare: '',
            departureAirportCode: seg.departureAirportCode,
            baggageUnit: '',
            stop: 0,
            flightNumber: seg.flightNumber,
            adultFarebasis: '',
            duration: 0,
            airlineCode: seg.airlineCode,
            BookingClass: seg.bookingClass,
            baggagePieces: 0,
            isShare: false,
            arrivalTime: moment(seg.arrivalTime).format('MM/DD/YYYY HH:mm'),
            stopAirports: [],
            baggageAllowance: 0,
            aircraftCode: '',
            cabin:
              seg.cabin && seg.cabin != '' ? seg.cabin.substring(0, 1) : '',
          });
        }

        trips.push({
          duration: 0,
          segments: segments,
          tripTime: 0,
        });

        // console.log(trips)
        let findAdult = apiResultJson.order.price.find(
          (p) => p.passengerType === 'Adult',
        );
        let findChild = apiResultJson.order.price.find(
          (p) => p.passengerType === 'Child',
        );
        let adults = apiResultJson.order.passengers.filter(
          (p) => p.type === 'Adult',
        ).length;
        let children = apiResultJson.order.passengers.filter(
          (p) => p.type === 'Child',
        ).length;
        sqlResultJson = {
          routingText: JSON.stringify({
            totalPrice: apiResultJson.order.totalPrice,
            adultBasePrice: findAdult ? findAdult.baseFare : 0,
            adultTax: findAdult ? findAdult.tax : 0,
            childBasePrice: findChild ? findChild.baseFare : 0,
            childTax: findChild ? findChild.tax : 0,
            cid: apiResultJson.order.source,
            trips: trips,
          }),
          order: {
            adults: adults,
            children: children,
            currency: 'USD',
            shortLink: '',
          },
        };
      }

      // setSqlResult(sqlResultJson);
      // setContact(apiResultJson.order.contact);
      navigate(`/success`, {
        state: {
          bookInfo: {
            routing: sqlResultJson.routingText,
            passengers: apiResultJson.order.passengers,
            adults: sqlResultJson && sqlResultJson.order.adults,
            children: sqlResultJson && sqlResultJson.order.children,
            openid: location.state.bookInfo.openid,
            contact: apiResultJson.order.contact,
          },
          bookedRsp: location.state.bookedRsp,
          currency: location.state.currency,
          orderTime: location.state.orderTime,
          orderStatus: parseStatus(apiResultJson.order.status, apiResultJson.order.paymentStatus),
          seats: seats,
          bundles: services,
          order: location.state.order,
          source: location.state.source,
        },
      });
    })
    .catch(error => {enqueueSnackbar(error.message, {
      variant: 'error',
      autoHideDuration: 6000,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
    });}).then(
      function () {
        setOpen(false)
      }
    )
    
    // fetch(`api/orders?caseNumber=${location.state.bookedRsp.airTicketBookingResponse.confirmationCode}&cid=${
    //   location.state.source
    // }&customerSessionId=${'S12345'}`)
    // .then((response) => {
    //   if (response.ok) {
    //     return response.json();
    //   } else {
    //     return Promise.reject(response.statusText);
    //   }
    // })
    // .then((data) => {
    //   // let jsonData = JSON.parse(data);
    //   let apiResultJson = data.apiResult;
    //   let sqlResultJson = data.sqlResult;
    //   let seats = [];
    //   let services = [];
    //   if (apiResultJson.order && apiResultJson.order.passengers) {
    //     for (let passenger of apiResultJson.order.passengers) {
    //       if (passenger.seats) {
    //         for (let seat of passenger.seats) {
    //           seats.push({
    //             segment: null,
    //             seats: [
    //               {
    //                 travelerPricing: [
    //                   {
    //                     price: { currency: 'USD', total: seat.TotalPrice },
    //                   },
    //                 ],
    //               },
    //             ],
    //           });
    //         }
    //       }

    //       if (passenger.services) {
    //         let bundles = [];
    //         for (let service of passenger.services) {
    //           bundles.push({ totalAmount: service.TotalPrice });
    //         }

    //         services.push({ bundles: bundles });
    //       }
    //     }
    //   }

    //   // console.log('services', services);
    //   // {segment: null, seats: [{travelerPricing:[{price: {currency: 'USD', total: }}]}]
    //   if (sqlResultJson === null) {
    //     let trips = [];
    //     let segments = [];
    //     for (let seg of apiResultJson.order.segments) {
    //       // console.log('seg', seg);
    //       segments.push({
    //         departureTime: moment(seg.departureTime).format(
    //           'MM/DD/YYYY HH:mm',
    //         ),
    //         operateFlight: '',
    //         arrivalAirportCode: seg.arrivalAirportCode,
    //         // childFarebasis: "",
    //         codeShare: '',
    //         departureAirportCode: seg.departureAirportCode,
    //         baggageUnit: '',
    //         stop: 0,
    //         flightNumber: seg.flightNumber,
    //         adultFarebasis: '',
    //         duration: 0,
    //         airlineCode: seg.airlineCode,
    //         BookingClass: seg.bookingClass,
    //         baggagePieces: 0,
    //         isShare: false,
    //         arrivalTime: moment(seg.arrivalTime).format('MM/DD/YYYY HH:mm'),
    //         stopAirports: [],
    //         baggageAllowance: 0,
    //         aircraftCode: '',
    //         cabin:
    //           seg.cabin && seg.cabin != '' ? seg.cabin.substring(0, 1) : '',
    //       });
    //     }

    //     trips.push({
    //       duration: 0,
    //       segments: segments,
    //       tripTime: 0,
    //     });

    //     // console.log(trips)
    //     let findAdult = apiResultJson.order.price.find(
    //       (p) => p.passengerType === 'Adult',
    //     );
    //     let findChild = apiResultJson.order.price.find(
    //       (p) => p.passengerType === 'Child',
    //     );
    //     let adults = apiResultJson.order.passengers.filter(
    //       (p) => p.type === 'Adult',
    //     ).length;
    //     let children = apiResultJson.order.passengers.filter(
    //       (p) => p.type === 'Child',
    //     ).length;
    //     sqlResultJson = {
    //       routingText: JSON.stringify({
    //         totalPrice: apiResultJson.order.totalPrice,
    //         adultBasePrice: findAdult ? findAdult.baseFare : 0,
    //         adultTax: findAdult ? findAdult.tax : 0,
    //         childBasePrice: findChild ? findChild.baseFare : 0,
    //         childTax: findChild ? findChild.tax : 0,
    //         cid: apiResultJson.order.source,
    //         trips: trips,
    //       }),
    //       order: {
    //         adults: adults,
    //         children: children,
    //         currency: 'USD',
    //         shortLink: '',
    //       },
    //     };
    //   }

    //   // setSqlResult(sqlResultJson);
    //   // setContact(apiResultJson.order.contact);
    //   navigate(`/success`, {
    //     state: {
    //       bookInfo: {
    //         routing: sqlResultJson.routingText,
    //         passengers: apiResultJson.order.passengers,
    //         adults: sqlResultJson && sqlResultJson.order.adults,
    //         children: sqlResultJson && sqlResultJson.order.children,
    //         openid: location.state.bookInfo.openid,
    //         contact: apiResultJson.order.contact,
    //       },
    //       bookedRsp: location.state.bookedRsp,
    //       currency: location.state.currency,
    //       orderTime: location.state.orderTime,
    //       orderStatus: parseStatus(apiResultJson.order.status, apiResultJson.order.paymentStatus),
    //       seats: seats,
    //       bundles: services,
    //       order: location.state.order,
    //       source: location.state.source,
    //     },
    //   });
    // })
    // .catch((error) => {
    //   enqueueSnackbar(error.message, {
    //     variant: 'error',
    //     autoHideDuration: 6000,
    //     anchorOrigin: { horizontal: 'center', vertical: 'top' },
    //   });
    // }).then(function () {
    //   setOpen(false)
    // });
  },[])
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleOpen = () => {
  //   setOpen(true);
  // };



  return (
    <div>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      {/* <h3>Loading</h3> */}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
