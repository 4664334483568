import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function MyTabs(props) {
  // console.log('fare rule', props)
    const [value, setValue] = React.useState(0);
    const rules = []

    function processBaggage(key, baggageName, rule){
      if(key == baggageName && props.fareRules.fullNdcRulls[key]){
        for(let baggage of props.fareRules.fullNdcRulls[key]){
          for(let baggageInfo of baggage.baggageInfos){
            let measurementMsgs = []
            if(baggageInfo.measurement){
                for(let measurement of baggageInfo.measurement){
                  measurementMsgs.push(`${measurement.MeasurementType}: ${measurement.Value}${measurement.Unit}`)
                }
            }

            rule.messages.push(`${baggage.routing}, Quantity: ${baggageInfo.quantity}${measurementMsgs.length > 0 ?  ", " + measurementMsgs.join("; "): ""}`)
          }
        }
    }
    }


    if(props.fareRules){
      if(props.fareRules.rules && !props.fareRules.fullNdcRulls){
          let rule = {name: 'Rules', messages: []}
          for(let r in props.fareRules.rules){
              rule.messages.push(`${r}: ${props.fareRules.rules[r]}`)
          }
  
          rules.push(rule)
      }
  
      if(props.fareRules.fullNdcRulls){
          for(let key in props.fareRules.fullNdcRulls){
              let rule = {name: key, messages: []}
              if(key == 'penalty' && props.fareRules.fullNdcRulls[key]){
                  for(let penalty of props.fareRules.fullNdcRulls[key]){
                      for(let r of penalty.rules){
                          rule.messages.push(`${r.Name}: ${r.Value.replaceAll('/n', "<br/>")}`)
                      }
                  }
              }

              processBaggage(key, 'baggage', rule)
              processBaggage(key, 'carryon', rule)
              
              // if(key == 'baggage' && props.fareRules.fullNdcRulls[key]){
              //     for(let baggage of props.fareRules.fullNdcRulls[key]){
              //       for(let baggageInfo of baggage.baggageInfos){
              //         let measurementMsgs = []
              //         if(baggageInfo.measurement){
              //             for(let measurement of baggageInfo.measurement){
              //               measurementMsgs.push(`${measurement.MeasurementType}: ${measurement.Value}${measurement.Unit}`)
              //             }
              //         }

              //         // if(!baggageInfo.quantity){
              //         //   measurementMsgs.push('not allowed');
              //         // }

              //         rule.messages.push(`${baggage.routing}, Quantity: ${baggageInfo.quantity}${measurementMsgs.length > 0 ?  ", " + measurementMsgs.join("; "): ""}`)
              //       }
              //     }
              // }

            //   if(key == 'carryon' && props.fareRules.fullNdcRulls[key]){
            //     for(let carryon of props.fareRules.fullNdcRulls[key]){
            //       for(let baggageInfo of carryon.baggageInfos){
            //         let measurementMsgs = []
            //           for(let measurement of baggageInfo.measurement){
            //             measurementMsgs.push(`${measurement.MeasurementType}: ${measurement.Value}${measurement.Unit}`)
            //           }
            //         if(baggageInfo.measurement){
                      

            //           if(baggageInfo.quantity){
            //             rule.messages.push(`${carryon.routing}, Quantity: ${baggageInfo.quantity}${measurementMsgs.length > 0 ?  ", " + measurementMsgs.join("; "): ""}`)
            //           }
            //        }
            //       }
            //     }
            // }
  
              rules.push(rule)
          }
      }
    }
  
    if(props.brandRules && props.brandRules.brands){
      let rule = {name: 'Attributes', messages: []}
      for(let brand of props.brandRules.brands){
          if(brand.attrs){
              for(let attr of brand.attrs){
                  rule.messages.push(`${attr.name}: ${attr.value}`)
              }
          }

          if(brand.descriptions){
            let index = 1
            for(let description of brand.descriptions){
              rule.messages.push(`${index}: ${description}`)
              index++
            }
          }
      }
  
      rules.push(rule)
    }else if(props.fareRules && props.fareRules.brand && props.fareRules.brand.Brands){
      let rule = {name: 'Attributes', messages: []}
      for(let brand of props.fareRules.brand.Brands){
        // console.log('attr', brand)
        if(brand.Descriptions){
          let index = 1
          for(let description of brand.Descriptions){
            rule.messages.push(`${index}: ${description}`)
            index++
          }
        }
      }

      rules.push(rule)
    }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
  scrollButtons="auto">
            {rules.map((rule, index)=> <Tab key={rule.name} label={rule.name} {...a11yProps(index)} />)}
        </Tabs>
      </Box>
      {rules.map((rule, index)=>
            <TabPanel key={rule.name} value={value} index={index}>
            {rule.messages && rule.messages.length > 0 ?   
            rule.messages.map((message, i)=>
            <Typography sx={{borderBottom: "1px dashed"}} key={rule.name + i} paragraph dangerouslySetInnerHTML={{__html: message}}/>
            ) : <Alert severity="warning">No Result</Alert>}
          </TabPanel>
      )}
    </Box>
  );
}