import React, { Component, useState } from 'react';
import intl from 'react-intl-universal';
import ButtonAppBar from '../../components/ButtonAppBar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Alert, Box, Snackbar } from '@mui/material';
import SearchBox from '../../components/SearchBox';
import { getURLParam } from '../../common/helper.js';
import { loadCss } from '../../common/helper.js';

import searchBg from '../../common/images/bg-home.jpg';
export default class Search extends Component {
  state = {
    open: false,
    cid: null,
    snackbarOpen: false,
    alertMessage: null,
    disableButton: false,
  };

  

  componentDidMount() {
    if(process.env.NODE_ENV === 'development'){
      console.log(process.env)
    }
    let openid = getURLParam('p');
    let uid = getURLParam('u');
    let useId = null;
    let type = null;
    if (uid) {
      useId = uid;
      type = 'unionid';
    } else if (openid) {
      useId = openid;
      type = 'openid';
    }

    this.setState({ cid: getURLParam('cid') });
    let search_comp = false;
    if (this.props.showClose && !useId) {
      openid = sessionStorage.getItem('openid');
      useId = openid;
      type = 'openid';
      search_comp = true;
    }

    if (useId) {
      this.setState({ open: true });
      if (window.location.pathname.toLocaleLowerCase() == '/search_auth') {
        sessionStorage.setItem(
          'searchAuth',
          JSON.stringify({ search: window.location.search }),
        );
        // console.log('auth', window.location.search);
        fetch('api/auths' + window.location.search)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return Promise.reject(response.statusText);
            }
          })
          .then((data) => {
            // console.log('auth', data)
            if (data.agentInfo && data.userInfo && data.userInfo.openid) {
              let agentInfo = data.agentInfo;
              agentInfo.AutoTicketEmailAddress = data.userInfo.email;
              agentInfo.OpenID = data.userInfo.openid;

              agentInfo.HandleAgentEmail = data.userInfo.email;
              // sessionStorage.setItem('auth', )
              sessionStorage.setItem('openid', data.userInfo.openid);
              sessionStorage.setItem('userInfo', JSON.stringify(agentInfo));

              // console.log('session', sessionStorage.getItem("openid"), sessionStorage.getItem("userInfo"))
            }else{
              this.setState({
                alertMessage: intl.get('SEARCH.BIND_ALERT'),
                snackbarOpen: true,
                disableButton: true,
              });
            }
            // console.log("auth data", data)
            // if (data) {
            //   let json = JSON.parse(data);
            //   // console.log('json', json)
            //   // console.log('agent info json', JSON.parse(json.agentInfo))
              
            // }

            this.setState({ open: false });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ open: false });
          });
      } else {
        if (!search_comp) {
          sessionStorage.clear();
          // sessionStorage.removeItem("searchAuth")
          if (type == 'openid') {
            sessionStorage.setItem('openid', openid);
          }

          fetch('api/users?type=' + type + '&openId=' + useId + '&source=gttty')
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return Promise.reject(response.statusText);
              }
            })
            .then((data) => {
              // console.log("user data", data)
              if (!data.OpenID) {
                // alert(intl.get('SEARCH.BIND_ALERT'));
                this.setState({
                  alertMessage: intl.get('SEARCH.BIND_ALERT'),
                  snackbarOpen: true,
                  disableButton: true,
                });
                // this.setState({snackbarOpen: true})
                // this.setState({disableButton: true})
              }

              if (type == 'unionid' && data.OpenID) {
                sessionStorage.setItem('openid', data.OpenID);
              }

              if (
                data.AccountCode &&
                (data.AccountCode.toUpperCase() == 'COMTVO' ||
                data.AccountCode == 'AIG222')
              ) {
                data['type'] = 'traveloutlet';
                if (
                  window.location.href
                    .toLocaleLowerCase()
                    .indexOf('thetraveloutlet') < 0
                ) {
                  // console.log(window.location.href.replace(window.location.host, 'wechat.thetraveloutlet.com'))
                  window.location = window.location.href.replace(
                    window.location.host,
                    'wechat.thetraveloutlet.com',
                  );
                }
              }

              sessionStorage.setItem('userInfo', JSON.stringify(data));
              this.setState({ open: false });
            })
            .catch((error) => {
              console.error(error);
              this.setState({ open: false });
            });
        } else {
          this.setState({ open: false });
        }
      }
    }
    //  else {
    //   sessionStorage.clear();
    // }

    document.title = intl.get('SEARCH.TITLE');

    // loadCss('font-awesome/css/all.min.css');
    loadCss('css/MVstyle-2022.css');
    // loadCss('css/bootstrap.min.css');
    loadCss(
      'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css',
    );

    loadCss('css/jquery-ui/jquery-ui.min.css');
    loadCss('Flight-Hotel/vendor/daterangepicker/daterangepicker.css');
    loadCss('css/stylesheet.css');
    loadCss('css/search.css');

    let loadScript = (path, async) => {
      const script = document.createElement('script');
      script.src = process.env.PUBLIC_URL + path;
      script.async = async;

      document.body.appendChild(script);
    };
    loadScript('/js/jquery-ui.min.js');
    // loadScript('/js/bootstrap-spinner.js')
    loadScript('/js/theme.js');
    loadScript('/js/moment.min.js');
    loadScript('/js/daterangepicker.js');
    loadScript('/js/search-v1.js');
  }

  // handleClick=(e)=>{
  //   e.preventDefault();
  //   let airportPattern = new RegExp("\(([A-Z]{3})\)");
  //   let from = document.getElementById("flightFrom").value;
  //   let to = document.getElementById("flightTo").value;
  //   let departDate = document.getElementById("flightDepart").value;
  //   let returnDate = document.getElementById("flightReturn").value;
  //   let adults = document.getElementById("adulttravellers").value;
  //   let children = document.getElementById("childrentravellers").value;
  //   let fromMatch = airportPattern.exec(from);
  //   let fromCode = null;
  //   let toCode = null;
  //   if (fromMatch == null) {
  //     fromCode = from;
  //   }
  //   else {
  //     fromCode = fromMatch[1];
  //   }

  //   let toMatch = airportPattern.exec(to);
  //   if (toMatch == null) {
  //     toCode = to;
  //   }
  //   else {
  //     toCode = toMatch[1];
  //   }

  //   let departDateFormat = moment(departDate).format("YYYYMMDD");
  //   let returnDateFormat = null;
  //   if (!!returnDate) {
  //     returnDateFormat = moment(returnDate).format("YYYYMMDD");
  //   }

  //   let localStorage = window.localStorage;
  //   localStorage.from = from;
  //   localStorage.to = to;
  //   localStorage.departDate = departDate;
  //   localStorage.returnDate = returnDate;
  //   window.location = `/flight?flights=${fromCode}/${toCode}/${departDateFormat}${returnDateFormat != null ? "/" + returnDateFormat : ""}&cabin=${this.state.cabin}&adults=${adults}&children=${children}&infants=0`;
  // }

  // changeCabin=(e)=>{
  //   this.setState({cabin: e.target.value})
  // }

  // handleFocus=(dataType)=>{
  //   // console.log("focus")
  //   return(e)=>{
  //     // console.log('focus', {history: {[dataType]: this.state.data[dataType]}})
  //     this.setState({history: {[dataType]: this.state.data[dataType]}})
  //     this.setState({data: {...this.state.data, [dataType]:''}})
  //   }
  // }

  // handleBlur=(dataType)=>{
  //   // console.log('blur')
  //   return(e)=>{
  //     // console.log('blur', dataType, e.target.value)
  //     if(!e.target.value){
  //       this.setState({data: {...this.state.data, [dataType]: this.state.history[dataType]}})
  //     }else{
  //       this.setState({data: {...this.state.data, [dataType]: e.target.value}})
  //     }
  //   }
  // }

  // handleChange=(dataType)=>{
  //   // console.log('dataType', dataType, this.state.data)
  //   return (e)=>{
  //     // console.log(dataType, e.target.value)

  //     // console.log('change', {...this.state.data, [dataType]: e.target.value})
  //     this.setState({data: {...this.state.data, [dataType]: e.target.value}})
  //   }
  // }
  handleClose = () => {
    this.setState({ snackbarOpen: false, alertMessage: null });
  };

  render() {
    return (
      <>
        {this.props.showClose || <ButtonAppBar />}
        {/* <section className="page-header page-header-text-light bg-secondary"> */}
        <Box
          sx={{
            py: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="home-search-box"
        >
          <div id="content" style={{ flexGrow: 1 }}>
            <section className="container container-Search home-container-Search">
              <div className="bg-white shadow-md rounded">
                <div className="row">
                  {/* <SearchBox {...this.props} cid={this.state.cid} disableButton={this.state.disableButton}/> */}
                  <SearchBox
                    {...this.props}
                    disableButton={this.state.disableButton}
                  />
                </div>
              </div>
            </section>
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={8000}
          onClose={this.handleClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Alert severity="warning" sx={{ width: '100%' }}>
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}
