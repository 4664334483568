import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import intl from 'react-intl-universal';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import moment from 'moment';
import { countries } from '../../data/countries';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete, Button } from '@mui/material';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {NDC_AIRLINES} from '../../data/config'

// const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&::before': {
//     display: 'none',
//   },
// }));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark'
//       ? 'rgba(255, 255, 255, .05)'
//       : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1),
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)',
// }));

export default function Traveler(props) {
  // let types = ['student', 'youth']
  // console.log('traveler', props);
  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  // const { onChange, onBlur, name, ref } = register(`traveler.${props.type}[${props.number}].birthday`);
  const [dateValue, setDateValue] = useState(null);
  const [IDExpirationDate, setIDExpirationDate] = useState(null);
  const [disabledDatePicker, setDisabledDatePicker] = useState(false);
  const [IDType, setIDType] = useState('');
  const [gender, setGender] = useState(0);
  const [expanded, setExpanded] = useState(false);
  let minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 100),
  );
  let maxDate = new Date();
  if (props.type == 'adult') {
    maxDate = maxDate.setFullYear(maxDate.getFullYear() - 18);
  } else if (props.type == 'child') {
    minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 12));
  }

  let defaultCountry = null;
  if (props.value) {
    for (let country of countries) {
      if (props.value.nationality == country.abbr) {
        defaultCountry = country;
      }
    }
  }

  // console.log('traveler default country', defaultCountry);

  // if(watch(`traveler.${props.type}[${props.number}]`)){
  // 	console.log(getValues())
  // }

  // watch((data, { name, type }) => console.log(data, name, type))

  // console.log("get values", getValues());
  useEffect(() => {
    // console.log('effect', props.value);
    if (props.value) {
      setValue(`traveler.${props.type}[${props.number}]`, props.value);
      if (props.value.gender == 'M') {
        setGender(2);
      } else if (props.value.gender == 'F') {
        setGender(1);
      }

      setValue(
        `traveler.${props.type}[${props.number}].sex`,
        props.value.gender,
      );
      setDateValue(props.value.birthday);
      // setDisabledDatePicker(true);
      setIDExpirationDate(props.value.idExpirationDate);
      setIDType(props.value.idType);
      // setValue(`traveler.${props.type}[${props.number}].birthday`, props.value.birthday)
      // let birthdays = props.value.birthday.split('/')
      // setValue(`traveler.${props.type}[${props.number}].month`, birthdays[0])
      // setValue(`traveler.${props.type}[${props.number}].day`, parseInt(birthdays[1]))
      // setValue(`traveler.${props.type}[${props.number}].year`, birthdays[2])
    }
  }, []);

  // const handleChange = (event) => {
  // 	console.log('id type', event.target.value)
  // 	setIDType(event.target.value);
  //   };

  const handleChange = (event) => {
    setIDType(event.target.value);
    setValue(
      `traveler.${props.type}[${props.number}].idType`,
      event.target.value,
    );
  };

  const handleExpande = () => {
    setExpanded(!expanded);
  };

  const handleChangeFlyerProgram = (event)=>{
  }

  return (
    <div className="theme-payment-page-sections-item _p-20">
      <h3 className="theme-payment-page-sections-item-title">
        <i className="_pr-10">
          <PersonIcon />
        </i>
        {intl.get('BOOK.TRAVELER')}
        <span className="Required">
          <span className="required">*</span>
          {intl.get('BOOK.REQUIRED_DESCRIPTION')}
        </span>
      </h3>
      <legend className="block-fieldset__legend">
        {props.index}. {intl.get('BOOK.TRAVELER')}{' '}
        <span className="paxes-fieldset__type">
          &nbsp;（{intl.get('TRAVELER.' + props.type.toUpperCase())}）
        </span>
      </legend>
      <div className="col-md-12 _p-15 ba-1" style={{ marginBottom: '20px' }}>
        <div className="block-fieldset__body block-fieldset__body--alert-wrap">
          <div className="alert-box-inline alert-box-inline--info">
            <i className="fa fa-info-circle alert-box-inline__icn"></i>
            <p className="alert-box-inline__text">
              {intl.get('BOOK.NAME_REMIND')}
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 ">
          <div className="form-group">
            {/* <label className="control-label">{intl.get("COMMON.LAST_NAME")}<span className="required">*</span></label> */}

            {/* <input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].lastName`, { required: true })} disabled={props.disabled} /> */}

            <FormControl fullWidth disabled={props.disabled}>
              <InputLabel htmlFor={`input-lastName-${props.index}`}>
                {intl.get('COMMON.LAST_NAME')}
                <span className="required">*</span>
              </InputLabel>
              <OutlinedInput
                id={`input-lastName-${props.index}`}
                defaultValue=""
                label={intl.get('COMMON.LAST_NAME') + '*'}
                {...register(
                  `traveler.${props.type}[${props.number}].lastName`,
                  {
                    required: true,
                  },
                )}
              />
            </FormControl>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            {/* <label className="control-label">{intl.get("COMMON.MIDDLE_NAME")}</label>
							<input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].middleName`, { required: false })} disabled={props.disabled}/>
							<p className="help-block help-block-error"></p> */}

            <FormControl fullWidth disabled={props.disabled}>
              <InputLabel htmlFor={`input-middleName-${props.index}`}>
                {intl.get('COMMON.MIDDLE_NAME')}
              </InputLabel>
              <OutlinedInput
                id={`input-middleName-${props.index}`}
                defaultValue=""
                label={intl.get('COMMON.MIDDLE_NAME')}
                {...register(
                  `traveler.${props.type}[${props.number}].middleName`,
                )}
              />
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <FormControl fullWidth disabled={props.disabled}>
              <InputLabel htmlFor={`input-firstName-${props.index}`}>
                {intl.get('COMMON.FIRST_NAME')}
                <span className="required">*</span>
              </InputLabel>
              <OutlinedInput
                id={`input-firstName-${props.index}`}
                defaultValue=""
                label={intl.get('COMMON.FIRST_NAME') + '*'}
                {...register(
                  `traveler.${props.type}[${props.number}].firstName`,
                  {
                    required: true,
                  },
                )}
              />
            </FormControl>
            {/* <label className="control-label">{intl.get("COMMON.FIRST_NAME")}<span className="required">*</span></label>
					<input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].firstName`, { required: true })} disabled={props.disabled}/>
					<p className="help-block help-block-error"></p> */}
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group field-gender show-validation show-validation–inline field-passenger-0-ps_gender  has-success">
            <label className="control-label control-label--inline">
              {intl.get('COMMON.GENDER')}
              <span className="required">*</span>
            </label>
            <div className="mb-3">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id={`traveler${props.type}${props.number}SexMale`}
                  className="custom-control-input"
                  type="radio"
                  value="M"
                  defaultChecked={gender == 1}
                  disabled={props.disabled}
                  {...register(`traveler.${props.type}[${props.number}].sex`, {
                    required: true,
                  })}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`traveler${props.type}${props.number}SexMale`}
                >
                  {intl.get('GENDER.MALE')}
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id={`traveler${props.type}${props.number}SexFemale`}
                  className="custom-control-input"
                  type="radio"
                  value="F"
                  defaultChecked={gender == 2}
                  disabled={props.disabled}
                  {...register(`traveler.${props.type}[${props.number}].sex`, {
                    required: true,
                  })}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`traveler${props.type}${props.number}SexFemale`}
                >
                  {intl.get('GENDER.FEMALE')}
                </label>
              </div>
            </div>
            <p className="help-block help-block-error"></p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            {/* <label className="control-label">{intl.get("COMMON.BIRTHDAY")}</label> */}
            <Controller
              name={`traveler.${props.type}[${props.number}].birthday`}
              control={control}
              rules={{ required: true }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputRef={field.ref}
                    onBlur={field.onBlur}
                    disabled={props.disabled}
                    // onChange={onChange} // send value to hook form
                    //   disableFuture
                    minDate={minDate}
                    maxDate={maxDate}
                    // label={intl.get('COMMON.BIRTHDAY') + '*'}
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                      field.onChange(
                        newValue ? moment(newValue).format('MM/DD/YYYY') : '',
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <FormControl fullWidth disabled={props.disabled}>
                          <InputLabel htmlFor={`input-birthday-${props.index}`}>
                            {intl.get('COMMON.BIRTHDAY')}
                            <span className="required">*</span>
                          </InputLabel>
                          <OutlinedInput
                            inputRef={params.inputRef}
                            id={`input-birthday-${props.index}`}
                            label={intl.get('COMMON.BIRTHDAY') + '*'}
                            endAdornment={params.InputProps?.endAdornment}
                            {...params.inputProps}
                          />
                        </FormControl>
                      );
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
        </div>
        {props.includeTypes && props.includeTypes.length > 0 && (
          <div className="col-sm-6">
            <div className="form-group field-gender show-validation show-validation–inline field-passenger-0-ps_gender required has-success">
              <label className="control-label control-label--inline">
                {intl.get('BOOK.TRAVELER_TYPE')}
                <span className="required"></span>
              </label>
              <div className="mb-3">
                {((props.includeTypes.length == 1 &&
                  props.includeTypes[0] == 'student') ||
                  props.includeTypes.length > 1) && (
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      id={`traveler${props.type}${props.number}TravelerTypeStudent`}
                      className="custom-control-input"
                      type="radio"
                      value="student"
                      defaultChecked={
                        props.includeTypes.length == 1 &&
                        props.includeTypes[0] == 'student'
                      }
                      {...register(
                        `traveler.${props.type}[${props.number}].travelerType`,
                        { required: true, disabled: props.disabled },
                      )}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`traveler${props.type}${props.number}TravelerTypeStudent`}
                    >
                      {intl.get('TRAVELER.STUDENT')}
                    </label>
                  </div>
                )}
                {((props.includeTypes.length == 1 &&
                  props.includeTypes[0] == 'youth') ||
                  props.includeTypes.length > 1) && (
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      id={`traveler${props.type}${props.number}TravelerTypeYouth`}
                      className="custom-control-input"
                      type="radio"
                      value="youth"
                      defaultChecked={
                        props.includeTypes.length == 1 &&
                        props.includeTypes[0] == 'youth'
                      }
                      {...register(
                        `traveler.${props.type}[${props.number}].travelerType`,
                        { required: true, disabled: props.disabled },
                      )}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`traveler${props.type}${props.number}TravelerTypeYouth`}
                    >
                      {intl.get('TRAVELER.YOUTH')}
                    </label>
                  </div>
                )}
              </div>
              <p className="help-block help-block-error"></p>
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-sm-6 ">
          <div className="form-group">
            <Autocomplete
              defaultValue={defaultCountry}
              disabled={props.disabled}
              onChange={(event, newValue) => {
                if (newValue) {
                  setValue(
                    `traveler.${props.type}[${props.number}].nationality`,
                    newValue.abbr,
                  );
                  setValue(
                    `traveler.${props.type}[${props.number}].idIssueCountry`,
                    newValue.abbr,
                  );
                } else {
                  setValue(
                    `traveler.${props.type}[${props.number}].nationality`,
                    '',
                  );
                  setValue(
                    `traveler.${props.type}[${props.number}].idIssueCountry`,
                    '',
                  );
                }
              }}
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      {intl.get('BOOK.TRAVELER_NATIONALITY')}
                      <span className="required">*</span>
                    </span>
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  {...register(
                    `traveler.${props.type}[${props.number}].nationality`,
                    {
                      required: true,
                    },
                  )}
                />
              )}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            {/* <FormControl sx={{ m: 0, minWidth: 120 }}>
              <InputLabel>{intl.get('BOOK.TRAVELER_ID_TYPE')}</InputLabel>
              <Select
                //   labelId="demo-simple-select-label"
                //   id="demo-simple-select"
                disabled={props.disabled}
                value={IDType}
                label="IDType"
                onChange={handleChange}
              >
                <MenuItem value="P">
                  {intl.get('BOOK.TRAVELER_ID_PASSPORT')}
                </MenuItem>
              </Select>
            </FormControl> */}

            {/* <TextField label="ID Type" variant="standard" /> */}
            {/* <label className="control-label">ID Type</label>
							<input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].IDType`, { required: false })}/>
							<p className="help-block help-block-error"></p> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 ">
          <div className="form-group">
            {/* <label className="control-label">ID Number</label> */}

            {/* <input type="text" className="form-control" placeholder="" {...register(`traveler.${props.type}[${props.number}].IDNumber`)}/> */}

            {/* <TextField
              fullWidth
              label={intl.get('BOOK.TRAVELER_ID_NUMBER')}
              disabled={props.disabled}
              defaultValue=""
              //   helperText="Some important text"
              {...register(`traveler.${props.type}[${props.number}].idNumber`)}
            /> */}
            <FormControl fullWidth disabled={props.disabled}>
              <InputLabel
                htmlFor={`traveler.${props.type}[${props.number}].idNumber`}
              >
                {intl.get('BOOK.TRAVELER_ID_NUMBER')}
                <span className="required">*</span>
              </InputLabel>
              <OutlinedInput
                id={`traveler.${props.type}[${props.number}].idNumber`}
                defaultValue=""
                label={intl.get('BOOK.TRAVELER_ID_NUMBER') + '*'}
                {...register(
                  `traveler.${props.type}[${props.number}].idNumber`,
                  {
                    required: true,
                  },
                )}
              />
            </FormControl>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="form-group">
            <Controller
              name={`traveler.${props.type}[${props.number}].idExpirationDate`}
              control={control}
              rules={{ required: true }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    // {...field}
                    onBlur={field.onBlur} // notify when input is touched
                    inputRef={field.ref}
                    disabled={disabledDatePicker}
                    // onChange={onChange} // send value to hook form
                    //   disableFuture
                    minDate={new Date()}
                    // maxDate={maxDate}
                    label={intl.get('BOOK.TRAVELER_ID_EXPIRATION_DATE')}
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={IDExpirationDate}
                    onChange={(newValue) => {
                      setIDExpirationDate(newValue);

                      // console.log("date", newValue)

                      field.onChange(
                        newValue ? moment(newValue).format('MM/DD/YYYY') : '',
                      );
                      // setValue(name, moment(newValue).format("MM/DD/YYYY"))
                    }}
                    // renderInput={(params) => <TextField {...params} />}
                    renderInput={(params) => {
                      return (
                        <FormControl fullWidth disabled={props.disabled}>
                          <InputLabel
                            htmlFor={`input-traveler.${props.type}[${props.number}].idExpirationDate`}
                          >
                            {intl.get('BOOK.TRAVELER_ID_EXPIRATION_DATE')}
                            <span className="required">*</span>
                          </InputLabel>
                          <OutlinedInput
                            inputRef={params.inputRef}
                            id={`input-traveler.${props.type}[${props.number}].idExpirationDate`}
                            label={
                              intl.get('BOOK.TRAVELER_ID_EXPIRATION_DATE') + '*'
                            }
                            endAdornment={params.InputProps?.endAdornment}
                            {...params.inputProps}
                          />
                        </FormControl>
                      );
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {/* <Typography variant='body2'>Demo</Typography> */}
          <Button
            onClick={handleExpande}
            color="info"
            variant="text"
            startIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          >
            Known traveler & frequent flyer number
          </Button>
        </div>
      </div>
      {expanded && (
        <>
          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group">
                {/* <TextField
              fullWidth
              variant="outlined"
              label={'Frequent Flyer Program'}
              {...register(
                `traveler.${props.type}[${props.number}].flyerProgram`,
              )}
            /> */}
                {/* <FormControl fullWidth>
        <InputLabel id="input-select-flyer-program-label">Frequent Flyer Program</InputLabel>
        <Select
          {...register(`traveler.${props.type}[${props.number}].flyerProgram`)}
          labelId="input-select-flyer-program-label"
          id="select-flyer-program"
          label="Frequent Flyer Program"
        >
          <MenuItem value=""><em>''</em></MenuItem>
              <MenuItem value='AA'>American Airlines</MenuItem>
              <MenuItem value='UA'>United Airlines</MenuItem>
              <MenuItem value='EK'>Emirates</MenuItem>
              <MenuItem value='CM'>Copa Airlines</MenuItem>
        </Select>
        </FormControl> */}
        <FormControl fullWidth>
        <InputLabel id='input-select-flyer-program-label'>Frequent Flyer Program</InputLabel>
        <Controller
          name={`traveler.${props.type}[${props.number}].flyerProgram`}
          control={control}
          defaultValue={props.mainAirlineCode || ''}
          render={({ field }) => (
            <Select
              id="select-flyer-program"
              labelId='input-select-flyer-program-label'
              label='Frequent Flyer Program'
              {...field}
              // onChange={(e) => {
              //   field.onChange(e);
              //   setValue(`traveler.${props.type}[${props.number}].flyerProgram`, e.target.value);
              // }}
            >

              <MenuItem value=""><em>Select</em></MenuItem>
              {NDC_AIRLINES.map(airline=><MenuItem key={airline.code} value={airline.code}>{airline.label}</MenuItem>)}
              {/* <MenuItem value='AA'>American Airlines</MenuItem>
              <MenuItem value='UA'>United Airlines</MenuItem>
              <MenuItem value='EK'>Emirates</MenuItem>
              <MenuItem value='CM'>Copa Airlines</MenuItem> */}
            </Select>
          )}
        />
      </FormControl>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group">
                <TextField
                  fullWidth
                  variant="outlined"
                  label={'Frequent Flyer Number'}
                  {...register(
                    `traveler.${props.type}[${props.number}].flyerNumber`,
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group">
                <TextField
                  fullWidth
                  variant="outlined"
                  label={'Known Traveler Number'}
                  {...register(
                    `traveler.${props.type}[${props.number}].travelerNumber`,
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
