import React from 'react'
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import intl from 'react-intl-universal';
import axios from 'axios'
import ProgressBox from '../../components/ProgressBox';
import {getURLParam} from '../../common/helper.js'



export default function PaymentResult() {
  const location = useLocation();
  const [alerts, setAlerts] = useState(null)
  // console.log(location)
  useEffect(()=>{
    // console.log('payment result location', location)
    if(location.search){
      let casenumber = getURLParam('casenumber')
      let rl = getURLParam('rl')
      let state = getURLParam('state')
      if(state == "ok"){
        
        let params = {paymentType: 0, paymentModel: 'wechat', req: { domain: `${window.location.protocol}//${window.location.host}`}, 
        rsp: {airTicketBookingResponse: {confirmationCode: casenumber, recordLocator: rl}}}
        axios.post('api/payment', params)
				.then(function (res) {
					var data = JSON.parse(res.data);

          setAlerts({type:'success', message: 'OK'})
				})
				.catch(function (error) {
          setAlerts({type:'error', message: error+''})
					// console.error(error);
				}).then(function () {
          
				});
      }else if(state=='cancel'){
        setAlerts({type:'warning', message: 'CANCEL'})
      }else{
        setAlerts({type:'error', message: 'FAIL'})
      }
    }

  },[])
  return (
    alerts ? <Alert severity={alerts.type}>{intl.get('PAYMENT.' + alerts.message).defaultMessage(alerts.message)}</Alert> : <ProgressBox/>
  )
}
