import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Paper from '@mui/material/Paper';
import { Person } from '@mui/icons-material';
import RecipeReviewCard from '../RecipeReviewCard';
import { useSnackbar } from 'notistack';
import axios from 'axios';

function isObject(object) {
  return object != null && typeof object === 'object';
}

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Bundle(props) {
  console.log('init bundle', props);
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(props.currentStep || 0);
  // const [completed, setCompleted] = useState([]);
  const [travelerRefs, setTravelersRefs] = useState(null);
  const [selectedBundles, setSelectedBundles] = useState([]);

  // const [selectedBundles, setSelectedBundles] = useState([])
  useEffect(() => {
    // console.log('currentStep', props.currentStep)
    setActiveStep(props.currentStep || 0);
  }, [props.currentStep]);

  useEffect(() => {
    if (props.loadingBook) {
      // console.log('submit', selectedBundles);

      let hasServices = false;
      for (let selectedService of selectedBundles) {
        if (
          selectedService.passengers != null &&
          selectedService.passengers.some(
            (p) => p.bundles != null && p.bundles.length > 0,
          )
        ) {
          hasServices = true;
        }
      }

      if (!hasServices) {
        enqueueSnackbar("You don't choose any of the add-on services.", {
          variant: 'warning',
          autoHideDuration: 4000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });

        props.onBookResponse(null);
        return;
      }

      if(props.shouldBook){

      let passengerServices = [];
      let newSubmitServices = [...selectedBundles];
      for (let submitService of newSubmitServices) {
        for (let passenger of submitService.passengers) {
          let passengerIndex = passengerServices.findIndex(
            (item) => item.passengeRefs === passenger.passengeRefs,
          );
          if (passengerIndex < 0) {
            passengerServices.push({
              passengeRefs: passenger.passengeRefs,
              bundles: [...passenger.bundles],
            });
          } else {
            for (let bundle of passenger.bundles) {
              if (
                !passengerServices[passengerIndex].bundles.find((item) =>
                  deepEqual(item, bundle),
                )
              ) {
                passengerServices[passengerIndex].bundles.push(bundle);
              }
            }
          }
        }
      }

      bookServices(
        props.requestParas.cid,
        props.requestParas.gds,
        props.requestParas.pcc,
        props.requestParas.pnr,
        passengerServices,
        props.passengers,
      );
    }else{
      props.onBookResponse([...selectedBundles])
    }
    }
  }, [props.loadingBook]);

  let defaultTravelersRefs = null;
  let bundles = [];
  for (let trip of props.routing.trips) {
    for (let segment of trip.segments) {
      let services = [];
      if (props.services) {
        for (let service of props.services) {
          let matched = false;

          for (let routing of service.routings) {
            if (
              segment.departureAirportCode == routing.DepartureAirport &&
              segment.arrivalAirportCode == routing.ArrivalAirport
            ) {
              matched = true;
            }
          }

          if (matched) {
            services.push(service);
          }
        }
      }

      if (services.length == 0) {
        continue;
      }

      let passengers = [];

      for (let service of services) {
        for (let offerItem of service.offerInfo.offerItems) {
          let passengerIndex = passengers.findIndex(
            (item) => item.passengeRefs == offerItem.passengeRefs,
          );
          if (passengerIndex < 0) {
            if (travelerRefs == null) {
              setTravelersRefs(offerItem.passengeRefs);
              defaultTravelersRefs = offerItem.passengeRefs;
            }
            passengers.push({
              passengeRefs: offerItem.passengeRefs,
              passenger: { type: service.passengerTypes },
              services: [service],
            });
          } else {
            passengers[passengerIndex].services.push(service);
          }
        }
      }

      // console.log('passengers', passengers)

      bundles.push({
        segment: {
          departureAirportCode: segment.departureAirportCode,
          arrivalAirportCode: segment.arrivalAirportCode,
        },
        passengers,
      });
    }
  }

  // console.log('bundles', bundles);

  const handleStep = (step) => () => {
    // console.log('step', step, activeStep)
    if (step == activeStep) {
      return;
    }

    if (props.onClickNext) {
      props.onClickNext(step);
    } else {
      setActiveStep(step);
    }

    setTravelersRefs(defaultTravelersRefs);
  };

  const handleSelectBundle = (bundle, checked) => {
    // console.log('select bundle', props.selectedBundles)
    let newSelectedBundles = selectedBundles;
    if (checked) {
      for (let i = 0, len = bundles.length; i < len; i++) {
        for (let passenger of bundles[i].passengers) {
          if (passenger.passengeRefs == travelerRefs) {
            for (let service of passenger.services) {
              // console.log(i, passenger, bundle, service, deepEqual(bundle, service))
              if (deepEqual(bundle, service)) {
                let bundlesIndex = newSelectedBundles.findIndex(
                  (item) => item.index == i,
                );
                if (bundlesIndex < 0) {
                  newSelectedBundles.push({
                    index: i,
                    passengers: [
                      { passengeRefs: travelerRefs, bundles: [bundle] },
                    ],
                  });
                } else {
                  let passengerIndex = newSelectedBundles[
                    bundlesIndex
                  ].passengers.findIndex(
                    (item) => item.passengeRefs == travelerRefs,
                  );
                  if (passengerIndex < 0) {
                    newSelectedBundles[bundlesIndex].passengers.push({
                      passengeRefs: travelerRefs,
                      bundles: [bundle],
                    });
                  } else {
                    newSelectedBundles[bundlesIndex].passengers[
                      passengerIndex
                    ].bundles.push(bundle);
                  }
                }
              }
            }
          }
        }
      }
    } else {
      for (let newSelectedBundle of newSelectedBundles) {
        for (let passenger of newSelectedBundle.passengers) {
          if (passenger.passengeRefs == travelerRefs) {
            let newBundles = [];
            for (let newBundle of passenger.bundles) {
              if (!deepEqual(newBundle, bundle)) {
                newBundles.push(newBundle);
              }
            }

            passenger.bundles = newBundles;
          }
        }
      }
    }

    // if(checked){
    //   let bundlesIndex = newSelectedBundles.findIndex(item=>item.index == activeStep)
    //   // console.log('step', activeStep, 'refs', travelerRefs)
    //   if(bundlesIndex < 0){
    //     newSelectedBundles.push({index: activeStep, passengers:[{passengeRefs: travelerRefs, bundles: [bundle]}]})
    //   }else{
    //     let passengerIndex = newSelectedBundles[bundlesIndex].passengers.findIndex(item=>item.passengeRefs == travelerRefs)
    //     if(passengerIndex < 0){
    //       newSelectedBundles[bundlesIndex].passengers.push({passengeRefs: travelerRefs, bundles: [bundle]})
    //     }else{
    //       newSelectedBundles[bundlesIndex].passengers[passengerIndex].bundles.push(bundle)
    //     }
    //   }
    // }else{
    //   let bundlesIndex = newSelectedBundles.findIndex(item=>item.index == activeStep)
    //   let passengerIndex = newSelectedBundles[bundlesIndex].passengers.findIndex(item=>item.passengeRefs == travelerRefs)
    //   let newBundles = []
    //   for(let newBundle of newSelectedBundles[bundlesIndex].passengers[passengerIndex].bundles){
    //     if(!deepEqual(newBundle, bundle)){
    //       newBundles.push(newBundle)
    //     }
    //   }

    //   newSelectedBundles[bundlesIndex].passengers[passengerIndex].bundles = newBundles
    // }

    // console.log('selected bundles', newSelectedBundles)
    // props.onSelectBundle([...newSelectedBundles]);
    setSelectedBundles([...newSelectedBundles]);
  };

  const bookServices = (
    cid,
    gds,
    pcc,
    pnr,
    passengerToServices,
    passengers,
  ) => {
    // console.log('book services', passengerToServices, passengers)
    if (
      !passengerToServices ||
      passengerToServices.length === 0 ||
      passengerToServices
        .map((x) => x.bundles.length)
        .reduce((prev, curr) => prev + curr, 0) === 0
    ) {
      return;
    }

    let passengerServices = [];
    for (let passenger of passengers) {
      passengerServices.push({
        firstName: passenger.firstName,
        middleName: passenger.middleName,
        lastName: passenger.lastName,
        nameRef: passenger.nameRef,
      });
    }

    for (let passengerToService of passengerToServices) {
      if (
        passengerToService.passengeRefs &&
        passengerToService.passengeRefs.length >= 2
      ) {
        // let passengerIndex = parseInt(passengerToService.passengeRefs.substring(1)) - 1
        let passengerIndex = passengerServices.findIndex(
          (p) => p.nameRef === passengerToService.passengeRefs,
        );
        if (passengerIndex < 0) {
          continue;
        }

        if (passengerServices[passengerIndex]) {
          for (let bundleItem of passengerToService.bundles) {
            for (let offerItem of bundleItem.offerInfo.offerItems) {
              let selections = [];
              selections.push({
                serviceid: offerItem.serviceId,
                serviceDefinitionId: offerItem.serviceDefinitionId,
              });

              let service = {
                shoppingResponseId: bundleItem.offerInfo.responseId,
                offerId: bundleItem.offerInfo.offerId,
                owner: bundleItem.offerInfo.owner,
                offerItemRef: offerItem.offerItemId,
                passengerRef: offerItem.passengeRefs,
                baseFare: bundleItem.baseAmount,
                tax: bundleItem.taxAmount,
                totalPrice: bundleItem.totalAmount,
                serviceName: bundleItem.name,
                selections: selections,
              };

              if (passengerServices[passengerIndex].services) {
                passengerServices[passengerIndex].services.push(service);
              } else {
                passengerServices[passengerIndex].services = [service];
              }
            }
          }
        }
      }
    }

    // console.log({passengers: passengerServices})
    // setProgressingServices(true);
    let params = {
      cid: cid,
      customerSessionId: 'S12345',
      gds: gds,
      pcc: pcc,
      pnr: pnr,
      passengers: JSON.stringify({ passengers: passengerServices }),
    };

    // console.log('book', params)
    // props.onBookResponse([...passengerToServices])
    // return

    axios
      .post('api/reserveServices', params)
      .then(function (res) {
        const resJson = JSON.parse(res.data);
        if ('APIError' in resJson) {
          for (let error of resJson.APIError.errors) {
            enqueueSnackbar('Book services: ' + error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        } else if (resJson.reserveServiceResponse.isSuccess) {
          enqueueSnackbar('Book services: success', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });

          // setPassengerToServices([...passengerToServices]);
          props.onBookResponse([...passengerToServices]);
        } else {
          enqueueSnackbar(
            'Book services: ' + resJson.reserveServiceResponse.errorMessage,
            {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            },
          );
        }
      })
      .catch(function (error) {
        enqueueSnackbar('Book services: ' + error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        // setProgressingServices(false);
        props.onBookResponse(null);
      });
  };

  // const handleSelectService = (bundles) => {
  //   // console.log('selected services', bundles)
  //   setSelectedServices([...bundles]);
  // };

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Stepper nonLinear activeStep={activeStep}>
            {bundles.map((item, index) => (
              <Step
                key={
                  item.segment.departureAirportCode +
                  item.segment.arrivalAirportCode
                }
              >
                <StepButton
                  color="inherit"
                  onClick={handleStep(index)}
                  optional={
                    <Typography variant="caption" display="block" gutterBottom>
                      {item.segment.departureAirportCode +
                        ' to ' +
                        item.segment.arrivalAirportCode}
                    </Typography>
                  }
                >
                  {`flight ${index + 1} of ${bundles.length}`}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            {bundles[activeStep] &&
              bundles[activeStep].passengers.find(
                (item) => item.passengeRefs == travelerRefs,
              ) &&
              bundles[activeStep].passengers
                .find((item) => item.passengeRefs == travelerRefs)
                .services.sort((a, b) => a.totalAmount - b.totalAmount)
                .map((service, index) => (
                  <Grid
                    key={
                      bundles[activeStep].segment.departureAirportCode +
                      bundles[activeStep].segment.arrivalAirportCode +
                      travelerRefs +
                      'service' +
                      index
                    }
                    item
                    xs={12}
                    md={6}
                  >
                    <RecipeReviewCard
                      index={index}
                      service={service}
                      onSelectBundle={handleSelectBundle}
                      some={
                        selectedBundles.find(
                          (selectedBundle) =>
                            selectedBundle.index == activeStep,
                        ) &&
                        selectedBundles
                          .find(
                            (selectedBundle) =>
                              selectedBundle.index == activeStep,
                          )
                          .passengers.find(
                            (passenger) =>
                              passenger.passengeRefs == travelerRefs,
                          ) &&
                        selectedBundles
                          .find(
                            (selectedBundle) =>
                              selectedBundle.index == activeStep,
                          )
                          .passengers.find(
                            (passenger) =>
                              passenger.passengeRefs == travelerRefs,
                          )
                          .bundles.some((bundle) => deepEqual(bundle, service))
                      }
                    />
                  </Grid>
                ))}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
              {bundles[activeStep] &&
                bundles[activeStep].segment.departureAirportCode +
                  ' to ' +
                  bundles[activeStep].segment.arrivalAirportCode}
            </Typography>
            <Divider />
            <Grid container spacing={2}>
              {bundles[activeStep] &&
                bundles[activeStep].passengers.map((item) => (
                  <Grid key={item.passengeRefs} item xs={12}>
                    <Item>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <Button
                            variant={
                              travelerRefs == item.passengeRefs
                                ? 'contained'
                                : 'outlined'
                            }
                            onClick={() => setTravelersRefs(item.passengeRefs)}
                            startIcon={<Person />}
                          >
                            {item.passengeRefs.replace('T', 'P')}
                            {props.passengers &&
                              props.passengers.find(
                                (p) => p.nameRef == item.passengeRefs,
                              ) &&
                              ` (${
                                props.passengers.find(
                                  (p) => p.nameRef == item.passengeRefs,
                                ).lastName
                              } ${
                                props.passengers.find(
                                  (p) => p.nameRef == item.passengeRefs,
                                ).middleName
                              } ${
                                props.passengers.find(
                                  (p) => p.nameRef == item.passengeRefs,
                                ).firstName
                              })`}
                            {/* {item.passenger && item.passenger.type && "("+ item.passenger.type.join(",") +")"} */}
                          </Button>
                        </Grid>
                        <Grid item xs={8}>
                          {selectedBundles.find(
                            (selectedBundle) =>
                              selectedBundle.index == activeStep,
                          ) &&
                            selectedBundles
                              .find(
                                (selectedBundle) =>
                                  selectedBundle.index == activeStep,
                              )
                              .passengers.find(
                                (passenger) =>
                                  passenger.passengeRefs == item.passengeRefs,
                              ) &&
                            selectedBundles
                              .find(
                                (selectedBundle) =>
                                  selectedBundle.index == activeStep,
                              )
                              .passengers.find(
                                (passenger) =>
                                  passenger.passengeRefs == item.passengeRefs,
                              )
                              .bundles.map((bundle, index) => (
                                <Grid
                                  key={
                                    activeStep +
                                    item.passengeRefs +
                                    bundle.name +
                                    index
                                  }
                                  container
                                  spacing={2}
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{ borderBottom: '1px gray dashed' }}
                                >
                                  <Grid item xs={6}>
                                    <Typography>{bundle.name}</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>
                                      {bundle.totalAmount.toLocaleString('en', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))}
                        </Grid>
                      </Grid>
                    </Item>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
