import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Contact from '../../components/Contact';
import PaymentDetail from '../../components/PaymentDetail';
import Traveler from '../../components/Traveler';
import axios from 'axios';
import moment from 'moment/moment';
import { AppBar, LinearProgress } from '@mui/material';
import {loadCss} from '../../common/helper.js'


export default function HotelBook() {
    const location = useLocation()
    const methods = useForm()
    const [loading, setLoading] = useState(false)

    const onSubmit=data=>{
        // console.log('submit', data);
        let passengers = []
        for(let travelerKey in data.traveler){
          for(let traveler of data.traveler[travelerKey]){
            passengers.push({FirstName: traveler.firstName, MiddleName: traveler.middleName,
              LastName: traveler.lastName, Type: travelerKey, Gender: traveler.sex})
          }
        }

        let contact = {FirstName: data.contact.firstName, MiddleName: data.contact.middleName,
          LastName: data.contact.lastName,
          CountryName: data.contact.country, ProvinceName: data.contact.state,
          CityName: data.contact.city, StreetAddress: data.contact.address1,
          Telephone: data.contact.phoneNumber, Email: data.contact.emailAddress,
          ZipCode: data.contact.zipCode
        }

        let m = moment(data.payment.expiration, 'MM/YY')
        let payment = {PaymentType: 'CC', CreditCardType: 'VI', CreditCardNumber: data.payment.creditCardNumber, ExpirationYear: m.year().toString(), ExpirationMonth: (m.month() +1).toString(),
        CardVerificationNumber: data.payment.cardVerificationNumber,
        FirstName: data.payment.firstName, MiddleName: data.payment.middleName, LastName: data.payment.lastName
      }

        let params = {...location.state.hotelInfo, Rooms: [location.state.selectedRooms], OpenID: 'oead2wblKN9D9dy6E5ZK5yaL7GcY', 
        Ver: 'cn', Source: 'FLYH', SubSource: 'FLYH', Passengers: passengers, Contact: contact,
        Payment: payment
      }
        setLoading(true)
        axios.post('api/HotelBook', params)
				.then(function (res) {
					// console.log(res)
          let json = JSON.parse(res.data)
          if(json.ErrorCode != 0){
            if(json.ExternalBookingId){
              alert(`Although the order was successful, some errors were encountered. ERROR: ${json.ErrorMassage}`)
            }else{
              alert(json.ErrorMassage)
            }
          }else{
            alert(`successfully ordered, case number: ${json.CaseNumber}`)
          }
				})
				.catch(function (error) {
					// console.error(error);
          alert(error)
				}).then(function () {
          setLoading(false)
				});

    }

    // console.log('hotel book', location)
    useEffect(()=>{
        loadCss('css/MVstyle-2022.css');
        loadCss('font-awesome/css/all.min.css');
        loadCss('css/bootstrap.min.css');
        loadCss('css/stylesheet.css');
        loadCss('Flight-Hotel/css/styles.css');
    },[])
    return (
        <>
        {loading && <AppBar position="fixed"><LinearProgress/></AppBar>}
        
        <div className="theme-page-section theme-page-section-lg _mt-desk-30">
          <div className="container">
            <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="row row-col-static row-col-mob-gap" id="sticky-parent" data-gutter={60}>
              {/*///// ===== 右 邊 訂房+機票 區塊 ====== /////*/}
              <div className="col-md-8 _pt-mob-25 _pb-mob-25">
                <div className="theme-payment-page-sections">
                  <div className="theme-payment-page-sections-item">
                    <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
                      {/* ==========================
                              /// 酒 店 資 訊 //// 
                          ========================*/}
                      <div className="row">
                        <div className="col-md-8">
                          <div className="theme-search-results-item-flight-details-schedule">
                            <ul className="theme-search-results-item-flight-details-schedule-list">
                              <li>
                                <i className="fa fa-bed theme-search-results-item-flight-details-schedule-icon" />
                                <h5 className="theme-search-results-item-title">{location.state.hotelInfo.HotelName}</h5>
                                <p className="mb-2">
                                  <span className="mr-2">
                                  {location.state.hotelInfo.StarRating && Array.from(new Array(parseInt(location.state.hotelInfo.StarRating)), (v, i) => i).map((item, index)=>{return <i key={index} className="fas fa-star text-warning" />})}
                                  </span>
                                  <span className="text-black-50"><i className="fas fa-map-marker-alt" /> </span>
                                </p>
                                <p className="theme-sidebar-section-hotel-thumbnail-address _mv-desk-20">{location.state.hotelInfo.HotelAddress}</p>
                                <p className="theme-search-results-item-flight-payment-info _fw-600 _mt-20"></p>
                                <div className="theme-search-results-item-flight-details-schedule-time _mt-20">
                                  <ul className="theme-search-results-item-hotel-room-features">
                                    <li className="_fw-500"><i className="fa fa-check mb-1 " />1 room, 2 adults<div width="1px" className="pipe" />6 nights</li>
                                    <li className="_fw-500"><i className="fa fa-check mb-1" />Check-in : {location.state.hotelInfo.CheckIn}</li>
                                    <li className="_fw-500"><i className="fa fa-check mb-1" />Check-out : {location.state.hotelInfo.CheckOut}</li>
                                    <li className="_fw-500"><i className="fa fa-check mb-1" />Free Cancellation</li>
                                    <li><i className="fa fa-bed" />1 King bed</li>
                                    <li><i className="fa fa-wifi" />Free wifi</li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>{/*訂 房 資 訊*/}
                        </div>{/*col-md-9*/}
                        <div className="col-md-4">{/*酒店圖片*/}
                          <div className="theme-search-results-item-img-wrap">
                            <img className="theme-search-results-item-img _mob-h" src="images/brands/hotels/hotel-2.jpg" alt="Image Alternative text" title="Image Title" />
                          </div>
                          <ul className="Change">
                            <a href="#"><li className="Changehotel">Change hotel</li></a>
                          </ul>
                        </div>
                      </div>{/*row  酒 店 資 訊 end */}							
                    </div>
                  </div>
                  <Contact displaySame/>
                  <Traveler key={"travelerAdult0"} index={1} type={"adult"} number={0} disabled={false} /> 

                  <PaymentDetail/>
                </div>
              </div>{/*col-md-8 左邊區塊結束*/}
              {/*============================================
              ========== 右 邊 收 費 摘 要 ==================*/}
              <div className="col-md-4 ">
                <div className="sticky-col">
                  <div className="theme-sidebar-section _mb-10">
                    <h5 className="theme-sidebar-section-title">订单摘要</h5>
                    <div className="theme-sidebar-section-charges">
                      <ul className="theme-sidebar-section-charges-list">
                        <li className="theme-sidebar-section-charges-item">
                          <h5 className="theme-sidebar-section-charges-item-title">1 间客房 2 晚</h5>
                          <p className="theme-sidebar-section-charges-item-price">{location.state.selectedRooms.Rates[0].Sellprice}</p>
                        </li>
                        {/* <li className="theme-sidebar-section-charges-item">
                          <h5 className="theme-sidebar-section-charges-item-title">机票</h5>
                          <p className="theme-sidebar-section-charges-item-subtitle">经济舱</p>
                          <p className="theme-sidebar-section-charges-item-subtitle" />
                          <p className="theme-sidebar-section-charges-item-price _mt-10">$584.00</p>
                        </li> */}
                        <li className="theme-sidebar-section-charges-item">
                          <h5 className="theme-sidebar-section-charges-item-title">旅客人数</h5>
                          <p className="theme-sidebar-section-charges-item-subtitle" />
                          <p className="theme-sidebar-section-charges-item-price _mt-10">{parseInt(location.state.selectedRooms.Adults) + parseInt(location.state.selectedRooms.Children)}</p>
                        </li>
                      </ul>
                      <p className="theme-sidebar-section-charges-total">总计<span>${(parseInt(location.state.selectedRooms.Adults) + parseInt(location.state.selectedRooms.Children)) * location.state.selectedRooms.Rates[0].Sellprice}</span></p>
                      <p className="theme-sidebar-section-charges-item-subtitle">以美元报价的价格包括。 税费。</p>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button type="submit" className="btn checkout-form__btn mkt-chkt-pscrlnw-btn">
                      <span className="btn-text mkt-chkt-pscrlnw-btn">立即预订 </span>
                      <i className="btn-icn hif hif-arrow-right" />
                      <span className="btn-preloader" />
                      <i className="fa fa-arrow-right" />
                    </button></div> 	
                </div>
              </div>{/*== 右 邊 收 費 摘 要 結束==*/}
            </div>{/*row row-col-static*/}
            </form>
            </FormProvider>
          </div>{/*container*/}
        </div>
        </>
      );
}
