import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import intl from 'react-intl-universal';
import Deck from '../Deck';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
// import Pagination from '@mui/material/Pagination';
import Card from '@mui/material/Card';
import TravelerSeats from '../TravelerSeats';
import { deepPureEqual } from '../../utils/utils.js';
import axios from 'axios';
import { useSnackbar } from 'notistack';
// import PeopleIcon from '@mui/icons-material/People';

function convertSeatStatus(status) {
  if (status == 'A') {
    return 'AVAILABLE';
  } else if (status == 'N') {
    return 'UNAVAILABLE';
  }

  return status;
}

const SearchSeat = (seatNumber, seatMapDetails) => {
  let seat = null;
  if (seatNumber && seatMapDetails) {
    let rowNum = seatNumber.substring(0, seatNumber.length - 1);
    let columnNum = seatNumber.substring(seatNumber.length - 1);
    for (let seatMapDetail of seatMapDetails) {
      for (let airRow of seatMapDetail.airRows) {
        for (let airSeat of airRow.airSeats) {
          if (airRow.rowNumber === rowNum && airSeat.seatNumber === columnNum) {
            seat = airSeat;
          }
        }
      }
    }
  }

  return seat;
};

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

// const steps = ['Flight 1 of 2', 'Flight 2 of 2'];

export default function Seats(props) {
  console.log('seat demo', props);
  // const location = useLocation();
  // const [seatmaps, setSeatmaps] = useState(null)
  // const [traverlers, setTravelers] = useState([])
  // const [selectedSeats, setSelectedSeats] = useState(props.selectedSeats)
  const [activeStep, setActiveStep] = useState(props.currentStep || 0);
  const [completed, setCompleted] = useState([]);
  const [open, setOpen] = useState(false);
  const [clickStep, setClickStep] = useState(0);
  const [deleteSeats, setDeleteSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [travelers, setTravelers] = useState(props.travelers);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // console.log('currentStep', props.currentStep)
    setActiveStep(props.currentStep || 0);
  }, [props.currentStep]);

  function issueTravelers(selectedSeats) {
    let travelers = props.travelers;
    for (let i = 0; i < travelers.length; i++) {
      let flights = travelers[i].flights;
      for (let flight of flights) {
        if (flight.selectedSeat) {
          flight.selectedSeat = null;
        }
      }

      for (let j = 0; j < flights.length; j++) {
        let findSeat = selectedSeats.find((item) => item.index === j);
        if (findSeat && findSeat.seats.length > 0) {
          for (let k = 0; k < findSeat.seats.length; k++) {
            if (i === k) {
              travelers[i].flights[j].selectedSeat = {
                number: findSeat.seats[k].number,
                orderItemId: null,
                seat: SearchSeat(
                  findSeat.seats[k].number,
                  props.seatmaps.seatMapResponse.seatMapResponses[j]
                    .SeatMapDetails,
                ),
              };
            }
          }
        }
      }
    }

    // console.log('new travelers', travelers);

    setTravelers([...travelers]);
  }

  useEffect(() => {
    if (props.loadingBook) {
      let seatmaps = props.seatmaps;
      let submitSeats = [];
      for (let selectedSeat of selectedSeats) {
        if (selectedSeat.seats.length > 0) {
          let seat = {
            segment:
              seatmaps.seatMapResponse.seatMapResponses[selectedSeat.index]
                .FlightSegments[0],
            seats: selectedSeat.seats,
          };

          submitSeats.push(seat);
        }
      }

      let travelers = props.travelers;
      for (let i = 0; i < travelers.length; i++) {
        let flights = travelers[i].flights;
        for (let j = 0; j < flights.length; j++) {
          let findSeat = selectedSeats.find((item) => item.index === j);
          if (findSeat && findSeat.seats.length > 0) {
            for (let k = 0; k < findSeat.seats.length; k++) {
              if (i === k) {
                travelers[i].flights[j].selectedSeat = {
                  number: findSeat.seats[k].number,
                  orderItemId: null,
                  seat: SearchSeat(
                    findSeat.seats[k].number,
                    seatmaps.seatMapResponse.seatMapResponses[j].SeatMapDetails,
                  ),
                };
              }
            }
          }
        }
      }

      if ((submitSeats && submitSeats.length > 0) || deleteSeats.length > 0) {
        if (props.shouldBook) {
          let passengers = [];
          for (let travelerDetail of travelers) {
            let names = travelerDetail.traveler.name.split(' ');
            let firstName = '';
            let middleName = '';
            let lastName = '';
            if (names.length === 3) {
              firstName = names[2];
              middleName = names[1];
              lastName = names[0];
            } else if (names.length === 2) {
              firstName = names[1];
              lastName = names[0];
            } else {
              firstName = travelerDetail.traveler.name;
            }

            let seats = [];
            for (let flight of travelerDetail.flights) {
              let deleted =
                deleteSeats.findIndex(
                  (s) =>
                    s.travelerId === travelerDetail.passengerKey &&
                    deepPureEqual(s.flight, flight),
                ) >= 0;
              if (deleted) {
                seats.push({
                  shoppingResponseId:
                    seatmaps.seatMapResponse.shoppingResponseID,
                  offerId: seatmaps.seatMapResponse.offerID,
                  owner: seatmaps.seatMapResponse.owner,
                  // offerItemRef: '',
                  passengerRef: travelerDetail.passengerKey,
                  // rowNumber: '',
                  // seatNumber: '',
                  // baseFare: offerItem.BaseFare,
                  // tax: offerItem.Tax,
                  // totalPrice: offerItem.TotalPrice,
                  flightId: flight.segments[0].FlightID,
                  airline: flight.segments[0].marketingAirline,
                  flightNumber: flight.segments[0].flightNumber,
                  oldOrderItemId:
                    flight.areadySelectedSeat &&
                    flight.areadySelectedSeat.orderItemId
                      ? flight.areadySelectedSeat.orderItemId
                      : '',
                });
              } else {
                if (flight.selectedSeat && flight.selectedSeat.number) {
                  const offerItem = flight.selectedSeat.seat.OfferItems.find(
                    (item) => item.PassengerRef === travelerDetail.passengerKey,
                  );

                  seats.push({
                    shoppingResponseId:
                      seatmaps.seatMapResponse.shoppingResponseID,
                    offerId: seatmaps.seatMapResponse.offerID,
                    owner: seatmaps.seatMapResponse.owner,
                    offerItemRef: offerItem.OfferItemRef,
                    passengerRef: offerItem.PassengerRef,
                    rowNumber: flight.selectedSeat.number.substring(
                      0,
                      flight.selectedSeat.number.length - 1,
                    ),
                    seatNumber: flight.selectedSeat.number.substring(
                      flight.selectedSeat.number.length - 1,
                    ),
                    baseFare: offerItem.BaseFare,
                    tax: offerItem.Tax,
                    totalPrice: offerItem.TotalPrice,
                    flightId: flight.segments[0].FlightID,
                    airline: flight.segments[0].marketingAirline,
                    flightNumber: flight.segments[0].flightNumber,
                    oldOrderItemId:
                      flight.areadySelectedSeat &&
                      flight.areadySelectedSeat.orderItemId
                        ? flight.areadySelectedSeat.orderItemId
                        : '',
                  });
                }
              }
            }
            passengers.push({
              firstName: firstName,
              middleName: middleName,
              lastName: lastName,
              seats: seats,
            });
          }

          // console.log({
          //   cid: props.requestParas.cid,
          //   customerSessionId: props.requestParas.customerSessionId,
          //   gds: props.requestParas.gds,
          //   pcc: props.requestParas.pcc,
          //   pnr: props.requestParas.pnr,
          //   passengers: JSON.stringify({ passengers: passengers }),
          // });
          // props.onSubmit(false);
          // return
          axios
            .post('api/reserveseat', {
              cid: props.requestParas.cid,
              customerSessionId: props.requestParas.customerSessionId,
              gds: props.requestParas.gds,
              pcc: props.requestParas.pcc,
              pnr: props.requestParas.pnr,
              passengers: JSON.stringify({ passengers: passengers }),
            })
            .then(function (res) {
              // console.log('seatmaps book', res, JSON.parse(res.data))
              const resJson = JSON.parse(res.data);
              if (resJson.reserveSeatResponse.isSuccess) {
                props.onBookResponse(submitSeats);

                enqueueSnackbar('Seats have been ordered', {
                  variant: 'success',
                  autoHideDuration: 4000,
                  anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
              } else {
                enqueueSnackbar(resJson.reserveSeatResponse.errorMessage, {
                  variant: 'error',
                  autoHideDuration: 6000,
                  anchorOrigin: { horizontal: 'center', vertical: 'top' },
                });
              }
            })
            .catch(function (error) {
              console.error(error);
              enqueueSnackbar(error.message, {
                variant: 'error',
                autoHideDuration: 6000,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
              });
            })
            .then(function () {
              
              props.onSubmit(false);
            });
        } else {//--------don't book
          props.onSubmit(false);
          props.onBookResponse(submitSeats);
        }
      } else {
        if (
          selectedSeats.length === 0 ||
          selectedSeats.findIndex((item) => item.selectSeats.length > 0) < 0 ||
          deleteSeats.length < 0
        ) {
          enqueueSnackbar('Please select seats', {
            variant: 'warning',
            autoHideDuration: 3000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        }

        props.onSubmit(false);
      }
    }
  }, [props.loadingBook]);

  const handleStep = (step) => () => {
    console.log('step', activeStep, step);
    if (activeStep == step) {
      return;
    }
    setClickStep(step);
    let selectedSeatsCount = 0;
    let selectedSeat = selectedSeats.find((item) => item.index == activeStep);
    if (selectedSeat) {
      selectedSeatsCount = selectedSeat.seats.length;
    }

    if (
      props.seatmaps.seatMapResponse.seatMapResponses[activeStep]
        .TravelerIdentifiers.length != selectedSeatsCount
    ) {
      setOpen(true);
    } else {
      // setActiveStep(step);

      if (props.onClickNext) {
        props.onClickNext(step);
      } else {
        setActiveStep(step);
      }
    }
  };

  const addSeat = (seat) => {
    const index = selectedSeats.findIndex((item) => item.index == activeStep);
    // console.log('add seat', selectedSeats)
    if (index < 0) {
      let newSelectedSeats = [
        ...selectedSeats,
        { index: activeStep, seats: [seat] },
      ];

      setSelectedSeats([...newSelectedSeats]);
      issueTravelers(newSelectedSeats);
      if (
        props.seatmaps.seatMapResponse.seatMapResponses[activeStep]
          .TravelerIdentifiers.length == 1
      ) {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted([...newCompleted]);
      }
    } else {
      let newSelectedSeats = selectedSeats;
      newSelectedSeats[index].seats = [...newSelectedSeats[index].seats, seat];
      setSelectedSeats([...newSelectedSeats]);
      issueTravelers(newSelectedSeats);
      if (
        props.seatmaps.seatMapResponse.seatMapResponses[activeStep]
          .TravelerIdentifiers.length == newSelectedSeats[index].seats.length
      ) {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted([...newCompleted]);
      }
    }
  };

  const removeSeat = (seatNumber) => {
    let newSelectedSeats = selectedSeats;
    newSelectedSeats[activeStep].seats = newSelectedSeats[
      activeStep
    ].seats.filter((item) => item.number !== seatNumber);
    // console.log('remove', newSelectedSeats);
    setSelectedSeats([...newSelectedSeats]);
    issueTravelers(newSelectedSeats);
    // console.log('remove');
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    setCompleted([...newCompleted]);
  };

  const handleIgnore = () => {
    setOpen(false);
    // console.log('click step', clickStep)
    if (props.onClickNext) {
      props.onClickNext(clickStep);
    } else {
      setActiveStep(clickStep);
    }
    // setActiveStep(clickStep);
  };

  // const handleChange = (event, value) => {
  //   // console.log('change', value)
  //   if (activeStep == value - 1) {
  //     return;
  //   }
  //   setClickStep(value - 1);
  //   let selectedSeatsCount = 0;
  //   let selectedSeat = props.selectedSeats.find(
  //     (item) => item.index == activeStep,
  //   );
  //   if (selectedSeat) {
  //     selectedSeatsCount = selectedSeat.seats.length;
  //   }

  //   if (
  //     props.seats[activeStep].TravelerIdentifiers.length != selectedSeatsCount
  //   ) {
  //     setOpen(true);
  //   } else {
  //     setActiveStep(value - 1);
  //   }
  // };

  const handleClickeDeleteSeat = (travelerId, flight, checked) => {
    // console.log(travelerId, flight, checked)
    let newDeleteSeats = [...deleteSeats];
    let findIndex = newDeleteSeats.findIndex(
      (s) => s.travelerId === travelerId && deepPureEqual(s.flight, flight),
    );
    // console.log('find', findIndex, newDeleteSeats)
    if (checked) {
      if (findIndex < 0) {
        newDeleteSeats.push({ travelerId, flight });
      }
    } else {
      if (findIndex >= 0) {
        newDeleteSeats.splice(findIndex, 1);
      }
    }

    setDeleteSeats(newDeleteSeats);

    // console.log('delete seats', newDeleteSeats)
  };
  // console.log(props.seats);

  // const handleSelectedSeats = (seats) => {
  //   // console.log('selected seats', seats)
  //   setSelectedSeats([...seats]);
  // };

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Stepper nonLinear activeStep={activeStep}>
            {props.seatmaps.seatMapResponse.seatMapResponses &&
              props.seatmaps.seatMapResponse.seatMapResponses.map(
                (seat, index) =>
                  seat.FlightSegments.map((segment) => (
                    <Step
                      key={segment.origin + segment.destination}
                      completed={completed[index]}
                    >
                      <StepButton
                        sx={{ color: 'primary.main' }}
                        onClick={handleStep(index)}
                        optional={
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {segment.origin + ' to ' + segment.destination}
                          </Typography>
                        }
                      >
                        {`flight ${index + 1} of ${
                          props.seatmaps.seatMapResponse.seatMapResponses.length
                        }`}
                      </StepButton>
                    </Step>
                  )),
              )}
          </Stepper>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: '1rem;' }}>
        <Grid item sm={12} lg={8}>
          {props.seatmaps.seatMapResponse.seatMapResponses && (
            <Deck
              deck={
                props.seatmaps.seatMapResponse.seatMapResponses[activeStep].deck
              }
              selectSeats={
                selectedSeats && selectedSeats[activeStep]
                  ? selectedSeats[activeStep].seats
                  : []
              }
              addSeat={addSeat}
              removeSeat={removeSeat}
              max={
                props.seatmaps.seatMapResponse.seatMapResponses[activeStep]
                  .TravelerIdentifiers.length
              }
            />
          )}
        </Grid>

        <Grid item sm={12} lg={4}>
          <Grid container>
            <Grid item xs={12} container>
              <Grid item xs={3} container>
                <Grid item xs={12} justifyContent="center">
                  <div
                    className="seat-example available"
                    style={{ margin: 'auto' }}
                  ></div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" align="center">
                    {intl.get('SEATMAP.AVAILABLE')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} justifyContent="center">
                  <div
                    className="seat-example unavailable"
                    style={{ margin: 'auto' }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#e70018"
                      align="center"
                    >
                      X
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" align="center">
                    {intl.get('SEATMAP.UNAVAILABLE')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} justifyContent="center">
                  <div
                    className="seat-example selected"
                    style={{ margin: 'auto' }}
                  ></div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" align="center">
                    {intl.get('SEATMAP.SELECTED')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} justifyContent="center">
                  <div
                    className="seat-example assigned"
                    style={{ margin: 'auto' }}
                  ></div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" align="center">
                    {intl.get('SEATMAP.ASSIGNED')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
            <Stack spacing={2} sx={{ padding: '10px' }}>
              <Item sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <div className="seat-example available"></div>
                <span>{intl.get('SEATMAP.AVAILABLE')}</span>
              </Item>
              <Item sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <div className="seat-example unavailable">X</div>
                <span>{intl.get('SEATMAP.UNAVAILABLE')}</span>
              </Item>
              <Item sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <div className="seat-example selected"></div>
                <span>{intl.get('SEATMAP.SELECTED')}</span>
              </Item>
              <Item sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <div className="seat-example assigned"></div>
                <span>{intl.get('SEATMAP.ASSIGNED')}</span>
              </Item>
            </Stack>
            </Grid> */}
            {/* <Grid item xs={12}>
            <Card variant="outlined">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ textAlign: 'center', padding: '10px 0' }}
            >
              {props.seats &&
                props.seats[activeStep].FlightSegments[0].origin +
                  ' to ' +
                  props.seats[activeStep].FlightSegments[0].destination}
            </Typography>

            <Stack spacing={2}>
              {props.selectedSeats.length > activeStep &&
                props.selectedSeats[activeStep].seats.map((seat, index) => (
                  <Item key={seat.number}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="h6" display="block" gutterBottom>
                          P {index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          {seat.number}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {seat.travelerPricing[0].price.total.toLocaleString(
                            'en',
                            {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Item>
                ))}
            </Stack>

            <Divider />
          </Card>
            </Grid> */}
            <Grid item xs={12} sx={{ paddingTop: '10px' }}>
              {/* <Grid container>
                <Grid item xs={12}>
              <Typography variant='h6'>
              <PeopleIcon sx={{verticalAlign: 'text-bottom'}}/>

              {intl.get('BOOK.TRAVELER')}
              </Typography>
              <Divider/>

                </Grid>

              </Grid> */}

              {travelers.map((item, index) => (
                <TravelerSeats
                  key={item.passengerKey}
                  index={index + 1}
                  {...item}
                  onHandleDeleteSeat={handleClickeDeleteSeat}
                  showBookedDeleted = {props.shouldBook}
                />
              ))}
              {/* <div className="theme-payment-page-sections-item _p-20">
                        <h3 className="theme-payment-page-sections-item-title">
                          <i className="fa fa-walking pwluggage _pr-10 _r"></i>
                          {intl.get('BOOK.TRAVELER')}
                        </h3>
                        
                      </div> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{'Seat Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There are some people who did not choose a seat
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIgnore} color="warning">
            Ignore
          </Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
