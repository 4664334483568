import Seat from '../Seat';
import Facility from '../Facility';
import Exit from '../Exit';
import Wing from '../Wing'
// import './index.css'
import { Typography } from "@mui/material";
import {generateRandomString} from '../../utils/utils'

const RawNoDeck = (props) => {
  return (
    <div className='rowNoDeck'>
      <Typography variant="button" display="block" sx={{fontSize: '1rem'}}>
      {props.number}
      </Typography>
    </div>
  )
}

const EmptySeat = () => {
  return (
    <div className='seat seat-empty'>
      
    </div>
  )
}

function addEmptySeat(num) {
  const emptySeats = []

  for (let i = 0; i< num; i++) {
    emptySeats.push({
      key: 'empty' + generateRandomString(10),
      empty: true
    })
  }

  return emptySeats
}

function addSeatRowNo(props) {
  return {
    key: 'rowNo-' + generateRandomString(10),
    isRowNo: true,
    rowNo: props.rowNo
  }
}

const displaySeats = (segments, id, seatList, selectSeats, addSeat, removeSeat, max, seatNumbers) => {
  const seatListRows = []
  let rowNo = ''
  seatList.forEach(seat => {
    if (!rowNo || rowNo !== seat.rowNo) {
      rowNo = seat.rowNo
      seatListRows.push(addEmptySeat(seatNumbers.length))
    }
    
    const len = seatListRows.length
      
	  for(let sindex = 0; sindex < seatNumbers.length; sindex++){
      const sn = seatNumbers[sindex]
      if (sn !== 'x') {
        if (sn === seat.colNo) {
          seatListRows[len - 1][sindex] = {...seat}
    
        }
      } else {
        seatListRows[len - 1][sindex] = addSeatRowNo({...seat})
      }
    }
  })

  function isExitRow(seats) {
    let flag = false
    seats.forEach(s => {
      if (s.isExitRow) {
        flag = true

        return false
      }
    })

    return flag
  }

  function isOverWings(seats) {
    let flag = false
    seats.forEach(s => {
      if (s.isOverWings) {
        flag = true

        return false
      }
    })

    return flag
  }

  return <div className='seat-row-container'>
    {
      seatListRows.map((sr, rindex) => {
        return <div className='seat-row-box' key={'row-' + rindex} data-exit={isExitRow(sr)} data-wings={isOverWings(sr)}>
          {isExitRow(sr) ? <div className='exit-part'></div> : null}
          {
            sr.map((seat) => {
              if (seat.empty) {
                return <EmptySeat key={seat.key} />
              } else if (seat.isRowNo) {
                return <RawNoDeck key={seat.key} number={seat.rowNo} />
              } else {
                return <Seat key={(segments ? segments[0].origin + segments[0].destination : "") + seat.number 
                  + seat.coordinates.x} id={id} number={seat.number} x={seat.coordinates.x} y={seat.coordinates.y} 
                availability={seat.travelerPricing[0].seatAvailabilityStatus} price={seat.travelerPricing[0].price.total}
                selectSeats={selectSeats} addSeat={addSeat} removeSeat={removeSeat} data={seat} max={max} selected={seat.selected}
                />
              }
            })
          }
        </div>
      })
    
  }
  </div>
}

const displayFacilities = (facilityList) => {
return <div>{facilityList.map((facility, i) => 
<Facility key={i} code={facility.code} x={facility.coordinates.x} y={facility.coordinates.y} />
)}</div>
}

const displayExits = (exitRows) => {
  return exitRows.map(row => {
    return <div key={'exit-' + row} className='exitRow' style={{top: 'calc(var(--seat-height) *' +row+')'}}></div>
  })
}

const displayWings = (start, end) => {
  return <><Wing orientation="left" start={start} end={end} />
  <Wing orientation="right" start={start} end={end} />
  </>
}

const displaySeatNumber = (seats) => {
  return <div className='deck-seatNumber-box'>
    {seats.map((seat, index) => {
      if (seat !== 'x') {
        return <div className='deck-seatNumber' key={'seatNumber-'+seat}><span>{seat}</span></div>
      }
      else {
        return <div className='deck-seatNumber-empty' key={'seatNumber-empty-' + index}></div>
      }
    })}
  </div>
  
}

const displayBack = () => {
  return <div className='deck-back'>Back</div>
}

const Deck = (props) => {
  // console.log('deck', props);
  const width = props.deck.deckConfiguration.width;
  const length = props.deck.deckConfiguration.length;
  const seatList = props.deck.seats;
  const facilities = props.deck.facilities;
  const wingStart = props.deck.deckConfiguration.startWingsX;
  const wingEnd = props.deck.deckConfiguration.endWingsX;
  const exitRows = props.deck.deckConfiguration.exitRowsX;


  const style={width:`${width*3}em`, height:`${length*3}em`, position: 'absolute', 
    left: '10vw'}
  
  return (
    <div className='deck-container'>
    <div className='deck'>
      {/* {displayWings(wingStart, wingEnd)} */}
      {displaySeatNumber(props.deck.seatNumbers || [])}
      {displaySeats(props.deck.segments, props.deck.id, seatList, props.selectSeats, props.addSeat, props.removeSeat, props.max, props.deck.seatNumbers)}
      {/* {displayFacilities(facilities)} */}
      {displayBack()}
    </div>
    </div>
  )
}

export default Deck;
