import React, { useEffect, useState } from 'react'
import ButtonAppBar from '../../components/ButtonAppBar'
import { Button, Dialog, Grid, DialogTitle, DialogContent, DialogActions, Box, CircularProgress, AppBar, LinearProgress, Typography, Tooltip } from '@mui/material'
import {loadCss} from '../../common/helper.js'
import Bundle from '../../components/Bundle'
import { useLocation, useNavigate } from 'react-router-dom'
import intl from 'react-intl-universal'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { FormProvider, useForm } from 'react-hook-form';


function isObject(object) {
	return object != null && typeof object === 'object';
}

function deepEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
	  return false;
	}
	for (const key of keys1) {
		// console.log('key', key, 'key1', keys1)

		if(keys1.indexOf("airlineCode")>=0 &&
		(key != "departureAirportCode" || key != "arrivalAirportCode" || key != "flightNumber" || key != "airlineCode")
		){
			continue
		}
		
	  const val1 = object1[key];
	  const val2 = object2[key];
	//   console.log('equal', val1, val2)
	  const areObjects = isObject(val1) && isObject(val2);
	  if (
		areObjects && !deepEqual(val1, val2) ||
		!areObjects && val1 !== val2
	  ) {
		return false;
	  }
	}
	return true;
}

function OrderSummary(props) {
    // console.log('summary', props)
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [progress, setProgress] = useState(false)

	const goback = ()=>{
		let data = props.successInfo
		if(props.isSuccess){
			data.bundles = props.bundles
		}
		
		navigate('/success', {state: data})
	}

	const handleNavigate = ()=>{
		let totalPrice = props.bundles.map(item=>item.bundles.reduce((prev, curr)=>prev + curr.totalAmount, 0)).reduce((prev, 
			curr)=>prev + curr, 0)

		// if(totalPrice <= 0){
		// 	enqueueSnackbar("What you order is free!", {variant: 'warning',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
		// 	return
		// }
		navigate('/checkout', {state: {confirmationCode: props.confirmationCode, recordLocator: props.recordLocator,
		cid: props.cid, totalPrice: totalPrice
		}})
	}

	const gotoPay = ()=>{

		if(props.mainAirlineCode == "EK"){
			setProgress(true)
			axios.get(`api/pnrs?pcc=${props.pcc}&recordLocator=${props.recordLocator}`)
			.then(function (res) {
				// console.log(res.data)
				if(res.data && res.data.PnrInfo && res.data.PnrInfo.GTTBookedServiceInfos){
					for(let serviceInfo of res.data.PnrInfo.GTTBookedServiceInfos){
						if(serviceInfo.ServiceDetails.some(item=>item.ServiceStatus == "HN")){
							enqueueSnackbar('The service status is HN, please wait airline to confime the service.', {variant: 'warning', autoHideDuration: 3000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
							return
						}
					}
				}

				handleNavigate()
			})
			.catch(function (error) {
				enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
			}).then(function () {
				setProgress(false)
			});
		}else{
			handleNavigate()
		}
	}

  return (
    <div className="sticky-col">
				  <div className="theme-sidebar-section _mb-10">
				  <h5 className="theme-sidebar-section-title">{intl.get("BOOK.ORDER_SUMMARY")}</h5>
                <div className="theme-sidebar-section-charges">
                  <ul className="theme-sidebar-section-charges-list">
				  {props.bundles && props.bundles.map(item=>item.bundles.reduce((prev, curr)=>prev + curr.totalAmount, 0)).reduce((prev, curr)=>prev + curr, 0) > 0 &&
                    <li className="theme-sidebar-section-charges-item">
                    <h5 className="theme-sidebar-section-charges-item-title">Travel add-ons</h5>
                    <p className="theme-sidebar-section-charges-item-subtitle">Total price</p>
                    <p className="theme-sidebar-section-charges-item-price _mt-10">{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL"), price: props.bundles.map(item=>item.bundles.reduce((prev, curr)=>prev + curr.totalAmount, 0)).reduce((prev, curr)=>prev + curr, 0) })}</p>
                    </li>}                   
					  <li className="theme-sidebar-section-charges-item">
						  <h5 className="theme-sidebar-section-charges-item-title">{intl.get("BOOK.TRAVELERS_NUMBER")}</h5>             
						  <p className="theme-sidebar-section-charges-item-price _mt-10">{props.travelers.adults + props.travelers.children}</p>
                    </li>
                  </ul>
                  <p className="theme-sidebar-section-charges-total">{intl.get("BOOK.ORDER_TOTAL")}<span>{intl.get('PRICE', 
				  {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL"), 
				  price: (props.bundles.map(item=>item.bundles.reduce((prev, curr)=>prev + curr.totalAmount, 0)).reduce((prev, 
					curr)=>prev + curr, 0))})}</span></p>
					{/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("BOOK.ORDER_CURRENCY_DESCRIPTION", {currency: intl.get('CURRENCY.'+props.currency+'.NAME')})}</p> */}
          {/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("FLIGHT.MARKUP_DESCRIPTION")}</p> */}
          {/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("FLIGHT.INVOICE_CREDIT_TOTAL")}{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.routing.currency), price: Math.round(((props.routing.adultBasePrice + props.routing.adultTax) * props.travelers.adults + (props.routing.childBasePrice + props.routing.childTax)*props.travelers.children + markup*(props.travelers.adults + props.travelers.children) + (props.bundles.map(item=>item.bundles.reduce((prev, curr)=>prev + curr.totalAmount, 0)).reduce((prev, curr)=>prev + curr, 0)) + props.seats.map(seat=>seat.seats.reduce((prev, curr)=>{return prev + curr.travelerPricing[0].price.total}, 0)).reduce((x,y)=>x+y, 0))*(1+3.0/100)*100)/100})}</p> */}
                </div>
              </div>
			  <div className="btn-wrapper">
   <button type="submit" className="btn checkout-form__btn mkt-chkt-pscrlnw-btn" disabled={props.disabled}>
       <span className="btn-text mkt-chkt-pscrlnw-btn">{intl.get("BOOK.ORDER_BOOK")}</span>
       <i className="btn-icn hif hif-arrow-right"></i>
   </button>
   </div>
   <div className='btn-wrapper'>
   <Button variant="contained" color='warning' onClick={goback} disabled={props.disabled}>Goback</Button>
   </div>
   {progress &&
<Box sx={{ textAlign: 'center', minHeight:'75px' }}>
      <CircularProgress />
    </Box>}
   {props.displayPay && !progress && <div className='btn-wrapper'><Button variant="contained" color='primary' onClick={gotoPay}>Pay</Button></div>}
   {/* {props.isSuccess && <div className='btn-wrapper'><Button variant="contained" color='primary' onClick={gotoPay}>Pay</Button></div>} */}
</div>	
  )
}

export default function ReserveBundle() {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    // console.log('reserve bundle', location)
	const methods = useForm();
	const [progressing, setProgressing] = useState(false)
    const [bundleOpen, setBundleOpen] = useState(false)
    const [services, setServices] = useState(null)
    // const [bundles, setBundles] = useState([])
    const [passengerToBundles, setPassengerToBundles] = useState([])
    const [selectedBundles, setSelectedBundles] = useState([])
    const [submitBundles, setSubmitBundles] = useState([])
    const [loading, setLoading] = useState(false)
	const [disabledButton, setDisabledButton] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const [passengers, setPassengers] = useState(null)
	const [passengerProgress, setPassengerProgress] = useState(false)
	const [displayPay, setDisplayPay] = useState(false)



    useEffect(()=>{
        loadCss('https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css')
        loadCss('/Flight-Hotel/css/stylesheet.css')
        loadCss('/Flight-Hotel/css/styles.css')
        loadCss('/Flight-Hotel/css/FlightDetails.css')
        loadCss('/css/book.css')

		setLoading(true)

        axios.get('api/services?' + new URLSearchParams({cid: location.state.source,
			customerSessionId: "S12345",
			gds: location.state.gds,
			pcc: location.state.pcc,
			pnr: location.state.pnr
		}))
		.then(function (res) {
		  // let json = JSON.parse(res.data);
		//   console.log("get service by order", json)
		  if("APIError" in res.data){
			  for(let error of res.data.APIError.errors){
				enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
			  }
		  }else if("serviceListResponse" in res.data){
			setServices(res.data.serviceListResponse.serviceList)
			let initPassengerToBundles = []
			if(location.state.passengers){
				for(let passenger of location.state.passengers){
					if(passenger.services){
						for(let passengerService of passenger.services){
							if(res.data.serviceListResponse.serviceList){
								for(let service of res.data.serviceListResponse.serviceList){
									if(passengerService.ServiceName == service.name &&
										passengerService.TotalPrice == service.totalAmount
										){
											for(let offerItem of service.offerInfo.offerItems){
												if(passengerService.PassengerRef == offerItem.passengeRefs){
													for(let selection of passengerService.Selections){
														if(selection.ServiceDefinitionId == offerItem.serviceDefinitionId){
															let bundleIndex = initPassengerToBundles.findIndex(item=>item.passengeRefs === passengerService.PassengerRef)
															if(bundleIndex<0){
																initPassengerToBundles.push({passengeRefs: passengerService.PassengerRef, bundles: [service]})
															}else{
																initPassengerToBundles[bundleIndex].bundles.push(service)
															}
														}
													}
												}
											}
										}
								}
							}
						}
					}
				}
			}

			if(initPassengerToBundles.length>0){
				setDisplayPay(true)
			}

			setPassengerToBundles(initPassengerToBundles)
			// console.log('init passenger bundles', initPassengerToBundles)
		  }
		})
		.catch(function (error) {
		enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
		// enqueueSnackbar('Coming back to re-search', {variant: 'info',  autoHideDuration: 2000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
		// setTimeout(goback, 3000)
		}).then(function () {
		//   setVerified(true)
		setLoading(false)
		});
    },[])

	// useEffect(()=>{
	// 	if(location.state?.routing?.mainAirlineCode == "EK"){
	// 		setPassengerProgress(true)
	// 		// setStatusProgress(true)
	// 		axios.get(`api/pnrs?pcc=${location.state.pcc}&recordLocator=${location.state.pnr}`)
	// 		.then(function (res) {
	// 			// console.log('passengers res', res)
	// 			if(res.data.Passengers){
	// 				setPassengers(res.data.Passengers)
	// 			}else{
	// 				enqueueSnackbar('Passenger not found !', {variant: 'warning', autoHideDuration: 3000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
	// 			}

	// 			if(res.data && res.data.PnrInfo && res.data.PnrInfo.GTTBookedServiceInfos){
	// 				for(let serviceInfo of res.data.PnrInfo.GTTBookedServiceInfos){
	// 					if(serviceInfo.ServiceDetails.some(item=>item.ServiceStatus == "HN")){
	// 						setDisallowed(true)
	// 						enqueueSnackbar('The service status is HN, please wait airline to confime the service.', {variant: 'warning', autoHideDuration: 3000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
	// 						break
	// 					}
	// 				}
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
	// 		}).then(function () {
	// 			setPassengerProgress(false)
	// 			// setStatusProgress(false)
	// 		});
	// 	}
	// },[])


	const onSubmit = data => {
		// console.log(passengerToBundles, submitBundles, selectedBundles)
		// return
		if(passengerToBundles.map(item=>item.bundles.length).reduce((prev, curr)=>prev + curr, 0)==0){
			enqueueSnackbar('Please select bundles.', {variant: 'warning',  autoHideDuration: 4000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
			return;
		}

		setProgressing(true)
		setDisabledButton(true)

		let passengerServices = [];
		for(let passenger of location.state.passengers){
			passengerServices.push({firstName: passenger.firstName, middleName: passenger.middleName, lastName: passenger.lastName})
		}

		for(let passengerToBundle of passengerToBundles){
			if(passengerToBundle.passengeRefs && passengerToBundle.passengeRefs.length >= 2){
				let passengerIndex = parseInt(passengerToBundle.passengeRefs.substring(1)) - 1
				if(passengerServices[passengerIndex]){
					for(let bundleItem of passengerToBundle.bundles){
						for(let offerItem of bundleItem.offerInfo.offerItems){
							let selections = []
							selections.push({serviceid: offerItem.serviceId,
								serviceDefinitionId: offerItem.serviceDefinitionId
								})
		
							let service = {
									shoppingResponseId: bundleItem.offerInfo.responseId,
									offerId: bundleItem.offerInfo.offerId,
									owner: bundleItem.offerInfo.owner,
									offerItemRef: offerItem.offerItemId,
									passengerRef: offerItem.passengeRefs,
									baseFare: bundleItem.baseAmount,
									tax: bundleItem.taxAmount,
									totalPrice: bundleItem.totalAmount,
									serviceName: bundleItem.name,
									selections: selections
								}
							
							if(passengerServices[passengerIndex].services){
								passengerServices[passengerIndex].services.push(service)
							}else{
								passengerServices[passengerIndex].services = [service]
							}
						}
					}
				}
			}

		  }
		
		if(passengers){
			for(let passengerService of passengerServices){
				if(passengerService.services){
					let nameRefs = passengerService.services.map(item=>item.passengerRef)
					if(nameRefs && nameRefs.length>0){
						let passengerItem = passengers.find(item=>item.nameRef==nameRefs[0])
						if(passengerItem){
							passengerService.firstName = passengerItem.firstName
							passengerService.middleName = passengerItem.middleName
							passengerService.lastName = passengerItem.lastName
						}
					}
				}
			}
		}
		

		axios.post('api/reserveServices', {cid: location.state.source, customerSessionId: "S12345", gds: location.state.gds, 
          pcc: location.state.pcc, pnr: location.state.pnr, passengers: JSON.stringify({passengers: passengerServices})})          
          .then(function (res) {
            // console.log('reserve services book', res, JSON.parse(res.data))
            const resJson = JSON.parse(res.data)
			if("APIError" in resJson){
				for(let error of resJson.APIError.errors){
					enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
				}			  
			}else if(resJson.reserveServiceResponse.isSuccess){
				enqueueSnackbar('success', {variant: 'success',  autoHideDuration: 3000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
				setIsSuccess(true)
				setDisplayPay(true)
				// checkStatus()
            }else{
				enqueueSnackbar(resJson.reserveServiceResponse.errorMessage, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
            }
          })
          .catch(function (error) {
            console.error(error);
            enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
          }).then(function () {
            setProgressing(false)
            setDisabledButton(false)
          });
    }

    const handleSelectBundle=(bundles)=>{
		setSelectedBundles([...bundles])
	}

    const handleCloseBundles=()=>{
		setBundleOpen(false)
		setSelectedBundles([])
	}

    const handleSubmitBundles = ()=>{
		// console.log('submit bundles', selectedBundles)
		
		setBundleOpen(false)
		setSubmitBundles([...selectedBundles])
		let passengerBundles = []
		let newSubmitBundles = [...selectedBundles]
		for(let submitBundle of newSubmitBundles){
			for(let passenger of submitBundle.passengers){
				let passengerIndex = passengerBundles.findIndex(item=>item.passengeRefs == passenger.passengeRefs)
				if(passengerIndex < 0){
					passengerBundles.push({passengeRefs: passenger.passengeRefs, bundles: [...passenger.bundles]})
				}else{
					for(let bundle of passenger.bundles){
						if(!passengerBundles[passengerIndex].bundles.find(item=> deepEqual(item, bundle))){
							passengerBundles[passengerIndex].bundles.push(bundle)
						}
					}
				}
			}
		}

		setPassengerToBundles([...passengerBundles])
		// console.log('submit bundles', passengerBundles)
	}

    const handleClickBundle=()=>{
		if(services == null){
			enqueueSnackbar('No Service', {variant: 'warning', autoHideDuration: 3000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
			return
		}
		setBundleOpen(true)
		// console.log('click to open bundle', submitBundles)
		setSelectedBundles([...submitBundles])
	}


  return (
    <>
    {progressing && <AppBar position="fixed"><LinearProgress /></AppBar>}
    <ButtonAppBar/>
    <div className="theme-page-section theme-page-section-lg _mt-desk-30">
      <div className="container">
	  <FormProvider {...methods}>
	  <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="row row-col-static row-col-mob-gap" id="sticky-parent" data-gutter="60">
          <div className="col-md-8 _pt-mob-25 _pb-mob-25">
            <div className="theme-payment-page-sections">
            <div className="theme-payment-page-sections-item _p-20">
	<h3 className="theme-payment-page-sections-item-title font-weight-600">
	  <i className="fa fa-cart-plus _pr-10"></i>Travel add-ons
	  </h3>
      {loading ?
      <Box sx={{ display: 'flex' }}>
      <CircularProgress/>
    </Box> : <Button variant="outlined" onClick={handleClickBundle}>add-ons service</Button>}
	{passengerProgress && <Box sx={{ width: '100%' }}>
	<Tooltip title="loading passengers" arrow>
      <LinearProgress/>
	  </Tooltip>
    </Box>}
      <Grid container sx={{p:2}} spacing={2}>
	{passengerToBundles.map(item=>
		  <Grid key={item.passengeRefs} item xs={12}>
		  <Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6" style={{textAlign: 'center'}}>{item.passengeRefs.replace('T', 'P')}</Typography>
			</Grid>
			{item.bundles.map((bundle,index)=>
				<Grid key={item.passengeRefs + bundle.name + index} item xs={12}>
				<Grid container spacing={2} alignItems="center" sx={{borderBottom: "1px solid #1976d2"}}>
				<Grid item xs={4}>
					<Typography>{bundle.name}</Typography>
				</Grid>
				<Grid item xs={4}>
					<Grid container spacing={2}>
						{bundle.routings.map(routing=>
						<Grid key={routing.DepartureAirport + routing.ArrivalAirport} item xs={12}>
							<Typography>{routing.DepartureAirport + " to " + routing.ArrivalAirport}</Typography></Grid>
							)}
					</Grid>
				</Grid>
				<Grid item xs={4}><Typography>${bundle.totalAmount}</Typography></Grid>
				</Grid>
				</Grid>
				)}
		  </Grid>
		  </Grid>
		)}
</Grid>
	  <Dialog
	fullScreen
	open={bundleOpen}
	onClose={()=>setBundleOpen(false)}
	aria-labelledby="responsive-dialog-title"
	// TransitionComponent={Transition}
  >
	<DialogTitle>
	  Travel add-ons
	</DialogTitle>
	<DialogContent>
		<Bundle routing={location.state.routing} 
		services={services && services.length > 0 && services.filter(item=>item.name !=="FareLock")}
		selectedBundles={selectedBundles}
		onSelectBundle={handleSelectBundle}
		passengers = {passengers}
		/>
	</DialogContent>
		<DialogActions>
		<Button variant="contained" onClick={handleCloseBundles}>
		  Close
		</Button>
		<Button variant="contained" autoFocus color='warning' onClick={handleSubmitBundles}>
		  Submit
		</Button>
	  </DialogActions>
	  </Dialog>
      </div>
                </div>
                </div>
                <div className="col-md-4 ">
					<OrderSummary travelers={{adults: location.state.travelers.adults, children: location.state.travelers.children}} 
					currency="USD" bundles={passengerToBundles} disabled={disabledButton} isSuccess={isSuccess}
					confirmationCode = {location.state.confirmationCode} recordLocator={location.state.pnr}
					cid = {location.state.source}
					successInfo={location.state.successInfo}
					displayPay = {displayPay}
					mainAirlineCode = {location.state?.routing?.mainAirlineCode}
					pcc = {location.state.pcc}
					/>
                </div>
        </div>
		</form>
		</FormProvider>
        </div>
		</div>
        </>
  )
}
