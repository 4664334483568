import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import intl from "react-intl-universal";
import LanguageIcon from "@mui/icons-material/Language";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";

// let pages = ["Flight", "My orders", "Request ticketing", "Ticket Exchange"];
let pages = ["Flight", "My orders"];

function isWechat() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}

// const pages = ['Package', 'My orders', 'Ticket requesting', 'Exchange']
export default function ButtonAppBar(props) {
  // console.log('button app', props)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [language, setLanguage] = React.useState("en-US");
  const [currency, setCurrency] = React.useState("USD");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      isWechat() ||
      window.location.hostname.indexOf("feiquanqiu") >= 0 ||
      window.location.hostname.indexOf("holidayonline") >= 0
    ) {
      pages = ["Flight", "My orders"];
    }
    if (
      intl.getInitOptions().currentLocale &&
      intl.getInitOptions().currentLocale.toLocaleLowerCase() == "en-us"
    ) {
      setLanguage("en-US");
    } else if (
      intl.getInitOptions().currentLocale &&
      intl.getInitOptions().currentLocale.toLocaleLowerCase() == "zh-cn"
    ) {
      setLanguage("zh-CN");
    }

    // console.log('currentLocale', intl.getInitOptions().currentLocale)

    if (localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"));
    } else {
      if (
        intl.getInitOptions().currentLocale &&
        intl.getInitOptions().currentLocale.toLocaleLowerCase() == "en-us"
      ) {
        setCurrency("USD");
      } else if (
        intl.getInitOptions().currentLocale &&
        intl.getInitOptions().currentLocale.toLocaleLowerCase() == "zh-cn"
      ) {
        setCurrency("CNY");
      }
    }
  }, []);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    localStorage.setItem("lang", language);
    localStorage.setItem("currency", currency);
    setOpen(false);
    window.location.reload();
  };

  const handleOpenNavMenu = (event) => {
    // console.log('open menu', event);
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickPage = (page) => {
    let p = "";
    // if (window.location.search.indexOf("?p=") >= 0) {
    //   p = window.location.search;
    // } 
    // else if (sessionStorage.getItem("openid")) {
    //   p = "?p=" + sessionStorage.getItem("openid");
    // }

    let nav = "";
    if (page == "Flight") {
      nav = "";
    } else if (page == "My orders") {
      nav = "order";
    } else if (page == "Request ticketing") {
      window.location.href = "https://ndcws.gttglobal.com/redirect.aspx" + p;
    } else if (page == "Ticket Exchange") {
      window.location.href = "https://exchange.gttglobal.com/";
    }

    navigate("/" + nav + p);
  };

  const handleClickLogo = () => {
    let p = "";
    if (window.location.search.indexOf("?p=") >= 0) {
      p = window.location.search;
    } else if (sessionStorage.getItem("openid")) {
      p = "?p=" + sessionStorage.getItem("openid");
    }

    navigate("/" + p);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="inherit" sx={{ bgcolor: "white" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleClickPage(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Avatar
              src={process.env.PUBLIC_URL + "/logo.svg"}
              alt="GTT"
              variant="square"
              sx={{ width: 136, height: 44, cursor: "pointer" }}
              onClick={handleClickLogo}
            ></Avatar>
            {/* <img src={process.env.PUBLIC_URL + '/logo.svg'} alt='GTT'></img> */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                color: "common.black",
              }}
            >
              {pages.map((page) => (
                <Button
                  // variant="outlined"
                  key={page}
                  onClick={() => handleClickPage(page)}
                  sx={{ color: "inherit" }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {/* News */}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            <Button
              color="inherit"
              variant="outlined"
              onClick={handleClickOpen}
              startIcon={<LanguageIcon />}
              endIcon={<CurrencyExchangeIcon />}
            ></Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{intl.get("REGIONAL_SETTINGS")}</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
                {/* <Typography variant="caption" display="block" gutterBottom>Language</Typography> */}
                <Box
                  sx={{
                    minWidth: 120,
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {intl.get("REGIONAL_SETTINGS_LANGUAGE")}
                    </InputLabel>
                    <Select
                      labelId="select-language"
                      id="select-language"
                      value={language}
                      label="Language"
                      onChange={handleChangeLanguage}
                    >
                      <MenuItem value={"en-US"}>
                        English (United States)
                      </MenuItem>
                      <MenuItem value={"zh-CN"}>简体中文</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/* <Typography variant="caption" display="block" gutterBottom>Currency</Typography> */}
                <Box
                  sx={{
                    minWidth: 120,
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {intl.get("REGIONAL_SETTINGS_CURRENCY")}
                    </InputLabel>
                    <Select
                      labelId="select-currency"
                      id="select-currency"
                      value={currency}
                      label="Currency"
                      onChange={handleChangeCurrency}
                    >
                      <MenuItem value={"USD"}>USD - $</MenuItem>
                      <MenuItem value={"CNY"}>CNY - ¥</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>
                  {intl.get("REGIONAL_SETTINGS_CANCEL")}
                </Button>
                <Button color="success" onClick={handleSave}>
                  {intl.get("REGIONAL_SETTINGS_SAVE")}
                </Button>
              </DialogActions>
            </Dialog>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
