import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import intl from 'react-intl-universal';
import ShareIcon from '@mui/icons-material/Share';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
  AppBar,
  Toolbar,
  Typography,
  Badge,
  Grid,
} from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import PubSub from 'pubsub-js';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import CircularProgress from '@mui/material/CircularProgress';
import PersistentDrawerLeft from '../../components/PersistentDrawerLeft';
import MyTabs from '../MyTabs';
import CloseIcon from '@mui/icons-material/Close';
import Crypto from '../../utils/ctypto.js';
import { IS_DirectNDCS } from '../../data/config';

function convertToTime(totalMinutes) {
  let minutes = parseInt(totalMinutes);
  let day = Math.floor(minutes / 60 / 24);
  let hour = Math.floor((minutes / 60) % 24);
  let minute = Math.floor(minutes % 60);

  return intl.get('FLIGHT.TIME', { day, hour, minute });
}

function showBaggage(baggage) {
  if (baggage) {
    let messages = [];
    if (baggage.allowancePiecesSpecified) {
      messages.push(baggage.allowancePieces + ' pieces');
    }

    if (baggage.dimensionSpecified) {
      messages.push(
        baggage.dimension +
          (baggage.dimensionUnitSpecified ? ' ' + baggage.dimensionUnit : ''),
      );
    }

    if (baggage.weightSpecified) {
      messages.push(
        baggage.weight + (baggage.unitSpecified ? ' ' + baggage.unit : ''),
      );
    }

    // console.log('carry on messages', messages)
    return messages.join(', ');
  }

  return null;
}

function Segment(props) {
  // console.log("segment", props)
  let carryOn = null;
  let checkedBaggage = null;
  let baggageEnable = false;
  // let baggageUnit = props.baggageUnit
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [prebookingInfo, setPrebookingInfo] = useState(null);
  if (props.baggage || props.carryOn) {
    if (
      props.baggage.allowancePiecesSpecified &&
      props.baggage.allowancePieces > 0
    ) {
      baggageEnable = true;
    }
    carryOn = showBaggage(props.carryOn);
    checkedBaggage = showBaggage(props.baggage);
  } else if (props.baggagePieces > 0 || props.baggageAllowance > 0) {
    baggageEnable = true;
    let messages = [];
    if (props.baggagePieces > 0) {
      messages.push(props.baggagePieces + ' pieces');
    }

    if (props.baggageAllowance > 0) {
      messages.push(
        props.baggageAllowance +
          (props.baggageUnit ? ' ' + props.baggageUnit : ''),
      );
    }

    checkedBaggage = messages.join(', ');
  }

  const handleClickModal = () => {
    // console.log('show modal', carryOn);

    let newCarryon = carryOn ? carryOn : (props.airlineCode === "SQ" ? '1 piece' : '')
    PubSub.publish('baggage', { baggageEnable, carryOn: newCarryon, checkedBaggage });
  };

  const requestFareRules = (value) => {
    axios
      .post('api/PrebookingOnly', { value: Crypto.encrypt(value) })
      .then(function (res) {
        // console.log(res)
        // var json = JSON.parse(res.data);
        // console.log('bundle', json);
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
          }
        } else {
          setPrebookingInfo(res.data.airTicketRuleResponse);
        }
      })
      .catch(function (error) {
        console.error(error);
        // enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
      })
      .then(function () {
        setDialogOpen(true);
        setOpen(false);
      });
  };

  const handleShowRules = () => {
    let params = {
      adults: props.travelers.adults,
      children: props.travelers.children,
      routing: JSON.stringify(props.routing),
      cid: props.routing.cid,
      isPrebooking: true,
    };

    setOpen(true);
    if (prebookingInfo == null) {
      axios
        .post('api/book', params)
        .then(function (res) {
          // let json = JSON.parse(res.data);
          //   console.log("prebooking", json)
          if ('APIError' in res.data) {
            for (let error of res.data.APIError.errors) {
              // console.log(error.errorId, error.message);
              // setMessage(error.message);

              alert(error.message);
            }
          } else if ('airTicketPriceChangedResponse' in res.data) {
            alert('Price changed, please re-search.');
          } else if ('airTicketRuleResponse' in res.data) {
            if (!IS_DirectNDCS.includes(props.routing.cid)) {
              setPrebookingInfo(res.data.airTicketRuleResponse);
              setDialogOpen(true);
            } else {
              requestFareRules(
                'farerule?' +
                  new URLSearchParams({
                    cid: props.routing.cid,
                    customerSessionId:
                    res.data.airTicketRuleResponse.customerSessionId,
                    gds: res.data.airTicketRuleResponse.gds.toUpperCase(),
                    pcc: res.data.airTicketRuleResponse.pcc,
                    adults: props.travelers.adults,
                    children: props.travelers.children,
                  }).toString() +
                  '&offer=' +
                  JSON.stringify(res.data.airTicketRuleResponse.offerInfo),
              );
            }
          }
        })
        .catch(function (error) {
          console.error(error, typeof error);
          alert(error);
        })
        .then(function () {
          if (!IS_DirectNDCS.includes(props.routing.cid)) {
            setOpen(false);
          }
        });
    } else {
      setOpen(false);
      setDialogOpen(true);
    }
  };

  return (
    <div className="leg__segment segment">
      <div className="segment__date">
        <span>
          {moment(props.departureTime, 'MM/DD/YYYY HH:mm').format('ddd MMM Do')}
        </span>
        {props.departureTime.substring(0, 10) ==
        props.arrivalTime.substring(0, 10) ? (
          ''
        ) : (
          <span className="segment__date-landing text-danger">
            {intl.get('FLIGHT.LANDS')}{' '}
            {moment(props.arrivalTime, 'MM/DD/YYYY HH:mm').format('ddd MMM Do')}{' '}
          </span>
        )}
      </div>
      <div className="segment__info">
        <div className="segment__options">
          <div className="segment__flight-options">
            <div className="segment__logo">
              <img
                src={
                  'https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir' +
                  props.airlineCode +
                  '.gif'
                }
                alt={props.airlineCode}
              />
            </div>
            <span className="segment__airline">
              {intl
                .get('AIRLINE.' + props.airlineCode)
                .defaultMessage(props.airlineCode)}
            </span>
            <span className="segment__flight-nr">
              {intl.get('FLIGHT.FLIGHT_NUMBER')} {props.airlineCode}{' '}
              {props.flightNumber}
            </span>
            <span className="segment__cabin">
              {intl.get('FLIGHT.BOOKING_CLASS').defaultMessage('Class')} {props.BookingClass}
            </span>
            <span className="segment__cabin">
              {intl.get('CABIN.' + props.cabin).defaultMessage(props.cabin)}
            </span>
            {!props.isShare ? null : (
              <span className="segment__Operatedby">
                {intl.get('FLIGHT.OPERATED_BY', {
                  airlineName: intl.get('AIRLINE.' + props.codeShare),
                })}
              </span>
            )}
            {props.aircraftCode == 'TRS' && <DirectionsTransitIcon />}
          </div>
          <div className="segment__eta">
          {props.duration > 0 &&<i className="fas fa-clock"></i>}
            <span className="segment__eta-value">
              {props.duration > 0 && convertToTime(props.duration)}
            </span>
          </div>
        </div>

        <div className="segment__itinerary segment-itinerary">
          <div className="segment-itinerary__outbound">
            <span className="segment-itinerary__time">
              {moment(props.departureTime, 'MM/DD/YYYY HH:mm').format(
                'hh:mm A',
              )}
            </span>
            <span className="segment-itinerary__location">
              {intl
                .get('AIRPORT.' + props.departureAirportCode + '.AIRPORTNAME')
                .defaultMessage(props.departureAirportCode)}
              <span className="segment-itinerary__iata">
                ({props.departureAirportCode})
              </span>
            </span>
          </div>
          <div className="segment-itinerary__inbound">
            <span className="segment-itinerary__time">
              {moment(props.arrivalTime, 'MM/DD/YYYY HH:mm').format('hh:mm A')}
            </span>
            <span className="segment-itinerary__location">
              {intl
                .get('AIRPORT.' + props.arrivalAirportCode + '.AIRPORTNAME')
                .defaultMessage(props.arrivalAirportCode)}
              <span className="segment-itinerary__iata">
                ({props.arrivalAirportCode})
              </span>
            </span>
          </div>
        </div>
        <div className="segment__footer" style={{paddingTop: '4px'}}>
        <Grid container>
  <Grid item xs={4}>
  <div className="segment__operator">
            {props.routing && !props.hideFareRules && (
              <Button onClick={handleShowRules} size="small" variant="text">
                {intl.get('BOOK.SHOW_FARE_RULES')}
              </Button>
            )}
          </div>
  </Grid>
  <Grid item xs={4}>
    <div className="segment__operator" style={{paddingTop: '4px', paddingLeft: "10px"}}>
  <Typography variant="caption" gutterBottom> Fare Basis </Typography><Typography gutterBottom variant="caption" color="rgb(30, 136, 229)">{props.adultFarebasis}</Typography>
  </div>
  </Grid>
  <Grid item xs={4}>
  <div
            className="segment__amenities amenities"
            data-toggle="modal"
            data-target="#tooltip-modal"
            onClick={handleClickModal}
            data-segment-title={
              props.departureAirportCode + '-' + props.arrivalAirportCode
            }
            data-id="amenities"

            style={{float: "right"}}
          >
            <Badge badgeContent={props.carryOn && props.carryOn.allowancePieces && props.carryOn.allowancePieces > 0 ? props.carryOn.allowancePieces : (props.airlineCode == "SQ" ? 1 : 0)} showZero color="yellow" sx={{marginRight: '15px'}}>
            <i
                className={
                  'fas fa-suitcase-rolling suitcase' +
                  (baggageEnable ? '' : ' suitcaseDisable')
                }
              ></i>
            </Badge>
            
            <Badge badgeContent={props.baggagePieces && props.baggagePieces.allowancePieces && props.baggagePieces.allowancePieces > 0 ? props.baggagePieces.allowancePieces 
              : (props.baggagePieces && props.baggagePieces > 0 ? props.baggagePieces : 0) } showZero color="yellow">
              <i
                className={
                  'fa fa-suitcase suitcase' +
                  (baggageEnable ? '' : ' suitcaseDisable')
                }
              ></i>
            </Badge>
            {/* <i className={"fa fa-suitcase suitcase" + (baggageEnable ? "" : " suitcaseDisable" )}></i> */}
            <i className="amenities__arrow hif fa fa-angle-down"></i>
            <div className="amenities-tooltip amenities-tooltip--new info-tooltip">
              <div className="amenities-tooltip__item amenity-row amenity-row--new ">
                <div className="amenity-row__header">
                  <div className="amenity-row__icn icn">
                  <i className="fas fa-suitcase-rolling"></i>
                    {/* <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 11V4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V11H16C17.1046 11 18 11.8954 18 13V19C18 20.1046 17.1046 21 16 21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21H10C10 21.5523 9.55229 22 9 22C8.44771 22 8 21.5523 8 21C6.89543 21 6 20.1046 6 19V13C6 11.8954 6.89543 11 8 11H9ZM14 4H10V11H14V4Z"
                        fill="white"
                      ></path>
                    </svg> */}
                  </div>

                  <div className="amenity-row__text">
                    {' '}
                    <div className="amenity-row__description">
                      {intl.get('FLIGHT.CARRY_ON')}{' '}
                    </div>
                  </div>
                  <div className="amenity-row__allowance">
                    <span className="amenity-row__value">
                      {/* {intl.get("FLIGHT.BAGGAGE", {baggage: carryOn})} */}
                      {carryOn || (props.airlineCode === "SQ" ? '1 piece' : ' not allowed')}
                    </span>{' '}
                  </div>
                </div>
                <div className="amenity-row__footer"></div>
              </div>

              <div className="amenities-tooltip__item amenity-row--new amenity-row amenity-row--new ">
                <div className="amenity-row__header">
                  <div className="amenity-row__icn icn">
                  <i className="fa fa-suitcase"></i>
                    {/* <svg
                      width="24"
                      height="21"
                      viewBox="0 0 14 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 1V4H2.33333C1.04467 4 0 5.03319 0 6.30769V17.6923C0 18.9668 1.04467 20 2.33333 20H3C3 20.5523 3.44771 21 4 21C4.55229 21 5 20.5523 5 20H9C9 20.5523 9.44772 21 10 21C10.5523 21 11 20.5523 11 20H11.6667C12.9553 20 14 18.9668 14 17.6923V6.30769C14 5.03319 12.9553 4 11.6667 4H10V1C10 0.447715 9.55228 0 9 0H5C4.44772 0 4 0.447715 4 1ZM9 2H5V4H9V2Z"
                        fill="white"
                      ></path>
                    </svg> */}
                  </div>

                  <div className="amenity-row__text">
                    {' '}
                    <div className="amenity-row__description">
                      {intl.get('FLIGHT.CHECKED_BAG')}{' '}
                    </div>
                  </div>
                  <div className="amenity-row__allowance">
                    <span className="amenity-row__value">
                      {/* {intl.get("FLIGHT.BAGGAGE", {baggage: checkedBaggage})}{baggageUnit} */}
                      {checkedBaggage || ' not allowed'}
                    </span>{' '}
                  </div>
                </div>
                <div className="amenity-row__footer"></div>
              </div>
            </div>
          </div>
  </Grid>
</Grid>

          
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={() => setOpen(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            fullScreen
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setDialogOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {intl.get('BOOK.FARE_RULES')}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <MyTabs
                fareRules={prebookingInfo}
                brandRules={props.routing.brand || null}
              />
            </DialogContent>
          </Dialog>

        </div>
      </div>
    </div>
  );
}

function Layover(props) {
  let totalMinutes = moment(props.departureTime, 'MM/DD/YYYY HH:mm').diff(
    moment(props.arrivalTime, 'MM/DD/YYYY HH:mm'),
    'minutes',
  );
  let transfer =
    props.departureTime.substring(0, 10) == props.arrivalTime.substring(0, 10)
      ? 0
      : 1;

  // console.log("layover", props, props.departureTime.substring(0,10), props.arrivalTime.substring(0,10), transfer)
  return (
    <div className="leg__layover layover">
      <div className="layover__description">
        <div className="layover__item layover__duration">
          <i className="far fa-clock layover__icn"></i>
          <span>
            {totalMinutes > 0 && convertToTime(totalMinutes)}
            <span>
              <span className="text-danger">
                {intl.get('FLIGHT.SEGMENT_CONNECTION', {
                  hours: totalMinutes / 60 > 3 ? 1 : 0,
                  airportName: intl
                    .get('AIRPORT.' + props.airportCode + '.AIRPORTNAME')
                    .defaultMessage(props.airlineCode),
                  airportCode: props.airportCode,
                  transfer,
                })}
              </span>
            </span>
          </span>
        </div>
        <div className="layover__item layover__note">
          <span className="trip-detailed__layover-location"></span>
        </div>
      </div>
    </div>
  );
}

function SegmentBound(props) {
  // console.log("segment bound", props)
  let outbound = props.tripIndex % 2 == 0;

  let segments = [];
  let departure = null;
  let arrival = null;
  for (let i = 0, len = props.segments.length; i < len; i++) {
    let baggage = null;
    let carryOn = null;
    if (IS_DirectNDCS.includes(props.routing.cid)) {
      // let segmentIndex = i
      // let index = 0
      let count = 0;
      for (let j = props.tripIndex; j > 0; j--) {
        count += props.routing.trips[j - 1].segments.length;
      }

      let segmentIndex = i + count;

      // for(let tripIndex = 0; tripIndex<props.routing.trips.length; tripIndex++){
      // 	for(let segmentIndex = 0; segmentIndex< props.routing.trips[tripIndex].segments.length;segmentIndex++){
      // 		if(props.tripIndex == tripIndex){

      // 		}
      // 		index++
      // 	}
      // }

      // if(props.tripIndex > 0){
      // 	segmentIndex = i + props.routing.trips[props.tripIndex-1].length
      // }

      if (props.routing.rules) {
        if (props.routing.rules.baggages) {
          for (let ruleBaggage of props.routing.rules.baggages) {
            if (ruleBaggage.segments.includes(segmentIndex.toString())) {
              baggage = ruleBaggage;
            }
          }
        }

        if (props.routing.rules.carryon) {
          for (let ruleCarryOn of props.routing.rules.carryon) {
            if (ruleCarryOn.segments.includes(segmentIndex.toString())) {
              carryOn = ruleCarryOn;
            }
          }
        }
      }
    }

    if (i == 0) {
      departure = props.segments[i].departureAirportCode;
    }

    arrival = props.segments[i].arrivalAirportCode;

    segments.push(
      <Segment
        key={i}
        {...props.segments[i]}
        routing={i == len - 1 && props.routing}
        travelers={i == len - 1 && props.travelers}
        hideFareRules={props.hideFareRules}
        baggage={baggage}
        carryOn={carryOn}
      />,
    );
    if (i != len - 1) {
      segments.push(
        <Layover
          key={i + 'layover'}
          departureTime={props.segments[i + 1].departureTime}
          arrivalTime={props.segments[i].arrivalTime}
          airportCode={arrival}
        />,
      );
    }
  }

  return (
    <div className="row mb-2">
      <div className="col-lg-12 col-sm-auto text-sm-left trip-details__leg leg">
        <div className="leg__heading leg-heading">
          <div className="leg-heading__itinerary">
            <div className="leg-heading__label">
              {outbound
                ? intl.get('FLIGHT.OUTBOUND')
                : intl.get('FLIGHT.INBOUND')}
              :
            </div>{' '}
            {intl
              .get('AIRPORT.' + departure + '.CITYNAME')
              .defaultMessage(departure)}{' '}
            -{' '}
            {intl
              .get('AIRPORT.' + arrival + '.CITYNAME')
              .defaultMessage(arrival)}{' '}
          </div>
          <div className="leg-heading__eta">
            {props.tripTime > 0 && convertToTime(props.tripTime)}
          </div>
          <div className="leg-heading__icn">
            <i className={'fa fa-plane' + (outbound ? '' : ' Inflight')}></i>
          </div>
        </div>

        {segments}
      </div>
    </div>
  );
}

function shareSite(url) {
  let clientName = null;
  if (sessionStorage.getItem('userInfo') != null) {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    if (
      !!userInfo.ClientBusinessName &&
      userInfo.ClientBusinessName != 'null'
    ) {
      clientName = userInfo.ClientBusinessName;
    }
  }

  navigator
    .share({
      title: (!clientName ? '' : `(${clientName})`) + intl.get('SHARE.TITLE'),
      text: '',
      url: url,
    })
    .then(() => {
      // console.log('Share completed successfuly')
    })
    .catch((error) => {
      alert(error);
    });
}

function getUserParams() {
  let accountCode = null;
  let openid = null;
  let phone = null;
  let email = null;
  let sourceType = null;

  if (sessionStorage.getItem('userInfo') != null) {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    if (userInfo.AccountCode && userInfo.AccountCode != 'null') {
      accountCode = userInfo.AccountCode;
    }

    phone = userInfo.PhoneNumber;
    email = userInfo.AutoTicketEmailAddress;

    if (userInfo.type) {
      sourceType = userInfo.type;
    }
  }

  if (sessionStorage.getItem('openid')) {
    openid = sessionStorage.getItem('openid');
  }

  return { accountCode, openid, phone, email, sourceType };
}

function getSearchInfo() {
  let from = '';
  let to = '';
  let departDate = '';
  let returnDate = '';
  if (localStorage.from) {
    from = localStorage.from;
  }

  if (localStorage.to) {
    to = localStorage.to;
  }

  if (localStorage.departDate) {
    departDate = localStorage.departDate;
  }

  if (localStorage.returnDate) {
    returnDate = localStorage.returnDate;
  }

  return { href: window.location.search, from, to, departDate, returnDate };
}

export default function TripDetail(props) {
  const navigate = useNavigate();
  // console.log("trip detail", props);
  const [open, setOpen] = useState(false);
  const [markup, setMarkup] = useState(0);

  function shareClick() {
    let userParams = getUserParams();

    let searchInfo = getSearchInfo();
    let params = {
      searchInfo: searchInfo,
      routing: JSON.stringify(props.routing),
      travelers: JSON.stringify(props.travelers),
      currency: props.currency,
      shareInfo: {
        isShare: true,
        accountCode: userParams.accountCode,
        openid: userParams.openid,
        phone: userParams.phone,
        email: userParams.email,
        sourceType: userParams.sourceType,
      },
    };

    if (!userParams.accountCode) {
      if (!navigator.canShare) {
        axios
          .post('api/share', params)
          .then(function (res) {
            var data = JSON.parse(res.data);
            navigate(`/share/${data.url}`, { state: { share: false } });
          })
          .catch(function (error) {
            console.error(error);
            alert(error);
          })
          .then(function () {});
      } else {
        axios
          .post('api/share', params)
          .then(function (res) {
            var data = JSON.parse(res.data);
            shareSite(
              `${window.location.protocol}//${window.location.host}/share/${data.url}`,
            );
          })
          .catch(function (error) {
            console.error(error);
            alert(error);
          })
          .then(function () {});
      }
    } else {
      // alert('has acct')
      setOpen(true);
      setMarkup(0);
      // alert(JSON.parse(params))
      // alert('markup demo', JSON.stringify(params))
      // 	fetch('api/markups', {
      // 		method: 'POST',
      // 		headers: {
      // 			'Content-Type': 'application/json; charset=utf-8'
      // 		},
      // 		body: JSON.stringify(params)
      // 	})
      // 	.then(response =>{
      // 		if(response.ok){
      // 			return response.json()
      // 		}else{
      // 			return Promise.reject(response.statusText)
      // 		}})
      // 	.then(data =>{
      // 		console.log("data", data);
      // 		alert(data)
      // 		var json = JSON.parse(data);
      // 		setMarkup(json.markup)
      // 	})
      // 	.catch(err=>{console.error(err), alert(err)});
    }
  }

  function changeMarkup(e) {
    // console.log("markup", e.target.value)
    setMarkup(e.target.value);
  }

  function handleSubmit() {
    let userParams = getUserParams();
    let searchInfo = getSearchInfo();

    let newRouting = { ...props.routing };

    // let travelerMarkup = parseFloat(markup)/(props.travelers.adults + props.travelers.children) * 100/100

    if (props.travelers.adults > 0) {
      newRouting.adultBasePrice =
        Math.round((props.routing.adultBasePrice + parseFloat(markup)) * 100) /
        100;
    }

    if (props.travelers.children > 0) {
      newRouting.childBasePrice =
        Math.round((props.routing.childBasePrice + parseFloat(markup)) * 100) /
        100;
    }

    // newRouting.totalPrice = (props.routing.totalPrice + parseFloat(markup))*100/100;

    let params = {
      searchInfo: searchInfo,
      routing: JSON.stringify(newRouting),
      travelers: JSON.stringify(props.travelers),
      currency: props.currency,
      shareInfo: {
        isShare: true,
        accountCode: userParams.accountCode,
        openid: userParams.openid,
        phone: userParams.phone,
        email: userParams.email,
        sourceType: userParams.sourceType,
      },
    };
    // console.log("params", params);
    if (!navigator.canShare) {
      axios
        .post('api/share', params)
        .then(function (res) {
          var data = JSON.parse(res.data);
          navigate(`/share/${data.url}`, { state: { share: false } });
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
        })
        .then(function () {});
    } else {
      axios
        .post('api/share', params)
        .then(function (res) {
          var data = JSON.parse(res.data);
          shareSite(
            `${window.location.protocol}//${window.location.host}/share/${data.url}`,
          );
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
        })
        .then(function () {});
    }
  }

  return (
    <div className="trip-details container">
      {props.routing.trips.map((trip, index) => {
        return (
          <SegmentBound
            key={'segment-bound' + index}
            routing={props.routing}
            travelers={props.travelers}
            {...trip}
            rules={props.routing.rules}
            tripIndex={index}
            hideFareRules={props.hideFareRules}
          />
        );
      })}
      {props.hideBookButton ? (
        ''
      ) : (
        <div className="btn-wrapper">
          {/* <h1>{props.routing.cid}</h1> */}
          <Typography variant="caption" gutterBottom>
            {props.routing.isStudent
              ? intl.get('COMMON.TICKET_TYPE.STUDENT')
              : ''}
            {props.routing.isYouth ? intl.get('COMMON.TICKET_TYPE.YOUTH') : ''}
            {props.routing.cid === 'flygtour'
              ? intl.get('COMMON.TICKET_TYPE.TOUR')
              : ''}
            {/* {IS_DirectNDCS.includes(props.routing.cid) ? "D-NDC": ""} */}
          </Typography>
          <Link
            href="#"
            className="btn btn-yellow mkt-popup-prc-btn"
            data-type="submit"
            to="/book"
            state={{
              ...props,
              shareInfo: sessionStorage.getItem('share')
                ? JSON.parse(sessionStorage.getItem('share'))
                : null,
            }}
          >
            <span className="btn-text">
              {intl.get('FLIGHT.BOOK_FOR')}&nbsp;
              {(props.routing.adultBasePrice + props.routing.adultTax).toLocaleString('en', {
                                    style: 'currency', 
                                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
              {/* {intl.get('PRICE', {
                currency: intl
                  .get('CURRENCY.' + props.currency + '.SYMBOL')
                  .defaultMessage(props.currency),
                price: props.routing.adultBasePrice + props.routing.adultTax,
              })} */}
            </span>
            {props.routing.sells.length > 0 && <small>(S)</small>}
            <span className="per-person">
              {props.travelers.adults + props.travelers.children > 1
                ? intl.get('FLIGHT.PER_PERSON')
                : ''}
            </span>
            {props.routing.cid == 'flygndc' && <small> (N)</small>}
          </Link>

          {/* {!sessionStorage.getItem('share') && <IconButton onClick={shareClick} color="primary" aria-label="share">
					<ShareIcon/>
				</IconButton>} */}
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {intl.get('FLIGHT.SET_MARKUP')}
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-description">
		  <Divider/>
          </DialogContentText> */}
              {/* <FormGroup sx={{marginTop: 1}}>
		  <TextField
          label="How old are you?"
          type="number"
		  value={this.state.dialogValues.age}
		  onChange={this.handleChange("age")}
          // sx={{ m: 1, width: '25ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">age</InputAdornment>,
          }}
		  />
		  <FormControlLabel control={<Checkbox checked={this.state.dialogValues.isStudent} onChange={this.handleCheck("isStudent")} />} label="Are you a student?" />
		  <FormControlLabel control={<Checkbox checked={this.state.dialogValues.isSchool} onChange={this.handleCheck("isSchool")} />} label="A school faculty?" />
		  </FormGroup> */}
              <span>
                {Math.round(
                  (props.routing.adultBasePrice + props.routing.adultTax) * 100,
                ) / 100}
                +
              </span>
              <input
                type="number"
                width="5rem"
                min="0"
                max="1000"
                value={markup}
                step="0.01"
                onChange={changeMarkup}
              ></input>
              <span>{`=${
                Math.round(
                  (props.routing.adultBasePrice +
                    props.routing.adultTax +
                    parseFloat(markup)) *
                    100,
                ) / 100
              }`}</span>
              <h6>{intl.get('FLIGHT.MARKUP_DESCRIPTION')}</h6>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>
                {intl.get('COMMON.CANCEL')}
              </Button>
              <Button color="warning" onClick={handleSubmit} autoFocus>
                {intl.get('COMMON.SUBMIT')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}
