import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import intl from 'react-intl-universal';
import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, IconButton, TextField, Box } from '@mui/material';
import { useEffect } from 'react';
import { countries } from '../../data/countries';
import { USA_STATES } from '../../data/config';


export default function Contact(props) {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [disabled, setDisabled] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [selectState, setSelectState] = useState(false)
  const [states, setStates] = useState([])
  // const [displaySame, setdisplaySame] = useState(false)

  console.log('contact', props);
  console.log('erros', errors)
  let defaultCountry = null;
  // if (props.contact) {
  //   for (let country of countries) {
  //     if (props.contact.country == country.name) {
  //       defaultCountry = country;
  //     }
  //   }
  // }

  // console.log('default country', defaultCountry)

  useEffect(() => {
    setDisabled(props.disabled);
    // console.log('effect', props.contact)
    if (props.contact) {
      // console.log('set value contact')
      // console.log('contact', props.contact);
      let contact = props.contact;
      contact.country = '';
      contact.address1 = '';
      contact.city = '';
      contact.state = '';
      contact.zipCode = '';

      setValue('contact', contact);
    }
  }, []);

  // console.log("get values", getValues());
  // if (watch('contact.country')) {

  //   console.log(getValues('contact.country'));
  //   // if(getValues('contact.country') === 'US'){
  //   //   setStates(USA_STATES)
  //   // }else{
  //   //   setStates([])
  //   // }
  //   // if()
  //   // setValue('contact.country', watch('contact.countryCode'))
  // }
  if (watch('sameName')) {
    // setValue('contact.country', 'US')
    // setValue("traveler.adult[0].lastName", "demo")
    let values = getValues();
    // values.traveler.
    for (let travelerType in values.traveler) {
      // console.log("traveler", travelerType, values.traveler[travelerType]);
      setValue(
        `traveler.${travelerType}[0].lastName`,
        watch('contact.lastName'),
      );
      setValue(
        `traveler.${travelerType}[0].middleName`,
        watch('contact.middleName'),
      );
      setValue(
        `traveler.${travelerType}[0].firstName`,
        watch('contact.firstName'),
      );
      break;
    }
  }

  function handleSetDisabled() {
    // console.log("set disabled", disabled);
    setDisabled(false);
  }

  return (
    <div className="theme-payment-page-sections-item _p-20">
      <h3 className="theme-payment-page-sections-item-title font-weight-600">
        <i className="fa fa-address-book _pr-10 _r"></i>
        {intl.get('BOOK.CONTACT_INFORMATION')}
        <span className="Required">
          <span className="required">*</span>
        </span>
      </h3>

      <div className="col-md-12 _p-15 ba-1" style={{ marginBottom: '20px' }}>
        <div className="block-fieldset__body block-fieldset__body--alert-wrap">
          <div className="alert-box-inline alert-box-inline--info">
            <i className="fa fa-info-circle alert-box-inline__icn"></i>
            <p className="alert-box-inline__text">
              {intl.get('BOOK.CONTACT_CREDIT_CARD_ADDRESS_REQUIREMENT')}
            </p>
          </div>
        </div>
      </div>
      <h6 className="text-2 font-weight-600 mb-3">
        {intl.get('BOOK.CONTACT_INFORMATION_REQUIREMENTS')}
      </h6>
      <div className="theme-payment-page-form">
        <div className="row row-col-gap" data-gutter="20">
          {props.displaySame && (
            <div className="_mb-desk-10 col-md-12">
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="contactsubscribe-cs_subscribe"
                  className="checkbox__input"
                  {...register('sameName')}
                />
                <label
                  className="checkbox__control"
                  htmlFor="contactsubscribe-cs_subscribe"
                ></label>
                <label
                  className="checkbox__label text-2 font-weight-600"
                  htmlFor="contactsubscribe-cs_subscribe"
                >
                  {intl.get('BOOK.NAME_SAME_FIRST_TRAVELER')}
                </label>
              </div>
            </div>
          )}

          <div className="col-sm-6 ">
            <div className="form-group">
              {/* <label className="control-label"></label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                {...register('contact.lastName', {
                  required: true,
                  maxLength: 25,
                })}
                disabled={disabled}
              /> */}
              <TextField
                {...register('contact.lastName', {
                  required: true,
                  maxLength: 25,
                })}
                disabled={disabled}
                variant="outlined"
                label={
                  <>
                    {intl.get('COMMON.LAST_NAME')}
                    <span className="required">*</span>
                  </>
                }
              />
              <p className="help-block help-block-error"></p>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              {/* <label className="control-label">
                {intl.get('COMMON.MIDDLE_NAME')}
              </label> */}
              {/* <input
                type="text"
                className="form-control"
                placeholder="Middle Name"
                {...register('contact.middleName', { required: false })}
                disabled={disabled}
              /> */}
              <TextField
                {...register('contact.middleName', { required: false })}
                disabled={disabled}
                variant="outlined"
                label={intl.get('COMMON.MIDDLE_NAME')}
              />
              <p className="help-block help-block-error"></p>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              {/* <label className="control-label">
                {intl.get('COMMON.FIRST_NAME')}
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                {...register('contact.firstName', { required: true })}
                disabled={disabled}
              /> */}
              <TextField
                {...register('contact.firstName', { required: true })}
                disabled={disabled}
                variant="outlined"
                label={
                  <>
                    {intl.get('COMMON.FIRST_NAME')}
                    <span className="required">*</span>
                  </>
                }
              />
              {/* <p className="help-block help-block-error">Hello</p> */}
            </div>
          </div>

          <div className="col-sm-6"></div>

          <div className="col-md-6">
            {/* <label className="control-label">
              {intl.get('BOOK.CONTACT_PHONE')}
              <span className="required">*</span>
            </label> */}
            <div className="row row-col-gap" data-gutter="20">
              <div className="col-md-6 ">
                <div className="theme-payment-page-form-item form-group">
                  {/* <input
                    className="form-control"
                    type="text"
                    placeholder="12354568972"
                    {...register('contact.phoneNumber', { required: true })}
                    disabled={disabled}
                  /> */}
                  <TextField
                    {...register('contact.phoneNumber', { required: true })}
                    disabled={disabled}
                    variant="outlined"
                    label={
                      <>
                        {intl.get('BOOK.CONTACT_PHONE')}
                        <span className="required">*</span>
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="form-group">
              {/* <label className="control-label" htmlFor="checkout-ch_email">
                {intl.get('BOOK.CONTACT_EMAIL')}
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="your@email.com"
                {...register('contact.emailAddress', { required: true })}
                disabled={disabled}
              /> */}
              <TextField
                fullWidth
                {...register('contact.emailAddress', { required: true })}
                disabled={disabled}
                variant="outlined"
                label={
                  <>
                    {intl.get('BOOK.CONTACT_EMAIL')}
                    <span className="required">*</span>
                  </>
                }
              />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <TextField
                fullWidth
                {...register('contact.zipCode', { required: props.addressRequired })}
                // disabled={disabled}
                variant="outlined"
                error = {errors?.contact?.zipCode?.type ? true : false}
                label={intl.get('BOOK.CONTACT_ZIP_CODE')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="theme-payment-page-form-item form-group">
              <Autocomplete
                defaultValue={defaultCountry}
                // disabled={disabled}
                onChange={(event, newValue) => {
                  console.log('value', newValue);
                  if(newValue && newValue.abbr === 'US'){
                    setStates(USA_STATES)
                  }else{
                    setStates([])
                  }
                  setValue('contact.country', newValue ? newValue.abbr : null);
                  setValue('contact.countryCode', newValue ? newValue.abbr : '');
                }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error = {errors?.contact?.country?.type ? true : false}
                    label={intl.get('BOOK.CONTACT_COUNTRY')}
                    // inputProps={{
                    //   ...params.inputProps,
                    //   // autoComplete: 'new-password', // disable autocomplete and autofill
                    // }}
                    {...register('contact.country', { required: props.addressRequired })}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <TextField
                fullWidth
                {...register('contact.city', { required: props.addressRequired })}
                // disabled={disabled}
                variant="outlined"
                error = {errors?.contact?.city?.type ? true : false}
                label={intl.get('BOOK.CONTACT_CITY')}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              {/* <TextField
                fullWidth
                {...register('contact.state')}
                disabled={disabled}
                variant="outlined"
                label={intl.get('BOOK.CONTACT_STATE')}
              /> */}
              <Autocomplete
              freeSolo = {states.length == 0}
                defaultValue={null}
                // disabled={disabled}
                onChange={(event, newValue) => {
                  console.log('value', newValue);
                  setValue('contact.state', newValue ? newValue.code : '');
                  setValue('contact.stateName', newValue ? newValue : null);
                }}
                options={states}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.get('BOOK.CONTACT_STATE')}
                    error = {errors?.contact?.stateName?.type ? true : false}
                    // inputProps={{
                    //   ...params.inputProps,
                    //   type: 'search'
                    //   // autoComplete: 'new-password', // disable autocomplete and autofill
                    // }}
                    {...register('contact.stateName', { required: props.addressRequired })}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="theme-payment-page-form-item form-group">
              <TextField
                fullWidth
                {...register('contact.address1', { required: props.addressRequired })}
                // disabled={disabled}
                variant="outlined"
                label={intl.get('BOOK.CONTACT_ADDRESS')}
                error = {errors?.contact?.address1?.type ? true : false}
                // helperText={errors?.contact?.address1?.type && "Incorrect entry."}
              />
            </div>
          </div>
        </div>
      </div>
      {props.displaySame || (
        <h6 className="text-2 font-weight-600 mb-3">
          need to modify?
          <IconButton
            color="primary"
            aria-label="need to modify"
            onClick={handleSetDisabled}
          >
            <EditIcon />
          </IconButton>
        </h6>
      )}
    </div>
  );
}
