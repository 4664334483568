import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios'
import './index.css'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';


export default function BasicSpeedDial(props) {
  const { enqueueSnackbar } = useSnackbar();
  // console.log('speed dial', props)
  const[open, setOpen] = useState(false)
  const[email, setEmail] = useState('')
  const[loading, setLoading] = useState(false)

  const getOpenid = ()=>{
    if(props.info.openid){
      return props.info.openid
    }

    if(props.info.bookInfo && props.info.bookInfo.openid){
      return props.info.bookInfo.openid
    }

    if(sessionStorage.getItem("openid")){
      return sessionStorage.getItem("openid")
    }
  }

    const sendMail =()=>{
        setLoading(true)
        let contact = props.info.bookInfo.contact
        if(email){
          contact.emailAddress = email
        }
        let params = {req: {adults: props.info.bookInfo.adults, children: props.info.bookInfo.children, routing: props.info.bookInfo.routing,
        passengers: props.info.bookInfo.passengers, contact: contact, dateTime: props.info.orderTime,
        openId: getOpenid()
        },
        rsp: props.info.bookedRsp,
        routing: JSON.parse(props.info.bookInfo.routing),
        currency: props.info.currency,
        orderStatus: props.info.orderStatus,
        link: ""
        }

        // console.log(params)

        // return

        axios.post('api/email', params)
        .then(function (res) {
            setOpen(false)
            console.log('sent', res)
            if(res.data == "SENT"){
              enqueueSnackbar("Sent", {variant: 'success', autoHideDuration: 2000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
            }else{
              enqueueSnackbar(res.data, {variant: 'warning', autoHideDuration: 4000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
            }
        })
        .catch(function (error) {
            console.error(error);
            enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
        }).then(function () {
          setLoading(false)
        });
    }

    const handleEmail = ()=>{
      setEmail(props.info.contact ? props.info.contact.emailAddress : '')
      setOpen(true)
    }

    const handleChangeEmail=(e)=>{
      setEmail(e.target.value)
    }
    const actions = [
        //   { icon: <FileCopyIcon />, name: 'Copy' },
        //   { icon: <SaveIcon />, name: 'Save' },
          { icon: <PrintIcon onClick={()=>{window.print()}} />, name: 'Print' },
        //   { icon: <ShareIcon />, name: 'Share' },
            // {icon: <EmailIcon onClick={()=>sendMail()}/>, name: 'Email'}
            {icon: <EmailIcon onClick={handleEmail}/>, name: 'Email'}

        ];

  return (
    <Box sx={{ height: 20, transform: 'translateZ(0px)', flexGrow: 1 }} className="no-print">
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>

      <Dialog open={open} onClose={()=>setOpen(false)}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If to change the default email, please enter your new email address here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={handleChangeEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpen(false)}>Cancel</Button>
          {loading ? <CircularProgress size='1.4em'/> : 
          <Button onClick={sendMail}>
          Send
        </Button>}       
        </DialogActions>
      </Dialog>
    </Box>
  );
}
