import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OrderSummary from '../../components/OrderSummary';
import TripDetail from '../../components/TripDetail';
import {
  AppBar,
  Button,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  IconButton,
  Tooltip,
} from '@mui/material';
import intl from 'react-intl-universal';
import style from './index.module.css';
import moment from 'moment';
import BasicSpeedDial from '../../components/BasicSpeedDial';
import { useState } from 'react';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Seatmap from '../Seatmap';
import axios from 'axios';
import { useSnackbar } from 'notistack';
// import { createRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ButtonAppBar from '../../components/ButtonAppBar';
import { loadCss } from '../../common/helper.js';
import { IS_DirectNDCS } from '../../data/config';
import Seats from '../../components/Seats/index.jsx';
import seatmapHelper from '../../utils/seatmapHelper.js';
import { LoadingButton } from '@mui/lab';
import Bundle from '../../components/Bundle/index.jsx';
import { deepEqual } from '../../utils/utils';
import { getSegments } from '../../utils/utils.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function ToUpper(s) {
  if (s) {
    return s.replace(/^\S/, (s) => s.toUpperCase());
  }

  return s;
}

function Traveler(props) {
  // console.log('traveler', props);
  const { passenger } = props;
  // const [progressing, setProgressing] = useState(false)
  let ticketNumber = null;
  if (props.passengerInfos) {
    for (let passengerInfo of props.passengerInfos) {
      if (
        passengerInfo.firstName === passenger.firstName &&
        passengerInfo.middleName === passenger.middleName &&
        passengerInfo.lastName === passenger.lastName
      ) {
        ticketNumber = passengerInfo.tktnumber;
      }
    }
  }

  let EMDTicketNumbers = [];
  let passengerName = passenger.lastName + ' ' + passenger.firstName;
  if (passenger.middleName) {
    passengerName =
      passenger.lastName +
      ' ' +
      passenger.middleName +
      ' ' +
      passenger.firstName;
  }

  // let seatPassengerRef = null
  // if(passenger.seats){
  // 	for(let seat of passenger.seats){
  // 		if(seat.PassengerRef){
  // 			seatPassengerRef = seat.PassengerRef.replace("T", "P")
  // 		}
  // 	}
  // }

  // let servicePassengerRef = null
  // if(passenger.services){
  // 	for(let service of passenger.services){
  // 		if(service.PassengerRef){
  // 			servicePassengerRef = service.PassengerRef.replace("T", "P")
  // 		}
  // 	}
  // }

  // console.log('service', servicePassengerRef)

  if (props.EMDTickets) {
    for (let EMDTicket of props.EMDTickets) {
      // console.log(EMDTicket.Name, passengerName.toUpperCase())
      if (EMDTicket.Name && EMDTicket.Name === passengerName.toUpperCase()) {
        EMDTicketNumbers.push({
          number: EMDTicket.Number,
          type: EMDTicket.ServiceInfos[0].Type,
        });
      }
    }
  }

  // console.log("traveler", props);
  return (
    <div className="col-md-12 theme-payment-success-box">
      <ul className="theme-payment-success-summary">
        <li>
          <i className="fa fa-user-check _mr-10"></i>
          <b>{intl.get('SUCCESS.TRAVELER_NAME')}</b>
          <span>{`${passenger.lastName}/${ToUpper(passenger.firstName)}${
            !passenger.middleName ? '' : ' ' + ToUpper(passenger.middleName)
          }`}</span>
        </li>
        <li>
          <i className="fa fa-ticket-alt _mr-10"></i>
          <b>{intl.get('SUCCESS.TICKETNUMBER')}</b>
          <span>
            {props.progressing && <LinearProgress />}
            {passenger.ticketNumber
              ? passenger.ticketNumber
              : ticketNumber || intl.get('SUCCESS.TO_DO')}
          </span>
        </li>
        <li>
          <i className="fa fa-venus-mars _mr-10"></i>
          <b>{intl.get('COMMON.GENDER')}</b>
          <span>
            {intl.get(
              'GENDER.' +
                (passenger.gender === 'Male' || passenger.gender === 'M'
                  ? 'MALE'
                  : 'FEMALE'),
            )}
          </span>
        </li>
        <li>
          <i className="fa fa-calendar-alt _mr-10"></i>
          <b>{intl.get('COMMON.BIRTHDAY')}</b>
          <span>{moment(passenger.birthday).format('MM/DD/YYYY')}</span>
        </li>
        <li>
          <i className="fa fa-chair _mr-10"></i>
          <b>Seat</b>
          {/* <span>{passenger.seats &&
            passenger.seats
              .map((item) =>
                item.RowNumber
                  ? item.RowNumber + item.SeatNumber
                  : item.rowNumber + item.seatNumber,
              )
              .join(';')}</span> */}
          <LoadingButton
            sx={{ float: 'right' }}
            loading={props.loadingSeatmap}
            variant="text"
            size="small"
            onClick={props.onClickSeatmap}
          >
            {passenger.seats &&
            passenger.seats
              .map((item) =>
                item.RowNumber
                  ? item.RowNumber + item.SeatNumber
                  : item.rowNumber + item.seatNumber,
              )
              .join(';')
              ? passenger.seats
                  .map((item) =>
                    item.RowNumber
                      ? item.RowNumber + item.SeatNumber
                      : item.rowNumber + item.seatNumber,
                  )
                  .join(';')
              : 'Seat map'}
          </LoadingButton>
        </li>
        <li>
          <i className="fa fa-star _mr-10"></i>
          <b>Service</b>
          <LoadingButton
            sx={{ float: 'right' }}
            loading={props.loadingServices}
            variant="text"
            size="small"
            onClick={props.onClickServices}
          >
            {passenger.services &&
            passenger.services
              .map((item) =>
                item.ServiceName ? item.ServiceName : item.serviceName,
              )
              .join(';')
              ? passenger.services
                  .map((item) =>
                    item.ServiceName ? item.ServiceName : item.serviceName,
                  )
                  .join(';')
              : 'Travel Extras'}
          </LoadingButton>
          {/* <LoadingButton
            sx={{ float: 'right' }}
            loading={props.loadingServices}
            variant="text"
            size="small"
            onClick={props.onClickServices}
          >
            {passenger.services &&
              passenger.services
                .map((item) =>
                  item.ServiceName ? item.ServiceName : item.serviceName,
                )
                .join(';') ? passenger.services &&
                passenger.services
                  .map((item) =>
                    item.ServiceName ? item.ServiceName : item.serviceName,
                  )
                  .join(';') : 'See All'}
          </LoadingButton> */}
        </li>
        {props.progressingEMD ? (
          <li>
            <i className="fa fa-money-bill _mr-10"></i>
            <b>EMD</b>
            <span>
              <LinearProgress />
              Checking
            </span>
          </li>
        ) : (
          EMDTicketNumbers.map((item, index) => (
            <li key={item.number}>
              <i className="fa fa-money-bill _mr-10"></i>
              <b>EMD {'(' + item.type + ')'}</b>
              <span>{item.number}</span>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

function PaymentBox(props) {
  return (
    <>
      {intl.getHTML('SUCCESS.PAYMENT_LINK', {
        link: 'payment/' + props.paymentLink,
      })}
    </>
  );
}

// function parseStatus(orderStatus){
// 	switch(orderStatus){
// 		case 0:
// 			return "NEW"
// 		case 1:
// 			return "PAYMENTING"
// 		case 2:
// 			return "PROCESSING"
// 		case 3:
// 			return "ISSUED"
// 		case 4:
// 			return "CANCELLED"
// 	}
// }

function parseStatus(orderStatus) {
  if (orderStatus) {
    if (orderStatus === 'No Payment') {
      return 'PAYMENTING';
    } else if (orderStatus === 'Full Payment') {
      return 'PROCESSING';
    } else if (orderStatus === 'Issued') {
      return 'ISSUED';
    } else if (orderStatus === 'FAILED') {
      return 'FAILED';
    } else if (orderStatus === 'Cancelled') {
      return 'CANCELLED';
    }

    return orderStatus.toUpperCase();
  }

  return null;
}

// const MySeatmap = (props) => {
// 	// console.log('my seatmap', props)
//     const { enqueueSnackbar } = useSnackbar();
// 	const[open, setOpen] = useState(false)
// 	const [seatmapsInfo, setSeatmapsInfo] = useState(null)
// 	const [submiting, setSubmiting] = useState(false)
// 	// const [payment, setPayment] = useState(null)
// 	// useEffect(()=>{
// 	// 	enqueueSnackbar('I love hooks', { variant:'success' });
// 	// },[])
// 	const handleSeatmaps = ()=>{
// 		let payment = childRef.current.func()
// 		// console.log('payment', payment);
// 		// return;
// 		// console.log('seat maps info', seatmapsInfo);

// 		if(seatmapsInfo === null || seatmapsInfo.selectedSeatmaps === null || seatmapsInfo.selectedSeatmaps.length === 0
// 			|| seatmapsInfo.selectedSeatmaps.findIndex(item=>item.seats.length>0)<0){
// 			alert('Not selected')
// 			return
// 		}

// 		setSubmiting(true)
// 		let newPayment = null;
// 		if(payment){
// 			newPayment = {lastName: payment.lastName, middleName: payment.middleName, firstName: payment.firstName,
// 				creditCardNumber: payment.cardNumber, cardVerificationNumber: payment.verificationCode,
// 				expirationMonth: payment.expirationDate && payment.expirationDate.length === 7 ? payment.expirationDate.substring(0,2) : "",
// 				expirationYear: payment.expirationDate && payment.expirationDate.length === 7 ? payment.expirationDate.substring(3) : ""
// 			}
// 		}

// 		axios.post('api/seatmaps', {...seatmapsInfo, pcc: '1ED5', pnr: props.pnr, confirmationCode: props.confirmationCode,
// 		payment: newPayment
// 	})
// 		.then(function (res) {
// 			// console.log('seatmaps book', res, JSON.parse(res.data))
// 			let json = JSON.parse(res.data)
// 			for(let v of json){
// 				if(v.rsp){
// 					enqueueSnackbar(v.rsp, {variant: 'error'})
// 				}else{
// 					enqueueSnackbar('success', {variant: 'success'})
// 				}
// 			}
// 		})
// 		.catch(function (error) {
// 			console.error(error);
// 			alert(error)
// 		}).then(function () {
// 			setSubmiting(false)
// 		});
// 	}

// 	const changeSeatmaps=(value)=>{
// 		// console.log('change seat maps', value)
// 		setSeatmapsInfo(value)
// 	}

// 	// const changeValue=(values)=>{
// 	// 	console.log('values', values)
// 	// 	setPayment(values)
// 	// }

// 	let childRef = createRef()

//     return (
// 		<>
// 		<Button onClick={()=>setOpen(true)}>{intl.get('SUCCESS.SEATMAP_SERVICE')}</Button>
// 		<Dialog
// 	fullScreen
// 	open={open}
// 	onClose={()=>setOpen(false)}
// 	aria-labelledby="responsive-dialog-title"
// 	// TransitionComponent={Transition}
//   >
// 	<DialogTitle>
// 	  {intl.get("SEATMAP.SEATMAP")}
// 	</DialogTitle>
// 	<DialogContent>
// 		<Seatmap submiting={submiting} changeSeatmaps={changeSeatmaps}
// 		{...{cid:props.cid, pcc: "1ED5", pnr: props.pnr, travelers: props.travelers, passengers: props.passengers}}
// 		// changeValue={changeValue}
// 		onRef={childRef}
// 		/>
// 	</DialogContent>
// 		<DialogActions>
// 		<Button autoFocus onClick={()=>setOpen(false)}>
// 		  Close
// 		</Button>
// 		<Button onClick={handleSeatmaps} autoFocus color='warning'>
// 		  Submit
// 		</Button>
// 	  </DialogActions>
// 	  </Dialog>
// 	  </>
//     );
// }

function convertGds(gds) {
  switch (gds) {
    case null:
      return '';
    case 'F':
      return 'Direct NDC';
    case 'N':
      return 'Galileo NDC';
    case 'S':
      return 'Sabre';
    case 'A':
      return 'Amadeus';
    case 'P':
      return 'Wspan uAPI';
    case 'W':
      return 'Wspan';
    case 'G':
      return 'Galileo';
    case 'D':
      return 'Amadeus NDC';
    default:
      return gds;
  }
}

const SearchSeat = (seatNumber, seatMapDetails) => {
  let seat = null;
  if (seatNumber && seatMapDetails) {
    let rowNum = seatNumber.substring(0, seatNumber.length - 1);
    let columnNum = seatNumber.substring(seatNumber.length - 1);
    for (let seatMapDetail of seatMapDetails) {
      for (let airRow of seatMapDetail.airRows) {
        for (let airSeat of airRow.airSeats) {
          if (airRow.rowNumber === rowNum && airSeat.seatNumber === columnNum) {
            seat = airSeat;
          }
        }
      }
    }
  }

  return seat;
};

export default function Success() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log('success location', location);
  const { state } = location;
  let routing = JSON.parse(state.bookInfo.routing);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [orderStatus, setOrderStatus] = useState(state.orderStatus);
  const [disabled, setDisabled] = useState(false);
  const [passengerInfos, setPassengerInfos] = useState(null);
  const [progressing, setProgressing] = useState(false);
  const [progressingEMD, setProgressingEMD] = useState(false);
  const [EMDTickets, setEMDTickets] = useState(null);
  const [openSeat, setOpenSeat] = useState(false);
  const [seatmaps, setSeatmaps] = useState(null);

  const [seatsActiveStep, setSeatsActiveStep] = useState(0);
  const [seatsCount, setSeatsCount] = useState(0);
  const [showSeatSubmit, setShowSeatSubmit] = useState(false);
  const [travelers, setTravelers] = useState([]);
  const [loadingSeatmap, setLoadingSeatmap] = useState(false);
  // const [submitSeats, setSubmitSeats] = useState([]);
  // const [deleteSeats, setDeleteSeats] = useState([])
  const [loadingBookSeat, setLoadingBookSeat] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [openSerivce, setOpenSerivce] = useState(false);
  const [trips, setTrips] = useState([]);
  const [services, setServices] = useState(null);
  // const [selectedServices, setSelectedServices] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [servicesActiveStep, setServicesActiveStep] = useState(0);
  const [bundlesCount, setBundlesCount] = useState(0);
  const [showServicesSubmit, setShowServicesSubmit] = useState(false);
  const [progressingServices, setProgressingServices] = useState(false);
  const [passengerToServices, setPassengerToServices] = useState([]);
  const [loadingBookServices, setLoadingBookServices] = useState(false);
  const [openTooltips, setOpenTooltips] = useState([]);

  useEffect(() => {
    // loadCss('/Flight-Hotel/vendor/bootstrap/css/bootstrap.min.css')
    loadCss(
      'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css',
    );
    // loadCss('/Flight-Hotel/vendor/font-awesome/css/all.min.css')
    loadCss('/Flight-Hotel/css/stylesheet.css');
    loadCss('/Flight-Hotel/css/styles.css');
    loadCss('/Flight-Hotel/css/FlightDetails.css');
    loadCss('/css/flag.css');
    loadCss('/css/seatmap.css');

    document.scrollingElement.scrollTop = 0;
  }, []);

  const searchStatus = () => {
    setProgressing(true);
    axios.interceptors.response.use(
      function (response) {
        // console.log('response', response);
        let config = response.config;
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        if (!config || !config.retry) return response;

        // 设置变量以跟踪重试次数
        config.__retryCount = config.__retryCount || 0;

        // 判断是否超过总重试次数
        if (config.__retryCount >= config.retry) {
          // 返回错误并退出自动重试
          return response;
        }

        // 增加重试次数
        config.__retryCount += 1;

        //打印当前重试次数
        // console.log(config.url + ' retry ' + config.__retryCount + 'times');

        //     // 创建新的Promise
        // if(response.data.indexOf('issued')<0 && response.data.indexOf("cancelled")<0)
        if (
          'tktStatusResponse' in response.data &&
          response.data.tktStatusResponse.status !== 'issued' &&
          response.data.tktStatusResponse.status !== 'cancelled'
        ) {
          var backoff = new Promise(function (resolve) {
            setTimeout(function () {
              resolve();
            }, config.retryDelay || 1);
          });

          // 返回重试请求
          return backoff.then(function () {
            return axios(config);
          });
        } else {
          return response;
        }
      },
      function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.reject(error);
      },
    );

    axios
      .get(
        `/api/status?cid=${
          JSON.parse(state.bookInfo.routing).cid
        }&customerSessionId=S12345&confirmationCode=${
          state.bookedRsp.airTicketBookingResponse.confirmationCode
        }&recordLocator=${
          state.bookedRsp.airTicketBookingResponse.recordLocator
        }`,
        {
          retry: 10,
          retryDelay: 10000,
        },
      )
      .then(function (res) {
        // console.log('res', res);
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
            break;
          }
        } else {
          if (res.data.tktStatusResponse.status === 'issued') {
            setPassengerInfos(res.data.tktStatusResponse.passengerInfo);
            setOrderStatus('Issued');
            enqueueSnackbar('Tickets issued success', {
              variant: 'success',
              autoHideDuration: 3000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          } else {
            enqueueSnackbar(res.data.tktStatusResponse.status, {
              variant: 'warning',
              autoHideDuration: 3000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setProgressing(false);
      });
  };

  // const searchEMDStatus = ()=>{
  // 	setProgressingEMD(true)
  // 	axios.interceptors.response.use(function (response) {
  // 		// console.log('response', response)
  // 		let config = response.config;
  // 		// 2xx 范围内的状态码都会触发该函数。
  // 		// 对响应数据做点什么
  // 		if (!config || !config.retry) return response;

  // 		// 设置变量以跟踪重试次数
  // 		config.__retryCount = config.__retryCount || 0;

  // 		// 判断是否超过总重试次数
  // 		if (config.__retryCount >= config.retry) {
  // 			// 返回错误并退出自动重试
  // 			return response;
  // 		}

  // 		// 增加重试次数
  // 		config.__retryCount += 1;

  // 		//打印当前重试次数
  // 		// console.log(config.url +' retry ' + config.__retryCount + 'times');

  // 	//     // 创建新的Promise
  // 	// if(response.data.indexOf('issued')<0 && response.data.indexOf("cancelled")<0)
  // 		if(response.data && Array.isArray(response.data) && response.data.length === 0){
  // 			var backoff = new Promise(function (resolve) {
  // 			setTimeout(function () {
  // 				resolve();
  // 			}, config.retryDelay || 1);
  // 		});

  // 		// 返回重试请求
  // 		return backoff.then(function () {
  // 			return axios(config);
  // 		});
  // 	  }else{
  // 		return response;
  // 	  }
  // 	  }, function (error) {
  // 		// 超出 2xx 范围的状态码都会触发该函数。
  // 		// 对响应错误做点什么
  // 		return Promise.reject(error);
  // 	  });

  // 	  axios.get(`/api/status?pcc=${location.state.order.pcc}&recordLocator=${state.bookedRsp.airTicketBookingResponse.recordLocator}`, {
  // 		retry: 0,
  // 		retryDelay: 10000,
  // 	  })
  // 	  .then(function (res) {
  // 		// console.log('res', res)
  // 		if(Array.isArray(res.data) && res.data.length > 0){
  // 			setEMDTickets(res.data)
  // 			// enqueueSnackbar('EMD Tickets issued success', {variant: 'success', autoHideDuration: 3000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
  // 		}else{
  // 			enqueueSnackbar("EMD Tickets not found!", {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
  // 		}
  // 	  })
  // 	  .catch(function (error) {
  // 		enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
  // 	  }).then(function () {
  // 		setProgressingEMD(false)
  // 	  });
  // }

  useEffect(() => {
    if (orderStatus === 'Full Payment') {
      if (state.bookInfo.passengers.find((item) => !item.ticketNumber)) {
        searchStatus();
      }
    }

    // searchStatus()
  }, []);

  const handleClickSeat = () => {
    navigate(`/seatmapdemo`, {
      state: {
        routing,
        cid: routing.cid,
        pnr: state.bookedRsp.airTicketBookingResponse.recordLocator,
        confirmationCode:
          state.bookedRsp.airTicketBookingResponse.confirmationCode,
        travelers: {
          adults: state.bookInfo.adults,
          children: state.bookInfo.children,
        },
        passengers: state.bookInfo.passengers,
        contact: state.bookInfo.contact,
        order: state.order,
        successInfo: state,
      },
    });
  };

  const handleClickSeats = () => {
    setLoadingSeatmap(true);
    axios
      .post('api/seats', {
        byOrder: true,
        cid: routing.cid,
        gds: state.order.gds,
        pcc: state.order.pcc,
        pnr: state.bookedRsp.airTicketBookingResponse.recordLocator,
        customerSessionId: 'S12345',
      })
      .then(function (res) {
        // let json = JSON.parse(res.data);
        let json = res.data;
        // console.log('seat map json', {...json});
        if ('APIError' in json) {
          for (let error of json.APIError.errors) {
            // alert(error.message)
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
            break;
          }
          return;
        }

        let segments = getSegments(routing);

        seatmapHelper.process(json, segments);
        setSeatsCount(json.seatMapResponse.seatMapResponses.length);
        if (json.seatMapResponse.seatMapResponses.length <= 1) {
          setShowSeatSubmit(true);
        }
        setSeatmaps(json);

        let travelerList = [];
        for (let flight of json.seatMapResponse.seatMapResponses) {
          for (let travelerIdentifier of flight.TravelerIdentifiers) {
            let seat = SearchSeat(
              travelerIdentifier.AreadySelectedSeatNumber,
              flight.SeatMapDetails,
            );

            let travelerFlight = {
              segments: flight.FlightSegments,
              areadySelectedSeat: {
                number: travelerIdentifier.AreadySelectedSeatNumber,
                orderItemId: travelerIdentifier.AreadySelectedSeatOrderItemId,
                seat: seat,
              },
              selectedSeat: null,
            };

            let travelerIndex = travelerList.findIndex(
              (item) => item.passengerKey === travelerIdentifier.PassengerKey,
            );
            if (travelerIndex < 0) {
              let traveler = {
                passengerKey: travelerIdentifier.PassengerKey,
                traveler: {
                  passengerTypeCode: travelerIdentifier.PassengerTypeCode,
                  id: travelerIdentifier.ID,
                  name: travelerIdentifier.Name,
                },
                flights: [travelerFlight],
              };

              travelerList.push(traveler);
            } else {
              travelerList[travelerIndex].flights.push(travelerFlight);
            }
          }
        }

        setTravelers(travelerList);

        // let selectedSeats= []

        // json.seatMapResponse.seatMapResponses.forEach((seatmap, index) => {
        // 	selectedSeats[index] = {
        // 		index: index,
        // 		seats: []
        // 	}

        // 	seatmap.TravelerIdentifiers.forEach((travelerIdentifier, tIndex) => {
        // 		if (!travelerIdentifier.AreadySelectedSeatNumber) return;

        // 		const seat = SearchSeat(
        // 			travelerIdentifier.AreadySelectedSeatNumber,
        // 			seatmap.SeatMapDetails,
        // 		);

        // 		const travelerPricing = []
        // 		travelerPricing.push({
        // 			id: '1',
        // 			seatAvailabilityStatus: seat.seatAvailabilityStatus.toUpperCase(),
        // 			price: {
        // 				currency: seat.OfferItems[tIndex].CurrencyCode,
        // 				total: seat.OfferItems[tIndex].TotalPrice,
        // 			},
        // 		})
        // 		selectedSeats[index].seats.push({
        // 			number: travelerIdentifier.AreadySelectedSeatNumber,
        // 			travelerPricing,
        // 			selectedSeat: {
        // 				number: travelerIdentifier.AreadySelectedSeatNumber,
        // 				seat
        // 			}
        // 		})
        // 	})
        // })

        // console.log(selectedSeats);
        // setSelectedSeats(selectedSeats)
        setOpenSeat(true);
      })
      .catch(function (error) {
        // console.error(error);
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setLoadingSeatmap(false);
      });
  };

  const handleCancel = () => {
    setLoading(true);
    setDisabled(true);
    axios
      .post('api/cancel', {
        value: new URLSearchParams({
          cid: JSON.parse(state.bookInfo.routing).cid,
          customerSessionId: 'S12345',
          confirmationCode:
            state.bookedRsp.airTicketBookingResponse.confirmationCode,
          pnr: state.bookedRsp.airTicketBookingResponse.recordLocator,
          gds: state.order.gds,
          pcc: state.order.pcc,
        }).toString(),
        contact: state.bookInfo.contact,
      })
      .then(function (res) {
        // let json = JSON.parse(res.data);

        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        } else {
          if (!res.data.cancelpnrResponse.isSuccess) {
            enqueueSnackbar(res.data.cancelpnrResponse.errorMessage, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          } else {
            let ticketNumbers = [];
            if (state.bookInfo.passengers) {
              for (let passenger of state.bookInfo.passengers) {
                if (passenger.tktnumber) {
                  ticketNumbers.push(passenger.tktnumber);
                }
              }
            }
            enqueueSnackbar(
              'The PNR: ' +
                res.data.cancelpnrResponse.pnr +
                (ticketNumbers.length > 0
                  ? ' and ticket (' + ticketNumbers.join('; ') + ')'
                  : '') +
                ' has been successfully cancelled!',
              {
                variant: 'success',
                autoHideDuration: 6000,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
              },
            );
            setOrderStatus('Cancelled');
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            // setTimeout(() => {
            // 	document.location.reload();
            // }, 3000);
          }
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setLoading(false);
        setDisabled(false);
      });
    // console.log('cancel', state)
    // if(state.from && state.from === "book"){ //----------从book跳转过来
    // 	setLoading(true)
    // 	setDisabled(true)
    // 	let requestCancel = false
    // 	axios.get(`api/orders?caseNumber=${state.bookedRsp.airTicketBookingResponse.confirmationCode}&cid=${JSON.parse(state.bookInfo.routing).cid}&customerSessionId=S12345&onlyAPI=true`)
    // 	.then(function(res){
    // 		console.log(res)
    // 		if('order' in res.data){
    // 			requestCancel = true
    // 			console.log('cancelling')
    // 	axios.post('api/cancel', {value: new URLSearchParams({cid: JSON.parse(state.bookInfo.routing).cid, customerSessionId: "S12345",
    // 	confirmationCode: state.bookedRsp.airTicketBookingResponse.confirmationCode,
    // 	pnr: state.bookedRsp.airTicketBookingResponse.recordLocator, gds: state.order.gds, pcc: state.order.pcc}).toString()})
    // 	.then(function (res) {
    // 		let json = JSON.parse(res.data);

    // 		if("APIError" in json){
    // 			for(let error of json.APIError.errors){
    // 				enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    // 			}
    // 		}else{
    // 			if(!json.cancelpnrResponse.isSuccess){
    // 				enqueueSnackbar(json.cancelpnrResponse.errorMessage, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    // 			}else{
    // 				let ticketNumbers =[]
    // 				if(state.bookInfo.passengers){
    // 					for(let passenger of state.bookInfo.passengers){
    // 						if(passenger.tktnumber){
    // 							ticketNumbers.push(passenger.tktnumber)
    // 						}
    // 					}
    // 				}
    // 				enqueueSnackbar('The PNR: ' + json.cancelpnrResponse.pnr + (ticketNumbers.length > 0 ? ' and ticket ('+ticketNumbers.join('; ')+')': '') + ' has been successfully cancelled!', {variant: 'success',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    // 				setOrderStatus("Cancelled")
    // 				document.body.scrollTop=document.documentElement.scrollTop=0
    // 				// setTimeout(() => {
    // 				// 	document.location.reload();
    // 				// }, 3000);
    // 			}
    // 		}

    // 	})
    // 	.catch(function (error) {
    // 		enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    // 	}).then(function () {
    // 		setLoading(false)
    // 		setDisabled(false)
    // 	});
    // 		}else{
    // 			enqueueSnackbar('please try again later', {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    // 		}
    // 	})
    // 	.catch(function(error){
    // 		enqueueSnackbar(error.message, {variant: 'error',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    // 	}).then(function(){
    // 		if(!requestCancel){
    // 			setLoading(false)
    // 			setDisabled(false)
    // 		}

    // 	})
    // }else{//------------------从我的订单跳转过来

    // }
  };

  // const handleCheckEMDAStatus = ()=>{
  // 	if(IS_DirectNDCS.includes(state.source) &&  (routing.mainAirlineCode === "EK" || routing.mainAirlineCode === "UA") && orderStatus === "Issued" &&
  // 	(location.state.bookInfo.passengers.find(item=>item.services && item.services.length>0) ||
  // 	location.state.bookInfo.passengers.find(item=>item.seats && item.seats.length>0))){
  // 		searchEMDStatus()
  // 	}else{
  // 		enqueueSnackbar('Does not meet the inspection conditions.', {variant: 'warning', autoHideDuration: 4000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
  // 	}
  // }

  const handleClickPayment = () => {
    // console.log('state.bookedRsp.airTicketBookingResponse.paymentLink');
    navigate(
      '/payment/' + state.bookedRsp.airTicketBookingResponse.paymentLink,
    );
  };

  const handleClickSeatNext = (step) => {
    // console.log('click next', step)
    // console.log('click next', step, seatsActiveStep, seatsCount)
    if (typeof step === 'number') {
      if (step === seatsCount - 1) {
        setShowSeatSubmit(true);
      }
      setSeatsActiveStep(step);
    } else {
      if (seatsActiveStep >= seatsCount - 1) {
        return;
      }
      setSeatsActiveStep(seatsActiveStep + 1);
      if (seatsActiveStep + 1 === seatsCount - 1) {
        setShowSeatSubmit(true);
      }
    }
  };

  const handleSubmitSeat = () => {
    setLoadingBookSeat(true);
    // return
  };

  const handleSetloadingBookSeat = (loading) => {
    setLoadingBookSeat(loading);
  };

  const handleBookSeatResponse = (response) => {
    // console.log('booked seats success');
    navigate('/OrderDetail', { state: state });
  };

  const handleClickServices = () => {
    console.log('click services');
    setLoadingServices(true);
    axios
      .get('api/services', {
        params: {
          cid: routing.cid,
          gds: state.order.gds,
          pcc: state.order.pcc,
          pnr: state.bookedRsp.airTicketBookingResponse.recordLocator,
          customerSessionId: 'S12345',
        },
      })
      .then(function (res) {
        if ('APIError' in res.data) {
          for (let error of res.data.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        } else if ('serviceListResponse' in res.data) {
          setServices(res.data.serviceListResponse.serviceList);
          let segments = [];
          for (let serviceList of res.data.serviceListResponse.serviceList) {
            for (let segment of serviceList.routings) {
              if (
                segments.findIndex(
                  (seg) =>
                    seg.departureAirportCode === segment.DepartureAirport &&
                    seg.arrivalAirportCode === segment.ArrivalAirport,
                ) >= 0
              ) {
                continue;
              }

              segments.push({
                departureAirportCode: segment.DepartureAirport,
                arrivalAirportCode: segment.ArrivalAirport,
              });
            }
          }

          let bundles = [];
          let filterServices =
            res.data.serviceListResponse.serviceList &&
            res.data.serviceListResponse.serviceList.length > 0 &&
            res.data.serviceListResponse.serviceList.filter(
              (item) => item.name !== 'FareLock',
            );
          for (let segment of segments) {
            let newServices = [];
            if (filterServices) {
              for (let service of filterServices) {
                let matched = false;

                for (let routing of service.routings) {
                  if (
                    segment.departureAirportCode === routing.DepartureAirport &&
                    segment.arrivalAirportCode === routing.ArrivalAirport
                  ) {
                    matched = true;
                  }
                }

                if (matched) {
                  newServices.push(service);
                }
              }
            }

            if (newServices.length === 0) {
              continue;
            }

            let passengers = [];

            for (let service of newServices) {
              for (let offerItem of service.offerInfo.offerItems) {
                let passengerIndex = passengers.findIndex(
                  (item) => item.passengeRefs === offerItem.passengeRefs,
                );
                if (passengerIndex < 0) {
                  passengers.push({
                    passengeRefs: offerItem.passengeRefs,
                    passenger: { type: service.passengerTypes },
                    services: [service],
                  });
                } else {
                  passengers[passengerIndex].services.push(service);
                }
              }
            }

            // console.log('passengers', passengers)

            bundles.push({
              segment: {
                departureAirportCode: segment.departureAirportCode,
                arrivalAirportCode: segment.arrivalAirportCode,
              },
              passengers,
            });
          }

          setBundlesCount(bundles.length);
          if (bundles.length <= 1) {
            setShowServicesSubmit(true);
          }

          let passengerList = [...state.bookInfo.passengers];

          // console.log('bundles', bundles);
          // console.log('passengers', passengerList);

          if (passengerList.some((p) => !p.passengeRefs)) {
            // console.log('passengers refs is missing')
            for (let pax of passengerList) {
              let paxType = null;
              if (pax.type) {
                if (pax.type.toLowerCase() === 'adult') {
                  paxType = 'ADT';
                } else if (pax.type.toLowerCase() === 'child') {
                  paxType = 'CHD';
                } else if (pax.type.toLowerCase() === 'infant') {
                  paxType = 'inf';
                }
              }

              for (let bundle of bundles) {
                // console.log('one bundle', bundle)
                for (let passengerInfo of bundle.passengers) {
                  // console.log('passenger info', passengerInfo)
                  if (
                    passengerInfo.passenger.type.indexOf(paxType) >= 0 &&
                    !passengerList.every(
                      (p) =>
                        p.nameRef && p.nameRef === passengerInfo.passengeRefs,
                    )
                  ) {
                    // console.log('add nameref')
                    pax.nameRef = passengerInfo.passengeRefs;
                  }
                }
              }
            }
          }

          // console.log('last passengers', passengerList);
          setPassengers(passengerList);
          setTrips([{ segments }]);
          setOpenSerivce(true);
        } else {
          enqueueSnackbar('DATA NOT FOUND', {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        }
      })
      .catch(function (error) {
        // console.error(error);
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setLoadingServices(false);
      });
  };

  // const handleSelectService = (bundles) => {
  //   setSelectedServices([...bundles]);
  // };

  const handleClickServicesNext = (step) => {
    if (typeof step === 'number') {
      if (step === bundlesCount - 1) {
        setShowServicesSubmit(true);
      }
      setServicesActiveStep(step);
    } else {
      if (servicesActiveStep >= bundlesCount - 1) {
        return;
      }

      setServicesActiveStep(servicesActiveStep + 1);
      if (servicesActiveStep + 1 === bundlesCount - 1) {
        setShowServicesSubmit(true);
      }
    }
  };

  const handleServicesSubmit = () => {
    setLoadingBookServices(true);
  };

  const handleBookServicesResponse = (response) => {
    setLoadingBookServices(false);
    if (response) {
      console.log('services response', response);
      navigate('/OrderDetail', { state: state });
    }
  };

  const handleCopyText = (name, text) => {
    console.log('copy text', text);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert('Text copied to clipboard');
        let findTooltip = openTooltips.find((t) => t.name === name);
        if (!findTooltip) {
          openTooltips.push({ name: name, status: true });
        } else {
          findTooltip.status = true;
        }

        setOpenTooltips([...openTooltips]);

        setTimeout(() => {
          let findTooltip = openTooltips.find((t) => t.name === name);
          if (!findTooltip) {
            openTooltips.push({ name: name, status: false });
          } else {
            findTooltip.status = false;
          }
          setOpenTooltips([...openTooltips]);
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <>
      {/* <Button onClick={()=>{
			navigate('/OrderDetail', {state: state})
		}}>Demo</Button> */}
      {loading && (
        <AppBar position="fixed">
          <LinearProgress />
        </AppBar>
      )}
      <ButtonAppBar />
      <div className="theme-page-section">
        <div className="container-fluid invoice-container">
          <div className="row align-items-center _pr-15 _pl-15">
            <div className="col-md-12">
              <h3>
                {intl
                  .get('SUCCESS.' + parseStatus(orderStatus))
                  .defaultMessage(orderStatus)}
              </h3>
            </div>

            <div className="col-md-12 order-status__body">
              <div className="order-status__text">
                {intl.get('SUCCESS.NAME') +
                  ' ' +
                  state.bookInfo.passengers[0].firstName +
                  ','}
                <br />
                <br />
                {
                  orderStatus === 'No Payment' ? (
                    <PaymentBox
                      paymentLink={
                        state.bookedRsp.airTicketBookingResponse.paymentLink
                      }
                    />
                  ) : (
                    orderStatus &&
                    intl
                      .get('SUCCESS.' + parseStatus(orderStatus) + '_MESSAGE')
                      .defaultMessage(orderStatus)
                  )
                  // <OtherBox orderStatus={orderStatus}/>
                }
              </div>
              <div className="order-status__action-controls">
                {/* <p className="text-left _mb-desk-10 _mt-desk-20"><a href="Booking-success.html"><span style={{fontSize: "21px"}}>« </span>{intl.get("SUCCESS.VIEW_ORDER")}</a></p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid invoice-container">
          <div className="row align-items-center _pr-15 _pl-15">
            <div className="col-md-12">
              <h6>{intl.get('SUCCESS.BOOKING_ID')}</h6>
            </div>
            <div className="col-md-12 theme-payment-success-box">
              <ul className="theme-payment-success-summary">
                <li>
                  <b>{intl.get('SUCCESS.BOOKING_PCC')}</b>
                  <span>
                    {state.order && convertGds(state.order.gds)
                      ? convertGds(state.order.gds) + '_'
                      : ''}
                    {state.order && state.order.pcc}
                    <Tooltip
                      title="Copied"
                      open={
                        openTooltips.find(
                          (t) => t.name === 'pcc',
                        )
                          ? openTooltips.find(
                              (t) => t.name === 'pcc',
                            ).status
                          : false
                      }
                      placement="right"
                    >
                    <IconButton aria-label="Example" onClick={()=>handleCopyText('pcc', state.order && state.order.pcc)}>
                <ContentCopyIcon fontSize='small' color='primary' />
                </IconButton>
                </Tooltip>
                  </span>
                </li>
                <li>
                  <b>{intl.get('SUCCESS.RECORD_LOCATOR')}</b>
                  <span>
                    {state.bookedRsp.airTicketBookingResponse.recordLocator}
                    <Tooltip
                      title="Copied"
                      open={
                        openTooltips.find(
                          (t) => t.name === 'recordLocator',
                        )
                          ? openTooltips.find(
                              (t) => t.name === 'recordLocator',
                            ).status
                          : false
                      }
                      placement="right"
                    >
                    <IconButton
                      aria-label="Example"
                      onClick={() =>
                        handleCopyText('recordLocator',
                          state.bookedRsp.airTicketBookingResponse
                            .recordLocator,
                        )
                      }
                    >
                      <ContentCopyIcon fontSize="small" color="primary" />
                    </IconButton>
                    </Tooltip>
                  </span>
                </li>
                <li>
                  <b>{intl.get('SUCCESS.AIRLINE_RECORD_LOCATOR')}</b>
                  <span>
                    {
                      state.bookedRsp.airTicketBookingResponse
                        .airlineRecordLocator
                    }
                    <Tooltip
                      title="Copied"
                      open={
                        openTooltips.find(
                          (t) => t.name === 'airlineRecordLocator',
                        )
                          ? openTooltips.find(
                              (t) => t.name === 'airlineRecordLocator',
                            ).status
                          : false
                      }
                      placement="right"
                    >
                      <IconButton
                        aria-label="Example"
                        onClick={() =>
                          handleCopyText(
                            'airlineRecordLocator',
                            state.bookedRsp.airTicketBookingResponse
                              .airlineRecordLocator,
                          )
                        }
                      >
                        <ContentCopyIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                  </span>
                </li>
                <li>
                  <b>{intl.get('SUCCESS.BOOK_DATE')}</b>
                  <span>
                    {moment(
                      state.orderTime ? state.orderTime : new Date(),
                    ).format('YYYY-MM-DD')}
                  </span>
                </li>
                <li>
                  <b>Invoice Number</b>
                  <Link
                    href={`http://invoicesys.gttglobal.com/InvoicePDFByNumber.aspx?num=${
                      state?.order?.invoiceNumber
                    }&date=${moment(
                      state.orderTime ? state.orderTime : new Date(),
                    ).format('YYYY-MM-DD')}&tktnum=${
                      state.bookInfo.passengers?.[0]?.ticketNumber
                    }`}
                    sx={{ float: 'right' }}
                    target="_blank"
                    underline="hover"
                  >
                    {state?.order?.invoiceNumber}
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="col-md-12 order-status__header text-sm-left"><small className="order-status__label">FLYG {intl.get("SUCCESS.BOOKING_ID")}:</small>
				<div className="order-status__info">
				<span className="order-status__state">{intl.get("SUCCESS.RECORD_LOCATOR")}: </span><b className="order-status__number">{state.bookedRsp.airTicketBookingResponse.recordLocator}</b>
				</div>
				<div className="order-status__info">
				<span className="order-status__state">{intl.get("SUCCESS.CONFIRMATION_CODE")}: </span><b className="order-status__number">{state.bookedRsp.airTicketBookingResponse.confirmationCode}</b>
				</div>
			</div> */}
            {/* <div className="col-md-12 order-status__header">
              <small className="order-status__label">
                {intl.get('SUCCESS.BOOKING_PCC')}:
              </small>
              <div className="order-status__info">
                <b className="order-status__number">
                  {state.order && state.order.pcc}
                </b>
              </div>
            </div> */}

            {/* <div className="col-md-12 order-status__header">
              <small className="order-status__label">
                {intl.get('SUCCESS.RECORD_LOCATOR')}:{' '}
              </small>
              <div className="order-status__info">
                <b className="order-status__number">
                  {state.bookedRsp.airTicketBookingResponse.recordLocator}
                </b>
              </div>
            </div> */}
            {/* <div className="col-md-12 order-status__header">
              <small className="order-status__label">
                {intl.get('SUCCESS.CONFIRMATION_CODE')}:{' '}
              </small>
              <div className="order-status__info">
                <b className="order-status__number">
                  {state.bookedRsp.airTicketBookingResponse.confirmationCode}
                </b>
              </div>
            </div> */}
            {/* <div className="col-md-12 order-status__header">
              <small className="order-status__label">
                {intl.get('SUCCESS.AIRLINE_RECORD_LOCATOR')}:{' '}
              </small>
              <div className="order-status__info">
                <b className="order-status__number">
                  {
                    state.bookedRsp.airTicketBookingResponse
                      .airlineRecordLocator
                  }
                </b>
              </div>
            </div> */}
            {/* <div className="col-md-12 order-status__header">
              <small className="order-status__label">
                {intl.get('SUCCESS.BOOK_DATE')}:{' '}
              </small>
              <div className="order-status__info">
                <b className="order-status__number">
                  {moment(
                    state.orderTime ? state.orderTime : new Date(),
                  ).format('YYYY-MM-DD')}
                </b>
              </div>
            </div> */}
            {/* <div className="col-md-12 order-status__body">
				<div className="order-status__text">
					{intl.get("SUCCESS.DESCRIPTION")}
        </div>
				<div className="order-status__action-controls"></div>
			</div> */}
          </div>
        </div>
        {/* <div className="container-fluid invoice-container">
          <div className="row align-items-center _pr-15 _pl-15">
            <div className="col-md-12">
              <LoadingButton
                loading={loadingSeatmap}
                onClick={handleClickSeats}
                variant="outlined"
              >
                {intl.get('SUCCESS.SEATMAP_SERVICE')}
              </LoadingButton>  
            </div>
          </div>
        </div> */}
        <Dialog
          className="dialog-seatMap"
          fullScreen
          open={openSeat}
          onClose={() => setOpenSeat(false)}
          aria-labelledby="responsive-dialog-title"
          // TransitionComponent={Transition}
        >
          <DialogTitle>Seat map</DialogTitle>
          <DialogContent>
            <Seats
              seatmaps={seatmaps}
              // onSelectedSeats={handleSelectedSeats}
              // selectedSeats={selectedSeats}
              currentStep={seatsActiveStep}
              onClickNext={handleClickSeatNext}
              travelers={travelers}
              loadingBook={loadingBookSeat}
              onSubmit={handleSetloadingBookSeat}
              requestParas={{
                cid: routing.cid,
                customerSessionId: 'S12345',
                gds: state.order.gds,
                pcc: state.order.pcc,
                pnr: state.bookedRsp.airTicketBookingResponse.recordLocator,
              }}
              onBookResponse={handleBookSeatResponse}
              shouldBook={true}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              onClick={() => setOpenSeat(false)}
            >
              Close
            </Button>
            {!showSeatSubmit && (
              <Button
                variant="contained"
                color="warning"
                onClick={handleClickSeatNext}
              >
                Next
              </Button>
            )}
            {showSeatSubmit && (
              // <Button
              //   variant="contained"
              //   onClick={handleSubmitSeat}
              //   autoFocus
              //   color="warning"
              // >
              //   Submit
              // </Button>
              <LoadingButton
                loading={loadingBookSeat}
                onClick={handleSubmitSeat}
                variant="contained"
              >
                Submit
              </LoadingButton>
            )}
          </DialogActions>
        </Dialog>
        {/* {IS_DirectNDCS.includes(state.source) &&
          routing.mainAirlineCode === 'EK' && (

          )} */}
        {/* <div className="container-fluid invoice-container">
          <div className="row align-items-center _pr-15 _pl-15">
            <div className="col-md-12">
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(`/reserveBundle`, {
                    state: {
                      routing: routing,
                      travelers: {
                        adults: location.state.bookInfo.adults,
                        children: location.state.bookInfo.children,
                      },
                      passengers: location.state.bookInfo.passengers,
                      source: location.state.source,
                      gds: location.state.order.gds,
                      pcc: location.state.order.pcc,
                      pnr: location.state.bookedRsp.airTicketBookingResponse
                        .recordLocator,
                      confirmationCode:
                        location.state.bookedRsp.airTicketBookingResponse
                          .confirmationCode,
                      successInfo: location.state,
                    },
                  });
                }}
              >
                Travel extras
              </Button>
            </div>
          </div>
        </div> */}
        <Dialog
          fullScreen
          open={openSerivce}
          // onClose={()=>setBundleOpen(false)}
          aria-labelledby="responsive-dialog-title"
          // TransitionComponent={Transition}
        >
          <DialogTitle>Travel add-ons</DialogTitle>
          <DialogContent>
            <Bundle
              routing={{ trips }}
              services={
                services &&
                services.length > 0 &&
                services.filter((item) => item.name !== 'FareLock')
              }
              // selectedBundles={selectedServices}
              // onSelectBundle={handleSelectService}
              passengers={passengers}
              currentStep={servicesActiveStep}
              onClickNext={handleClickServicesNext}
              loadingBook={loadingBookServices}
              onBookResponse={handleBookServicesResponse}
              requestParas={{
                cid: routing.cid,
                customerSessionId: 'S12345',
                gds: state.order.gds,
                pcc: state.order.pcc,
                pnr: state.bookedRsp.airTicketBookingResponse.recordLocator,
              }}
              shouldBook={true}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpenSerivce(false)}>
              Close
            </Button>
            {!showServicesSubmit && (
              <Button
                variant="contained"
                color="warning"
                onClick={handleClickServicesNext}
              >
                Next
              </Button>
            )}
            {showServicesSubmit && (
              <LoadingButton
                loading={loadingBookServices}
                variant="contained"
                onClick={handleServicesSubmit}
                autoFocus
                color="warning"
              >
                Submit
              </LoadingButton>
            )}
          </DialogActions>
        </Dialog>

        <div className="container-fluid invoice-container">
          {/* <div className="row  align-items-center _pr-15 _pl-15">
	<Button variant="outlined" sx={{float: 'right'}} onClick={handleCheckEMDAStatus}>Check EMDA</Button>
	</div> */}
          <div className="row  align-items-center _pr-15 _pl-15">
            {/* <div className="col-md-12">
			<h6>{intl.get("SUCCESS.TRAVELERS")}:</h6>
			</div> */}
            {state.bookInfo.passengers.map((v, i) => {
              return (
                <Traveler
                  key={i}
                  passenger={v}
                  progressing={progressing}
                  passengerInfos={passengerInfos}
                  progressingEMD={progressingEMD}
                  EMDTickets={EMDTickets}
                  loadingSeatmap={loadingSeatmap}
                  onClickSeatmap={handleClickSeats}
                  loadingServices={loadingServices}
                  onClickServices={handleClickServices}
                />
              );
            })}
            {/* <MySeatmap {...{cid: routing.cid, pnr: state.bookedRsp.airTicketBookingResponse.recordLocator, confirmationCode:state.bookedRsp.airTicketBookingResponse.confirmationCode, travelers: {adults: state.bookInfo.adults, children: state.bookInfo.children}, passengers: state.bookInfo.passengers}}/> */}
            {/* {!IS_DirectNDCS.includes(state.source) && (
              <div className="col-md-12">
                <Link
                  to="/seatmapdemo"
                  state={{
                    routing,
                    cid: routing.cid,
                    pnr: state.bookedRsp.airTicketBookingResponse.recordLocator,
                    confirmationCode:
                      state.bookedRsp.airTicketBookingResponse.confirmationCode,
                    travelers: {
                      adults: state.bookInfo.adults,
                      children: state.bookInfo.children,
                    },
                    passengers: state.bookInfo.passengers,
                    contact: state.bookInfo.contact,
                  }}
                >
                  Seats
                </Link>
              </div>
            )} */}
          </div>
        </div>

        <div className={'container-fluid invoice-container ' + style.payment}>
          <div
            className="row row-col-static row-col-mob-gap"
            id="sticky-parent"
            data-gutter="60"
          >
            <div className="col-md-12 _pt-mob-25">
              <div className="theme-payment-page-sections">
                <div
                  className={'theme-payment-page-sections-item ' + style.item}
                >
                  <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
                    <div className="row _br-3 _mv-10 " data-gutter="20">
                      <div className="col-md-12 ">
                        <div className="theme-search-results-item-flight-details-schedule">
                          <ul className="theme-search-results-item-flight-details-schedule-list">
                            <li>
                              <i className="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                              {routing.trips && (
                                <TripDetail routing={routing} hideBookButton />
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid invoice-container">
          <div className="row align-items-center">
            <div className="col-md-12 ">
              <OrderSummary
                displayBookButton={false}
                routing={routing}
                travelers={{
                  adults: state.bookInfo.adults,
                  children: state.bookInfo.children,
                }}
                currency={state.currency}
                displaySetMarkup={false}
                seats={state.seats}
                bundles={state.bundles}
              />
            </div>
          </div>
        </div>
        {orderStatus !== 'Cancelled' && (
          <div className="container-fluid invoice-container">
            <div className="row align-items-center">
              <div className="col-md-12 ">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    }
                    label="Cancel PNR?"
                  />
                  <Stack spacing={2}>
                    {orderStatus !== 'Cancelled' && (
                      <Button
                        variant="contained"
                        color="error"
                        disabled={!checked || disabled}
                        onClick={handleCancel}
                      >
                        Confirm to cancel
                      </Button>
                    )}
                    {orderStatus === 'No Payment' && (
                      <Button
                        variant="contained"
                        color="yellow"
                        onClick={handleClickPayment}
                      >
                        continue to payment
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate('/order')}
                    >
                      My orders
                    </Button>
                  </Stack>
                </FormGroup>
              </div>
            </div>
          </div>
        )}
        <BasicSpeedDial info={state} />
      </div>
    </>
  );
}
