import { Alert, Button, Stack, AppBar, LinearProgress, FormGroup, Backdrop, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OrderSummary from '../../components/OrderSummary';
import TripDetail from '../../components/TripDetail';
import 'moment/locale/zh-cn';
import intl from 'react-intl-universal';
import ProgressBox from '../../components/ProgressBox';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import Contact from '../../components/Contact';
import Traveler from '../../components/Traveler';
import PaymentDetail from '../../components/PaymentDetail';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ButtonAppBar from '../../components/ButtonAppBar';
import { loadCss } from '../../common/helper.js';
import { useSnackbar } from 'notistack';

export default function Payment() {
  const { key } = useParams();
  const navigate = useNavigate();
  // console.log("key", key);
  const [bookData, setBookData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const methods = useForm();
  const [travelers, setTravelers] = useState(null);
  // const [responseData, setResponseData] = useState(null)
  const [progress, setProgress] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [seats, setSeats] = useState(null)
  const [services, setServices] = useState(null)
  const [isCreditCard, setIsCreditCard] = useState(false)


  async function requestData(confirmationCode, cid){
    // fetch(`api/orders?caseNumber=${confirmationCode}&cid=${cid}&customerSessionId=${'S12345'}`)
    //     .then((response) => {
    //       if (response.ok) {
    //         return response.json();
    //       } else {
    //         return Promise.reject(response.statusText);
    //       }
    //     })
    //     .then((data) => {
    //       let apiResultJson = data.apiResult;
    //       let sqlResultJson = data.sqlResult;
    //       let seats = [];
    //       let services = [];
    //       if (apiResultJson.order && apiResultJson.order.passengers) {
    //         for (let passenger of apiResultJson.order.passengers) {
    //           if (passenger.seats) {
    //             for (let seat of passenger.seats) {
    //               seats.push({
    //                 segment: null,
    //                 seats: [
    //                   {
    //                     travelerPricing: [
    //                       {
    //                         price: { currency: 'USD', total: seat.TotalPrice },
    //                       },
    //                     ],
    //                   },
    //                 ],
    //               });
    //             }
    //           }

    //           if (passenger.services) {
    //             let bundles = [];
    //             for (let service of passenger.services) {
    //               bundles.push({ totalAmount: service.TotalPrice });
    //             }

    //             services.push({ bundles: bundles });
    //           }
    //         }
    //       }

    //       // console.log('services', services);
    //       // {segment: null, seats: [{travelerPricing:[{price: {currency: 'USD', total: }}]}]
    //       if (sqlResultJson == null) {
    //         let trips = [];
    //         let segments = [];
    //         for (let seg of apiResultJson.order.segments) {
    //           console.log('seg', seg);
    //           segments.push({
    //             departureTime: moment(seg.departureTime).format(
    //               'MM/DD/YYYY HH:mm',
    //             ),
    //             operateFlight: '',
    //             arrivalAirportCode: seg.arrivalAirportCode,
    //             // childFarebasis: "",
    //             codeShare: '',
    //             departureAirportCode: seg.departureAirportCode,
    //             baggageUnit: '',
    //             stop: 0,
    //             flightNumber: seg.flightNumber,
    //             adultFarebasis: '',
    //             duration: 0,
    //             airlineCode: seg.airlineCode,
    //             BookingClass: seg.bookingClass,
    //             baggagePieces: 0,
    //             isShare: false,
    //             arrivalTime: moment(seg.arrivalTime).format('MM/DD/YYYY HH:mm'),
    //             stopAirports: [],
    //             baggageAllowance: 0,
    //             aircraftCode: '',
    //             cabin:
    //               seg.cabin && seg.cabin != '' ? seg.cabin.substring(0, 1) : '',
    //           });
    //         }

    //         trips.push({
    //           duration: 0,
    //           segments: segments,
    //           tripTime: 0,
    //         });

    //         // console.log(trips)
    //         let findAdult = apiResultJson.order.price.find(
    //           (p) => p.passengerType == 'Adult',
    //         );
    //         let findChild = apiResultJson.order.price.find(
    //           (p) => p.passengerType == 'Child',
    //         );
    //         let adults = apiResultJson.order.passengers.filter(
    //           (p) => p.type == 'Adult',
    //         ).length;
    //         let children = apiResultJson.order.passengers.filter(
    //           (p) => p.type == 'Child',
    //         ).length;
    //         sqlResultJson = {
    //           routingText: JSON.stringify({
    //             totalPrice: apiResultJson.order.totalPrice,
    //             adultBasePrice: findAdult ? findAdult.baseFare : 0,
    //             adultTax: findAdult ? findAdult.tax : 0,
    //             childBasePrice: findChild ? findChild.baseFare : 0,
    //             childTax: findChild ? findChild.tax : 0,
    //             cid: apiResultJson.order.source,
    //             trips: trips,
    //           }),
    //           order: {
    //             adults: adults,
    //             children: children,
    //             currency: 'USD',
    //             shortLink: '',
    //           },
    //         };
    //       }

    //       navigate(`/success`, {
    //         state: {
    //           bookInfo: {
    //             routing: sqlResultJson.routingText,
    //             passengers: apiResultJson.order.passengers,
    //             adults: sqlResultJson && sqlResultJson.order.adults,
    //             children: sqlResultJson && sqlResultJson.order.children,
    //             openid: props.openid,
    //             contact: apiResultJson.order.contact,
    //           },
    //           bookedRsp: {
    //             airTicketBookingResponse: {
    //               recordLocator: props.recordLocator,
    //               confirmationCode: props.confirmationCode,
    //               paymentLink: sqlResultJson && sqlResultJson.order.shortLink,
    //               airlineRecordLocator: props.airlineRecordLocator || apiResultJson.order.airlineLocator,
    //             },
    //           },
    //           currency: sqlResultJson && sqlResultJson.order.currency,
    //           orderTime: props.bookingDate,
    //           orderStatus: parseStatus(props.opStatus, props.paymentStatus),
    //           seats: seats,
    //           bundles: services,
    //           order: { gds: props.gds, pcc: props.pcc },
    //           source: props.source,
    //         },
    //       });
    //     })
    //     .catch((error) => {

    //     });

    try
    {
      const response = await fetch(`/api/orders?caseNumber=${confirmationCode}&cid=${cid}&customerSessionId=${'S12345'}`)
      if(response.ok){
        return await response.json();
      }

      throw new Error('Network response was not ok')
    }catch(error){
      throw error
    }
  }

  async function loadData(){
    if (key) {
      fetch('../api/share?key=' + key)
        .then((response) => response.json())
        .then(async (data) => {
          const json = JSON.parse(data);
          console.log("data", json);
          if ('error' in json) {
            setErrorMsg('The payment has exceeded the limit time.');
            setLoaded(true);
            return;
          }

          let bookResponse = JSON.parse(json.bookRsp)
          let request = await requestData(bookResponse.airTicketBookingResponse.confirmationCode, json.bookReq.cid)
          console.log('request', request)
          let seats = [];
          let services = [];
          if (request.apiResult.order && request.apiResult.order.passengers) {
            for (let passenger of request.apiResult.order.passengers) {
              if (passenger.seats) {
                for (let seat of passenger.seats) {
                  seats.push({
                    segment: null,
                    seats: [
                      {
                        travelerPricing: [
                          {
                            price: { currency: 'USD', total: seat.TotalPrice },
                          },
                        ],
                      },
                    ],
                  });
                }
              }

              if (passenger.services) {
                let bundles = [];
                for (let service of passenger.services) {
                  bundles.push({ totalAmount: service.TotalPrice });
                }

                services.push({ bundles: bundles });
              }
            }
          }

          setSeats(seats)
          setServices(services)

          // console.log("json", json);
          // console.log(json.bookReq.routing);
          // setResponseData(JSON.parse(json.bookRsp))

          let bookInfo = {
            req: {
              routing: JSON.parse(json.bookReq.routing),
              travelers: {
                adults: json.bookReq.adults,
                children: json.bookReq.children,
              },
              currency: json.bookReq.currency,
              contact: json.bookReq.contact,
              passengers: json.bookReq.passengers,
              payment: json.bookReq.payment,
              userInfo: json.bookReq.userInfo,
              dateTime: json.bookReq.dateTime,
              domain: json.bookReq.domain,
              cid: json.bookReq.cid,
              openId: json.bookReq.openId,
              shareInfo: json.bookReq.shareInfo,
            },
            rsp: bookResponse,
          };
          let travelerList = [];
          let adultIndex = 0;
          let childrenIndex = 0;
          let infantIndex = 0;
          let index = 0;
          for (let passenger of json.bookReq.passengers) {
            // console.log('passenger', passenger);
            if (passenger.type == 'adult') {
              travelerList.push({
                key: 'travelerAdult' + adultIndex,
                index: index + 1,
                type: passenger.type,
                number: adultIndex,
                value: passenger,
                disabled: false,
              });
              adultIndex++;
            } else if (passenger.type == 'children') {
              travelerList.push({
                key: 'travelerChild' + childrenIndex,
                index: index + 1,
                type: passenger.type,
                number: childrenIndex,
                value: passenger,
                disabled: false,
              });
              childrenIndex++;
            } else {
              travelerList.push({
                key: 'travelerInfant' + infantIndex,
                index: index + 1,
                type: passenger.type,
                number: infantIndex,
                value: passenger,
                disabled: false,
              });
              infantIndex++;
            }

            index++;
          }

          console.log(bookInfo);
          setBookData(bookInfo);
          setTravelers(travelerList);
          // console.log("book info", bookInfo);
          setLoaded(true);
        })
        .catch((err) => {
          console.error(err);
          setErrorMsg(err.message);
          setLoaded(true);
        });
    } else {
      setErrorMsg('DATA NOT FOUND');
      setLoaded(true);
    }
  }
  useEffect(() => {

    setDisabled(true)
    // loadCss('/Flight-Hotel/vendor/bootstrap/css/bootstrap.min.css')
    loadCss(
      'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css',
    );
    // loadCss('/Flight-Hotel/vendor/font-awesome/css/all.min.css')
    loadCss('/Flight-Hotel/css/stylesheet.css');
    loadCss('/Flight-Hotel/css/styles.css');
    loadCss('/Flight-Hotel/css/FlightDetails.css');
    loadCss('/css/flag.css');
    loadData()
    
  }, []);

  const onSubmit = (data) => {
    // console.log("submit", data);
    // console.log('routing', bookData.req.routing)
    // return
    if(data.contact && data.contact.countryCode){
      data.contact.country = data.contact.countryCode
    }
    if (data.payment) {
      data.payment.expirationYear = moment(
        data.payment.expiration,
        'MM/DD/YYYY',
      ).format('YYYY');
      data.payment.expirationMonth = moment(
        data.payment.expiration,
        'MM/DD/YYYY',
      ).format('MM');
    } else if (data.PaymentMethod !== 'CK') {
      // alert('PAYMENT NOT FOUND')
      enqueueSnackbar('PAYMENT NOT FOUND', {
        variant: 'warning',
        autoHideDuration: 3000,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      return;
    }

    if(data.PaymentMethod === 'Wechat'){
      return
    }

    if (data.PaymentMethod === 'CK' && bookData.req.routing.mainAirlineCode !== 'SQ') {
      if (
        bookData &&
        bookData.req &&
        bookData.req.passengers &&
        (bookData.req.passengers.some(
          (p) => p.services != null && p.services.length > 0,
        ) ||
          bookData.req.passengers.some(
            (p) => p.seats != null && p.seats.length > 0,
          ))
      ) {
        enqueueSnackbar(
          'Ancillary services require credit card payment. Check payment is not allowed.',
          {
            variant: 'warning',
            autoHideDuration: 3000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
        return;
      }
    }

    bookData.req.payment = data.payment;
    // console.log('book data', bookData)

    let params = { ...bookData, paymentType: data.PaymentMethod !== 'CK' ? 0 : 2 };

    // console.log('request payment', params)
    // console.log('state', {
    //   bookInfo: {
    //     routing: JSON.stringify(bookData.req.routing),
    //     passengers: bookData.req.passengers,
    //     adults: bookData.req.travelers.adults,
    //     children: bookData.req.travelers.children,
    //   },
    //   bookedRsp: {
    //     airTicketBookingResponse: bookData.rsp.airTicketBookingResponse
    //   },
    //   currency: bookData.req.currency,
    //   orderTime: bookData.req.dateTime,
    //   orderStatus: 'Full Payment',
    //   seats: seats,
    //   bundles: services,
    //   source: bookData.req.cid,
    //   order: {
    //     gds: bookData.rsp.airTicketBookingResponse.gds,
    //     pcc: bookData.rsp.airTicketBookingResponse.pcc,
    //   },
    // })

    // return

    setProgress(true);
    setDisabled(true);
    axios
      .post('/api/payment', params)
      .then(function (res) {
        // console.log(res)
        let json = JSON.parse(res.data);
        if ('APIError' in json) {
          for (let error of json.APIError.errors) {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
            break;
          }
        } else {
          for (let passenger of bookData.req.passengers) {
            for (let passengerInfo of json.tktStatusResponse.passengerInfo) {
              if (
                passenger.firstName == passengerInfo.firstName &&
                passenger.lastName == passengerInfo.lastName
              ) {
                passenger.tktnumber = passengerInfo.tktnumber;
              }
            }
          }

          navigate(`/success`, {
            state: {
              bookInfo: {
                routing: JSON.stringify(bookData.req.routing),
                passengers: bookData.req.passengers,
                adults: bookData.req.travelers.adults,
                children: bookData.req.travelers.children,
              },
              bookedRsp: {
                airTicketBookingResponse: bookData.rsp.airTicketBookingResponse
              },
              currency: bookData.req.currency,
              orderTime: bookData.req.dateTime,
              orderStatus: 'Full Payment',
              seats: seats,
              bundles: services,
              source: bookData.req.cid,
              order: {
                gds: bookData.rsp.airTicketBookingResponse.gds,
                pcc: bookData.rsp.airTicketBookingResponse.pcc,
              },
            },
          });
        }
      })
      .catch(function (error) {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 6000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      })
      .then(function () {
        setProgress(false);
        setDisabled(false);
      });
  };

  const handleConfirmPayment =(paymentMethod, pay)=>{
    console.log('payment method', paymentMethod)
    setIsCreditCard(paymentMethod === 'CC')
    setDisabled(!pay)
  }

  return loaded ? (
    errorMsg ? (
      <Stack spacing={2}>
        <Alert severity="error">{errorMsg}</Alert>
      </Stack>
    ) : (
      <>
        {progress && (
          <AppBar position="fixed">
            <LinearProgress />
          </AppBar>
        )}
        <ButtonAppBar />
        <div className="theme-page-section">
          <div className="container-fluid invoice-container">
            <div className="row align-items-center _pr-15 _pl-15">
              <div className="col-md-12 order-status__header text-sm-left">
                <small className="order-status__label">
                  {intl.get('PAYMENT.BOOKING_ID')}:
                </small>
                <div className="order-status__info">
                  <b className="order-status__number">
                    {bookData.rsp.airTicketBookingResponse.recordLocator}
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid invoice-container">
            <div
              className="row row-col-static row-col-mob-gap"
              id="sticky-parent"
              data-gutter="60"
            >
              <div className="col-md-12 _pt-mob-25">
                <div className="theme-payment-page-sections">
                  <div className="theme-payment-page-sections-item ">
                    <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
                      <div className="row _br-3 _mv-10 " data-gutter="20">
                        <div className="col-md-12 ">
                          <div className="theme-search-results-item-flight-details-schedule">
                            <ul className="theme-search-results-item-flight-details-schedule-list">
                              <li>
                                <i className="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                                <TripDetail
                                  routing={bookData.req.routing}
                                  hideBookButton
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid invoice-container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <OrderSummary
                  displayBookButton={false}
                  routing={bookData.req.routing}
                  travelers={bookData.req.travelers}
                  currency={bookData.req.currency}
                  seats={seats}
                  bundles={services}
                />
              </div>
            </div>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="container-fluid invoice-container">
                <div className="row align-items-center">
                  <Contact
                    displaySame={false}
                    disabled={true}
                    contact={bookData.req.contact}
                    addressRequired = {isCreditCard}
                  />
                </div>
              </div>
              <div className="container-fluid invoice-container">
                <div className="row align-items-center">
                  {/* <Traveler key="travelerAdult0" index={1} type={"adult"} number={0} disabled={true}/> */}
                  {travelers.map((item) => {
                    return <Traveler {...item} />;
                  })}
                </div>
              </div>
              <div className="container-fluid invoice-container">
                <div className="row align-items-center">
                  <PaymentDetail
                    // displaySelect={true}
                    payment={bookData.req.payment}
                    displayPaymentButton={true}
                    info={bookData}
                    disabled={disabled}
                    onConfirmPayment = {handleConfirmPayment}
                  />
                </div>
              </div>
            
          
          <div className="container-fluid invoice-container">
            <div className="row align-items-center">
              <div className="col-md-12">
              <FormGroup>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    }
                    label="Cancel PNR?"
                  /> */}
                  <Stack spacing={2}>
                    {/* {orderStatus !== 'Cancelled' && (
                      <Button
                        variant="contained"
                        color="error"
                        disabled={!checked || disabled}
                        onClick={handleCancel}
                      >
                        Confirm to cancel
                      </Button>
                    )} */}
                    {/* {orderStatus === 'No Payment' && (
                      <Button
                        variant="contained"
                        color="yellow"
                        onClick={handleClickPayment}
                      >
                        continue to payment
                      </Button>
                    )} */}

                  <Button type="submit" color='yellow' variant="contained" disabled={disabled}>{intl.get("PAYMENT.CONFIRM_PAYMENT")}</Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={()=>navigate('/order')}
                    >
                      My orders
                    </Button>
                  </Stack>
                </FormGroup>
              </div>
            </div>
          </div>
          </form>
          </FormProvider>
        </div>
      </>
    )
  ) : (
    // <ProgressBox />
    <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={!loaded}
  // onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop>
  );
}
