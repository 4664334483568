import React, { useEffect } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';
import { useLocation, useNavigate } from 'react-router-dom';
// import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
// import { useForm, FormProvider, useFormContext } from "react-hook-form";
import EventSeatIcon from '@mui/icons-material/EventSeat';
import moment from 'moment/moment';
import axios from 'axios';
import {
  Alert,
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  Paper,
  Box,
  CircularProgress,
  Skeleton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
// import Seatmaps from '../../components/Seatmaps';
import Contact from '../../components/Contact';
// import SeatDemo from '../../components/SeatDemo';
import SeatMapDialog from '../../components/SeatMapDialog';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import ButtonAppBar from '../../components/ButtonAppBar';

import { loadCss } from '../../common/helper.js';
// import { isInclude } from '../../common/helper.js';
// import { removeCss } from '../../common/helper.js';
import seatmapHelper from '../../utils/seatmapHelper.js'
import {deepPureEqual} from '../../utils/utils.js'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function convertToTime(totalMinutes) {
  let minutes = parseInt(totalMinutes);
  let day = Math.floor(minutes / 60 / 24);
  let hour = Math.floor((minutes / 60) % 24);
  let minute = Math.floor(minutes % 60);

  return intl.get('FLIGHT.TIME', { day, hour, minute });
}


function Segment(props) {
  // console.log("segment", props)
  // const [open, setOpen] = useState(false);
  const [selectSeats, setSelectedSeats] = useState([]);
  // let carryOn = props.baggagePieces
  // let checkedBaggage = props.baggageAllowance;
  // let baggageEnable = false
  // let baggageUnit = props.baggageUnit

  // if(props.baggagePieces > 0 || props.baggageAllowance > 0){
  // 	baggageEnable = true
  // }

  // const handleClose = () => {
  //   setSelectedSeats([]);
  //   props.onSelectSeats({ seatmaps: props.seatmap, selectSeats: [] });
  //   setOpen(false);
  // };

  // const handleSubmit = () => {
  //   props.onSelectSeats({ seatmaps: props.seatmap, selectSeats });
  //   setOpen(false);
  // };

  // const addSeat = (seat) => {
  //   setSelectedSeats([...selectSeats, seat]);
  //   // console.log('add seat', seat)
  //   // let index = selectSeats.findIndex(item=>item.id === id)
  //   // if(index < 0){
  //   //   setSelectedSeats([...selectSeats, {id: id, seats: [seat]}])
  //   // }else{
  //   //   selectSeats[index].seats = [...selectSeats[index].seats, seat]
  //   //   setSelectedSeats([...selectSeats])
  //   // }
  // };

  // const removeSeat = (seatNumber) => {
  //   setSelectedSeats(selectSeats.filter((item) => item.number !== seatNumber));
  //   // console.log('remove', id, seatNumber);
  //   // let index = selectSeats.findIndex(item=>item.id === id)
  //   // if(index >= 0){
  //   //   selectSeats[index].seats = selectSeats[index].seats.filter(item=>item.number !== seatNumber)
  //   //   setSelectedSeats([...selectSeats])
  //   // }
  // };

  return (
    <div className="leg__segment segment">
      <div className="segment__date">
        <span>{moment(props.departDatetime).format('ddd MMM Do')}</span>
        {props.departDatetime.substring(0, 10) ===
        props.arrivalDatetime.substring(0, 10) ? (
          ''
        ) : (
          <span className="segment__date-landing text-danger">
            {/* {intl.get('FLIGHT.LANDS')}{' '} */}
            {/* {moment(props.arrivalDatetime).format('ddd MMM Do')}{' '} */}
          </span>
        )}
      </div>
      <div className="segment__info">
        <div className="segment__options">
          <div className="segment__flight-options">
            <div className="segment__logo">
              <img
                src={
                  'https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir' +
                  props.marketingAirline +
                  '.gif'
                }
                alt={props.marketingAirline}
              />
            </div>
            <span className="segment__airline">
              {intl
                .get('AIRLINE.' + props.marketingAirline)
                .defaultMessage(props.marketingAirline)}
            </span>
            <span className="segment__flight-nr">
              {intl.get('FLIGHT.FLIGHT_NUMBER')} {props.marketingAirline}{' '}
              {props.flightNumber}
            </span>
            {/* <span className="segment__cabin">{intl.get('CABIN.' + props.cabin).defaultMessage(props.cabin)}</span> */}
            {!props.operatingAirline ? null : (
              <span className="segment__Operatedby">
                {intl.get('FLIGHT.OPERATED_BY', {
                  airlineName: intl.get('AIRLINE.' + props.operatingAirline),
                })}
              </span>
            )}
            {/* {props.aircraftCode === "TRS" && <DirectionsTransitIcon/>} */}
          </div>
          <div className="segment__eta">
            {/* <i className="fas fa-clock"></i>
                        <span className="segment__eta-value">{convertToTime(props.duration)}</span> */}
          </div>
        </div>

        <div className="segment__itinerary segment-itinerary">
          <div className="segment-itinerary__outbound">
            <span className="segment-itinerary__time">
              {props.departDatetime.substring(11, 16)}
            </span>
            <span className="segment-itinerary__location">
              {intl
                .get('AIRPORT.' + props.origin + '.AIRPORTNAME')
                .defaultMessage(props.origin)}
              <span className="segment-itinerary__iata">({props.origin})</span>
            </span>
          </div>
          <div className="segment-itinerary__inbound">
            <span className="segment-itinerary__time">
              {props.arrivalDatetime.substring(11, 16)}
            </span>
            <span className="segment-itinerary__location">
              {intl
                .get('AIRPORT.' + props.destination + '.AIRPORTNAME')
                .defaultMessage(props.destination)}
              <span className="segment-itinerary__iata">
                ({props.destination})
              </span>
            </span>
          </div>
        </div>
        <div className="segment__footer">
          <div className="segment__operator">
            {selectSeats.length > 0 && (
              <span>
                {intl.get('SEATMAP.SELECTED_SEATMAPS')}:{' '}
                {selectSeats.map((item) => item.number).join(', ')}
              </span>
            )}
          </div>
          <div
            className="segment__amenities amenities"
            data-toggle="modal"
            data-target="#tooltip-modal"
            data-segment-title={props.origin + '-' + props.destination}
            data-id="amenities"
          >
            {/* <a onClick={()=>setOpen(true)} style={{cursor: 'pointer'}}>{intl.get('SEATMAP.SEATMAPS_CLICK')}<EventSeatIcon color='primary'/></a> */}
            {/* <Dialog
	fullScreen
	open={open}
	onClose={()=>setOpen(false)}
	aria-labelledby="responsive-dialog-title"
	// TransitionComponent={Transition}
  >
	<DialogTitle>
	  {intl.get("SEATMAP.SEATMAP")}
	</DialogTitle>
	<DialogContent>
		<Seatmaps deck={props.seatmap.deck} travelers={props.seatmap.TravelerIdentifiers} addSeat={addSeat} removeSeat={removeSeat}
        selectSeats={selectSeats}
        />
	</DialogContent>
		<DialogActions>
		<Button autoFocus onClick={handleClose}>
		  Close
		</Button>
		<Button onClick={handleSubmit} autoFocus color='warning'>
		  Submit
		</Button>
	  </DialogActions>
	  </Dialog> */}

            {/* <i className={"fa fa-suitcase suitcase" + (baggageEnable ? "" : " suitcaseDisable" )}></i>
						<i className="amenities__arrow hif fa fa-angle-down"></i>
						<div className="amenities-tooltip amenities-tooltip--new info-tooltip">
							<div className="amenities-tooltip__item amenity-row amenity-row--new ">
								<div className="amenity-row__header">
									<div className="amenity-row__icn icn">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd" d="M9 11V4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V11H16C17.1046 11 18 11.8954 18 13V19C18 20.1046 17.1046 21 16 21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21H10C10 21.5523 9.55229 22 9 22C8.44771 22 8 21.5523 8 21C6.89543 21 6 20.1046 6 19V13C6 11.8954 6.89543 11 8 11H9ZM14 4H10V11H14V4Z" fill="white"></path>
										</svg>
									</div>

									<div className="amenity-row__text">	<div className="amenity-row__description">{intl.get("FLIGHT.CARRY_ON")} </div></div>
									<div className="amenity-row__allowance"><span className="amenity-row__value">{intl.get("FLIGHT.BAGGAGE", {baggage: carryOn})}</span>	</div>
								</div>
								<div className="amenity-row__footer"></div>
							</div>

							<div className="amenities-tooltip__item amenity-row--new amenity-row amenity-row--new ">
								<div className="amenity-row__header">
									<div className="amenity-row__icn icn">
										<svg width="24" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd" d="M4 1V4H2.33333C1.04467 4 0 5.03319 0 6.30769V17.6923C0 18.9668 1.04467 20 2.33333 20H3C3 20.5523 3.44771 21 4 21C4.55229 21 5 20.5523 5 20H9C9 20.5523 9.44772 21 10 21C10.5523 21 11 20.5523 11 20H11.6667C12.9553 20 14 18.9668 14 17.6923V6.30769C14 5.03319 12.9553 4 11.6667 4H10V1C10 0.447715 9.55228 0 9 0H5C4.44772 0 4 0.447715 4 1ZM9 2H5V4H9V2Z" fill="white"></path>
										</svg>
									</div>

									<div className="amenity-row__text">	<div className="amenity-row__description">{intl.get("FLIGHT.CHECKED_BAG")} </div></div>
									<div className="amenity-row__allowance"><span className="amenity-row__value">{intl.get("FLIGHT.BAGGAGE", {baggage: checkedBaggage})}{baggageUnit}</span>	</div>
								</div>
								<div className="amenity-row__footer"></div>
							</div>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function Layover(props) {
  let totalMinutes = moment(props.departureTime).diff(
    moment(props.arrivalTime),
    'minutes',
  );
  let transfer =
    props.departureTime.substring(0, 10) === props.arrivalTime.substring(0, 10)
      ? 0
      : 1;

  // console.log("layover", props, props.departureTime.substring(0,10), props.arrivalTime.substring(0,10), transfer)
  return (
    <div className="leg__layover layover">
      <div className="layover__description">
        <div className="layover__item layover__duration">
          <i className="far fa-clock layover__icn"></i>
          <span>
            {convertToTime(totalMinutes)}
            <span>
              <span className="text-danger">
                {intl.get('FLIGHT.SEGMENT_CONNECTION', {
                  hours: totalMinutes / 60 > 3 ? 1 : 0,
                  airportName: intl
                    .get('AIRPORT.' + props.airportCode + '.AIRPORTNAME')
                    .defaultMessage(props.airlineCode),
                  airportCode: props.airportCode,
                  transfer,
                })}
              </span>
            </span>
          </span>
        </div>
        <div className="layover__item layover__note">
          <span className="trip-detailed__layover-location"></span>
        </div>
      </div>
    </div>
  );
}

function SegmentBound(props) {
  // console.log("segment bound", props)
  let outbound = false;

  let segments = [];
  let departure = null;
  let arrival = null;
  for (let i = 0, len = props.FlightSegments.length; i < len; i++) {
    if (i === 0) {
      departure = props.FlightSegments[i].origin;
    }

    arrival = props.FlightSegments[i].destination;

    segments.push(
      <Segment
        key={props.CatalogOfferingIdentifierValue + i}
        {...props.FlightSegments[i]}
        seatmap={props}
        onSelectSeats={props.onSelectSeats}
      />,
    );
    if (i !==  len - 1) {
      segments.push(
        <Layover
          key={i + 'layover'}
          departureTime={props.FlightSegments[i + 1].departDatetime}
          arrivalTime={props.FlightSegments[i].arrivalDatetime}
          airportCode={arrival}
        />,
      );
    }
  }

  return (
    <div className="row border mb-2">
      {/* <div className="col-lg-1 align-items-center text-center company-info pt-2">
		<div className="custom-control custom-radio custom-control-inline mr-0 mt-3">
			<input name="departure-flight" className="custom-control-input" type="radio" />
			<label className="custom-control-label" htmlFor="departure-flight1"></label>
		</div>
	</div> */}

      <div className="col-lg-12 col-sm-auto text-sm-left trip-details__leg leg">
        <div className="leg__heading leg-heading">
          <div className="leg-heading__itinerary">
            <div className="leg-heading__label">
              {/* {outbound ? intl.get('FLIGHT.OUTBOUND') : intl.get('FLIGHT.INBOUND')}: */}
            </div>
            {intl
              .get('AIRPORT.' + departure + '.CITYNAME')
              .defaultMessage(departure)}{' '}
            -{' '}
            {intl
              .get('AIRPORT.' + arrival + '.CITYNAME')
              .defaultMessage(arrival)}{' '}
          </div>
          <div className="leg-heading__eta">
            {/* {convertToTime(props.tripTime)} */}
          </div>
          <div className="leg-heading__icn">
            <i className={'fa fa-plane' + (outbound ? '' : ' Inflight')}></i>
          </div>
        </div>

        {segments}
      </div>
    </div>
  );
}

function TripDetail(props) {
  // console.log(props)
  return (
    <div className="trip-details">
      {props.seatmaps &&
        props.seatmaps.seatMapResponse.seatMapResponses.map(
          (seatmap, index) => {
            return (
              <SegmentBound
                key={'segment-bound' + index}
                {...seatmap}
                onSelectSeats={props.onSelectSeats}
              />
            );
          },
        )}
    </div>
  );
}

function Traveler(props) {
  // console.log('traveler', props);
  const [checkedSeats, setCheckedSeats] = useState([])


  const handleClickCheck = (flight)=>{
    // console.log('click flight', flight)
    // console.log('click flights', checkedSeats)
    
    let newCheckedSeats = [...checkedSeats]
    let findIndex = newCheckedSeats.findIndex(s=>s.id === flight.segments[0].origin + flight.segments[0].destination)
    let checked
    if(findIndex >= 0){
      checked = !newCheckedSeats[findIndex].checked
      newCheckedSeats[findIndex].checked = checked
      
    }else{
      newCheckedSeats.push({id: flight.segments[0].origin + flight.segments[0].destination, checked: true})
      checked = true
    }
    
    // console.log('new checked seats', checked, newCheckedSeats)
    // return
    setCheckedSeats(newCheckedSeats)
    props.onHandleDeleteSeat(props.passengerKey, flight, checked)
  }

  return (
    
      <div className='mt-3'>
      <legend className="block-fieldset__legend">
        {props.index}. {props.traveler.name}
        <span className="paxes-fieldset__type">
          &nbsp;(
          {intl.get(
            'TRAVELER.' +
              (props.traveler.passengerTypeCode.toUpperCase() === 'ADT'
                ? 'ADULT'
                : 'CHILD'),
          )}
          )
        </span>
      </legend>
      <Stack spacing={2}>
        {props.flights.map((flight) => (
          <Item
            key={flight.segments[0].origin + flight.segments[0].destination}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="h6" display="block" gutterBottom>
                  {flight.segments[0].origin +
                    '-' +
                    flight.segments[0].destination}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="button" display="block" gutterBottom>
                  {flight.areadySelectedSeat.number}
                  {flight.areadySelectedSeat.number && ' (booked)'}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {flight.areadySelectedSeat.seat &&
                    flight.areadySelectedSeat.seat.OfferItems.find(
                      (item) => item.PassengerRef === props.passengerKey,
                    ).TotalPrice.toLocaleString('en', {
                      style: 'currency', 
                      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="button" display="block" gutterBottom>
                  {flight.selectedSeat && flight.selectedSeat.number}
                  {flight.selectedSeat &&
                    flight.selectedSeat.number &&
                    flight.areadySelectedSeat.number &&
                    ' (updating)'}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {flight.selectedSeat &&
                    '$' +
                      flight.selectedSeat.seat.OfferItems.find(
                        (item) => item.PassengerRef === props.passengerKey,
                      ).TotalPrice}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {flight.areadySelectedSeat.number &&
              <FormControlLabel
            control={
              <Checkbox checked={checkedSeats.find(s=>s.id===flight.segments[0].origin + flight.segments[0].destination)
               ? checkedSeats.find(s=>s.id===flight.segments[0].origin + flight.segments[0].destination).checked : false
              } onChange={()=>handleClickCheck(flight)} />
            }
            label="Delete"
          />
          }

              </Grid>
            </Grid>
          </Item>
        ))}
      </Stack>
      </div>

    
  );
}

function OrderSummary(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  // const [displayPay, setDisplayPay] = useState(false)

  const goback = () => {
    let data = props.successInfo;
    if (props.isSuccess) {
      data.seats = props.seats;
    }

    navigate('/success', { state: data });
  };

  const HandleNavigate = () => {
    let totalPrice = props.seats
      .map((seat) =>
        seat.seats.reduce((prev, curr) => {
          return prev + curr.travelerPricing[0].price.total;
        }, 0),
      )
      .reduce((x, y) => x + y, 0);

    // if(totalPrice <= 0){
    // 	enqueueSnackbar("What you order is free!", {variant: 'warning',  autoHideDuration: 6000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
    // 	return
    // }
    navigate('/checkout', {
      state: {
        confirmationCode: props.confirmationCode,
        recordLocator: props.recordLocator,
        cid: props.cid,
        totalPrice: totalPrice,
      },
    });
  };

  const gotoPay = () => {
    if (props.mainAirlineCode === 'EK') {
      setProgress(true);
      axios
        .get(`api/pnrs?pcc=${props.pcc}&recordLocator=${props.recordLocator}`)
        .then(function (res) {
          // console.log(res.data)
          if (
            res.data &&
            res.data.PnrInfo &&
            res.data.PnrInfo.GTTBookedServiceInfos
          ) {
            for (let serviceInfo of res.data.PnrInfo.GTTBookedServiceInfos) {
              if (
                serviceInfo.ServiceDetails.some(
                  (item) => item.ServiceStatus === 'HN',
                )
              ) {
                enqueueSnackbar(
                  'The service status is HN, please wait airline to confime the service.',
                  {
                    variant: 'warning',
                    autoHideDuration: 3000,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                  },
                );
                return;
              }
            }
          }

          HandleNavigate();
        })
        .catch(function (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        })
        .then(function () {
          setProgress(false);
        });
    } else {
      HandleNavigate();
    }
  };

  // console.log('order summary', props)
  let seats = [];
  for (let seatmap of props.seats) {
    for (let seat of seatmap.seats) {
      seats.push({
        number: seat.number,
        price: seat.travelerPricing[0].price.total,
      });
    }
  }
  return (
    <div className="sticky-col">
      <div className="theme-sidebar-section _mb-10">
        <h5 className="theme-sidebar-section-title">
          {intl.get('BOOK.ORDER_SUMMARY')}
        </h5>
        <div className="theme-sidebar-section-charges">
          <ul className="theme-sidebar-section-charges-list">
            {/* <li className="theme-sidebar-section-charges-item">
            <h5 className="theme-sidebar-section-charges-item-title">{intl.get("SUCCESS.SEATMAP_SERVICE")}</h5>
            <p className="theme-sidebar-section-charges-item-subtitle"></p>
            {seats.map((item, index)=> <p key={index} className="theme-sidebar-section-charges-item-subtitle">{item.number + " ($"+item.price+")"}</p>)}
            <p className="theme-sidebar-section-charges-item-price _mt-10">{intl.get('FLIGHT.SELLING_PRICE', {price: (props.routing.adultBasePrice*1) + (props.routing.adultTax) *1 })}</p>
            <p className="theme-sidebar-section-charges-item-price _mt-10">{intl.get('PRICE', {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.currency), price: seats.reduce((prev, cure)=>prev + cure.price, 0) })}</p>
          </li> */}

            {props.seats && props.seats.length > 0 && (
              <li className="theme-sidebar-section-charges-item">
                <h5 className="theme-sidebar-section-charges-item-title">
                  Seat
                </h5>
                <p className="theme-sidebar-section-charges-item-subtitle">
                  Total price for{' '}
                  {props.seats
                    .map((item) => item.seats.length)
                    .reduce((x, y) => x + y)}{' '}
                  seats
                </p>
                <p className="theme-sidebar-section-charges-item-price _mt-10">
                  {intl.get('PRICE', {
                    currency: intl
                      .get(
                        'CURRENCY.' +
                          props.seats[0].seats[0].travelerPricing[0].price
                            .currency +
                          '.SYMBOL',
                      )
                      .defaultMessage(
                        props.seats[0].seats[0].travelerPricing[0].price
                          .currency,
                      ),
                    price: props.seats
                      .map((seat) =>
                        seat.seats.reduce((prev, curr) => {
                          return prev + curr.travelerPricing[0].price.total;
                        }, 0),
                      )
                      .reduce((x, y) => x + y, 0),
                  })}
                </p>
              </li>
            )}
          </ul>
          {/* <p className="theme-sidebar-section-charges-total">{intl.get("BOOK.ORDER_TOTAL")}<span>{intl.get('PRICE', 
        {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.currency), price: seats.reduce((prev, cure)=>prev + cure.price, 0)})}</span></p> */}
        </div>
      </div>
      <div className="btn-wrapper">
        <Button
          type="submit"
          size="large"
          variant="contained"
          fullWidth
          disabled={props.disabled}
        >
          {intl.get('BOOK.ORDER_BOOK')}
        </Button>
      </div>
      <div className="btn-wrapper">
        <Button
          variant="contained"
          color="warning"
          onClick={goback}
          disabled={props.disabled}
        >
          Goback
        </Button>
      </div>
      {progress && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {props.displayPay && !progress && (
        <div className="btn-wrapper">
          <Button variant="contained" color="primary" onClick={gotoPay}>
            Pay
          </Button>
        </div>
      )}
      {/* {props.isSuccess && <div className='btn-wrapper'><Button variant="contained" color='primary' onClick={gotoPay}>Pay</Button></div>} */}
    </div>
  );
}

function TravellersSeats(props) {

  return <div>
    {props.travelers.map((item, index) => (
      <Traveler
        key={item.passengerKey}
        index={index + 1}
        {...item}
        onHandleDeleteSeat={props.handleClickeDeleteSeat}
      />
    ))}
  </div>
}

export default function SeatmapDemo() {
  const location = useLocation();
  // console.log('seatmapdemo', location);
  const [progress, setProgress] = useState(false);
  const methods = useForm();
  const [seatmaps, setSeatmaps] = useState(null);
  const [travelers, setTravelers] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(false);
  const [submitSeats, setSubmitSeats] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayPay, setDisplayPay] = useState(false);
  const [requesting, setRequesting] = useState(false)
  const [deleteSeats, setDeleteSeats] = useState([])
  // const [deck, setDecks] = useState(null)


  useEffect(() => {
    loadCss(
      'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css',
    );
    loadCss('/Flight-Hotel/css/stylesheet.css');
    loadCss('/Flight-Hotel/css/styles.css');
    loadCss('/css/seatmap.css');

    handleRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    // console.log('submit', submitSeats, deleteSeats, travelers)
    setProgress(true);
    setAlerts([]);
    setDisabled(true);

    if ((submitSeats && submitSeats.length > 0) || deleteSeats.length>0) {
      let passengers = [];
      for (let travelerDetail of travelers) {
        let names = travelerDetail.traveler.name.split(' ');
        let firstName = '';
        let middleName = '';
        let lastName = '';
        if (names.length === 3) {
          firstName = names[2];
          middleName = names[1];
          lastName = names[0];
        } else if (names.length === 2) {
          firstName = names[1];
          lastName = names[0];
        } else {
          firstName = travelerDetail.traveler.name;
        }

        let seats = [];
        for (let flight of travelerDetail.flights) {
          let deleted = deleteSeats.findIndex(s=>s.travelerId === travelerDetail.passengerKey && deepPureEqual(s.flight, flight)) >= 0
          if (deleted) {
            seats.push({
              shoppingResponseId: seatmaps.seatMapResponse.shoppingResponseID,
              offerId: seatmaps.seatMapResponse.offerID,
              owner: seatmaps.seatMapResponse.owner,
              // offerItemRef: '',
              passengerRef: travelerDetail.passengerKey,
              // rowNumber: '',
              // seatNumber: '',
              // baseFare: offerItem.BaseFare,
              // tax: offerItem.Tax,
              // totalPrice: offerItem.TotalPrice,
              flightId: flight.segments[0].FlightID,
              airline: flight.segments[0].marketingAirline,
              flightNumber: flight.segments[0].flightNumber,
              oldOrderItemId:
                flight.areadySelectedSeat &&
                flight.areadySelectedSeat.orderItemId
                  ? flight.areadySelectedSeat.orderItemId
                  : '',
            });
          } else {
            if (flight.selectedSeat && flight.selectedSeat.number) {
              const offerItem = flight.selectedSeat.seat.OfferItems.find(
                (item) => item.PassengerRef === travelerDetail.passengerKey,
              );

              seats.push({
                shoppingResponseId: seatmaps.seatMapResponse.shoppingResponseID,
                offerId: seatmaps.seatMapResponse.offerID,
                owner: seatmaps.seatMapResponse.owner,
                offerItemRef: offerItem.OfferItemRef,
                passengerRef: offerItem.PassengerRef,
                rowNumber: flight.selectedSeat.number.substring(
                      0,
                      flight.selectedSeat.number.length - 1,
                    ),
                seatNumber: flight.selectedSeat.number.substring(
                      flight.selectedSeat.number.length - 1,
                    ),
                baseFare: offerItem.BaseFare,
                tax: offerItem.Tax,
                totalPrice: offerItem.TotalPrice,
                flightId: flight.segments[0].FlightID,
                airline: flight.segments[0].marketingAirline,
                flightNumber: flight.segments[0].flightNumber,
                oldOrderItemId:
                  flight.areadySelectedSeat &&
                  flight.areadySelectedSeat.orderItemId
                    ? flight.areadySelectedSeat.orderItemId
                    : '',
              });
            }
          }
        }
        passengers.push({
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          seats: seats,
        });
      }

      // axios.post('api/reserveseat', {cid: location.state.cid, customerSessionld: "S12345", gds: location.state.order.gds,
      // pcc: location.state.order.pcc, pnr: location.state.pnr, passenger: JSON.stringify(passengers)})
      // console.log({
      //   cid: location.state.cid,
      //   customerSessionId: 'S12345',
      //   gds: location.state.order.gds,
      //   pcc: location.state.order.pcc,
      //   pnr: location.state.pnr,
      //   passengers: JSON.stringify({ passengers: passengers }),
      // })
      // return
      axios
        .post('api/reserveseat', {
          cid: location.state.cid,
          customerSessionId: 'S12345',
          gds: location.state.order.gds,
          pcc: location.state.order.pcc,
          pnr: location.state.pnr,
          passengers: JSON.stringify({ passengers: passengers }),
        })
        .then(function (res) {
          // console.log('seatmaps book', res, JSON.parse(res.data))
          const resJson = JSON.parse(res.data);
          let responses = [];
          if (resJson.reserveSeatResponse.isSuccess) {
            responses.push({
              message: 'success',
              severity: 'success',
              id: '1',
              seatNumber: '',
            });
            setIsSuccess(true);

            setDisplayPay(true);
            setSelectedSeats([])
            handleRequest()
            // checkStatus()
          } else {
            responses.push({
              message: resJson.reserveSeatResponse.errorMessage,
              severity: 'error',
              id: '1',
              seatNumber: '',
            });
          }

          setAlerts(responses);
        })
        .catch(function (error) {
          console.error(error);
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        })
        .then(function () {
          setProgress(false);
          setDisabled(false);
        });
    } else {
      if (
        selectedSeats.length === 0 ||
        selectedSeats.findIndex((item) => item.selectSeats.length > 0) < 0 || deleteSeats.length < 0
      ) {
        enqueueSnackbar('Please select seats', {
          variant: 'warning',
          autoHideDuration: 3000,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
        // return;
      }

      setProgress(false);
      setAlerts([]);
      setDisabled(false);
      // let params = {
      //   seatmapToSelectedSeats: selectedSeats,
      //   pcc: '1ED5',
      //   pnr: location.state.pnr,
      //   confirmationCode: location.state.confirmationCode,
      //   payment: data.payment || null,
      // };
      // // console.log('params', params)

      // axios
      //   .post('api/seatmaps', params)
      //   .then(function (res) {
      //     // console.log('seatmaps book', res, JSON.parse(res.data))
      //     let json = JSON.parse(res.data);
      //     let responses = [];
      //     for (let v of json) {
      //       if (v.rsp) {
      //         responses.push({
      //           message: v.rsp,
      //           severity: 'error',
      //           id: v.id,
      //           seatNumber: v.seatNumber,
      //         });
      //       } else {
      //         responses.push({
      //           message: 'success',
      //           severity: 'success',
      //           id: v.id,
      //           seatNumber: v.seatNumber,
      //         });
      //         setDisplayPay(true);
      //       }
      //     }

      //     setAlerts(responses);
      //   })
      //   .catch(function (error) {
      //     enqueueSnackbar(error.message, {
      //       variant: 'error',
      //       autoHideDuration: 6000,
      //       anchorOrigin: { horizontal: 'center', vertical: 'top' },
      //     });
      //   })
      //   .then(function () {
      //     setProgress(false);
      //     setDisabled(false);
      //   });
    }
  };

  // useEffect(()=>{
  //   checkStatus()
  // },[])

  const SearchSeat = (seatNumber, seatMapDetails) => {
    let seat = null;
    if (seatNumber && seatMapDetails) {
      let rowNum = seatNumber.substring(0, seatNumber.length - 1);
      let columnNum = seatNumber.substring(seatNumber.length - 1);
      for (let seatMapDetail of seatMapDetails) {
        for (let airRow of seatMapDetail.airRows) {
          for (let airSeat of airRow.airSeats) {
            if (airRow.rowNumber === rowNum && airSeat.seatNumber === columnNum) {
              seat = airSeat;
            }
          }
        }
      }
    }

    return seat;
  };

  const handleRequest = () => {
    setProgress(true);
    setRequesting(true)
    setSeatmaps(null);
    setTravelers([])
    if (location.state.order) {
      let params = {
        byOrder: true,
        cid: location.state.cid,
        gds: location.state.order.gds,
        pcc: location.state.order.pcc,
        pnr: location.state.pnr,
        customerSessionId: 'S12345',
      };

      // let params = {byOrder: true, cid: location.state.cid, gds: "F", pcc: "A8V6", pnr: "LUPFAX",
      //   customerSessionId: "S12345"
      // }

      axios
        .post('api/seats', params)
        .then(function (res) {
          // let json = JSON.parse(res.data);
          let json = res.data;
          // console.log('seat map json', {...json});
          if ('APIError' in json) {
            for (let error of json.APIError.errors) {
              // alert(error.message)
              enqueueSnackbar(error.message, {
                variant: 'error',
                autoHideDuration: 6000,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
              });
              break;
            }
            return;
          }

          seatmapHelper.process(json)
          // console.log('json', json)

          setSeatmaps(json);

          let travelerList = [];
          for (let flight of json.seatMapResponse.seatMapResponses) {
            for (let travelerIdentifier of flight.TravelerIdentifiers) {
              let seat = SearchSeat(
                travelerIdentifier.AreadySelectedSeatNumber,
                flight.SeatMapDetails,
              );

              let travelerFlight = {
                segments: flight.FlightSegments,
                areadySelectedSeat: {
                  number: travelerIdentifier.AreadySelectedSeatNumber,
                  orderItemId: travelerIdentifier.AreadySelectedSeatOrderItemId,
                  seat: seat,
                },
                selectedSeat: null,
              };

              let travelerIndex = travelerList.findIndex(
                (item) => item.passengerKey === travelerIdentifier.PassengerKey,
              );
              if (travelerIndex < 0) {
                let traveler = {
                  passengerKey: travelerIdentifier.PassengerKey,
                  traveler: {
                    passengerTypeCode: travelerIdentifier.PassengerTypeCode,
                    id: travelerIdentifier.ID,
                    name: travelerIdentifier.Name,
                  },
                  flights: [travelerFlight],
                };

                travelerList.push(traveler);
              } else {
                travelerList[travelerIndex].flights.push(travelerFlight);
              }
            }
          }

          setTravelers(travelerList);

          let selectedSeats= []

          json.seatMapResponse.seatMapResponses.forEach((seatmap, index) => {
            selectedSeats[index] = {
              index: index,
              seats: []
            }

            seatmap.TravelerIdentifiers.forEach((travelerIdentifier, tIndex) => {
              if (!travelerIdentifier.AreadySelectedSeatNumber) return;

              const seat = SearchSeat(
                travelerIdentifier.AreadySelectedSeatNumber,
                seatmap.SeatMapDetails,
              );

              const travelerPricing = []
              travelerPricing.push({
                id: '1',
                seatAvailabilityStatus: seat.seatAvailabilityStatus.toUpperCase(),
                price: {
                  currency: seat.OfferItems[tIndex].CurrencyCode,
                  total: seat.OfferItems[tIndex].TotalPrice,
                },
              })
              selectedSeats[index].seats.push({
                number: travelerIdentifier.AreadySelectedSeatNumber,
                travelerPricing,
                selectedSeat: {
                  number: travelerIdentifier.AreadySelectedSeatNumber,
                  seat
                }
              })
            })
          })

          console.log(selectedSeats);
          setSelectedSeats(selectedSeats)
        })
        .catch(function (error) {
          console.error(error);
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 6000,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
        })
        .then(function () {
          setProgress(false);
          setRequesting(false)
        });
    } else {
      axios.interceptors.response.use(
        function (response) {
          let config = response.config;
          // 2xx 范围内的状态码都会触发该函数。
          // 对响应数据做点什么
          if (!config || !config.retry) return response;

          // 设置变量以跟踪重试次数
          config.__retryCount = config.__retryCount || 0;

          // 判断是否超过总重试次数
          if (config.__retryCount >= config.retry) {
            // 返回错误并退出自动重试
            return response;
          }

          // 增加重试次数
          config.__retryCount += 1;

          //打印当前重试次数
          console.log(config.url + ' retry ' + config.__retryCount + 'times');

          //     // 创建新的Promise
          if (response.data.indexOf('*UNABLE TO PROCESS*') >= 0) {
            var backoff = new Promise(function (resolve) {
              setTimeout(function () {
                resolve();
              }, config.retryDelay || 1);
            });

            // 返回重试请求
            return backoff.then(function () {
              return axios(config);
            });
          } else {
            return response;
          }
        },
        function (error) {
          // 超出 2xx 范围的状态码都会触发该函数。
          // 对响应错误做点什么
          return Promise.reject(error);
        },
      );

      axios
        .get(
          `api/seatmaps?cid=${location.state.cid}&pcc=1ED5&pnr=${location.state.pnr}`,
          {
            // axios.get(`api/seatmaps?cid=flygndc&pcc=1ED5&pnr=3B41S2`, {
            retry: 1,
            retryDelay: 5000,
          },
        )
        // axios.get(`api/seatmaps?cid=flygndc&pcc=1ED5&pnr=37JF0D`)
        .then(function (res) {
          const json = JSON.parse(res.data);
          // console.log('seat map json', json);
          //   setSeatmaps(json)
          if ('APIError' in json) {
            alert(json.APIError.errors[0].message);
            return;
          }
          // let procDecks = [];
          let seatNum = [];
          for (let flight of json) {
            for (let seatMapDetail of flight.SeatMapDetails) {
              for (let airRow of seatMapDetail.airRows) {
                for (let airSeat of airRow.airSeats) {
                  let alphabet = airSeat.seatNumber.substring(
                    airSeat.seatNumber.length - 1,
                  );
                  if (seatNum.indexOf(alphabet) < 0) {
                    seatNum.push(alphabet);
                  }
                }
              }
            }
          }

          seatNum.sort();

          for (let flight of json) {
            for (let seatMapDetail of flight.SeatMapDetails) {
              for (let airRow of seatMapDetail.airRows) {
                for (let i = 0; i < airRow.airSeats.length; i++) {
                  let alphabet = airRow.airSeats[i].seatNumber.substring(
                    airRow.airSeats[i].seatNumber.length - 1,
                  );
                  if (i > 0) {
                    if (
                      airRow.airSeats[i].seatCharacteristics &&
                      airRow.airSeats[i].seatCharacteristics
                        .split(',')
                        .indexOf('A') >= 0 &&
                      airRow.airSeats[i - 1].seatCharacteristics &&
                      airRow.airSeats[i - 1].seatCharacteristics
                        .split(',')
                        .indexOf('A') >= 0
                    ) {
                      if (
                        seatNum.indexOf(alphabet) - 1 >= 0 &&
                        seatNum[seatNum.indexOf(alphabet) - 1] !==  'x'
                      )
                        seatNum.splice(seatNum.indexOf(alphabet), 0, 'x');
                    }
                  }
                }
              }
            }
          }

          for (let flight of json) {
            let deck = {
              deckConfiguration: {
                width: 7,
                length: 30,
                startseatRow: 14,
                endSeatRow: 38,
                startWingsRow: 4,
                endWingsRow: 14,
                startWingsX: 1,
                endWingsX: 11,
                exitRowsX: [0, 0],
              },
              facilities: [],
              seats: [],
              id: flight.CatalogOfferingIdentifierValue,
            };

            let x = 1;
            for (let i = 0; i < flight.SeatMapDetails.length; i++) {
              if (i > 0) {
                x +=
                  parseInt(flight.SeatMapDetails[i].StartRowNumber) -
                  parseInt(flight.SeatMapDetails[i - 1].EndRowNumber) -
                  1;
              }

              for (let airRow of flight.SeatMapDetails[i].airRows) {
                for (let j = 0; j < airRow.airSeats.length; j++) {
                  let seat = {
                    cabin: 'ECONOMY',
                    number: airRow.airSeats[j].seatNumber,
                    characteristicsCodes: airRow.airSeats[j].seatCharacteristics
                      ? airRow.airSeats[j].seatCharacteristics.split(',')
                      : [],
                    travelerPricing: [
                      {
                        id: '1',
                        seatAvailabilityStatus: airRow.airSeats[j]
                          .seatAvailabilityStatus
                          ? airRow.airSeats[
                              j
                            ].seatAvailabilityStatus.toUpperCase()
                          : airRow.airSeats[j].seatAvailabilityStatus,
                        price: {
                          currency: airRow.airSeats[j].CurrencyCode,
                          total: airRow.airSeats[j].TotalPrice,
                        },
                      },
                    ],
                    coordinates: {
                      x: x,
                      y: seatNum.indexOf(
                        airRow.airSeats[j].seatNumber.substring(
                          airRow.airSeats[j].seatNumber.length - 1,
                        ),
                      ),
                    },
                    selected: flight.TravelerIdentifiers.find(
                      (travelerIdentifier) =>
                        travelerIdentifier.AreadySelectedSeatNumber ===
                        airRow.airSeats[j].seatNumber,
                    )
                      ? true
                      : false,
                  };

                  deck.seats.push(seat);
                }

                x++;
              }
            }

            deck.deckConfiguration.width =
              Math.max.apply(
                Math,
                deck.seats.map((item) => item.coordinates.y),
              ) + 1;
            deck.deckConfiguration.length =
              deck.seats[deck.seats.length - 1].coordinates.x;
            // deck.segments = flight.FlightSegments
            // deck.travelers = flight.TravelerIdentifiers
            // procDecks.push(deck)
            // break
            flight.deck = deck;
          }

          setSeatmaps(json);
          // setTravelers(flight.TravelerIdentifiers);
          //   setDecks(procDecks)
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
        })
        .then(function () {
          setProgress(false);
        });
    }
  };

  const handleSelectSeats = (data) => {
    console.log('get select seats', selectedSeats, data)
    let index = selectedSeats.findIndex(
      (item) =>
        item.seatmaps.CatalogOfferingIdentifierValue ===
        data.seatmaps.CatalogOfferingIdentifierValue,
    );
    if (index >= 0) {
      selectedSeats[index] = data;
      // console.log('>=0', selectedSeats[index])

      setSelectedSeats([...selectedSeats]);
    } else {
      setSelectedSeats([...selectedSeats, data]);
    }
  };

  const handleSelectedSeats = (seats) => {
    // console.log('selected seats', seats)
    setSelectedSeats([...seats]);
  };

  const handleCloseSeat = () => {
    setOpen(false);
  };

  const handleSubmitSeat = () => {
    // console.log('submit seat', selectedSeats)
    setOpen(false);
    let submitSeats = [];
    for (let selectedSeat of selectedSeats) {
      if (selectedSeat.seats.length > 0) {
        let seat = {
          segment:
            seatmaps.seatMapResponse.seatMapResponses[selectedSeat.index]
              .FlightSegments[0],
          seats: selectedSeat.seats,
        };

        submitSeats.push(seat);
      }
    }
    setSubmitSeats(submitSeats);
    for (let i = 0; i < travelers.length; i++) {
      let flights = travelers[i].flights;
      for (let j = 0; j < flights.length; j++) {
        let findSeat = selectedSeats.find((item) => item.index === j);
        if (findSeat && findSeat.seats.length > 0) {
          for (let k = 0; k < findSeat.seats.length; k++) {
            if (i === k) {
              travelers[i].flights[j].selectedSeat = {
                number: findSeat.seats[k].number,
                orderItemId: null,
                seat: SearchSeat(
                  findSeat.seats[k].number,
                  seatmaps.seatMapResponse.seatMapResponses[j].SeatMapDetails,
                ),
              };
            }
          }
        }
      }
    }

    setTravelers([...travelers]);
  };

  const handleClickeDeleteSeat = (travelerId, flight, checked)=>{
    // console.log(travelerId, flight, checked)
    let newDeleteSeats = [...deleteSeats]
    let findIndex = newDeleteSeats.findIndex(s=>s.travelerId === travelerId && deepPureEqual(s.flight, flight))
    // console.log('find', findIndex, newDeleteSeats)
    if(checked){
      if(findIndex < 0){
        newDeleteSeats.push({travelerId, flight})
      }
    }else{
      if(findIndex>=0){
        newDeleteSeats.splice(findIndex,1)
      }
    }

    setDeleteSeats(newDeleteSeats)

    // console.log('delete seats', newDeleteSeats)
  }

  return (
    <>
      <ButtonAppBar />
      <div className="theme-page-section theme-page-section-lg _mt-desk-30">
        <div className="container">
          {progress ? (
            <AppBar position="fixed">
              <LinearProgress />
            </AppBar>
          ) : null}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                className="row row-col-static row-col-mob-gap"
                id="sticky-parent"
                data-gutter="60"
              >
                <div className="col-md-8 _pt-mob-25 _pb-mob-25">
                  <div className="theme-payment-page-sections">
                    <div className="theme-payment-page-sections-item">
                    {requesting ? <Skeleton variant="rectangular" width='100%' height='15rem' /> :
                      <div className="theme-search-results-item theme-payment-page-item-thumb _p-desk-15 ">
                        <div className="row _br-3 _mv-10 " data-gutter="20">
                          <div className="col-md-12 ">
                            <div className="theme-search-results-item-flight-details-schedule">
                              <ul className="theme-search-results-item-flight-details-schedule-list">
                                <li>
                                  <i className="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                                  <TripDetail
                                    seatmaps={seatmaps}
                                    onSelectSeats={handleSelectSeats}
                                  />
                                  {/* <TripDetail routing={location.state.routing} hideBookButton={true} hideFareRules={true}/> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {seatmaps && (
                          <div className="row">
                            <div className="col-md-12">
                              <Button
                                sx={{ width: '100%' }}
                                onClick={() => setOpen(true)}
                                variant="outlined"
                              >
                                {intl.get('SEATMAP.SEATMAPS_CLICK')}
                                <EventSeatIcon color="primary" />
                              </Button>
                              <Dialog
                                className='dialog-seatMap'
                                fullScreen
                                open={open}
                                onClose={() => setOpen(false)}
                                aria-labelledby="responsive-dialog-title"
                                // TransitionComponent={Transition}
                              >
                                <DialogTitle>
                                  {intl.get('SEATMAP.SEATMAP')}
                                </DialogTitle>
                                <DialogContent>
                                  <SeatMapDialog
                                    seats={
                                      seatmaps &&
                                      seatmaps.seatMapResponse.seatMapResponses
                                    }
                                    travellers={travelers}
                                    onSelectedSeats={handleSelectedSeats}
                                    selectedSeats={selectedSeats}
                                  >
                                    
                                  </SeatMapDialog>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCloseSeat}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={handleSubmitSeat}
                                    autoFocus
                                    color="yellow"
                                  >
                                    Submit
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        )}
                      </div>
                    }
                    </div>
                    {/* {console.log('selectedSeats', selectedSeats, selectedSeats.findIndex(item=>item.selectSeats.findIndex(i=>i.travelerPricing[0].price.total>0)>=0))} */}
                    {/* demo:{selectedSeats.findIndex(item=>item.selectedSeats.findIndex(seat=>seat.travelerPricing[0].price.total>0)>=0)} */}
                    {/* {console.log('selected seats', selectedSeats)} */}
                    {/* {selectedSeats.findIndex(item=>item.selectSeats.findIndex(i=>i.travelerPricing[0].price.total>0)>=0) >= 0 && <PaymentDetail displaySelect={false} payment={null} displayPaymentButton={false} info={null}/>} */}
                    {/* {traverlers.map((item, index)=><Traveler key={item.PassengerKey} index={index+1} name={item.Name} type={item.PassengerTypeCode =="ADT" ? "ADULT" : "CHILD"} />)} */}
                    {requesting ? <Skeleton variant="rectangular" width='100%' height='15rem' /> :
                    (
                      <div className="theme-payment-page-sections-item _p-20">
                        <h3 className="theme-payment-page-sections-item-title">
                          <i className="fa fa-walking pwluggage _pr-10 _r"></i>
                          {intl.get('BOOK.TRAVELER')}
                        </h3>
                        {
                          travelers.map((item, index) => (
                            <Traveler
                              key={item.passengerKey}
                              index={index + 1}
                              {...item}
                              onHandleDeleteSeat={handleClickeDeleteSeat}
                            />
                          ))
                        }
                      </div>
                    )
                    }

                    {location.state.contact && (
                      <Contact
                        displaySame={false}
                        disabled={true}
                        contact={location.state.contact}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-4 ">
                  <OrderSummary
                    currency={'USD'}
                    seats={submitSeats}
                    disabled={disabled}
                    isSuccess={isSuccess}
                    successInfo={location.state.successInfo}
                    confirmationCode={location.state.confirmationCode}
                    recordLocator={location.state.pnr}
                    cid={location.state.cid}
                    displayPay={displayPay}
                    // progress = {statusProgress}
                    mainAirlineCode={location.state?.routing?.mainAirlineCode}
                    pcc={location.state.order.pcc}
                  />
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    {alerts.map((item) => (
                      <Alert
                        key={item.id + item.seatNumber}
                        severity={item.severity}
                      >
                        {(item.seatNumber ? item.seatNumber + ', ' : '') +
                          item.message}
                      </Alert>
                    ))}
                  </Stack>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
}
