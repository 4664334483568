import React, { Fragment, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Enumerable from 'linq';
import intl from 'react-intl-universal';
import OwlCarousel from 'react-owl-carousel';
import Loading from '../../components/Loading';
import TripDetail from '../../components/TripDetail';
import LinearProgress from '@mui/material/LinearProgress';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  Tabs,
  Tab,
  TextField,
  Slider,
  Autocomplete,
  Snackbar,
  Alert,
  Button,
  Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Search from '../Search';
import PubSub from 'pubsub-js';
import Checkbox from '@mui/material/Checkbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getURLParam } from '../../common/helper.js';
import { loadCss } from '../../common/helper.js';
import { loadScript } from '../../common/helper.js';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ButtonAppBar from '../../components/ButtonAppBar';
import { IS_NDCS } from '../../data/config.js';
import { generateRandomString } from '../../utils/utils.js';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

// if (process.env.NODE_ENV === 'development'){
// 	require('../../mock/index')
// }

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function FlightType(props) {
  return props.isOneway ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-arrow-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-arrow-left-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
      />
    </svg>
  );
}

function ShopResultsTitle(props) {
  // const [filterAltOff, setFilterAltOff] = useState(false)
  // console.log("show results ticket", props);
  let origin = null;
  let destination = null;
  let departureDate = null;
  let arrivalDate = null;
  if (props.flight) {
    const flights = props.flight.split(';');
    for (let i = 0; i < flights.length; i++) {
      let params = flights[i].split('/');
      if (i === 0) {
        origin = params[0];
        departureDate = params[2];
      } else {
        arrivalDate = params[2];
      }

      destination = params[1];
      if (params.length >= 4) {
        arrivalDate = params[3];
      }
    }
  }

  // console.log(origin, destination, departureDate, arrivalDate)

  function handleClick(showType) {
    props.changeShowType(showType);
  }

  const handleChange = (e) => {
    props.filterNDC(e.target.checked);
  };

  const handleFilter = () => {
    // setFilterAltOff(!filterAltOff)
    props.handleFilter(!props.showFilter);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        {origin != null
          ? intl.get('AIRPORT.' + origin.toUpperCase() + '.CITYNAME')
          : ''}
        &nbsp;
        <FlightType isOneway={props.type === 'oneway'} /> &nbsp;
        {destination != null
          ? intl.get('AIRPORT.' + destination.toUpperCase() + '.CITYNAME')
          : ''}
        <IconButton
          color="primary"
          aria-label="Edit"
          onClick={() => handleClick(true)}
        >
          <EditIcon />
        </IconButton>
      </Typography>
      <Typography variant="body1" gutterBottom>
        {departureDate != null
          ? moment(departureDate).format('MMM Do YYYY')
          : ''}
        {arrivalDate === null
          ? ''
          : ' - ' + moment(arrivalDate).format('MMM Do YYYY')}
        ,{props.adults + props.children + ' ' + intl.get('FLIGHT.TRAVELERS')},
        {props.cabin === ''
          ? intl.get('CABIN.E')
          : intl.get('CABIN.' + (props.cabin || 'E').toUpperCase())}
        {/* , Ndc only
        <Checkbox onChange={handleChange} /> */}
        <IconButton color="primary" aria-label="filter" onClick={handleFilter}>
          {!props.showFilter ? <FilterAltIcon /> : <FilterAltOffIcon />}
        </IconButton>
      </Typography>
    </Box>
  );
}

function Item(props) {
  // console.log('item', props);
  let stop = 0;
  let brandName = '';
  let hasNDC = false;

  let types = [];
  let titles = [];
  for (let value of props.values) {
    for (let trip of value.trips) {
      if (trip.segments.length - 1 > stop) {
        stop = trip.segments.length - 1;
      }
    }

    if (value.brand && value.brand.brands) {
      for (let brand of value.brand.brands) {
        brandName = brand.name;
      }
    }

    if (IS_NDCS.includes(value.cid)) {
      hasNDC = true;
    }

    if (value.cid === 'gtttour' && types.indexOf('T') < 0) {
      types.push('T');
      titles.push('T=' + intl.get('COMMON.TICKET_TYPE.TOUR'));
    }

    if (value.isStudent && types.indexOf('S') < 0) {
      types.push('S');
      titles.push('S=' + intl.get('COMMON.TICKET_TYPE.STUDENT'));
    }

    if (value.isYouth && types.indexOf('Y') < 0) {
      types.push('Y');
      titles.push('Y=' + intl.get('COMMON.TICKET_TYPE.YOUTH'));
    }
  }

  const handleClick = (data) => {
    // console.log('pub', data);
    PubSub.publish('owlItem' + props.airlineCode, data);

    props.onSelect(props.itemId);
  };

  return (
    <div
      className="item"
      onClick={() =>
        handleClick({
          values: props.values,
          id: props.sellingPrice,
          fullData: props.fullData,
          itemId: props.itemId,
        })
      }
    >
      <div className={props.selected ? `selected airsubnav` : 'airsubnav'}>
        <button
          className="airsubnavbtn"
          style={{ paddingTop: '2px' }}
          alt="airlines 1"
        >
          <div className="airsubnavbtn-price">
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: 'left',
                  whiteSpace: 'pre',
                  fontSize: 'small',
                  fontWeight: '500',
                }}
              >
                <span>{brandName || ' '}</span>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: 'right',
                  fontSize: 'small',
                  fontWeight: '500',
                }}
              >
                <span>{hasNDC && 'NDC'}</span>
                {/* <BootstrapTooltip title={titles.join(";")}> */}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        {titles.join(';')}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span>{types.join('&')}</span>
                </HtmlTooltip>
              </Grid>
            </Grid>
            <span className="markup">
              {props.sellingPrice.toLocaleString('en', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              {/* {intl.get('PRICE', {
                currency: intl
                  .get('CURRENCY.' + props.currency + '.SYMBOL')
                  .defaultMessage(props.currency),
                price: props.sellingPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 4
                }),
              })} */}
            </span>
            <div className="fromDepart">
              <span>{props.departTime}</span>
              <span>
                {intl
                  .get('AIRPORT.' + props.depart + '.AIRPORTNAME')
                  .defaultMessage(props.depart)}
              </span>
            </div>
            {/* <div className="fromDepart">{intl.get('FLIGHT.FROM')} {intl.get("AIRPORT." + props.depart).defaultMessage(props.depart)} {intl.get("FLIGHT.AT")} {props.departTime}</div> */}
          </div>
          <div className="Direct">
            <div>{intl.get('FLIGHT.STOP', { stop })}</div>
            <div>
              <i className="fa fa-suitcase suitcase"></i>
            </div>
            <div style={{ lineHeight: '15px' }}>
              ({props.option} {intl.get('FLIGHT.OPTION')})
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

// function ShowItem(props){
// 	const [isShow, setIsShow] = useState(false)
// 	const [flights, setFlights] = useState([])
// 	const [history, setHistory] = useState(null)
// 	const [baggage, setBaggage] = useState({})
// 	const [fullData, setFullData] = useState(null)

// }

function ListItem(props) {
  const [selectedId, setSelectedId] = useState('');

  function selectItem(itemId) {
    setSelectedId(itemId);
  }

  return (
    <Fragment>
      {props.list.map((priceGroup) => {
        return (
          <Item
            selected={selectedId === priceGroup.itemId}
            key={priceGroup.key}
            itemId={priceGroup.itemId}
            values={priceGroup.value}
            airlineCode={props.airlineCode}
            sellingPrice={priceGroup.key}
            depart={
              priceGroup.value[0].trips[0].segments[0].departureAirportCode
            }
            departTime={moment(
              priceGroup.value[0].trips[0].segments[0].departureTime,
              'MM/DD/YYYY HH:mm',
            ).format('hh:mm A')}
            option={priceGroup.value.length}
            currency={props.currency}
            fullData={props.items.filter((item) => !IS_NDCS.includes(item.cid))}
            onSelect={selectItem}
          />
        );
      })}
    </Fragment>
  );
}

function LoadingSkeleton() {
  const loadingBox = [];

  for (let i = 0; i < 3; i++) {
    loadingBox.push(
      <Grid container spacing={2} alignItems="center" my={2} key={i}>
        <Grid item xs={3}>
          <Skeleton variant="rounded" animation="wave" height={40} />
        </Grid>
        <Grid item xs={9}>
          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="rounded" animation="wave" height={200} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" animation="wave" height={200} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" animation="wave" height={200} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>,
    );
  }

  return <div>{loadingBox}</div>;
}

class ShowItem extends React.Component {
  constructor(props) {
    super(props);
    // console.log("card", props);

    this.state = {
      isShow: false,
      flights: [],
      history: null,
      baggage: {},
      fullData: null,
    };
  }

  componentDidMount() {
    let that = this;
    this.sub = PubSub.subscribe(
      'owlItem' + this.props.airlineCode,
      function (msg, data) {
        // console.log('owl item sub', msg, data);
        // console.log('start history', that.state.history);
        that.setState({ flights: data.values, fullData: data.fullData });

        if (that.state.history === data.id) {
          that.setState({ isShow: !that.state.isShow });
        } else {
          that.setState({ isShow: true });
        }
        that.setState({ history: data.id });
        // console.log('end history', that.state.history);
      },
    );

    this.mySub = PubSub.subscribe('baggage', function (msg, data) {
      // console.log('sub', msg, data)
      that.setState({ baggage: data });
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.sub);
    PubSub.unsubscribe(this.mySub);
  }

  handleClickAwayShow() {
    this.setState({ isShow: false });
    this.setState({ history: '' });
  }

  render() {
    return (
      this.state.isShow && (
        <ClickAwayListener onClickAway={() => this.handleClickAwayShow()}>
          <div className="card-body">
            {this.state.flights.map((item) => {
              return (
                <TripDetail
                  key={item.key}
                  hideBookButton={false}
                  travelers={this.props.travelers}
                  routing={item}
                  currency={this.props.currency}
                  fullData={
                    IS_NDCS.includes(item.cid) ? this.state.fullData : null
                  }
                />
              );
            })}
            <div
              className="modal amenity-modal fade"
              tabIndex="-1"
              role="dialog"
              id="tooltip-modal"
              style={{ display: 'none', zIndex: 1050 }}
              data-max-modal=""
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <div className="modal-header__title">New York City-Taipei</div> */}
                    <button
                      className="modal-header__close"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="amenities-tooltip__item amenity-row amenity-row--new ">
                      <div className="amenity-row__header">
                        <div className="amenity-row__icn icn">
                          <i className="fas fa-suitcase-rolling"></i>
                          {/* <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9 11V4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V11H16C17.1046 11 18 11.8954 18 13V19C18 20.1046 17.1046 21 16 21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21H10C10 21.5523 9.55229 22 9 22C8.44771 22 8 21.5523 8 21C6.89543 21 6 20.1046 6 19V13C6 11.8954 6.89543 11 8 11H9ZM14 4H10V11H14V4Z"
                              fill="black"
                            ></path>
                          </svg> */}
                        </div>
                        <div className="amenity-row__text">
                          {' '}
                          <div className="amenity-row__description">
                            {intl.get('FLIGHT.CARRY_ON')}{' '}
                          </div>
                        </div>
                        <div className="amenity-row__allowance">
                          <span className="amenity-row__value">
                            {/* {intl.get("FLIGHT.BAGGAGE", {baggage: this.state.baggage.carryOn})} */}
                            {this.state.baggage.carryOn || ' not allowed'}
                          </span>
                        </div>
                      </div>

                      <div className="amenity-row__footer"></div>
                    </div>
                    <div className="amenities-tooltip__item amenity-row--new amenity-row amenity-row--new ">
                      <div className="amenity-row__header">
                        <div className="amenity-row__icn icn">
                          <i className="fa fa-suitcase"></i>
                          {/* <svg
                            width="24"
                            height="21"
                            viewBox="0 0 14 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4 1V4H2.33333C1.04467 4 0 5.03319 0 6.30769V17.6923C0 18.9668 1.04467 20 2.33333 20H3C3 20.5523 3.44771 21 4 21C4.55229 21 5 20.5523 5 20H9C9 20.5523 9.44772 21 10 21C10.5523 21 11 20.5523 11 20H11.6667C12.9553 20 14 18.9668 14 17.6923V6.30769C14 5.03319 12.9553 4 11.6667 4H10V1C10 0.447715 9.55228 0 9 0H5C4.44772 0 4 0.447715 4 1ZM9 2H5V4H9V2Z"
                              fill="black"
                            ></path>
                          </svg> */}
                        </div>
                        <div className="amenity-row__text">
                          {' '}
                          <div className="amenity-row__description">
                            {intl.get('FLIGHT.CHECKED_BAG')}{' '}
                          </div>
                        </div>
                        <div className="amenity-row__allowance">
                          <span className="amenity-row__value">
                            {/* {intl.get("FLIGHT.BAGGAGE", {baggage: this.state.baggage.checkedBaggage})}{this.state.baggage.baggageUnit} */}
                            {this.state.baggage.checkedBaggage ||
                              ' not allowed'}
                          </span>
                        </div>
                      </div>

                      {/* <div className="amenity-row__footer">
											<div className="amenity-row__notes-row"><div className="amenity-row__note">•Up to 50 pounds/23 kilograms</div></div>
											<div className="amenity-row__notes-row"><div className="amenity-row__note">•Up to 62 linear inches/158 linear centimeters</div></div>
										</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )
    );
  }
}

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardId: generateRandomString(10),
    };
  }

  componentDidMount() {
    // let that = this;
    // this.mySub = PubSub.subscribe(
    //   'owlItem' + this.props.airlineCode,
    //   function (msg, data) {
    //     // console.log('sub', msg, data);
    //     // that.setState({ selectedId: data.itemId });
    //   },
    // );
    // this.owlItemSub = PubSub.subscribe('owlItem', function(msg, data){
    // 	console.log('owl item sub', msg, data);
    // })
  }

  componentWillUnmount() {
    // PubSub.unsubscribe(this.mySub);
    // PubSub.unsubscribe(this.owlItemSub)
  }

  options = {
    margin: 15,
    // responsiveClass: true,
    responsiveRefreshRate: 300,
    // responsiveBaseElement: '.container>.row>div',
    responsiveBaseElement: '.col-lg-9.col-sm-auto',
    loop: false,
    nav: true,
    dots: true,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 500,
    slideBy: 'page',
    // autoWidth: true,
    responsive: {
      0: {
        items: 2,
        slideBy: 'page',
        loop: false,
      },
      350: {
        items: 2,
        slideBy: 'page',
        loop: false,
      },
      500: {
        items: 3,
        slideBy: 'page',
        loop: false,
      },
      // 576: {
      // 	items: 2,
      // },
      // 770: {
      // 	items: 3,
      // },
      800: {
        items: 4,
        loop: false,
      },
    },
  };

  handleClick(item) {
    // // console.log("click item", item);
    // let currentShow = this.state.isShow;
    // if(this.state.id === item.id){
    // 	currentShow = !this.state.isShow;
    // }else{
    // 	currentShow = true;
    // }
    // this.setState({ isShow: currentShow, flights: item.values, id: item.id })
    // // this.props.changeShow(currentShow);
  }

  render() {
    let priceGroups = Enumerable.from(this.props.items)
      .groupBy('i=>i.adultBasePrice + i.adultTax')
      .select('{key:$.key(),value:$.toArray()}')
      .toArray()
      .map((pg, index) => {
        return {
          ...pg,
          itemId: 'item-' + (index + 1),
        };
      });
    // console.log('price group', priceGroups)
    // let itemList = []
    // for (let priceGroup of priceGroups) {
    // 	// itemList.push(<Item key={priceGroup.key} values={priceGroup.value} handleClick={item => this.handleClick(item)} sellingPrice={priceGroup.key} depart={priceGroup.value[0].trips[0].segments[0].departureAirportCode} departTime={priceGroup.value[0].trips[0].segments[0].departureTime.substring(11)} option={priceGroup.value.length} currency={this.props.currency} />)
    // 	itemList.push(<Item key={priceGroup.key} values={priceGroup.value} handleClick={item => this.handleClick(item)} sellingPrice={priceGroup.key} depart={priceGroup.value[0].trips[0].segments[0].departureAirportCode} departTime={priceGroup.value[0].trips[0].segments[0].departureTime.substring(11)} option={priceGroup.value.length} currency={this.props.currency} />)
    // }

    // priceGroups.map((priceGroup)=>{return <Item key={priceGroup.key} values={priceGroup.value} handleClick={item => this.handleClick(item)} sellingPrice={priceGroup.key} depart={priceGroup.value[0].trips[0].segments[0].departureAirportCode} departTime={priceGroup.value[0].trips[0].segments[0].departureTime.substring(11)} option={priceGroup.value.length} currency={this.props.currency} />})

    return (
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-lg-3 airlogo">
              <img
                className="icon-air-hotel"
                alt={this.props.airlineCode}
                src={
                  'https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir' +
                  this.props.airlineCode +
                  '.gif'
                }
              />
              <span className="ResultAir_Carrier">
                {intl
                  .get('AIRLINE.' + this.props.airlineCode)
                  .defaultMessage(this.props.airlineCode)}
              </span>
            </div>
            <div
              className="col-lg-9 col-sm-auto text-center text-sm-left"
              style={{ minWidth: '320px' }}
            >
              <div className="px-3">
                <OwlCarousel className="owl-theme" {...this.options}>
                  {/* {itemList} */}
                  <ListItem list={priceGroups} {...this.props} />
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
        <ShowItem
          airlineCode={this.props.airlineCode}
          travelers={this.props.travelers}
          currency={this.props.currency}
        />
      </div>
    );
  }
}

function CardSkeleton() {
  return (
    <div className="card">
      <Grid container spacing={8}>
        <Grid item xs>
          {/* <Skeleton animation="wave" /> */}
          {/* <Skeleton variant="circular" width={40} height={40} /> */}
          <Skeleton variant="rectangular" height={180} />
        </Grid>
      </Grid>
    </div>
  );
}

function checkFound(routing, data) {
  let found = false;
  for (let d of data) {
    if (
      d.mainAirlineCode === routing.mainAirlineCode &&
      d.adultBasePrice === routing.adultBasePrice &&
      d.adultTax === routing.adultTax &&
      d.childBasePrice === routing.childBasePrice &&
      d.childTax === routing.childTax &&
      d.trips.length === routing.trips.length
    ) {
      for (let i = 0; i < d.trips.length; i++) {
        if (d.trips[i].segments.length === routing.trips[i].segments.length) {
          for (let j = 0; j < d.trips[i].segments.length; j++) {
            if (
              d.trips[i].segments[j].airlineCode ==
                routing.trips[i].segments[j].airlineCode &&
              d.trips[i].segments[j].departureAirportCode ==
                routing.trips[i].segments[j].departureAirportCode &&
              d.trips[i].segments[j].departureTime ==
                routing.trips[i].segments[j].departureTime &&
              d.trips[i].segments[j].arrivalAirportCode ==
                routing.trips[i].segments[j].arrivalAirportCode &&
              d.trips[i].segments[j].arrivalTime ==
                routing.trips[i].segments[j].arrivalTime
            ) {
              found = true;
            }
          }
        }
      }
    }
  }

  return found;
}

function valuetext(value) {
  return `${value}°C`;
}

function valueLabelFormat(value) {
  return `${value}:00`;
}

function valueLabelFormatDuration(value) {
  let minutes = value % 60;
  return parseInt(value / 60) + ':' + (minutes < 10 ? '0' + minutes : minutes);
}

export default class Flight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParams: null,
      data: [],
      cards: null,
      isLoading: true,
      selectedValue: '',
      loaded: [],
      travelers: {},
      // isShow: false,
      progress: { value: 0, buffer: 10 },
      showType: false,
      tabValue: null,
      tabItems: [],
      currency: null,
      errors: [],
      prevData: [],
      airlines: [],
      filterAirlines: [],
      filterAirports: [],
      filterStops: [],
      showFilter: true,
      rangeTime: [0, 24],
      rangeTimes: [],
      filterFlightNumbers: [],
      filters: [],
      backupData: null,
      durantion: [],
      // cids: ['gttnee', 'gttstu'],//-------------realse ['flxndc']
      // accountToCids:[{accountCode: "SHXCOD", cids: ['gttstu', 'gttnee', 'gtttour']}, {accountCode: "SYXSHA", cids: ['gttstu', 'gttnee', 'gtttour']}, {accountCode: "ITTEST", cids: ['ndctest']}],//{accountCode: "ITTEST", cids: ['gttstu', 'gttnee', 'gtttour', 'flxndc', 'flygndc', 'ndctest']}

      // cids: ['gttnee', 'gttstu','flxndc','gtttour','flxgndc'],//-------------realse ['flxndc']
      // accountToCids:[{accountCode: "SHXCOD", cids: ['gttstu', 'gttnee', 'gtttour']}, {accountCode: "SYXSHA", cids: ['gttstu', 'gttnee', 'gtttour']}, {accountCode: "ITTEST", cids: ['gttnee', 'gttstu','flxndc','gtttour','flxgndc']}, {accountCode: "CITGRP", cids: ['citstd', "citndc"]}],//{accountCode: "ITTEST", cids: ['gttstu', 'gttnee', 'gtttour', 'flxndc', 'flygndc', 'ndctest']}
      cids: ['flxndc', 'flxgndc'], //-------------realse ['flxndc']
      accountToCids: [
        { accountCode: 'SHXCOD', cids: ['gttstu', 'gttnee', 'gtttour'] },
        { accountCode: 'SYXSHA', cids: ['gttstu', 'gttnee', 'gtttour'] },
        { accountCode: 'ITTEST', cids: ['flxndc', 'flxgndc'] },
        // { accountCode: 'ITTEST', cids: ['flxgndc'] },
        { accountCode: 'CITGRP', cids: ['citstd', 'citndc'] },
      ], //{accountCode: "ITTEST", cids: ['gttstu', 'gttnee', 'gtttour', 'flxndc', 'flygndc', 'ndctest']}
    };
  }

  requestFlights(
    that,
    searchParams,
    cid,
    currency,
    luggage,
    departTime,
    returnTime,
  ) {
    return axios
      .post('api/flights', { ...searchParams, cid })
      .then(function (response) {
        let data = response.data;
        // console.log('response data', cid, data);

        if ('APIError' in data) {
          for (let error of data.APIError.errors) {
            that.setState({
              errors: that.state.errors.concat(
                error.message === 'No Tickets.'
                  ? 'no flight schedules'
                  : error.message,
              ),
            });
          }

          return;
        } else if (
          !!data.airTicketListResponse.currency &&
          data.airTicketListResponse.currency != currency
        ) {
          that.setState({ currency: data.airTicketListResponse.currency });
        }

        let routings = data.airTicketListResponse.routings;
        let newRoutings = [];
        for (let routing of routings) {
          routing.cid = data.airTicketListResponse.customerSessionId;
          // console.log('luggage', typeof luggage, luggage);
          if (
            luggage &&
            parseInt(luggage) > 0 &&
            routing.rules &&
            routing.rules.baggages &&
            routing.rules.baggages.every(
              (b) => b.allowancePieces < parseInt(luggage),
            )
          ) {
            continue;
          }

          if (departTime) {
            let unmatch = false;
            for (let i = 0; i < routing.trips.length; i++) {
              for (let j = 0; j < routing.trips[i].segments.length; j++) {
                let hour = moment(
                  routing.trips[i].segments[j].departureTime,
                  'MM/DD/YYYY HH:mm',
                ).hour();

                if (departTime === '1') {
                  if (hour < 5 || hour >= 12) {
                    unmatch = true;
                  }
                } else if (departTime === '2') {
                  if (hour < 12 || hour >= 18) {
                    unmatch = true;
                  }
                } else if (departTime === '3') {
                  if (hour < 18 || hour >= 24) {
                    unmatch = true;
                  }
                }

                break;
              }

              break;
            }

            if (unmatch) {
              continue;
            }
          }

          if (returnTime) {
            let unmatch = false;
            for (let i = 0; i < routing.trips.length; i++) {
              for (let j = 0; j < routing.trips[i].segments.length; j++) {
                if (j != routing.trips[i].segments.length - 1) {
                  continue;
                }
                let hour = moment(
                  routing.trips[i].segments[j].arrivalTime,
                  'MM/DD/YYYY HH:mm',
                ).hour();

                if (departTime === '1') {
                  if (hour < 5 || hour >= 12) {
                    unmatch = true;
                  }
                } else if (departTime === '2') {
                  if (hour < 12 || hour >= 18) {
                    unmatch = true;
                  }
                } else if (departTime === '3') {
                  if (hour < 18 || hour >= 24) {
                    unmatch = true;
                  }
                }
              }
              break;
            }

            if (unmatch) {
              continue;
            }
          }

          routing.sells = [];
          if (
            'upsell' in routing &&
            routing.upsell === false &&
            'brand' in routing &&
            routing.brand &&
            routing.brand.group
          ) {
            for (let secondaryRouting of routings) {
              if (
                'upsell' in secondaryRouting &&
                secondaryRouting.upsell === true &&
                'brand' in secondaryRouting &&
                secondaryRouting.brand &&
                routing.brand.group === secondaryRouting.brand.group
              ) {
                routing.sells.push(secondaryRouting);
              }
            }
          }

          if ('upsell' in routing && routing.upsell === true) {
            continue;
          }

          // console.log(`cid: ${routing.cid}, air: ${routing.mainAirlineCode}, total: ${routing.adultBasePrice  + routing.adultTax}`, routing)
          // console.log(routing)
          // let totalPrice = (routing.adultBasePrice + routing.adultTax) * adults + (routing.childBasePrice + routing.childTax) * children;
          // routing.totalPrice = Math.round(totalPrice * 100)/100
          // let found = checkFound(routing, that.state.data);
          // console.log('is found', routing.cid, found)
          // if (!found) {
          //   newRoutings.push(routing);
          // }

          newRoutings.push(routing);
        }

        // console.log('last new routings', data.airTicketListResponse.customerSessionId, newRoutings);
        that.setState(
          (prevState) => {
            return {
              data: prevState.data.concat(newRoutings),
              airlines: Enumerable.from(prevState.data.concat(newRoutings))
                .select('x=>x.mainAirlineCode')
                .distinct()
                .toArray(),
            };
          },
          () => {
            // console.log('last routings state', data.airTicketListResponse.customerSessionId, that.state.data);
          },
        );
      })
      .catch(function (error) {
        // console.error(cid, error);
        that.setState({ errors: that.state.errors.concat(error.message) });
      })
      .then(function () {
        // that.setState({ isLoading: false });
        that.setState(
          (prevState) => {
            return { loaded: prevState.loaded.concat(cid) };
          },
          () => {
            // console.log('then', cid, that.state.loaded);
          },
        );
      });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
    loadCss('css/loaders.css/loaders.min.css');
    loadCss('css/loaders.css/theme.css');
    // loadCss('Flight-Hotel/vendor/font-awesome/css/all.min.css')
    loadCss('css/stylesheet.css');
    loadCss('css/FlightDetails.css');
    loadCss('css/flight.css');

    // loadScript(process.env.PUBLIC_URL + '/js/jquery.min.js')
    // loadScript(process.env.PUBLIC_URL + '/js/jquery-ui.min.js')
    // loadScript(process.env.PUBLIC_URL + '/js/bootstrap.bundle.min.js')
    loadScript(
      'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/js/bootstrap.bundle.min.js',
    );

    switch (getURLParam('type')) {
      case 'oneway':
        {
          this.setState({
            rangeTimes: [{ index: 0, departure: [0, 24], arrival: [0, 24] }],
          });
        }
        break;
      case 'return':
        {
          this.setState({
            rangeTimes: [
              { index: 0, departure: [0, 24], arrival: [0, 24] },
              { index: 1, departure: [0, 24], arrival: [0, 24] },
            ],
          });
        }
        break;
      case 'multicities':
        {
          if (getURLParam('flights')) {
            let times = [];
            for (let i = 0; i < getURLParam('flights').split(';').length; i++) {
              times.push({ index: i, departure: [0, 24], arrival: [0, 24] });
            }

            this.setState({ rangeTimes: times });
          }
        }
        break;
    }

    let cid = null;

    if (sessionStorage.getItem('userInfo')) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      if (userInfo.type === 'traveloutlet') {
        cid = 'gtttvo';
      }
    }

    if (getURLParam('cid')) {
      cid = getURLParam('cid');
    }

    let accountCode = null;
    let branchCode = null;
    if (sessionStorage.getItem('userInfo') != null) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      accountCode = userInfo.AccountCode;
      branchCode = userInfo.Branch;
    }

    // this.setState({cids: ['flygstu']})
    // if(accountCode && accountCode.toUpperCase() === "SHXCOD"){
    // 	this.setState({cids: ['flygstu']})
    // }

    var flights = getURLParam('flights');
    let searchParams = null;
    if (flights != null) {
      var flightsItem = flights.split('/');

      var from = flightsItem[0];
      var to = flightsItem[1];

      document.title = intl.get('FLIGHT.TITLE', {
        from: intl
          .get('AIRPORT.' + from.toUpperCase() + '.CITYNAME')
          .defaultMessage(from),
        to: intl
          .get('AIRPORT.' + to.toUpperCase() + '.CITYNAME')
          .defaultMessage(to),
      });

      var departDate = flightsItem[2];

      var returnDate = null;
      if (flightsItem.length === 4) {
        returnDate = flightsItem[3];
      }

      let adults = 0,
        children = 0,
        infants = 0;
      let adultsParam = getURLParam('adults');
      let childrenParam = getURLParam('children');
      let infantsParam = getURLParam('infants');
      if (!!adultsParam) {
        adults = parseInt(adultsParam);
      }

      if (!!childrenParam) {
        children = parseInt(childrenParam);
      }

      if (!!infantsParam) {
        infants = parseInt(infantsParam);
      }

      // console.log(intl.getInitOptions())

      let cabin = getURLParam('cabin');
      let currency = '';
      if (localStorage.getItem('currency')) {
        currency = localStorage.getItem('currency');
      } else if (intl.getInitOptions().currentLocale === 'zh-cn') {
        currency = 'CNY';
      } else if (intl.getInitOptions().currentLocale === 'en-us') {
        currency = 'USD';
      }

      let airline = getURLParam('airline');

      this.setState({ currency: currency });

      this.setState({ travelers: { adults, children, infants } });

      // searchParams = { origin: from, destination: to, departureDate: departDate, arrivalDate: returnDate, adults, children, cabin, currency, accountCode, cid }
      searchParams = {
        flight: flights,
        type: getURLParam('type'),
        adults,
        children,
        cabin,
        currency,
        accountCode,
        cid,
        airline: airline,
        branchCode: branchCode,
      };
      this.setState({ searchParams: searchParams });
      let lastCids = this.state.cids;
      for (let accountToCid of this.state.accountToCids) {
        if (accountToCid.accountCode === accountCode) {
          this.setState({ cids: accountToCid.cids });
          lastCids = accountToCid.cids;
        }
      }

      // console.log('last cids', lastCids);
      let luggage = getURLParam('luggage');
      let departTime = getURLParam('departtime');
      let returnTime = getURLParam('returntime');

      let that = this;
      that.setState({ isLoading: true });

      const promiseList = [];

      for (let cidItem of lastCids) {
        if (cidItem === null && cid) {
          promiseList.push(
            this.requestFlights(
              that,
              searchParams,
              cid,
              currency,
              luggage,
              departTime,
              returnTime,
            ),
          );
        } else {
          promiseList.push(
            this.requestFlights(
              that,
              searchParams,
              cidItem,
              currency,
              luggage,
              departTime,
              returnTime,
            ),
          );
        }
      }

      Promise.allSettled(promiseList).then(() => {
        that.setState({ isLoading: false });
      });

      // 	if (process.env.NODE_ENV != 'development'){
      // 		// axios.post('api/flights', { ...searchParams, sourceFrom: "apply" })
      // 		// .then(function (response) {
      // 		// 	// console.log("apply flights", response);
      // 		// 	var data = JSON.parse(response.data);
      // 		// 	var routings = data.airTicketListResponse.routings;
      // 		// 	that.setState({ data: that.state.data.concat(routings) })
      // 		// })
      // 		// .catch(function (error) {
      // 		// 	console.error("apply", error);
      // 		// }).then(function () {
      // 		// 	that.setState({ isLoading: false, loaded: that.state.loaded.concat("apply") })
      // 		// });

      // 		this.setState({ isLoading: false, loaded: that.state.loaded.concat("apply") })
      // }else{
      // 	this.setState({ isLoading: false, loaded: that.state.loaded.concat("apply") })
      // }

      // 	if (process.env.NODE_ENV != 'development'){
      // 	axios.post('api/flights', {...searchParams, departureFlex: 3})
      // 	.then(function (response) {
      // 		// console.log("flights flex", response);
      // 		var data = JSON.parse(response.data);
      // 		let dateToPrices = []
      // 		for(let routing of data.airTicketListResponse.routings){
      // 			for(let trip of routing.trips){
      // 				for(let segment of trip.segments){
      // 					dateToPrices.push({date: moment(segment.departureTime).format("YYYY-MM-DD"), price: routing.totalPrice })
      // 					break
      // 				}
      // 				break
      // 			}
      // 		}

      // 		dateToPrices.sort((a, b) => (moment(a.date) > moment(b.date)) ? 1 : -1);
      // 		// console.log(dateToPrices);
      // 		that.setState({tabItems: dateToPrices, tabValue: moment(departDate).format("YYYY-MM-DD")})
      // 	})
      // 	.catch(function (error) {
      // 		console.error("flex", error);
      // 	}).then(function () {

      // 	});
      // }
    } else {
      document.title = intl.get('FLIGHT.TITLE', { from: '', to: '' });
      this.setState({ isLoading: false });
    }
  }

  changeLang = (e) => {
    let lang = e.target.value;
    this.setState({ selectedValue: lang });
    // console.log("lang", e.target.value);
  };

  changeShowType = (showType) => {
    this.setState({ showType });
  };

  handleChange = (event, newValue) => {
    let a = moment(this.state.searchParams.departureDate);
    let b = moment(newValue);
    let diff = a.diff(b, 'days');
    window.location = `/flight?flights=${this.state.searchParams.origin}/${
      this.state.searchParams.destination
    }/${moment(newValue).format('YYYYMMDD')}${
      this.state.searchParams.arrivalDate != null
        ? '/' +
          moment(this.state.searchParams.arrivalDate)
            .add(-diff, 'days')
            .format('YYYYMMDD')
        : ''
    }&cabin=${this.state.searchParams.cabin}&adults=${
      this.state.searchParams.adults
    }&children=${this.state.searchParams.children}&infants=0`;
  };

  filter = (type, value) => {
    let data = null;
    if (this.state.backupData === null) {
      this.setState({ backupData: this.state.data });
      data = this.state.data;
    } else {
      data = this.state.backupData;
    }

    let newFilters = this.state.filters;
    // console.log('new filters', type, value);
    // console.log('new filters', newFilters);
    let index = newFilters.findIndex((item) => item.type === type);
    switch (type) {
      case 'NDC':
        {
          if (value) {
            if (index < 0) {
              newFilters.push({ type: 'NDC', values: value });
            }
          } else {
            if (index >= 0) {
              newFilters = newFilters.filter((item) => item.type != 'NDC');
            }
          }
        }
        break;
      case 'stops':
        {
          if (index < 0) {
            newFilters.push({ type: 'stops', values: [value] });
          } else {
            if (
              newFilters[index].values.findIndex((item) => item === value) >= 0
            ) {
              newFilters[index].values = newFilters[index].values.filter(
                (item) => item != value,
              );
            } else {
              newFilters[index].values.push(value);
            }
          }
        }
        break;
      case 'airlines':
        {
          if (index < 0) {
            newFilters.push({ type: 'airlines', values: [value] });
          } else {
            if (
              newFilters[index].values.findIndex((item) => item === value) >= 0
            ) {
              newFilters[index].values = newFilters[index].values.filter(
                (item) => item != value,
              );
            } else {
              newFilters[index].values.push(value);
            }
          }
        }
        break;
      case 'flightNumbers':
        {
          if (index < 0) {
            newFilters.push({ type: 'flightNumbers', values: [value] });
          } else {
            let findFlightNumberIndex = newFilters[index].values.findIndex(
              (v) => v.index === value.index,
            );
            if (findFlightNumberIndex < 0) {
              newFilters[index].values.push(value);
            } else {
              newFilters[index].values[findFlightNumberIndex] = value;
            }
          }
        }
        break;
      case 'times':
        {
          if (index < 0) {
            newFilters.push({
              type: 'times',
              values: [
                {
                  tripIndex: value.index,
                  limits: [{ type: value.type, times: value.times }],
                },
              ],
            });
          } else {
            let tripIndex = newFilters[index].values.findIndex(
              (item) => item.tripIndex === value.index,
            );
            if (tripIndex < 0) {
              newFilters[index].values.push({
                tripIndex: value.index,
                limits: [{ type: value.type, times: value.times }],
              });
            } else {
              let limitIndex = newFilters[index].values[
                tripIndex
              ].limits.findIndex((item) => item.type === value.type);
              if (limitIndex < 0) {
                newFilters[index].values[tripIndex].limits.push({
                  type: value.type,
                  times: value.times,
                });
              } else {
                newFilters[index].values[tripIndex].limits[limitIndex].times =
                  value.times;
              }
            }
          }
        }
        break;
      case 'duration':
        {
          if (index < 0) {
            newFilters.push({ type: 'duration', values: value });
          } else {
            newFilters[index].values = value;
          }
        }
        break;
      case 'airports':
        {
          if (index < 0) {
            newFilters.push({
              type: 'airports',
              values: [
                {
                  index: value.index,
                  departureAirports:
                    value.airport.type === 'departure'
                      ? value.airport.code
                      : null,
                  arrivalAirports:
                    value.airport.type === 'arrival'
                      ? value.airport.code
                      : null,
                },
              ],
            });
          } else {
            let findTripAirports = newFilters[index].values.find(
              (x) => x.index == value.index,
            );
            if (findTripAirports) {
              if(value.airport.type === 'departure'){
                findTripAirports.departureAirports = value.airport.code
              }else{
                findTripAirports.arrivalAirports = value.airport.code
              }
            } else {
              newFilters[index].values.push({
                index: value.index,
                departureAirports:
                  value.airport.type === 'departure'
                    ? value.airport.code
                    : null,
                arrivalAirports:
                  value.airport.type === 'arrival' ? value.airport.code : null,
              });
            }
          }
          // console.log('airports', value);
        }
        break;
    }

    console.log('after new filters', newFilters)
    this.setState({ filters: newFilters });
    let newData = [];
    for (let item of data) {
      let isFilter = false;
      for (let filter of newFilters) {
        if (filter.type === 'NDC') {
          if (!IS_NDCS.includes(item.cid)) {
            isFilter = true;
            break;
          }
        } else if (filter.type === 'stops' && filter.values.length > 0) {
          let stops = 0;
          for (let trip of item.trips) {
            if (trip.segments.length - 1 > stops) {
              stops = trip.segments.length - 1;
              if (stops > 2) {
                stops = 2;
              }
            }
          }

          if (!filter.values.includes(stops)) {
            isFilter = true;
            break;
          }
        } else if (filter.type === 'airlines' && filter.values.length > 0) {
          if (!filter.values.includes(item.mainAirlineCode)) {
            isFilter = true;
            break;
          }
        } else if (filter.type === 'flightNumbers' && filter.values) {
          let hasFlightNumberStatuses = [];
          for (let tripIndex = 0; tripIndex < item.trips.length; tripIndex++) {
            let hasFlightNumberStatus = 0;
            let findFilterFlightNumberItem = filter.values.find(
              (v) => v.index === tripIndex,
            );
            if (
              findFilterFlightNumberItem != null &&
              findFilterFlightNumberItem.flightNumber
            ) {
              if (
                item.trips[tripIndex].segments.some(
                  (s) =>
                    s.airlineCode + s.flightNumber ===
                    findFilterFlightNumberItem.flightNumber,
                )
              ) {
                hasFlightNumberStatus = 1;
              } else {
                hasFlightNumberStatus = 2;
              }
            }

            hasFlightNumberStatuses.push(hasFlightNumberStatus);
          }

          console.log(hasFlightNumberStatuses);
          if (hasFlightNumberStatuses.indexOf(2) >= 0) {
            isFilter = true;
          }
        } else if (filter.type === 'times') {
          let pass = false;
          for (let i = 0, len = item.trips.length; i < len; i++) {
            let filterValue = filter.values.find((v) => v.tripIndex === i);
            // console.log('trip', filterValue)
            if (filterValue) {
              for (let limit of filterValue.limits) {
                let time = null;
                if (limit.type === 'departure') {
                  time = new Date(item.trips[i].segments[0].departureTime);
                } else {
                  time = new Date(
                    item.trips[i].segments[
                      item.trips[i].segments.length - 1
                    ].arrivalTime,
                  );
                }

                if (
                  limit.times[0] > time.getHours() ||
                  time.getHours() >= limit.times[1]
                ) {
                  pass = true;
                }
              }
            }
          }

          if (pass) {
            isFilter = true;
            break;
          }
        } else if (filter.type === 'duration') {
          if (item.duration > filter.values) {
            isFilter = true;
          }
        }else if(filter.type === 'airports'){
          let pass = false
          for (let i = 0, len = item.trips.length; i < len; i++) {
            let findFilterAirport = filter.values.find(x=>x.index == i)
            console.log('find filter airport', findFilterAirport)
            if(findFilterAirport){
              if(findFilterAirport.departureAirports !== null){
                if(item.trips[i].segments[0].departureAirportCode !== findFilterAirport.departureAirports){
                  pass = true
                }
              }

              if(findFilterAirport.arrivalAirports !== null){
                if(item.trips[i].segments[item.trips[i].segments.length-1].arrivalAirportCode !== findFilterAirport.arrivalAirports){
                  pass = true
                }
              }
            }
          }

          if (pass) {
            console.log(item, filter)
            isFilter = true;
            break;
          }
        }
      }

      if (isFilter) {
        continue;
      }

      newData.push(item);
    }

    this.setState({ data: newData });
  };

  filterNDC = (value) => {
    this.filter('NDC', value);
  };

  handleFilterByStops = (count) => {
    this.filter('stops', count);
  };

  handleFilterByAirline = (airline) => {
    this.filter('airlines', airline);
  };

  handleFilter = (off) => {
    this.setState({ showFilter: off });
  };

  handleChangeTime = (type, index) => {
    return (event, newValue) => {
      let newRangeTimes = this.state.rangeTimes;
      let rangeTimeIndex = newRangeTimes.findIndex(
        (item) => item.index === index,
      );
      newRangeTimes[rangeTimeIndex][type] = newValue;
      this.setState({ rangeTimes: newRangeTimes });
    };
  };

  handleChangeCommitted = (type, index) => {
    return (event, value) => {
      // console.log('committed', type, index, value)
      this.filter('times', { index: index, type: type, times: value });
    };
  };

  handleClickCollapseAirports = () => {
    if (this.state.filterAirports.length === 0) {
      let airportsList = [];
      let data =
        this.state.backupData === null
          ? this.state.data
          : this.state.backupData;

      for (let routing of data) {
        for (let i = 0; i < routing.trips.length; i++) {
          let findAirport = airportsList.find((f) => f.index === i);
          if (!findAirport) {
            airportsList.push({
              index: i,
              departureAirports: [],
              arrivalAirports: [],
            });
          }

          findAirport = airportsList.find((f) => f.index === i);
          if (
            findAirport.departureAirports.indexOf(
              routing.trips[i].segments[0].departureAirportCode,
            ) < 0
          ) {
            findAirport.departureAirports.push(
              routing.trips[i].segments[0].departureAirportCode,
            );
          }

          if (
            findAirport.arrivalAirports.indexOf(
              routing.trips[i].segments[routing.trips[i].segments.length - 1]
                .arrivalAirportCode,
            ) < 0
          ) {
            findAirport.arrivalAirports.push(
              routing.trips[i].segments[routing.trips[i].segments.length - 1]
                .arrivalAirportCode,
            );
          }
        }
      }

      console.log('airports', airportsList);
      this.setState({ filterAirports: airportsList });
    }
  };

  handleFilterAirports = (index, type, airportCode) => {
    this.filter('airports', { index, airport: { type, code: airportCode } });
    // console.log('filter flight number', index, flightNumber)
  };

  handleClickCollapseFlightNumber = () => {
    if (this.state.filterFlightNumbers.length === 0) {
      let flightNumberList = [];
      let data =
        this.state.backupData === null
          ? this.state.data
          : this.state.backupData;
      for (let routing of data) {
        for (let i = 0; i < routing.trips.length; i++) {
          let findFlightNumber = flightNumberList.find((f) => f.index === i);
          if (!findFlightNumber) {
            flightNumberList.push({ index: i, flightNumbers: [] });
          }

          findFlightNumber = flightNumberList.find((f) => f.index === i);
          for (let segment of routing.trips[i].segments) {
            if (
              findFlightNumber.flightNumbers.indexOf(
                segment.airlineCode + segment.flightNumber,
              ) < 0
            ) {
              findFlightNumber.flightNumbers.push(
                segment.airlineCode + segment.flightNumber,
              );
            }
          }
        }
      }

      for (let flightNumber of flightNumberList) {
        flightNumber.flightNumbers.sort();
      }

      this.setState({ filterFlightNumbers: flightNumberList });
    }
  };

  handleFilterFlightNumber = (index, flightNumber) => {
    this.filter('flightNumbers', { index, flightNumber });
    // console.log('filter flight number', index, flightNumber)
  };

  handleClickCollapseDuration = () => {
    if (this.state.durantion.length === 0) {
      let data =
        this.state.backupData === null
          ? this.state.data
          : this.state.backupData;
      this.setState({
        durantion: [
          Math.min.apply(
            Math,
            data.map((item) => item.duration),
          ),
          Math.max.apply(
            Math,
            data.map((item) => item.duration),
          ),
        ],
      });
    }
  };

  handleChangeCommittedDuration = (event, value) => {
    this.filter('duration', value);
  };

  handleResize = (e) => {
    if (e.target.innerWidth < 500) {
      this.setState({ showFilter: false });
    } else {
      this.setState({ showFilter: true });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  render() {
    return (
      <>
        <ButtonAppBar />
        <section className="container" style={{ paddingTop: '1rem' }}>
          {this.state.loaded.length != this.state.cids.length && (
            <AppBar position="fixed">
              <LinearProgress />
            </AppBar>
          )}
          <div className="row">
            <div className="col mb-2">
              {this.state.showType === false ? (
                <ShopResultsTitle
                  {...this.state.searchParams}
                  showFilter={this.state.showFilter}
                  changeShowType={(showType) => this.changeShowType(showType)}
                  filterNDC={this.filterNDC}
                  handleFilter={this.handleFilter}
                />
              ) : (
                <div className="flight_search">
                  <Search
                    showClose={true}
                    changeShowType={(showType) => this.changeShowType(showType)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {this.state.showFilter && (
              <aside className="col-lg-3">
                <div className="bg-light shadow-md rounded p-3">
                  <h3 className="text-5">{intl.get('FLIGHT.FILTER')}</h3>
                  <hr className="mx-n3" />
                  <div
                    className="accordion accordion-alternate style-2 mt-n3"
                    id="toggleAlternate"
                  >
                    <div className="card">
                      <div className="card-header" id="stops">
                        <h5 className="mb-0">
                          {' '}
                          <a
                            href="#"
                            data-toggle="collapse"
                            data-target="#togglestops"
                            aria-expanded="false"
                            aria-controls="togglestops"
                            className="collapsed"
                          >
                            {intl.get('FLIGHT.FILTER_STOPS')}
                          </a>{' '}
                        </h5>
                      </div>
                      <div
                        id="togglestops"
                        className="collapse"
                        aria-labelledby="stops"
                      >
                        <div className="card-body">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nonstop"
                              name="stop"
                              className="custom-control-input"
                              onChange={() => this.handleFilterByStops(0)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="nonstop"
                            >
                              {intl.get('FLIGHT.FILTER_STOPS_DIRECT')}
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="1stop"
                              name="stop"
                              className="custom-control-input"
                              onChange={() => this.handleFilterByStops(1)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="1stop"
                            >
                              {intl.get('FLIGHT.FILTER_STOPS_1STOP')}
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="2stop"
                              name="stop"
                              className="custom-control-input"
                              onChange={() => this.handleFilterByStops(2)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="2stop"
                            >
                              {intl.get('FLIGHT.FILTER_STOPS_OVER2STOPS')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="airlines">
                        <h5 className="mb-0">
                          {' '}
                          <a
                            href="#"
                            className="collapse collapsed"
                            data-toggle="collapse"
                            data-target="#toggleAirlines"
                            aria-expanded="false"
                            aria-controls="toggleAirlines"
                          >
                            {intl.get('FLIGHT.FILTER_AIRLINES')}
                          </a>{' '}
                        </h5>
                      </div>
                      <div
                        id="toggleAirlines"
                        className="collapse"
                        aria-labelledby="airlines"
                      >
                        <div className="card-body">
                          {this.state.airlines.sort().map((item) => {
                            return (
                              <div
                                key={item}
                                className="custom-control custom-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  id={'airline' + item}
                                  className="custom-control-input"
                                  onChange={() =>
                                    this.handleFilterByAirline(item)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={'airline' + item}
                                >
                                  {intl
                                    .get('AIRLINE.' + item)
                                    .defaultMessage(item)}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="airports">
                        <h5 className="mb-0">
                          <a
                            onClick={this.handleClickCollapseAirports}
                            href="#"
                            className="collapse collapsed"
                            data-toggle="collapse"
                            data-target="#toggleAirports"
                            aria-expanded="false"
                            aria-controls="toggleAirports"
                          >
                            {intl.get('FLIGHT.FILTER_AIRPORTS')}
                          </a>
                        </h5>
                      </div>
                      <div
                        id="toggleAirports"
                        className="collapse"
                        aria-labelledby="airports"
                      >
                        <div className="card-body">
                          {this.state.filterAirports.map((f) => (
                            <Box key={f.index}>
                              {getURLParam('type') === 'oneway' ? (
                                ''
                              ) : getURLParam('type') === 'return' ? (
                                <Typography variant="subtitle2">
                                  {f.index == 0 ? 'Outbound' : 'Inbound'}
                                </Typography>
                              ) : (
                                <Typography variant="subtitle2">
                                  {f.index + 1} Trip
                                </Typography>
                              )}

                              <Grid container direction="column" spacing={1}>
                                <Grid item>
                                  <Autocomplete
                                    //   disablePortal
                                    options={f.departureAirports}
                                    onChange={(event, newValue) => {
                                      console.log(event, newValue);
                                      this.handleFilterAirports(
                                        f.index,
                                        'departure',
                                        newValue,
                                      );
                                    }}
                                    //   sx={{ width: 300 }}
                                    size="small"
                                    renderInput={(params) => (
                                      <TextField
                                        hiddenLabel
                                        {...params}
                                        label="Departure"
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item>
                                  <Autocomplete
                                    //   disablePortal
                                    options={f.arrivalAirports}
                                    onChange={(event, newValue) => {
                                      console.log(event, newValue);
                                      this.handleFilterAirports(
                                        f.index,
                                        'arrival',
                                        newValue,
                                      );
                                    }}
                                    //   sx={{ width: 300 }}
                                    size="small"
                                    renderInput={(params) => (
                                      <TextField
                                        hiddenLabel
                                        {...params}
                                        label="Arrival"
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="departureTime">
                        <h5 className="mb-0">
                          {' '}
                          <a
                            href="#"
                            className="collapse collapsed"
                            data-toggle="collapse"
                            data-target="#toggleDepartureTime"
                            aria-expanded="false"
                            aria-controls="toggleDepartureTime"
                          >
                            {intl.get('FLIGHT.FILTER_TIMES')}
                          </a>{' '}
                        </h5>
                      </div>
                      <div
                        id="toggleDepartureTime"
                        className="collapse"
                        aria-labelledby="departureTime"
                      >
                        <div className="card-body">
                          <Box sx={{ padding: '0 8px' }}>
                            <Typography
                              id="track-inverted-range-slider"
                              gutterBottom
                            >
                              {intl.get('FLIGHT.FILTER_TIMES_DEPARTURE')}·
                              {this.state.rangeTimes.length > 0 &&
                                (this.state.rangeTimes.find(
                                  (item) => item.index === 0,
                                ).departure &&
                                this.state.rangeTimes.find(
                                  (item) => item.index === 0,
                                ).departure[0] === 0 &&
                                this.state.rangeTimes.find(
                                  (item) => item.index === 0,
                                ).departure[1] === 24
                                  ? intl.get('FLIGHT.FILTER_TIMES_UNLIMITED')
                                  : `${
                                      this.state.rangeTimes.find(
                                        (item) => item.index === 0,
                                      ).departure[0]
                                    }:00-${
                                      this.state.rangeTimes.find(
                                        (item) => item.index === 0,
                                      ).departure[1]
                                    }:00`)}
                            </Typography>
                            <Slider
                              size={'small'}
                              getAriaLabel={() => 'departure'}
                              min={0}
                              max={24}
                              value={
                                this.state.rangeTimes.length > 0
                                  ? this.state.rangeTimes.find(
                                      (item) => item.index === 0,
                                    ).departure
                                  : [0, 24]
                              }
                              onChange={this.handleChangeTime('departure', 0)}
                              valueLabelFormat={valueLabelFormat}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                              step={1}
                              onChangeCommitted={this.handleChangeCommitted(
                                'departure',
                                0,
                              )}
                            />
                            <Typography
                              id="track-inverted-range-slider"
                              gutterBottom
                            >
                              {intl.get('FLIGHT.FILTER_TIMES_ARRIVAL')}·
                              {this.state.rangeTimes.length > 0 &&
                                (this.state.rangeTimes.find(
                                  (item) => item.index === 0,
                                ).arrival &&
                                this.state.rangeTimes.find(
                                  (item) => item.index === 0,
                                ).arrival[0] === 0 &&
                                this.state.rangeTimes.find(
                                  (item) => item.index === 0,
                                ).arrival[1] === 24
                                  ? intl.get('FLIGHT.FILTER_TIMES_UNLIMITED')
                                  : `${
                                      this.state.rangeTimes.find(
                                        (item) => item.index === 0,
                                      ).arrival[0]
                                    }:00-${
                                      this.state.rangeTimes.find(
                                        (item) => item.index === 0,
                                      ).arrival[1]
                                    }:00`)}
                            </Typography>
                            <Slider
                              size={'small'}
                              getAriaLabel={() => 'arrival'}
                              min={0}
                              max={24}
                              value={
                                this.state.rangeTimes.length > 0
                                  ? this.state.rangeTimes.find(
                                      (item) => item.index === 0,
                                    ).arrival
                                  : [0, 24]
                              }
                              onChange={this.handleChangeTime('arrival', 0)}
                              valueLabelFormat={valueLabelFormat}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                              step={1}
                              onChangeCommitted={this.handleChangeCommitted(
                                'arrival',
                                0,
                              )}
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="duration">
                        <h5 className="mb-0">
                          {' '}
                          <a
                            href="#"
                            className="collapse collapsed"
                            onClick={this.handleClickCollapseDuration}
                            data-toggle="collapse"
                            data-target="#toggleDuration"
                            aria-expanded="false"
                            aria-controls="toggleDuration"
                          >
                            {intl.get('FLIGHT.FILTER_DURATION')}
                          </a>{' '}
                        </h5>
                      </div>
                      <div
                        id="toggleDuration"
                        className="collapse"
                        aria-labelledby="duration"
                      >
                        <div className="card-body">
                          {this.state.durantion.length > 0 && (
                            <Box sx={{ padding: '0 8px' }}>
                              <Slider
                                size={'small'}
                                getAriaLabel={() => 'duration'}
                                defaultValue={this.state.durantion[1]}
                                min={this.state.durantion[0]}
                                max={this.state.durantion[1]}
                                valueLabelFormat={valueLabelFormatDuration}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                step={1}
                                onChangeCommitted={
                                  this.handleChangeCommittedDuration
                                }
                              />
                            </Box>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="flightNumber">
                        <h5 className="mb-0">
                          {' '}
                          <a
                            href="#"
                            onClick={this.handleClickCollapseFlightNumber}
                            className="collapse collapsed"
                            data-toggle="collapse"
                            data-target="#toggleFlightNumber"
                            aria-expanded="false"
                            aria-controls="toggleflightNumber"
                          >
                            {intl.get('FLIGHT.FILTER_FLIGHT_NUMBERS')}
                          </a>{' '}
                        </h5>
                      </div>
                      <div
                        id="toggleFlightNumber"
                        className="collapse"
                        aria-labelledby="flightNumber"
                      >
                        <div className="card-body">
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            //   justifyContent="center"
                            //   alignItems="center"
                          >
                            {this.state.filterFlightNumbers.map((f) => (
                              <Grid item key={f.index}>
                                <Autocomplete
                                  //   disablePortal
                                  options={f.flightNumbers}
                                  onChange={(event, newValue) => {
                                    console.log(event, newValue);
                                    this.handleFilterFlightNumber(
                                      f.index,
                                      newValue,
                                    );
                                  }}
                                  //   sx={{ width: 300 }}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      hiddenLabel
                                      {...params}
                                      label="Outbound"
                                    />
                                  )}
                                />
                              </Grid>
                            ))}

                            {/* <Grid item>
                              <Autocomplete
                                //   disablePortal
                                options={this.state.filterFlightNumbers}
                                onChange={(event, newValue) => {
                                  this.handleFilterFlightNumber(1, newValue);
                                }}
                                //   sx={{ width: 300 }}
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    hiddenLabel
                                    {...params}
                                    label="Inbound"
                                  />
                                )}
                              />
                            </Grid> */}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            )}

            <div
              className={
                'col-lg-' +
                (this.state.showFilter ? '9' : '12') +
                ' mt-4 mt-lg-0'
              }
              style={{ backgroundColor: '#ffffff' }}
            >
              {this.state.isLoading ? (
                <LoadingSkeleton />
              ) : (
                <div>
                  <Box>
                    {this.state.loaded.length === this.state.cids.length &&
                    this.state.data.length === 0 &&
                    this.state.errors.length > 0 ? (
                      <Alert severity="error" sx={{ width: '100%' }}>
                        {this.state.errors[0]}
                      </Alert>
                    ) : (
                      <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                      >
                        {this.state.tabItems.map((item, idx) => {
                          return (
                            <Tab
                              key={item.date}
                              value={item.date}
                              label={
                                <>
                                  <span className="tab-date">
                                    {moment(item.date).format('MMM Do')}
                                  </span>
                                  <span className="tab-price">
                                    {intl.get('PRICE', {
                                      currency: intl
                                        .get(
                                          'CURRENCY.' +
                                            this.state.currency +
                                            '.SYMBOL',
                                        )
                                        .defaultMessage(this.state.currency),
                                      price: item.price,
                                    })}
                                  </span>
                                </>
                              }
                            />
                          );
                        })}
                      </Tabs>
                    )}
                  </Box>

                  {this.state.loaded.length === 0
                    ? Array.from({ length: 3 }, (v, k) => k).map((item) => {
                        return <CardSkeleton key={item} />;
                      })
                    : Enumerable.from(this.state.data)
                        .orderBy('i=>i.adultBasePrice + i.adultTax')
                        .groupBy('i=>i.mainAirlineCode')
                        .select('{key:$.key(),value:$.toArray()}')
                        .toArray()
                        .map((group, index) => {
                          return (
                            <Card
                              key={group.key + group.value.length}
                              airlineCode={group.key}
                              items={group.value}
                              travelers={this.state.travelers}
                              currency={this.state.currency}
                            />
                          );
                        })}
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}
