function getURLParam(name) {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
	let r = window.location.search.substring(1).match(reg);  //匹配目标参数
	if (r != null) return decodeURI(r[2]); return null; //返回参数值
}

function isInclude(name){
	var js= /js$/i.test(name);
	var es=document.getElementsByTagName(js?'script':'link');
	for(var i=0;i<es.length;i++) 
	if(es[i][js?'src':'href'].indexOf(name)!=-1)return true;
	return false;
}

function loadCss(href){
    let link = document.createElement("link")
    link.href = href
    link.rel = "Stylesheet"
    link.type = "text/css"

    document.head.appendChild(link)
}

function loadScript(path,async){
    const script = document.createElement("script")
    script.src = path
    script.async = async

    document.body.appendChild(script)
}

function removeCss(href) {
    var links = document.getElementsByTagName("link");
    for (var i = 0; i < links.length; i++) {
        var _href = links[i].href;
        if (links[i] && links[i].href && links[i].href.indexOf(href) != -1) {
            links[i].parentNode.removeChild(links[i]);
        }
    }
  }

export {getURLParam, loadCss, loadScript, isInclude, removeCss }
