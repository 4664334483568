import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import IconButton from '@mui/material/IconButton';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import moment from 'moment/moment';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Collapse,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormLabel from '@mui/material/FormLabel';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionGroup } from 'react-transition-group';
import { getURLParam } from '../../common/helper.js';
import {NDC_AIRLINES} from '../../data/config'

import './index.css';
import { TimePicker } from '@mui/x-date-pickers';

const FLIGHT_MAX_LENGTH = 3;

const AIRPORT_PATTERN = new RegExp('(([A-Z]{3}))');
// const NDC_AIRLINES = [
//   { label: 'American Airlines', code: 'AA' },
//   { label: 'Air France', code: 'AF' },
//   { label: 'Air Canada', code: 'AC' },
//   { label: 'British Airways', code: 'BA' },
//   { label: 'Copa Airlines', code: 'CM' },
//   { label: 'Emirates', code: 'EK' },
//   { label: 'Iberia', code: 'IB' },
//   { label: 'KLM Royal Dutch Airlines', code: 'KL' },
//   { label: 'Qantas Airlines', code: 'QF' },
//   { label: 'Singapore Airlines', code: 'SQ' },
//   { label: 'Taca Airlines', code: 'TA' },
//   { label: 'United Airlines', code: 'UA' },
// ];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function GetCode(where) {
  const match = AIRPORT_PATTERN.exec(where);
  if (match) {
    return match[1];
  } else {
    return where;
  }
}

function Flight(props) {
  const [history, setHistory] = useState({ from: '', to: '' });

  const getFlight = () => {
    return {
      index: props.index,
      from: props.from,
      to: props.to,
      departDate: props.departDate,
      returnDate: props.returnDate,
      displayReturnDate: props.displayReturnDate,
      displayDelete: props.displayDelete,
      disabeldReturnDate: props.disabeldReturnDate,
      departTime: props.departTime,
      returnTime: props.returnTime,
    };
  };

  // console.log('Flight', props);

  const handleFocus = (dataType) => {
    let flight = getFlight();
    setHistory({ ...history, [dataType]: flight[dataType] });
    props.onChangeFlight({ ...flight, [dataType]: '' });
  };

  const handleBlur = (dataType, e) => {
    if (!e.target.value) {
      // setData({...data, [dataType]: history[dataType]})
      props.onChangeFlight({ ...getFlight(), [dataType]: history[dataType] });
    } else {
      // setData({...data, [dataType]: e.target.value})
      props.onChangeFlight({ ...getFlight(), [dataType]: e.target.value });
    }
  };

  const handleChange = (dataType, e) => {
    // setData({...data, [dataType]: e.target.value})
    console.log(dataType, e.target.value);
    props.onChangeFlight({ ...getFlight(), [dataType]: e.target.value });
  };

  const handleDelete = () => {
    props.handleDelete(props.index);
  };

  const handleSwitch = () => {
    let flight = getFlight();
    props.onChangeFlight({ ...flight, from: flight.to, to: flight.from });
  };

  return (
    <Box>
      <div className="form-row position-relative">
        <div className="col-lg-6 form-group">
          <input
            type="text"
            onFocus={() => handleFocus('from')}
            onBlur={(e) => handleBlur('from', e)}
            onChange={(e) => handleChange('from', e)}
            value={props.from}
            className="form-control flight-where from"
            required
            placeholder={intl.get('SEARCH.FROM')}
          />
        </div>
        <IconButton
          onClick={handleSwitch}
          className="SwitchIcon"
          color="primary"
          aria-label="switch"
          component="label"
        >
          <ConnectingAirportsIcon />
        </IconButton>
        <div className="col-lg-6 form-group">
          <input
            type="text"
            onFocus={() => handleFocus('to')}
            onBlur={(e) => handleBlur('to', e)}
            onChange={(e) => handleChange('to', e)}
            value={props.to}
            className="form-control flight-where to"
            required
            placeholder={intl.get('SEARCH.TO')}
          />
          {/* <span className="icon-inside">
            <i className="fas fa-map-marker-alt"></i>
          </span> */}
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6 col-lg-3 form-group">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={intl.get('SEARCH.DEPARTDATE')}
              value={moment(props.departDate)}
              minDate={moment().add(1, 'days').toDate()}
              onChange={(newValue) => {
                let updated = false;
                if (props.returnDate) {
                  if (moment(newValue).isAfter(moment(props.returnDate))) {
                    props.onChangeFlight({
                      ...getFlight(),
                      departDate: moment(newValue).format('YYYY-MM-DD'),
                      returnDate: moment(newValue)
                        .add(4, 'days')
                        .format('YYYY-MM-DD'),
                    });
                    updated = true;
                  }
                }

                if (!updated) {
                  props.onChangeFlight({
                    ...getFlight(),
                    departDate: moment(newValue).format('YYYY-MM-DD'),
                  });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} autoComplete="off" />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-md-6 col-lg-3 form-group">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-departtime">
              {intl.get('SEARCH.DEPARTTIME')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-departtime"
              id="demo-simple-select-departtime"
              value={props.departTime || ''}
              label={intl.get('SEARCH.DEPARTTIME')}
              onChange={(e) => {
                // setLuggage(e.target.value);
                props.onChangeFlight({
                  ...getFlight(),
                  departTime: e.target.value,
                });
              }}
            >
              <MenuItem value="">
                <em>Any Time</em>
              </MenuItem>
              <MenuItem value="1">Morning</MenuItem>
              <MenuItem value="2">Afternoon</MenuItem>
              <MenuItem value="3">Evening</MenuItem>
            </Select>
          </FormControl>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={intl.get('SEARCH.DEPARTTIME')}
              value={props.departTime || null}
              onChange={(newValue) => {
                console.log(newValue);
                props.onChangeFlight({ ...getFlight(), departTime: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider> */}
        </div>

        <div className="col-md-6 col-lg-3 form-group">
          {props.displayReturnDate && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={intl.get('SEARCH.RETURNDATE')}
                value={props.returnDate ? moment(props.returnDate) : null}
                minDate={
                  (props.departDate && moment(props.departDate).toDate()) ||
                  moment().add(1, 'days').toDate()
                }
                defaultCalendarMonth={
                  props.departDate ? new Date(props.departDate) : new Date()
                }
                onChange={(newValue) => {
                  // setData({...data, returnDate: newValue})
                  // console.log('return', newValue)
                  props.onChangeFlight({
                    ...getFlight(),
                    returnDate: moment(newValue).format('YYYY-MM-DD'),
                  });
                }}
                // componentsProps={{
                //   actionBar: {
                //     actions: ["clear"]
                //   }
                // }}
                disabled={props.disabeldReturnDate}
                renderInput={(params) => (
                  <TextField {...params} autoComplete="off" />
                )}
              />
            </LocalizationProvider>
          )}
          {props.displayDelete && (
            <IconButton
              edge="end"
              aria-label="delete"
              title="Delete"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
        <div className="col-md-6 col-lg-3 form-group">
          {props.displayReturnDate && (
            // <LocalizationProvider dateAdapter={AdapterDateFns}>
            //   <TimePicker
            //     label={intl.get('SEARCH.RETURNTIME')}
            //     disabled={props.disabeldReturnDate}
            //     value={props.returnTime || null}
            //     onChange={(newValue) => {
            //       console.log(newValue);
            //       props.onChangeFlight({
            //         ...getFlight(),
            //         returnTime: newValue,
            //       });
            //     }}
            //     renderInput={(params) => <TextField {...params} />}
            //   />
            // </LocalizationProvider>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-returntime">
                {intl.get('SEARCH.RETURNTIME')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-returntime"
                id="demo-simple-select-returntime"
                value={props.returnTime || ''}
                label={intl.get('SEARCH.RETURNTIME')}
                onChange={(e) => {
                  // setLuggage(e.target.value);
                  props.onChangeFlight({
                    ...getFlight(),
                    returnTime: e.target.value,
                  });
                }}
              >
                <MenuItem value="">
                  <em>Any Time</em>
                </MenuItem>
                <MenuItem value="1">Morning</MenuItem>
                <MenuItem value="2">Afternoon</MenuItem>
                <MenuItem value="3">Evening</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </Box>
  );
}

export default function SearchBox(props) {
  const [cabin, setCabin] = useState('E');
  const [flightType, setFlightType] = useState('return');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [flights, setFlights] = useState([
    {
      index: 0,
      from: '',
      to: '',
      departDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      returnDate: null,
      displayReturnDate: false,
      disabeldReturnDate: false,
      displayDelete: false,
    },
  ]);
  const [airline, setAirline] = useState('');
  const [showAirline, setShowAirline] = useState(true);
  const [airlines, setAirlines] = useState([]);
  const [luggage, setLuggage] = useState('');

  useEffect(() => {
    let localStorage = window.localStorage;
    if (localStorage.flight) {
      const flight = JSON.parse(localStorage.flight);
      setFlightType(flight.flightType);
      // console.log(flight)
      // setFlights([...flight.flights])

      handleFlights(flight.flightType, flight.flights);
      setAdults(parseInt(flight.travelers.adults));
      setChildren(parseInt(flight.travelers.children));
      setCabin(flight.cabin);
      if (flight.airline) {
        setAirline(flight.airline);
        setAirlines([
          ...NDC_AIRLINES.filter((x) => flight.airline.indexOf(x.code) >= 0),
        ]);
      }
    } else {
      fetch('https://ipapi.co/json/')
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          fetch(
            `https://scholartrip.com//geonames/api/v1/airport/nearest?lat=${res.latitude}&lon=${res.longitude}&lang=en`,
          )
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              for (let airport of res.data) {
                let newFlights = flights;
                newFlights[0].from = airport.iata;
                setFlights([...newFlights]);
                break;
              }
            })
            .catch((error) => {
              console.error(error, typeof error);
            });
        })
        .catch((error) => {
          console.error(error, typeof error);
        });

      setFlightType('oneway');
    }

    let airlineParam = getURLParam('airline');
    if (airlineParam) {
      setShowAirline(false);
      setAirline(airlineParam);
    }
  }, []);

  // const changeCabin = (e) => {
  //   setCabin(e.target.value);
  // };

  const handleFlights = (type, data) => {
    // console.log('flights', type, data)
    if (type == 'return') {
      let newFlight = data[0];
      newFlight.departDate =
        !newFlight.departDate ||
        moment(newFlight.departDate).isBefore(new Date())
          ? moment().add(1, 'days').format('YYYY-MM-DD')
          : newFlight.departDate;
      // console.log('depart', newFlight)
      newFlight.returnDate = newFlight.departDate
        ? moment(newFlight.departDate).add(7, 'days').format('YYYY-MM-DD')
        : moment().add(8, 'days').format('YYYY-MM-DD');
      newFlight.displayReturnDate = true;
      newFlight.disabeldReturnDate = false;

      setFlights([newFlight]);
    } else if (type == 'oneway') {
      let newFlight = data[0];
      newFlight.returnDate = null;
      newFlight.displayReturnDate = true;
      newFlight.disabeldReturnDate = true;

      setFlights([newFlight]);
    } else if (type == 'multicities') {
      let newFlights = data;
      if (data.length < 2) {
        let maxDate = null;
        for (let flight of data) {
          if (
            flight.departDate ||
            moment(maxDate).isBefore(moment(flight.departDate))
          ) {
            maxDate = moment(flight.departDate);
          }
        }

        for (let i = 0; i < FLIGHT_MAX_LENGTH; i++) {
          if (!data.find((flight) => flight.index == i)) {
            newFlights.push({
              index: i,
              from: '',
              to: '',
              departDate: maxDate
                ? maxDate.add(1, 'days').format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
              returnDate: null,
              displayReturnDate: false,
              displayDelete: true,
            });
            break;
          }
        }
      }
      newFlights[0].returnDate = null;
      newFlights[0].displayReturnDate = false;
      newFlights[0].disabeldReturnDate = false;
      setFlights([...newFlights]);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();

    let flightParams = [];
    // let localStorageFlights = []
    let departtime = null;
    let returntime = null;
    for (let flight of flights) {
      let fromCode = GetCode(flight.from);
      let toCode = GetCode(flight.to);
      let departDate = moment(flight.departDate).format('YYYYMMDD');
      let returnDate =
        flight.returnDate && moment(flight.returnDate).format('YYYYMMDD');
      flightParams.push(
        `${fromCode}/${toCode}/${departDate}${
          returnDate ? '/' + returnDate : ''
        }`,
      );

      departtime = flight.departTime;
      returntime = flight.returnTime;

      // localStorageFlights.push({from: flight.from, to: flight.to, departDate: flight.departDate, returnDate: flight.returnDate})
    }

    // let adults = document.getElementById("adulttravellers").value;
    // let children = document.getElementById("childrentravellers").value;

    let localStorage = window.localStorage;
    // console.log('airline', airline)
    // localStorage.clear()
    localStorage.flight = JSON.stringify({
      flights: flights,
      flightType: flightType,
      cabin: cabin,
      travelers: { adults: adults, children: children, infants: 0 },
      airline: airline,
    });

    window.location =
      `/flight?flights=${flightParams.join(
        ';',
      )}&type=${flightType}&cabin=${cabin}&adults=${adults}&children=${children}&infants=0` +
      (props.cid ? '&cid=' + props.cid : '') +
      (airline ? '&airline=' + airline.toUpperCase() : '') +
      (luggage ? '&luggage=' + luggage : '') +
      (departtime ? '&departtime=' + departtime : '') +
      (returntime ? '&returntime=' + returntime : '');
  };

  const handleChangeFlightType = (event) => {
    setFlightType(event.target.value);
    handleFlights(event.target.value, flights);
  };

  const handleAdd = () => {
    let maxDate = null;
    for (let flight of flights) {
      if (
        flight.departDate ||
        moment(maxDate).isBefore(moment(flight.departDate))
      ) {
        maxDate = moment(flight.departDate);
      }
    }

    // console.log('max date', maxDate)
    for (let i = 0; i < FLIGHT_MAX_LENGTH; i++) {
      if (!flights.find((flight) => flight.index == i)) {
        setFlights([
          ...flights,
          {
            index: i,
            from: '',
            to: '',
            departDate: maxDate ? maxDate.add(1, 'days').toDate() : moment(),
            returnDate: null,
            displayReturnDate: false,
            displayDelete: true,
          },
        ]);
        break;
      }
    }
  };

  const handleDelete = (index) => {
    setFlights((prev) => [...prev.filter((item) => item.index !== index)]);
  };

  const handleChangeFlight = (flight) => {
    console.log('change father flight', flight);
    // console.log(flights.find(item=>item.index === flight.index), {...flights.find(item=>item.index === flight.index), ...flight})
    let newFlights = flights;
    let index = newFlights.findIndex((item) => item.index === flight.index);
    newFlights[index] = { ...newFlights[index], ...flight };

    // console.log('new flights', newFlights)
    setFlights([...newFlights]);
  };

  const handleChangeTravelers = (type, value) => {
    // console.log('travelers', type, value, adults, children)
    if (type == 'adult') {
      if (adults + value <= 0 || adults + value >= 9) {
        return;
      }
      setAdults(adults + value);
    } else if (type == 'child') {
      if (children + value < 0 || children + value >= 9) {
        return;
      }

      setChildren(children + value);
    }
  };

  // const handleChangeAirline = (event) =>{
  //   setAirline(event.target.value)
  // }

  return (
    <div className="col-lg-12 mb-lg-0">
      <form id="bookingFlight" method="post">
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            // defaultValue={flightType}
            value={flightType}
            onChange={handleChangeFlightType}
          >
            <FormControlLabel
              value="return"
              control={<Radio />}
              label={intl.get('SEARCH.RETURN')}
            />
            <FormControlLabel
              value="oneway"
              control={<Radio />}
              label={intl.get('SEARCH.ONE_WAY')}
            />
            <FormControlLabel
              value="multicities"
              control={<Radio />}
              label={intl.get('SEARCH.MULTI_CITY')}
            />
          </RadioGroup>
        </FormControl>
        <TransitionGroup>
          {flights.map((item) => (
            <Collapse key={item.index}>
              <Flight
                {...item}
                handleDelete={handleDelete}
                onChangeFlight={handleChangeFlight}
              />
            </Collapse>
          ))}
        </TransitionGroup>

        {/* <div className="hotel-part">
          <div className="custom-control custom-radio custom-control-inline">
              <input id="checked" name="checked" className="custom-control-input" required type="radio"/>
              <label className="custom-control-label" htmlhtmlFor="checked">
                <h6 style={{lineHeight: "24px"}}>I only need a hotel for part of my stay</h6>
              </label>
          </div>
      </div>	 */}
        {flightType == 'multicities' && flights.length < FLIGHT_MAX_LENGTH && (
          <div className="form-row">
            <div className="col-lg-12 form-group">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAdd}
              >
                Add another flight
              </Button>
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="col-md-6 col-lg-3 travellers-class form-group">
            {/* <input
              type="text"
              id="hotelsTravellersClass"
              name="hotelRoomsTravellers"
              className="travellers-class-input form-control"
              autoComplete="off"
              placeholder="Travelers"
              required
              onChange={() => console.log('total', adults, children)}
              value={adults + children + intl.get('SEARCH.TRAVELER')}
              disabled={props.disabled}
            />
            <span className="icon-inside">
              <i className="fas fa-caret-down"></i>
            </span> */}
            <FormControl fullWidth>
              <InputLabel id="hotelsTravellersClass-label">
                {intl.get('SEARCH.TRAVELLER')}
              </InputLabel>
              <Select
                labelId="hotelsTravellersClass-label"
                id="hotelsTravellersClass"
                name="hotelRoomsTravellers"
                value="1"
                label={intl.get('SEARCH.TRAVELLER')}
                inputProps={{ readOnly: true }}
              >
                <MenuItem value="1">
                {intl.get('SEARCH.TRAVELLERS', {travelers: adults + children})}
                </MenuItem>
              </Select>
            </FormControl>
            <div
              className="roomtravellers-dropdown travellers-dropdown"
              style={{ display: 'none' }}
            >
              <div className="row align-items-center">
                <div className="col-sm-7">
                  <p className="mb-sm-0">
                    {intl.get('SEARCH.ADULTS')}{' '}
                    <small className="text-muted">
                      (12+ {intl.get('SEARCH.YEAR')})
                    </small>
                  </p>
                </div>
                <div className="col-sm-5">
                  <div className="qty input-group">
                    <div className="input-group-prepend">
                      <button
                        type="button"
                        className="btn bg-light-4"
                        disabled={adults <= 1}
                        onClick={() => handleChangeTravelers('adult', -1)}
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      data-ride="spinner"
                      id="adulttravellers"
                      name="adult-travellers"
                      className="qty-spinner form-control"
                      value={adults}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn bg-light-4"
                        disabled={adults >= 8}
                        onClick={() => handleChangeTravelers('adult', 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row align-items-center">
                <div className="col-sm-7">
                  <p className="mb-sm-0">
                    {intl.get('SEARCH.CHILDREN')}{' '}
                    <small className="text-muted">
                      (1-12 {intl.get('SEARCH.YEAR')})
                    </small>
                  </p>
                </div>
                <div className="col-sm-5">
                  <div className="qty input-group">
                    <div className="input-group-prepend">
                      <button
                        type="button"
                        className="btn bg-light-4"
                        disabled={children <= 0}
                        onClick={() => handleChangeTravelers('child', -1)}
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      data-ride="spinner"
                      id="childrentravellers"
                      name="children-travellers"
                      className="qty-spinner form-control"
                      value={children}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn bg-light-4"
                        disabled={children >= 8}
                        onClick={() => handleChangeTravelers('child', 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-yellow btn-sm btn-block submit-done mt-3"
                type="button"
              >
                {intl.get('SEARCH.DONE')}
              </button>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 travellers-class form-group">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-luggage">Luggage</InputLabel>
              <Select
                labelId="demo-simple-select-luggage"
                id="demo-simple-select-luggage"
                value={luggage}
                label="Luggage"
                onChange={(e) => {
                  setLuggage(e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
              </Select>
            </FormControl>
            {/* <Autocomplete
              options={[{ title: '0', value: 0 }]}
              value={
                luggageNum > 0
                  ? { title: luggageNum.toString(), value: luggageNum }
                  : null
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <ButtonGroup variant="text" aria-label="Basic button group">
                    <Button
                      disabled={luggageNum <= 0}
                      onClick={() => setLuggageNum(luggageNum - 1)}
                    >
                      -
                    </Button>
                    <Button disabled>{luggageNum}</Button>
                    <Button
                      disabled={luggageNum >= 3}
                      onClick={() => setLuggageNum(luggageNum + 1)}
                    >
                      +
                    </Button>
                  </ButtonGroup>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Luggage" />
              )}
            /> */}
          </div>

          <div className="col-md-6 col-lg-3 travellers-class form-group">
            {/* <input type="text" id="flightClass" className="travellers-class-input form-control" required autoComplete="off" name="flightClass" placeholder={intl.get("CABIN.E")} value={intl.get("CABIN." + cabin)} readOnly disabled={props.disabled}/>
                            <span className="icon-inside"><i className="fas fa-caret-down"></i></span>
                            <div className="cabin-dropdown travellers-dropdown" style={{display: "none"}}>
                                <div className="mb-3" onChange={changeCabin}>
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassEconomic" name="flight-class" className="flight-class custom-control-input" value="E" checked={cabin == 'E'} readOnly type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassEconomic">{intl.get("CABIN.E")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassPremiumEconomic" name="flight-class" className="flight-class custom-control-input" value="P" checked={cabin == 'P'} readOnly type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassPremiumEconomic">{intl.get("CABIN.P")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassBusiness" name="flight-class" className="flight-class custom-control-input" value="B" checked={cabin == 'B'} readOnly type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassBusiness">{intl.get("CABIN.B")}</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input id="flightClassFirstClass" name="flight-class" className="flight-class custom-control-input" value="F" checked={cabin == 'F'} readOnly type="radio"/>
                                        <label className="custom-control-label" htmlFor="flightClassFirstClass">{intl.get("CABIN.F")}</label>
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-block submit-done mt-3" type="button">{intl.get("SEARCH.DONE")}</button>
                            </div> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {intl.get('SEARCH.CABIN')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cabin}
                label={intl.get('SEARCH.CABIN')}
                onChange={(e) => {
                  setCabin(e.target.value);
                }}
              >
                <MenuItem value="E">{intl.get('CABIN.E')}</MenuItem>
                <MenuItem value="P">{intl.get('CABIN.P')}</MenuItem>
                <MenuItem value="B">{intl.get('CABIN.B')}</MenuItem>
                <MenuItem value="F">{intl.get('CABIN.F')}</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* {showAirline && ( */}
            <div className="col-md-6 col-lg-3 travellers-class form-group">
              {/* <input type="text" className="form-control" autoComplete="off" placeholder={intl.get("SEARCH.AIRLINE")} value={airline} onChange={handleChangeAirline}/> */}
              <Autocomplete
                multiple
                // disablePortal
                options={NDC_AIRLINES}
                value={airlines}
                // defaultValue={airline ? airlines.find(x=>x.code == airline) : null}
                // sx={{ width: 300 }}
                getOptionLabel={(option) => option.label}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.code}
                      {...getTagProps({ index })}
                      // disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  ))
                }
                // isOptionEqualToValue={(option, value) => {
                //   console.log(option, value);
                //   return option.code === value.code;
                // }}
                onChange={(_, value) => {
                  if (value && value.length > 0) {
                    setAirline(value.map((v) => v.code).join(','));
                  } else {
                    setAirline(null);
                  }
                  // setAirline(value && value.map((v) => v.code).join(','));
                  setAirlines(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={intl.get('SEARCH.AIRLINE')} />
                )}
              />
            </div>
          {/* )} */}
        </div>
        <div id="bookingHotels" method="post" className="form-btns">
          <div className="container-lg">
            <div className="d-flex justify-content-center">
            {props.showClose && (
            <div className="col-lg-3">
                  <button
                    className="btn btn-light btn-block"
                    type="button"
                    onClick={() => {
                      props.changeShowType(false);
                    }}
                  >
                    {intl.get('SEARCH.CLOSE')}
                  </button>
               
              </div>)}
              {props.showClose && (<div className="col-lg-3"></div>)}
              {/*<div className="col-lg-3">
                 <IconButton className="SwitchIcon" color="primary" aria-label="switch" component="label" onClick={handleSwitch}>
        <ConnectingAirportsIcon/>
      </IconButton> 
              </div>*/}
              <div className="col-lg-3">
                <button
                  className="btn btn-yellow btn-block"
                  type="submit"
                  onClick={handleClick}
                  disabled={props.disableButton}
                >
                  {intl.get('SEARCH.SEARCH')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
