import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import intl from 'react-intl-universal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

import { Dialog, DialogContent, AppBar, Toolbar, IconButton, CardHeader, Radio } from '@mui/material';
import { useState } from 'react';
import MyTabs from '../MyTabs';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {IS_DirectNDCS} from '../../data/config'
import Crypto from "../../utils/ctypto"


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard(props) {
  const { enqueueSnackbar } = useSnackbar();
  // const [select, setSelect] = React.useState(props.routing.key == props.selectedKey)
  const [open, setOpen] = React.useState(false)
  const [prebookingInfo, setPrebookingInfo] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [brand, setBrand] = useState(null)
  console.log('basic card', props)
  
  let brandNames = []
  if(props.routing.brand && props.routing.brand.brands){
    brandNames = props.routing.brand.brands.map((item=>item.name));
  }else if(props.brandRule && props.brandRule.brands){
    brandNames = props.brandRule.brands.map((item=>item.name));
  }

  useEffect(()=>{
    if(props.routing.brand && props.routing.brand.brands && props.routing.brand.brands.some(b=>b.descriptions && b.descriptions.length>0)){
      setBrand(props.routing.brand)
    }else if(props.brandRule){
      setBrand(props.brandRule)
    }
  },[])

  // let rules = []
  // for(let rule in props.routing.rules){
  //   rules.push(`${rule}: ${props.routing.rules[rule]}`)
  // }

  const handleClick = ()=>{
    props.chooseSell(props.routing);
    // setSelect(!select)
  }

  const requestFareRules = (value)=>{
    // axios.post('api/PrebookingOnly', {value: "farerule?" + (new URLSearchParams({cid: props.routing.cid, customerSessionId: props.prebooking.customerSessionId, 
    //   gds: props.prebooking.gds.toUpperCase(), pcc: props.prebooking.pcc, adults: props.travelers.adults, children: props.travelers.children})).toString() + "&offer="+JSON.stringify(props.prebooking.offerInfo)})
		axios.post('api/PrebookingOnly', {value: Crypto.encrypt(value)})
    .then(function (res) {
		  // let json = JSON.parse(res.data);
		  // console.log("book page select fare prebooking", json)
		  if("APIError" in res.data){
			  for(let error of res.data.APIError.errors){
					for(let error of res.data.APIError.errors){
						enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
						break
					}
			  }			  
		  }else if("airTicketPriceChangedResponse" in res.data){
			  enqueueSnackbar('Price changed, coming back to re-search.', {variant: 'warning',  autoHideDuration: 5000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
		  }else if("airTicketRuleResponse" in res.data){
			  setPrebookingInfo(res.data.airTicketRuleResponse)
        setDialogOpen(true)
        props.addPrebookings(props.routing.key, res.data.airTicketRuleResponse)
		  }
		})
		.catch(function (error) {
		  // console.error(error, typeof(error));
		  enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
		}).then(function () {
		  setOpen(false)
		});
  }

  const handleShowRules=()=>{

    if(!IS_DirectNDCS.includes(props.routing.cid)){
      setDialogOpen(true)
      setPrebookingInfo(props.prebooking)
    }else{ 

    let params = {
      adults: props.travelers.adults,
      children: props.travelers.children,
      routing: JSON.stringify(props.routing),
      cid: props.routing.cid,
      isPrebooking: true,
      upsell: IS_DirectNDCS.includes(props.routing.cid),
      bundle: IS_DirectNDCS.includes(props.routing.cid)
    }


    setOpen(true)
    if(prebookingInfo == null){
    // 
      if(props.prebooking){
        requestFareRules("farerule?" + (new URLSearchParams({cid: props.routing.cid, customerSessionId: props.prebooking.customerSessionId, 
          gds: props.prebooking.gds.toUpperCase(), pcc: props.prebooking.pcc, adults: props.travelers.adults, children: props.travelers.children})).toString() + "&offer="+JSON.stringify(props.prebooking.offerInfo))
      }else{
        axios.post('api/book', params)
        .then(function (res) {
          // let json = JSON.parse(res.data);
          if("APIError" in res.data){
            for(let error of res.data.APIError.errors){
              for(let error of res.data.APIError.errors){
                enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
                break
              }
            }			  
          }else if("airTicketPriceChangedResponse" in res.data){
            enqueueSnackbar('Price changed, coming back to re-search.', {variant: 'warning',  autoHideDuration: 5000, anchorOrigin:{horizontal: 'center', vertical: 'top'}})
          }else if("airTicketRuleResponse" in res.data){
            requestFareRules("farerule?" + (new URLSearchParams({cid: props.routing.cid, customerSessionId: res.data.airTicketRuleResponse.customerSessionId, 
              gds: res.data.airTicketRuleResponse.gds.toUpperCase(), pcc: res.data.airTicketRuleResponse.pcc, adults: props.travelers.adults, children: props.travelers.children})).toString() + "&offer="+JSON.stringify(res.data.airTicketRuleResponse.offerInfo))
          }

          
        })
        .catch(function (error) {
            // console.error(error, typeof(error));
            enqueueSnackbar(error.message, {variant: 'error', autoHideDuration: 6000 ,anchorOrigin:{horizontal: 'center', vertical: 'top'}})
        }).then(function () {
            // setOpen(false)
          });
      }
    
    }else{
      setOpen(false)
      setDialogOpen(true)
    }
    }
  }

  return (
    <Card>
        <CardHeader
        action={
          // <IconButton aria-label="settings">
          //   <MoreVertIcon />
          // </IconButton>
          <Radio
          // checked={false}
          checked={props.routing.key == props.selectedKey}
          // defaultChecked={false}
          onChange={handleClick}
          value="a"
          name="radio-buttons"
          inputProps={{ 'aria-label': 'A' }}
        />
        }
        title={brandNames.join(',')}
        titleTypographyProps={{fontSize: 16}}
        // subheader="September 14, 2016"
      />
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography> */}
        {/* <Typography variant="h6">
        {brandNames.join(',')}
        </Typography> */}
        <ul style={{paddingLeft: '1rem'}}>
        {brand && brand.brands && brand.brands.map(item=> item.descriptions && item.descriptions.map((description, index)=>
                <li key={item.name + index} style={{listStyleType: 'disc'}}>
                <Typography variant="caption" display="block" gutterBottom>
                {description}
              </Typography>
              </li>
          ))}
          </ul>
        <Typography variant="subtitle2">
          {(props.routing.adultBasePrice + props.routing.adultTax).toLocaleString('en', {
                                    style: 'currency', 
                                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })} per passenger
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" size="small" onClick={handleClick} disabled={props.routing.key == props.selectedKey}>{props.routing.key == props.selectedKey ? intl.get('BOOK.CABIN_UNSELECT') : intl.get('BOOK.CABIN_SELECT')}</Button>

        <Button size="small" onClick={handleShowRules}>{intl.get('BOOK.SHOW_FARE_RULES')}</Button>
      </CardActions>
      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={open}
  onClick={()=>setOpen(false)}
>
  <CircularProgress color="inherit" />
</Backdrop>
<Dialog
	  	fullScreen
        open={dialogOpen}
        onClose={()=>{setDialogOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
<AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>setDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
			{intl.get('BOOK.FARE_RULES')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
      <MyTabs fareRules={prebookingInfo} brandRules={brand}/>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
