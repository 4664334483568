import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import intl from 'react-intl-universal';

export default function OrderSummary(props) {
  // console.log('order summary', props)
  const [markup, setMarkup] = useState(0);

  let brandNames = [];
  if (props.routing && props.routing.brand && props.routing.brand.brands) {
    brandNames = props.routing.brand.brands.map((item) => item.name);
  } else if (
    props.prebooking &&
    props.prebooking.brand &&
    props.prebooking.brand.brands
  ) {
    // console.log('brand rules');
    brandNames = props.prebooking.brand.brands.map((item) => item.name);
  }

  if (brandNames.length == 0) {
    if (props.routing && props.routing.trips) {
      let cabins = { E: 0, P: 1, B: 2, F: 3 };
      let cabin = null;
      for (let trip of props.routing.trips) {
        for (let segment of trip.segments) {
          if (segment.cabin) {
            if (!cabin) {
              cabin = segment.cabin;
            } else if (
              segment.cabin in cabins &&
              cabins[segment.cabin] > cabins[cabin]
            ) {
              cabin = segment.cabin;
            }
          }
        }
      }

      if (cabin) {
        brandNames = [intl.get('CABIN.' + cabin)];
      }
    }
  }

  // function calculateTotalPrice(routing, travelers){
  //     return (routing.adultBasePrice + routing.adultTax) * travelers.adults + (routing.childBasePrice + routing.childTax) * travelers.children
  // }

  function handleChangeMarkup(e) {
    if (e.target.value && e.target.value.indexOf('.') < 0) {
      e.target.value = ~~e.target.value;
    }

    if (e.target.value) {
      setMarkup(parseFloat(e.target.value));
      props.changeMarkup(parseFloat(e.target.value));
    } else {
      setMarkup(0);
      props.changeMarkup(0);
    }
  }

  return (
    <div className="sticky-col sticky-top">
      <div className="theme-sidebar-section _mb-10">
        <h5 className="theme-sidebar-section-title">
          {intl.get('BOOK.ORDER_SUMMARY')}
        </h5>
        <div className="theme-sidebar-section-charges">
          <ul className="theme-sidebar-section-charges-list">
            <li className="theme-sidebar-section-charges-item">
              <h5 className="theme-sidebar-section-charges-item-title">
                {intl.get('BOOK.ORDER_AIR_TICKET')}
              </h5>
              <Grid container>
              <Grid item xs={12} container>
                <Typography variant='body2' gutterBottom>{brandNames.join(',')}</Typography>
              </Grid>
              {props.travelers.children > 0 && <Grid item xs={12} container>
                <Grid item xs={12}>
                <Typography variant='subtitle1'>Adult</Typography>
                </Grid>
                </Grid>}
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      {/* {brandNames.join(',')} */}
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="right">
                      {(
                        props.routing.adultBasePrice + props.routing.adultTax
                      ).toLocaleString('en', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      Base fare
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="right">
                      {props.routing.adultBasePrice.toLocaleString('en', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                    <Typography variant="caption">Taxes</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="right">
                      {props.routing.adultTax.toLocaleString('en', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                {props.travelers.children > 0 && 
                <>
                <Grid item xs={12} container>
                  <Grid item xs={12}><Typography variant='subtitle1'>Child</Typography></Grid>
                </Grid>
                                <Grid item xs={12} container>

                                <Grid item xs={6}>
                                  <Typography variant="caption">
                                    {/* {brandNames.join(',')} */}
                                    Total
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="subtitle2" align="right">
                                    {(
                                      props.routing.childBasePrice + props.routing.childTax
                                    ).toLocaleString('en', {
                                      style: 'currency',
                                      currency: 'USD',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} container>
                                <Grid item xs={6}>
                                  <Typography variant="caption" gutterBottom>
                                    Base fare
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="subtitle2" align="right">
                                    {props.routing.childBasePrice.toLocaleString('en', {
                                      style: 'currency',
                                      currency: 'USD',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} container>
                                <Grid item xs={6}>
                                  <Typography variant="caption">Taxes</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="subtitle2" align="right">
                                    {props.routing.childTax.toLocaleString('en', {
                                      style: 'currency',
                                      currency: 'USD',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </Grid>
                              </Grid>
                              </>
                }
              </Grid>
              {/* <p className="theme-sidebar-section-charges-item-subtitle">
                {brandNames.join(',')}
              </p>
              <p className="theme-sidebar-section-charges-item-price _mt-10">
                {(props.routing.adultBasePrice + props.routing.adultTax).toLocaleString('en', {
                                    style: 'currency', 
                                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
              </p> */}
              {props.GDSPrice && (
                <p className="theme-sidebar-section-charges-item-subtitle">
                  GDS:{' '}
                  {/* {intl.get('PRICE', {
                    currency: intl
                      .get('CURRENCY.' + props.currency + '.SYMBOL')
                      .defaultMessage(props.routing.currency),
                    price: props.GDSPrice,
                  })} */}
                  {props.GDSPrice.toLocaleString('en', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              )}

{/* <p className="theme-sidebar-section-charges-item-price _mt-10">
                {props.travelers.adults + props.travelers.children}
              </p> */}
            </li>
            {/* {props.displaySetMarkup && (
              <li className="theme-sidebar-section-charges-item">
                <h5 className="theme-sidebar-section-charges-item-title">
                  {intl.get('FLIGHT.SET_MARKUP')}
                </h5>
                <input
                  type="number"
                  value={markup}
                  onChange={handleChangeMarkup}
                  width="3rem"
                  min="0"
                  max="1000"
                  step="0.01"
                ></input>
              </li>
            )} */}
            {props.seats && props.seats.length > 0 && (
              <li className="theme-sidebar-section-charges-item">
                <h5 className="theme-sidebar-section-charges-item-title">
                  Seat
                </h5>
                <Grid container>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography variant="caption" gutterBottom>
                        Number of seats
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" align="right">
                        {props.seats
                          .map((item) => item.seats.length)
                          .reduce((x, y) => x + y)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography variant="caption" gutterBottom>
                        Total price
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" align="right">
                        {props.seats
                          .map((seat) =>
                            seat.seats.reduce((prev, curr) => {
                              return prev + curr.travelerPricing[0].price.total;
                            }, 0),
                          )
                          .reduce((x, y) => x + y, 0)
                          .toLocaleString('en', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </li>
            )}

            {props.bundles &&
              props.bundles
                .map((item) =>
                  item.bundles.reduce(
                    (prev, curr) => prev + curr.totalAmount,
                    0,
                  ),
                )
                .reduce((prev, curr) => prev + curr, 0) > 0 && (
                <li className="theme-sidebar-section-charges-item">
                  <h5 className="theme-sidebar-section-charges-item-title">
                    Travel add-ons
                  </h5>
                  <Grid container>
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography variant="caption" gutterBottom>
                      Total price
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" align="right">
                      {props.bundles
                        .map((item) =>
                          item.bundles.reduce(
                            (prev, curr) => prev + curr.totalAmount,
                            0,
                          ),
                        )
                        .reduce((prev, curr) => prev + curr, 0).toLocaleString('en', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                  </Grid>
                </li>
              )}
            <li className="theme-sidebar-section-charges-item">
              <h5 className="theme-sidebar-section-charges-item-title">
                {intl.get('BOOK.TRAVELERS_NUMBER')}
              </h5>
              <Grid container>
              {props.travelers.adults > 0 &&
                  <Grid item xs={12} container>
                    <Grid item xs={6}>
                      <Typography variant="caption" gutterBottom>
                      {intl.get('COMMON.PERADULT') + ' total'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" align="right">
                      {(
                    (props.routing.adultBasePrice + props.routing.adultTax) *
                      1 +
                    markup * 1
                  ).toLocaleString('en', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                      </Typography>
                    </Grid>
                  </Grid>}
                  {props.travelers.children > 0 && (
                <Grid item xs={12} container>
                <Grid item xs={6}>
                  <Typography variant="caption" gutterBottom>
                  {intl.get('COMMON.PERCHILD') + ' total'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" align="right">
                  {(
                    (props.routing.childBasePrice + props.routing.childTax) *
                      1 +
                    markup * 1
                  ).toLocaleString('en', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  </Typography>
                </Grid>
              </Grid>
              )}
                  </Grid>

              

              <p className="theme-sidebar-section-charges-item-price _mt-10">
                {props.travelers.adults + props.travelers.children}
              </p>
            </li>
          </ul>
          <p className="theme-sidebar-section-charges-total">
            {intl.get('BOOK.ORDER_TOTAL')}
            <span>
              {/* {intl.get('PRICE', {
                currency: intl
                  .get('CURRENCY.' + props.currency + '.SYMBOL')
                  .defaultMessage(props.routing.currency),
                price:
                  (props.routing.adultBasePrice + props.routing.adultTax) *
                    props.travelers.adults +
                  (props.routing.childBasePrice + props.routing.childTax) *
                    props.travelers.children +
                  markup * (props.travelers.adults + props.travelers.children) +
                  props.bundles
                    .map((item) =>
                      item.bundles.reduce(
                        (prev, curr) => prev + curr.totalAmount,
                        0,
                      ),
                    )
                    .reduce((prev, curr) => prev + curr, 0) +
                  props.seats
                    .map((seat) =>
                      seat.seats.reduce((prev, curr) => {
                        return prev + curr.travelerPricing[0].price.total;
                      }, 0),
                    )
                    .reduce((x, y) => x + y, 0),
              })} */}
              {(
                (props.routing.adultBasePrice + props.routing.adultTax) *
                  props.travelers.adults +
                (props.routing.childBasePrice + props.routing.childTax) *
                  props.travelers.children +
                markup * (props.travelers.adults + props.travelers.children) +
                props.bundles
                  .map((item) =>
                    item.bundles.reduce(
                      (prev, curr) => prev + curr.totalAmount,
                      0,
                    ),
                  )
                  .reduce((prev, curr) => prev + curr, 0) +
                props.seats
                  .map((seat) =>
                    seat.seats.reduce((prev, curr) => {
                      return prev + curr.travelerPricing[0].price.total;
                    }, 0),
                  )
                  .reduce((x, y) => x + y, 0)
              ).toLocaleString('en', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </p>
          {/* <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("BOOK.ORDER_CURRENCY_DESCRIPTION", 
          {currency: intl.get('CURRENCY.'+props.currency+'.NAME')})}</p>
          <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("FLIGHT.MARKUP_DESCRIPTION")}</p>
          <p className="theme-sidebar-section-charges-item-subtitle">{intl.get("FLIGHT.INVOICE_CREDIT_TOTAL")}{intl.get('PRICE', 
          {currency: intl.get("CURRENCY."+ props.currency + ".SYMBOL").defaultMessage(props.routing.currency), 
          price: Math.round(((props.routing.adultBasePrice + props.routing.adultTax) * props.travelers.adults + 
          (props.routing.childBasePrice + props.routing.childTax)*props.travelers.children + 
          markup*(props.travelers.adults + props.travelers.children) + (props.bundles.map(item=>item.bundles.reduce((prev, 
          curr)=>prev + curr.totalAmount, 0)).reduce((prev, curr)=>prev + curr, 0)) + props.seats.map(seat=>seat.seats.reduce((prev, 
          curr)=>{return prev + curr.travelerPricing[0].price.total}, 0)).reduce((x,y)=>x+y, 0))*(1+3.0/100)*100)/100})}</p> */}
        </div>
      </div>
      {props.displayBookButton ? (
        <div className="btn-wrapper">
          <button
            type="submit"
            className="btn btn-yellow checkout-form__btn mkt-chkt-pscrlnw-btn"
            disabled={props.disabled}
          >
            <span className="btn-text mkt-chkt-pscrlnw-btn">
              {intl.get('BOOK.ORDER_BOOK')}
            </span>
            <i className="btn-icn hif hif-arrow-right"></i>
            {/* <span className="btn-preloader"></span> */}
            {/* <i className="fa fa-arrow-right"></i> */}
          </button>
        </div>
      ) : null}
    </div>
  );
}
