import React, { useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import { TextField } from '@mui/material';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import { Search, Close } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

const englishpattern = new RegExp('[A-Za-z]+');
// function removeCss() {
//   var links = document.getElementsByTagName('link');

//   // for (var i = 0; i < links.length; i++) {
//   //     // var _href = links[i].href;
//   //     console.log(links[i].href);
//   //     links[i].parentNode.removeChild(links[i]);
//   //     // if (links[i] && links[i].href && links[i].href.indexOf(href) != -1) {
//   //     //     links[i].parentNode.removeChild(links[i]);
//   //     // }
//   // }

//   for (var i = links.length - 1; i >= 0; i--) {
//     // var _href = links[i].href;
//     links[i].parentNode.removeChild(links[i]);
//     // if (links[i] && links[i].href && links[i].href.indexOf(href) != -1) {
//     //     links[i].parentNode.removeChild(links[i]);
//     // }
//   }
// }

// function removeScript() {
//   var scripts = document.getElementsByTagName('script');
//   // for (var i = 0; i < scripts.length; i++) {
//   //     console.log(scripts[i].src)

//   //     scripts[i].parentNode.removeChild(scripts[i]);
//   //     // if (scripts[i] && scripts[i].src && scripts[i].src.indexOf(src) != -1) {
//   //     //     scripts[i].parentNode.removeChild(scripts[i]);
//   //     // }
//   // }
//   for (var i = scripts.length - 1; i >= 0; i--) {
//     scripts[i].parentNode.removeChild(scripts[i]);
//     // if (scripts[i] && scripts[i].src && scripts[i].src.indexOf(src) != -1) {
//     //     scripts[i].parentNode.removeChild(scripts[i]);
//     // }
//   }
// }

// function remove(tag) {
//   var tags = document.getElementsByTagName(tag);
//   for (var i = tags.length - 1; i >= 0; i--) {
//     tags[i].parentNode.removeChild(tags[i]);
//   }
// }

// function removeStyle() {
//     var styles = document.getElementsByTagName("style");
//     for (var i = 0; i < styles.length; i++) {
//         styles[i].parentNode.removeChild(styles[i]);
//         // if (scripts[i] && scripts[i].src && scripts[i].src.indexOf(src) != -1) {
//         //     scripts[i].parentNode.removeChild(scripts[i]);
//         // }
//     }
// }

export default function SearchHotelInput(props) {
  const [search, setSearch] = useState(false);
  const recommandeds = [
    {
      label: '洛杉矶',
      longitude: '-118.24437',
      latitude: '34.05593',
      cityName: '',
      type: '',
    },
    {
      label: '纽约',
      longitude: '-74.00666',
      latitude: '40.71643',
      cityName: '',
      type: '',
    },
    {
      label: '新加坡',
      longitude: '103.87546',
      latitude: '1.30243',
      cityName: '',
      type: '',
    },
    {
      label: '首尔',
      longitude: '126.97937',
      latitude: '37.56403',
      cityName: '',
      type: '',
    },
    {
      label: '东京',
      longitude: '139.78738',
      latitude: '35.6792',
      cityName: '',
      type: '',
    },
    {
      label: '大阪',
      longitude: '135.51058',
      latitude: '34.68569',
      cityName: '',
      type: '',
    },
    {
      label: '迪拜',
      longitude: '54.9472934',
      latitude: '25.0757595',
      cityName: '',
      type: '',
    },
    {
      label: '巴黎',
      longitude: '2.29363',
      latitude: '48.87372',
      cityName: '',
      type: '',
    },
    {
      label: '伦敦',
      longitude: '-0.12563',
      latitude: '51.50568',
      cityName: '',
      type: '',
    },
    {
      label: '曼谷',
      longitude: '100.56033',
      latitude: '13.79156',
      cityName: '',
      type: '',
    },
    {
      label: '普吉岛',
      longitude: '98.3285753',
      latitude: '7.8537497',
      cityName: '',
      type: '',
    },
    {
      label: '芭提雅',
      longitude: '100.8740972',
      latitude: '12.9002587',
      cityName: '',
      type: '',
    },
    {
      label: '吉隆坡',
      longitude: '101.71288',
      latitude: '3.15618',
      cityName: '',
      type: '',
    },
  ];

  const [options, setOptions] = useState([]);
  const [searching, setSearching] = useState(false);
  const [inputValue, setInputValue] = useState('');

  // useEffect(() => {
  //   loadCss(
  //     'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css',
  //   );
  //   isInclude('css/searchHotelInput.css') ||
  //     loadCss('css/searchHotelInput.css');
  // }, []);

  // useEffect(()=>{
  //     console.log('effect', inputValue);
  // },[inputValue])

  const handleChange = (e) => {
    // console.log('change', e.target.value)
    // let inputValue = e.target.value
    setInputValue(e.target.value);
    setSearch(true);
    // console.log('input value', inputValue)
    if (!e.target.value) {
      return;
    }

    let url = 'api/SearchHotelWheres?where=' + e.target.value;
    if (!englishpattern.test(e.target.value)) {
      url += '&lang=cn';
    }
    setSearching(true);
    let newOptions = [];
    axios
      .get(url)
      .then(function (res) {
        // console.log(res)
        if (!res.data) {
          return;
        }
        let json = JSON.parse(res.data);
        let exists = [];
        for (let item of json) {
          if (
            !item.name ||
            !item.countryname ||
            !item.Longitude ||
            !item.Latitude
          ) {
            continue;
          }

          if (exists.indexOf(`${item.name},${item.countryname}`) >= 0) {
            continue;
          } else {
            exists.push(`${item.name},${item.countryname}`);
          }

          newOptions.push({
            label: item.name,
            longitude: item.Longitude,
            latitude: item.Latitude,
            cityName: item.cityname,
            countryName: item.countryname,
            type: item.type,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // console.log('new options', newOptions);
        setOptions(newOptions);
        setSearching(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          // width: 500,
          maxWidth: '100%',
        }}
      >
        <TextField
          size="small"
          onChange={handleChange}
          value={inputValue}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {searching ? <CircularProgress size={20} /> : <Search />}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Close
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setInputValue('')}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <div className="page-hotel">
        <div className="hotel-row">
          <div className="hotel-container-search">
            {/* {searching ? <div><div className="spinnericon" /></div>:<div><div id="searchicon" /></div>}
            
            <div><div id="xmarkicon" onClick={()=>setInputValue('')} /></div>
            <input type="text" id="searchstring" name="search" placeholder="台北" autoComplete='off' style={{width: '88.5%'}} 
            onChange={handleChange} value={inputValue} />
            <div className="remove" onClick={()=>{console.log('cancel')}}>取消</div> */}
          </div>
        </div>
        {!search ? (
          <>
            <div className="hotel-row">
              <div className="hotel-container-search-list">
                <div className="hotel-row">
                  <div className="col-50">
                    <div className="headTitle">當前城市</div>
                  </div>
                </div>
                <div className="hotel-row">
                  <div className="position">
                    <svg width={24} height={24} viewBox="0 0 384 512">
                      <path
                        d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"
                        fill="#ff5722"
                      />
                    </svg>
                    <span>定位中...</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hotel-row">
              <div className="hotel-container-search-list">
                <div className="hotel-row">
                  <div className="col-50">
                    <div className="headTitle">歷史搜尋</div>
                  </div>
                  <div className="col-50">
                    <div className="headTitle" style={{ textAlign: 'right' }}>
                      清除歷史
                    </div>
                  </div>
                </div>
                <div className="hotel-row">
                  <div className="item">
                    <div className="item-inner">
                      <div className="text">北京</div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-inner">
                      <div className="text">上海</div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-inner">
                      <div className="text">香港</div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-inner">
                      <div className="text">澳門</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hotel-row">
              <div className="hotel-container-search-list">
                <div className="hotel-row">
                  <div className="col-50">
                    <div className="headTitle">熱門站點</div>
                  </div>
                </div>
                <div className="hotel-row">
                  {recommandeds.map((item) => {
                    return (
                      <div
                        key={item.label}
                        onClick={() => props.selectedHotel(item)}
                        className="item"
                      >
                        <div className="item-inner">
                          <div className="text">{item.label}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {options.map((item) => {
              return (
                <div
                  key={item.label}
                  onClick={() => props.selectedHotel(item)}
                  className="hotel-row"
                >
                  <div className="hotel-container">
                    <div className="hotel-row" style={{ paddingLeft: '16px' }}>
                      <div style={{ width: '10%' }}>
                        <div
                          className={
                            item.type === '1' ? 'hotelicon' : 'planeicon'
                          }
                        />
                      </div>
                      <div style={{ width: '84%' }}>
                        <div className="cityTitle">
                          {item.label},
                          <span className="keywords">{item.cityName}</span>
                        </div>
                        <div className="cityTitle-box">
                          {/* <p>信義區 Ι 松山區 Ι 中山區</p> */}
                          <p>{item.countryName}</p>
                          <p>{item.type === '1' ? '酒店' : '机场'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}
